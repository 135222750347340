/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from '../../../../configs/Axios';

export const getAllMeterReading = async (token: any, month?: string, district?: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (month && district) {
		const response = await axios.get(`/meter-reading/all?page=1&pageSize=15000&month=${month}&district=${district}`, config);
		return response.data;
	}

	if (month && !district) {
		const response = await axios.get(`/meter-reading/all?page=1&pageSize=15000&month=${month}`, config);
		return response.data;
	}

	if (district && !month) {
		const response = await axios.get(`/meter-reading/all?page=1&pageSize=15000&district=${district}`, config);
		return response.data;
	}

	const response = await axios.get('/meter-reading/all?page=1&pageSize=15000', config);
	return response.data;
};
