/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button, TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createNewsRequest,

} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

const ActiveStatus = [
	{
		value: false,
		label: 'False',
	},
	{
		value: true,
		label: 'True',
	},
];

export default function NewsNewForm() {
	const dispatch = useDispatch();

	const News = useSelector((state) => state.News);
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const [fileState, setFileState] = React.useState();
	const [imageError, setImageError] = React.useState(false);
	const [imageErrorText, setImageErrorText] = React.useState('');
	const [cloudinaryCall, setCloudinaryCall] = React.useState(false);

	const onEditorStateChange = (newEditorState) => {
		setEditorState(newEditorState);
	};

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		title: Yup.string().required(),
		active: Yup.string().required(),
		date: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			title: '',
			active: false,
			date: dayjs(new Date()).format('YYYY-MM-DD'),
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (News.createNews) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/settings/news/list');
		}

		if (News.error) {
			enqueueSnackbar(News.error, {
				variant: 'error',
			});
		}
	}, [News.createNews, News.error]);

	const checkMimeType = (event) => {
		if (!event.target.files[0]) {
			return false;
		}
		// getting file object
		const file = event.target.files[0];
		// get file type
		const type = 'image';

		// define message container
		let err = '';
		const types = ['image/jpg', 'image/jpeg', 'image/png'];

		// compare file type find doesn't matach
		if (types.every((t) => file.type !== t)) {
			// create error message and assign to container
			err += `${file.type} is not a supported ${type} format\n`;
			setImageErrorText(err);
			setImageError(true);
		}
		if (err !== '') {
			// if message not same old that mean has error
			event.target.value = null; // discard selected file
			return false;
		}

		setFileState(event.target.files[0]);
		setImageError(false);
		setImageErrorText('');

		return true;
	};

	const onSubmit = async (data) => {
		const getActiveState = data.active === 'true';
		const cloudinaryData = new FormData();
		cloudinaryData.append('file', fileState);
		cloudinaryData.append('upload_preset', 'hfqwwfkh');
		cloudinaryData.append('cloud_name', 'dnwfbdp56');

		setCloudinaryCall(true);
		fetch(' https://api.cloudinary.com/v1_1/dnwfbdp56/image/upload', {
			method: 'post',
			body: cloudinaryData,
		}).then(resp => resp.json()).then(JsonResp => {
			const payload = {
				...data,
				cover_image: JsonResp.url,
				active: getActiveState,
				content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
			};
			dispatch(createNewsRequest(payload, Authentication.token));
			setCloudinaryCall(false);
		}).catch(() => {
			setCloudinaryCall(false);
		});
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create News
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/settings/news/list"
					startIcon={<Iconify icon="eva:plus-fill" />}
				>
					View News
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                }}
							>
								<RHFTextField
									name="title"
									label="Title"
								/>
								<RHFTextField
									InputLabelProps={{ shrink: true }}
									name="date"
									type="date"
									label="Date"
								/>
								<TextField
									type="file"
									required
									error={imageError}
									helperText={imageErrorText}
									onChange={checkMimeType}
								/>
								<RHFSelect name="active" label="Active" placeholder="Active">
									{ActiveStatus.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</RHFSelect>
							</Box>
							<Box sx={{ pb: 4, pt: 4 }}>
								<Editor
									editorState={editorState}
									wrapperClassName="demo-wrapper"
									editorClassName="demo-editor"
									onEditorStateChange={onEditorStateChange}
								/>
							</Box>

							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								<LoadingButton
									type="submit"
									variant="contained"
									loading={cloudinaryCall || News.isCreateNewsLoading}
								>
									Create News
								</LoadingButton>
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
