/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Link,
	Stack,
	TextField,
	IconButton,
	InputAdornment,
	Alert,
} from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useFormik, Form, FormikProvider } from 'formik';
import { motion } from 'framer-motion';
import { useState } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	loginUser,
} from '../../../Store';
import Iconify from '../../../components/Iconify';

function LoginForm({
	token, error, isLoginLoading, verificationMessage,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const auth = getAuth();

	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		id: Yup.string().email('ID must be a valid email address').required('ID is required'),
		password: Yup.string().required('Password is required'),
	});

	const formik = useFormik({
		initialValues: {
			id: '',
			password: '',
			remember: true,
		},
		validationSchema: LoginSchema,
		onSubmit: (values) => {
			dispatch(loginUser(values));
			signInWithEmailAndPassword(auth, values.id, values.password).then(() => {}).catch(() => {});
			localStorage.setItem(
				'nesco_login_details',
				JSON.stringify(values),
			);
		},
	});

	const {
		errors, touched, handleSubmit, getFieldProps,
	} = formik;

	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};

	React.useEffect(() => {
		if (token !== null) {
			navigate('/dashboard/home', { replace: true });
		}
	}, [token]);

	React.useEffect(() => {
		if (verificationMessage) {
			navigate('/verify-account', { replace: true });
		}
	}, [verificationMessage]);

	return (
		<>
			<motion.div
				initial={{ opacity: 0, marginBottom: 0 }}
				animate={{
				opacity: error ? 1 : 0,
				marginBottom: error ? 20 : 0,
				}}
			>
				<Alert severity="error" variant="filled">
					{error && error}
				</Alert>
			</motion.div>

			<FormikProvider value={formik}>
				<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<TextField
							fullWidth
							autoComplete="username"
							type="email"
							label="ID"
							{...getFieldProps('id')}
							error={Boolean(touched.id && errors.id)}
							helperText={touched.id && errors.id}
						/>
						<TextField
							fullWidth
							autoComplete="current-password"
							type={showPassword ? 'text' : 'password'}
							label="Password"
							{...getFieldProps('password')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleShowPassword} edge="end">
											<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
										</IconButton>
									</InputAdornment>
								),
								}}
							error={Boolean(touched.password && errors.password)}
							helperText={touched.password && errors.password}
						/>
					</Stack>
					<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
						<Link
							component={RouterLink}
							variant="subtitle2"
							to="/forgot-password"
							underline="hover"
						>
							Forgot password?
						</Link>
					</Stack>
					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						loading={isLoginLoading}
					>
						Login
					</LoadingButton>
				</Form>
			</FormikProvider>
		</>
	);
}

const mapStateToProps = ({ Authentication }) => {
	const {
		token, error, isLoginLoading, verificationMessage,
	} = Authentication;
	return {
		token, error, isLoginLoading, verificationMessage,
	};
};

export default connect(mapStateToProps)(LoginForm);
