/* eslint-disable react/jsx-filename-extension */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	passwordResetRequest,
	// clearCache,
} from '../../../Store';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

export default function ResetPasswordForm({ onGetEmail }) {
	const dispatch = useDispatch();
	const Authentication = useSelector((state) => state.Authentication);

	const { enqueueSnackbar } = useSnackbar();

	const ResetPasswordSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required'),
	});

	const methods = useForm({
		resolver: yupResolver(ResetPasswordSchema),
		defaultValues: { email: '' },
	});

	React.useEffect(() => {
		if (Authentication.error) {
			enqueueSnackbar(Authentication.error, {
				variant: 'error',
			});
		}

		// return () => dispatch(clearCache());
	}, [Authentication.error]);

	const { handleSubmit } = methods;

	const onSubmit = async (data) => {
		onGetEmail(data.email);
		dispatch(passwordResetRequest(data));
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				<RHFTextField name="email" label="Email address" />

				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					loading={Authentication.isPasswordResetRequestLoading}
				>
					Reset Password
				</LoadingButton>
			</Stack>
		</FormProvider>
	);
}
