export type BillingState = {
	isUploadBillingFileLoading: boolean;
	uploadBillingFile: null | string;
	error?: null | object;
	getAllRecords: null | string;
	isGetAllRecordsLoading: boolean;
	approveRecord: null | string;
	isApproveRecordLoading: boolean;
	declineRecord: null | string;
	isDeclineRecordLoading: boolean;
	printBillFromArray: null | object;
	printBillFromArrayLoading: boolean;
	isGenerateBillForNescoLoading: boolean;
	generateBillForNesco: object | null;
	isGenerateBillForPUBLoading: boolean;
	generateBillForPUB: object | null;
	isGetAllDistrictsLoading: boolean;
	getAllDistricts: object | null;
	isGetMeterReadingReport: boolean;
	getMeterReadingReport: object | null;
	isViewPrintPreviewLoading: boolean;
	viewPrintPreview: object | null;
	isUpdateBillLoading: boolean;
	updateBill: object | null;
}

export const initialBillingState: BillingState = {
	getAllRecords: null,
	isGetAllRecordsLoading: false,
	approveRecord: null,
	isApproveRecordLoading: false,
	declineRecord: null,
	isDeclineRecordLoading: false,
	isUploadBillingFileLoading: false,
	uploadBillingFile: null,
	printBillFromArray: null,
	printBillFromArrayLoading: false,
	isGenerateBillForNescoLoading: false,
	generateBillForNesco: null,
	isGenerateBillForPUBLoading: false,
	generateBillForPUB: null,
	isGetAllDistrictsLoading: false,
	getAllDistricts: null,
	isGetMeterReadingReport: false,
	getMeterReadingReport: null,
	isViewPrintPreviewLoading: false,
	viewPrintPreview: null,
	isUpdateBillLoading: false,
	updateBill: null,
};
