/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import {
	Box, TableRow, TableCell, TableHead, TableSortLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
// material

// ----------------------------------------------------------------------

const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: '1px',
	height: '1px',
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
	order: PropTypes.oneOf(['asc', 'desc']),
	orderBy: PropTypes.string,
	headLabel: PropTypes.array,
	onRequestSort: PropTypes.func,
};

export default function UserListHead({
	order,
	orderBy,
	headLabel,
	onRequestSort,
}) {
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headLabel.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.alignRight ? 'right' : 'left'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							hideSortIcon
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
						</TableSortLabel>
					</TableCell>
        ))}
			</TableRow>
		</TableHead>
	);
}
