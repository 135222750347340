export default function CssBaseline() {
	return {
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					margin: 0,
					padding: 0,
					boxSizing: 'border-box',
				},
				html: {
					width: '100%',
					height: '100%',
					WebkitOverflowScrolling: 'touch',
				},
				body: {
					width: '100%',
					height: '100%',
				},
				'#root': {
					width: '100%',
					height: '100%',
				},
				input: {
					'&[type=number]': {
						MozAppearance: 'textfield',
						'&::-webkit-outer-spin-button': {
							margin: 0,
							WebkitAppearance: 'none',
						},
						'&::-webkit-inner-spin-button': {
							margin: 0,
							WebkitAppearance: 'none',
						},
					},
				},
				img: {
					display: 'block',
					maxWidth: '100%',
				},
				'textarea#header': {
					height: '30px',
				},
				'#page-wrap': {
					backgroundColor: '#fff',
					width: '650px',
					margin: '0 auto',
					p: {
						fontSize: '12px',
					},
					'.title-image': {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					},
					'.grid-container-title': {

					},
					'.grid-container': {
						display: 'grid',
						gridTemplateColumns: 'auto 35%',
						gridGap: '10px',
					},
					'.grid-container > div': {
						fontSize: '13px',
						textAlign: 'center',
					},
					'.transfer-details': {
						textAlign: 'left',
						// marginLeft: '15%',
						paddingLeft: '5px',
						border: '2px solid gray',
						borderRadius: '9px',
						fontSize: '13px',
					},
					'.item1-details': {
						fontSize: '17px',
						textAlign: 'left',
					},

					'.item1-details > p': {
						fontSize: '13px',
						textAlign: 'left',
					},

					'.grid-container2': {
						display: 'grid',
						gridTemplateColumns: '27% 50% 20%',
						gridGap: '10px',
					},

					'.grid-container2 > div': {
						textAlign: 'center',
						padding: '10px 10px 0px 10px !important',
						border: '2px solid gray',
						borderRadius: '9px',
					},

					'.bill-details': {
						textAlign: 'right',
					},
					'.bill-details p ': {
						marginTop: '0.3em',
						marginBottom: '0.3em',
						fontSize: '13px',
					},

					'.grid-container3 ': {
						display: 'grid',
						gridTemplateColumns: 'auto',
						gridGap: '30px',
						paddingTop: '10px',
					},
					'.grid-container3 > div ': {
						textAlign: 'center',
						border: '2px solid gray',
						fontSize: '15px',
						borderRadius: '9px',
					},
					'table,th,tr,td': {
						border: '2px solid gray',
						borderCollapse: 'collapse',
					},
					table: {
						alignItems: 'center',
						textAlign: 'center',
						width: '100%',
						fontSize: '14.5px',

					},
					'.table': {
						padding: '10px 0px',
					},
					'.news ': {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-evenly',
					},
					'.amount ': {
						fontSize: '10px',
						border: 'none !important',
					},
					'.amount2 ': {
						fontSize: '10px',
						display: 'flex',
						justifyContent: 'space-evenly',
					},
					'.details ': {
						display: 'flex',
						justifyContent: 'space-evenly',
					},
					'.details h5 ': {
						borderBottom: '1px solid black',
					},
					'.current-changes ': {
						padding: '0px 2px',
						textAlign: 'right',
					},
					'.current-changes p ': {
						marginTop: '0.3em',
						marginBottom: '0.3em',
						fontSize: '13px',
					},
					'.amount1 ': {
						border: '1px solid gray',
						borderRadius: '9px',
						padding: '0px 10px',
						width: '130px',
					},
					'.amount1 p ': {
						marginTop: '5px',
						marginBottom: '5px',
						fontSize: '13px',
						textAlign: 'right',
					},
					'.item-amount p ': {
						marginTop: '5px',
						marginBottom: '5px',
						fontSize: '13px',
						textAlign: 'right',
					},
					'.black-box ': {
						backgroundColor: 'black',
						height: '239px',
					},
					'.item-bill h3 ': {
						fontWeight: '900',
						marginTop: '-13px',
					},
					'.bold ': {
						fontWeight: '900',
					},
					'.notice-note ': {
						fontSize: '13px',
					},
					'.title ': {
						textAlign: 'center',
					},
					'.title p ': {
						margin: 0,
					},
					'.title-text ': {
						fontSize: '30px',
					},
					'p ': {
						margin: '5px 0px',
					},
				},
				'#page-wrap-two': {
					backgroundColor: '#fff',
					width: '650px',
					margin: '20px auto',
					p: {
						fontSize: '13px',
					},
					'.title-image': {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					},
					'.grid-container-title': {

					},
					'.grid-container': {
						display: 'grid',
						gridTemplateColumns: 'auto 35%',
						gridGap: '10px',
					},
					'.grid-container > div': {
						fontSize: '13px',
						textAlign: 'center',
					},
					'.transfer-details': {
						textAlign: 'left',
						// marginLeft: '15%',
						paddingLeft: '5px',
						border: '2px solid gray',
						borderRadius: '9px',
						fontSize: '13px',
					},
					'.item1-details': {
						fontSize: '17px',
						textAlign: 'left',
					},

					'.item1-details > p': {
						fontSize: '13px',
						textAlign: 'left',
					},

					'.grid-container2': {
						display: 'grid',
						gridTemplateColumns: '27% 50% 20%',
						gridGap: '10px',
					},

					'.grid-container2 > div': {
						textAlign: 'center',
						padding: '10px 10px 0px 10px !important',
						border: '2px solid gray',
						borderRadius: '9px',
					},

					'.bill-details': {
						textAlign: 'right',
					},
					'.bill-details p ': {
						marginTop: '0.3em',
						marginBottom: '0.3em',
						fontSize: '13px',
					},

					'.grid-container3 ': {
						display: 'grid',
						gridTemplateColumns: 'auto',
						gridGap: '30px',
						paddingTop: '10px',
					},
					'.grid-container3 > div ': {
						textAlign: 'center',
						border: '2px solid gray',
						fontSize: '15px',
						borderRadius: '9px',
					},
					'table,th,tr,td': {
						border: '2px solid gray',
						borderCollapse: 'collapse',
					},
					table: {
						alignItems: 'center',
						textAlign: 'center',
						width: '100%',
						fontSize: '14.5px',

					},
					'.table': {
						padding: '10px 0px',
					},
					'.news ': {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-evenly',
					},
					'.amount ': {
						fontSize: '10px',
						border: 'none !important',
					},
					'.amount2 ': {
						fontSize: '10px',
						display: 'flex',
						justifyContent: 'space-evenly',
					},
					'.details ': {
						display: 'flex',
						justifyContent: 'space-evenly',
					},
					'.details h5 ': {
						borderBottom: '1px solid black',
					},
					'.current-changes ': {
						padding: '0px 2px',
						textAlign: 'right',
					},
					'.current-changes p ': {
						marginTop: '0.3em',
						marginBottom: '0.3em',
						fontSize: '13px',
					},
					'.amount1 ': {
						border: '1px solid gray',
						borderRadius: '9px',
						padding: '0px 10px',
						width: '130px',
					},
					'.amount1 p ': {
						marginTop: '5px',
						marginBottom: '5px',
						fontSize: '13px',
						textAlign: 'right',
					},
					'.item-amount p ': {
						marginTop: '5px',
						marginBottom: '5px',
						fontSize: '13px',
						textAlign: 'right',
					},
					'.black-box ': {
						backgroundColor: 'black',
						height: '239px',
					},
					'.item-bill h3 ': {
						fontWeight: '900',
						marginTop: '-13px',
					},
					'.bold ': {
						fontWeight: '900',
					},
					'.notice-note ': {
						fontSize: '13px',
					},
					'.title ': {
						textAlign: 'center',
					},
					'.title p ': {
						margin: 0,
					},
					'.title-text ': {
						fontSize: '28px',
					},
					'p ': {
						margin: '5px 0px',
					},
				},
				'#statement-table': {
					p: {
						padding: 0,
						margin: 0,
					},
					'.table-container': {
						width: '1350px',
						margin: 'auto',
					},
					'.table-header': {
						width: '100%',
						maxHeight: '100px',
						border: '2px solid black',
						paddingTop: '5px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					},
					'.header-title': {
						fontSize: '20px',
						fontWeight: '700',
					},
					'.header-subtitle': {
						fontWeight: '600',
						fontSize: '14px',
						marginTop: '8px',
					},
					'.sub-header': {
						width: '100%',
						paddingLeft: '1%',
						border: '2px solid black',
						height: '200px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					},
					'.sub-header-first': {
						flex: '1',
						paddingTop: '20px',
					},
					'.sub-header-text': {
						fontSize: '13px',
						fontWeight: '700',
						marginBottom: '8px',
					},
					'.sub-header-second': {
						flex: '1',
						paddingTop: '20px',
						display: 'flex',
						justifyContent: 'flex-end',
						maxHeight: '100%',
					},
					'.sub-header-second-inner': {
						height: '100%',
						width: '500px',
					},
					'.header-table': {
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						border: '1px solid black',
						width: '170px',
						height: '50px',
					},
					'.item': {
						paddingLeft: '2px',
						border: '1px solid black',
						display: 'flex',
						alignItems: 'center',
						height: '100%',
						fontSize: '13px',
						fontWeight: '700',
					},
					'.header-table2': {
						display: 'grid',
						gridTemplateColumns: '1fr',
						height: '50px',
						width: '120px',
						border: '1px solid black',
					},
					'.table': {
						width: '100%',
						border: '1px solid black',
						borderSpacing: '0',
					},
					th: {
						border: '1px solid black',
					},
					td: {
						border: '1px solid black',
						textAlign: 'center',
					},
				},
			},
		},
	};
}
