/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Box,
} from '@mui/material';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
	getOpenFaultReportRequest,
	clearSubmitFaultCacheRequest,
} from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'title', label: 'Title', alignRight: false },
	{ id: 'description', label: 'Description', alignRight: false },
	{ id: 'comment', label: 'Comment', alignRight: false },
	{ id: 'status', label: 'Status', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function Reporting({
	token,
	isgetOpenFaultReportLoading,
	getOpenFaultReport,
	getOpenFaultReportRequest,
	clearSubmitFaultCacheRequest,
}) {
	const [page, setPage] = React.useState(0);

	const [order, setOrder] = React.useState('asc');

	const [selected, setSelected] = React.useState([]);

	const [orderBy, setOrderBy] = React.useState('title');

	const [filterName, setFilterName] = React.useState('');

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	React.useEffect(() => {
		if (token) {
			getOpenFaultReportRequest(token, 'open');
			clearSubmitFaultCacheRequest();
		}
	}, [token]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getOpenFaultReport?.users?.map((n) => n.title);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOpenFaultReport?.users?.length ?? 0) : 0;

	const filteredReports = getOpenFaultReport?.users && applySortFilter(getOpenFaultReport?.users, getComparator(order, orderBy), filterName);

	const isReportNotFound = filteredReports?.length === 0;

	const loading = isgetOpenFaultReportLoading;

	return (
		<Page title="Opened Faults">
			<Container>
				{
					(getOpenFaultReport?.users) && (
						<Card sx={{ pb: 2 }}>
							<UserListToolbar
								numSelected={selected.length}
								filterName={filterName}
								onFilterName={handleFilterByName}
							/>
							{
								loading ? <Loader />
								: getOpenFaultReport?.users ? (
									<Box>
										<TableContainer sx={{ minWidth: 800 }}>
											<Table>
												<UserListHead
													order={order}
													orderBy={orderBy}
													headLabel={TABLE_HEAD}
													rowCount={getOpenFaultReport?.users?.length}
													numSelected={selected.length}
													onRequestSort={handleRequestSort}
													onSelectAllClick={handleSelectAllClick}
												/>
												<TableBody>
													{filteredReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
										const {
											id,
											title,
											description,
											comment,
											status,
										} = row;
										const isItemSelected = selected.indexOf(title) !== -1;

										return (
											<TableRow
												hover
												key={id}
												tabIndex={-1}
												role="checkbox"
												selected={isItemSelected}
												aria-checked={isItemSelected}
											>
												<TableCell align="left">
													<Stack direction="row" alignItems="center" spacing={2}>
														<Typography variant="subtitle2" noWrap>
															{title}
														</Typography>
													</Stack>
												</TableCell>
												<TableCell align="left">{description}</TableCell>
												<TableCell align="left">{comment}</TableCell>
												<TableCell align="left">{status}</TableCell>
												<TableCell align="right">
													<LoadingButton
														variant="outlined"
														color="info"
														component={RouterLink}
														to={`/dashboard/customer-support/fault-reports/${id}/`}
													>
														View Report
													</LoadingButton>
												</TableCell>
											</TableRow>
										);
									})}
													{emptyRows > 0 && (
													<TableRow style={{ height: 53 * emptyRows }}>
														<TableCell colSpan={12} />
													</TableRow>
										)}
												</TableBody>
												{isReportNotFound && (
												<TableBody>
													<TableRow>
														<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
															<SearchNotFound searchQuery={filterName} />
														</TableCell>
													</TableRow>
												</TableBody>
									)}
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											component="div"
											count={getOpenFaultReport?.users?.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</Box>
								) : (
									<Box sx={{ display: 'flex', justifyContent: 'center' }}>
										<Typography variant="h5" gutterBottom>
											No Users Found
										</Typography>
									</Box>
								)
							}

						</Card>
					)
				}
			</Container>
		</Page>
	);
}

const mapStateToProps = ({
	Authentication, Control,
}) => {
	const { token } = Authentication;
	const {
		isgetOpenFaultReportLoading,
		getOpenFaultReport,

	} = Control;
	return {
		token,
		isgetOpenFaultReportLoading,
		getOpenFaultReport,
	};
};

export default connect(mapStateToProps, { getOpenFaultReportRequest, clearSubmitFaultCacheRequest })(Reporting);
