/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getAllRecords = async (token: any, day?: any, accountNumber?: any, receiptNumber?: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (day) {
		const response = await axios.get(`/billing/record-list?date=${day}&page=1&pageSize=2000`, config);
		return response.data;
	}

	if (accountNumber.length) {
		const response = await axios.get(
			`/billing/record-list?account_number=${accountNumber}&page=1&pageSize=2000`,
			config,
		);
		return response.data;
	}

	if (receiptNumber.length) {
		const response = await axios.get(
			`/billing/record-list?receipt_no=${receiptNumber}&page=1&pageSize=2000`,
			config,
		);
		return response.data;
	}

	const response = await axios.get('/billing/record-list?page=1&pageSize=3000', config);
	return response.data;
};
