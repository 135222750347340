/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button, TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import {
	EditorState,
	convertToRaw,
	convertFromHTML,
	ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	editNewsRequest,
} from '../../../Store';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

const ActiveStatus = [
	{
		value: false,
		label: 'False',
	},
	{
		value: true,
		label: 'True',
	},
];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function NewsEditForm({
	view,
	id,
	details,
	handleCloseEditDialog,
	isEditNewsLoading,
}) {
	const dispatch = useDispatch();

	const News = useSelector((state) => state.News);
	const Authentication = useSelector((state) => state.Authentication);

	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const [fileState, setFileState] = React.useState();
	const [imageError, setImageError] = React.useState(false);
	const [imageErrorText, setImageErrorText] = React.useState('');
	const [cloudinaryCall, setCloudinaryCall] = React.useState(false);

	React.useEffect(() => {
		if (details) {
			const blocksFromHTML = convertFromHTML(details.content);
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap,
			);
			setEditorState(EditorState.createWithContent(state));
		}
	}, [details]);

	const onEditorStateChange = (newEditorState) => {
		setEditorState(newEditorState);
	};

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		title: Yup.string().required(),
		active: Yup.string().required(),
		date: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			title: details?.title,
			active: details?.active,
			date: dayjs(details?.date).format('YYYY-MM-DD'),
		}),
		[details],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (News.editNews) {
			reset();
			enqueueSnackbar('Edit success!');
		}

		if (News.error) {
			enqueueSnackbar(News.error, {
				variant: 'error',
			});
		}
	}, [News.editNews, News.error]);

	const checkMimeType = (event) => {
		if (!event.target.files[0]) {
			return false;
		}
		// getting file object
		const file = event.target.files[0];
		// get file type
		const type = 'image';

		// define message container
		let err = '';
		const types = ['image/jpg', 'image/jpeg', 'image/png'];

		// compare file type find doesn't matach
		if (types.every((t) => file.type !== t)) {
			// create error message and assign to container
			err += `${file.type} is not a supported ${type} format\n`;
			setImageErrorText(err);
			setImageError(true);
		}
		if (err !== '') {
			// if message not same old that mean has error
			event.target.value = null; // discard selected file
			return false;
		}

		setFileState(event.target.files[0]);
		setImageError(false);
		setImageErrorText('');

		return true;
	};

	const getEditorState = () => draftToHtml(convertToRaw(editorState.getCurrentContent()));

	const onSubmit = async (data) => {
		const getActiveState = data.active === 'true';

		if ((fileState && typeof fileState === 'object') || fileState instanceof Array) {
			const cloudinaryData = new FormData();
			cloudinaryData.append('file', fileState);
			cloudinaryData.append('upload_preset', 'hfqwwfkh');
			cloudinaryData.append('cloud_name', 'dnwfbdp56');

			setCloudinaryCall(true);

			return fetch(' https://api.cloudinary.com/v1_1/dnwfbdp56/image/upload', {
				method: 'post',
				body: cloudinaryData,
			}).then(resp => resp.json()).then(JsonResp => {
				const payload = {
					...data,
					cover_image: JsonResp.url,
					active: getActiveState,
					content: getEditorState(),
				};
				dispatch(editNewsRequest(Authentication.token, id, payload));
				setCloudinaryCall(false);
			}).catch(() => {
				setCloudinaryCall(false);
			});
		}

		const payload = {
			...data,
			cover_image: details.cover_image,
			active: getActiveState,
			content: getEditorState(),
		};

		return dispatch(editNewsRequest(Authentication.token, id, payload));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Edit
					{' '}
					{details?.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update and submit your changes to save
					</DialogContentText>
				</DialogContent>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Card sx={{ p: 3 }}>
								<Box
									sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                }}
								>
									<RHFTextField
										name="title"
										label="Title"
									/>
									<RHFTextField
										InputLabelProps={{ shrink: true }}
										name="date"
										type="date"
										label="Date"
									/>
									<TextField
										type="file"
										error={imageError}
										helperText={imageErrorText}
										onChange={checkMimeType}
									/>
									<RHFSelect name="active" label="Active" placeholder="Active">
										{ActiveStatus.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
									))}
									</RHFSelect>
								</Box>
								<Box sx={{ pb: 4, pt: 4 }}>
									<Editor
										editorState={editorState}
										wrapperClassName="demo-wrapper"
										editorClassName="demo-editor"
										onEditorStateChange={onEditorStateChange}
									/>
								</Box>

								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											loading={cloudinaryCall || isEditNewsLoading}
										>
											Edit News
										</LoadingButton>
									</DialogActions>
								</Stack>
							</Card>
						</Grid>
					</Grid>
				</FormProvider>
			</Box>
		</Dialog>
	);
}
