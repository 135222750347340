/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Box,
	Tab,
	Tabs,
	TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { getAllMeterReadingRequest } from '../Store';
import Label from '../components/Label';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'meter_number', label: 'Meter Number', alignRight: false },
	{ id: 'account_number', label: 'Account Number', alignRight: false },
	{ id: 'reading_mode', label: 'Reading Mode', alignRight: false },
	{ id: 'units', label: 'Units', alignRight: false },
	{ id: 'reader', label: 'Reader', alignRight: false },
	{ id: 'low_volt_reading', label: 'Low Volt Reading', alignRight: false },
	{ id: 'month', label: 'Month', alignRight: false },
	{ id: 'year', label: 'Year', alignRight: false },
	{ id: 'substation', label: 'Substation', alignRight: false },
	{ id: 'feeder', label: 'Feader', alignRight: false },
	{ id: 'remarks', label: 'Remarks', alignRight: false },
	{ id: 'status', label: 'Status', alignRight: false },
	{ id: 'approved', label: 'Approved', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user?.account_number?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

// id(pin):2
// reader_id(pin):3
// customer_id(pin):9556
// meter_number(pin):"00302500"
// account_number(pin):"NCS0023"
// low_volt_reading(pin):49007
// month(pin):0
// feeder(pin):"North Residential"
// reading_mode(pin):null
// substation(pin):"Corona school"
// max_demand(pin):null
// remarks(pin):"Has light"
// meter_status(pin):"okay"
// account_status(pin):"active"
// approved(pin):false
// approver_id(pin):null
// createdAt(pin):"2022-10-21T10:17:46.000Z"
// updatedAt(pin):"2022-10-21T10:17:46.000Z"
// deletedAt(pin):null

function TableCard({
	pagination,
	getAllMeterReading,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
}) {
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getAllMeterReading?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagination.pageSize ?? 0) : 0;

	const filteredUsers = getAllMeterReading && applySortFilter(getAllMeterReading, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	// 	prevPage(pin):null
	// currentPage(pin):1
	// nextPage(pin):null
	// pageTotal(pin):1
	// pageSize(pin):29

	// "pagination":{"prevPage":null,"currentPage":1,"nextPage":null,"pageTotal":1,"pageSize":29}

	return (
		<Box>
			<UserListToolbar placeholder="Search Account Number" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
			<TableContainer sx={{ minWidth: 800 }}>
				<Table>
					<UserListHead
						order={order}
						orderBy={orderBy}
						headLabel={TABLE_HEAD}
						rowCount={getAllMeterReading?.length}
						numSelected={selected.length}
						onRequestSort={handleRequestSort}
						onSelectAllClick={handleSelectAllClick}
					/>
					<TableBody>
						{
							filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row) => {
									const {
										id,
										meter_number,
										account_number,
										reading_mode,
										units,
										reader,
										low_volt_reading,
										year,
										month,
										substation,
										feeder,
										remarks,
										status,
										approved,
										reader_id,
										customer_id,
										} = row;
									return (
										<TableRow
											hover
											key={id}
											tabIndex={-1}
											role="checkbox"
										>
											<TableCell align="left">
												<Typography variant="subtitle2">
													{meter_number}
												</Typography>
											</TableCell>
											<TableCell align="left">{account_number}</TableCell>
											<TableCell align="left">{reading_mode}</TableCell>
											<TableCell align="left">{units}</TableCell>
											<TableCell align="left">{reader?.fullname}</TableCell>
											<TableCell align="left">{low_volt_reading}</TableCell>
											<TableCell align="left">{month}</TableCell>
											<TableCell align="left">{year}</TableCell>
											<TableCell align="left">{substation}</TableCell>
											<TableCell align="left">{feeder}</TableCell>
											<TableCell align="left">{remarks}</TableCell>
											<TableCell align="left">{status ? 'Yes' : 'No'}</TableCell>
											<TableCell align="left">
												<Label variant="ghost" color={!approved ? 'error' : 'success'}>
													{!approved ? 'No' : 'Yes'}
												</Label>
											</TableCell>

											<TableCell align="right">
												{
													!approved && (
													<LoadingButton
														variant="contained"
														component={RouterLink}
														to={`/dashboard/billing/meter-reading/${reader_id}/${customer_id}`}
													>
														View
													</LoadingButton>
													)
												}
											</TableCell>
										</TableRow>
									);
							})
						}
						{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={12} />
						</TableRow>
									)}
					</TableBody>
					{isUserNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
								<SearchNotFound searchQuery={filterName} />
							</TableCell>
						</TableRow>
					</TableBody>
								)}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[15, 30, 50]}
				component="div"
				count={getAllMeterReading?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newpage) => handleChangePage(event, newpage)}
				onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
			/>
		</Box>

	);
}

// Math.ceil(data.length / PER_PAGE);

// total(pin):28
// prevPage(pin):null
// currentPage(pin):1
// nextPage(pin):null
// pageTotal(pin):1
// pageSize(pin):28

function TabPanel(props) {
	const {
		children, value, index, ...other
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
			<Box sx={{ p: 3 }}>
				<Typography>{children}</Typography>
			</Box>
      )}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function MeterReading({
	token,
	getAllMeterReading,
	isGetAllMeterReadingLoading,
	getAllMeterReadingRequest,
}) {
	const [value, setValue] = React.useState(0);
	const [district, setDistrict] = React.useState('');
	const [dateValue, setDateValue] = React.useState(dayjs());
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);

	React.useEffect(() => {
		if (token) {
			getAllMeterReadingRequest(token);
		}
	}, [token]);

	const loading = isGetAllMeterReadingLoading;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Page title="Meter Reading">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>
						All Meter Readings
					</Typography>
					<Stack direction="row">
						<TextField
							id="district-field"
							label="District"
							shrink
							placeholder="Enter District"
							value={district}
							onChange={(event) => {
								getAllMeterReadingRequest(token, dayjs(dateValue).format('MMMM'), event.target.value);
								setDistrict(event.target.value);
							}}
							sx={{ mr: 2 }}
						/>
						<DesktopDatePicker
							label="Select Month"
							disableMaskedInput
							inputFormat="MMMM"
							views={['month']}
							disableFuture
							openTo="month"
							value={dateValue}
							onChange={(newValue) => {
							getAllMeterReadingRequest(token, dayjs(newValue).format('MMMM'), district);
							setDateValue(newValue);
						}}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{ ...params.inputProps, readOnly: true }}
									sx={{
									'& .MuiInputBase-input': {
										caretColor: 'transparent',
									},
								}}
								/>
						)}
						/>
					</Stack>

				</Stack>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleChange} aria-label="meter reading">
							<Tab label="All" {...a11yProps(0)} />
							<Tab label="Approved" {...a11yProps(1)} />
							<Tab label="Pending" {...a11yProps(2)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<Card sx={{ pb: 2, pt: 2 }}>
							{
								loading ? <Loader />
								: getAllMeterReading ? (
									<TableCard
										pagination={getAllMeterReading.pagination}
										getAllMeterReading={getAllMeterReading.users}
										handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
										page={page}
										rowsPerPage={rowsPerPage}
									/>
										) : (
											<Box sx={{ display: 'flex', justifyContent: 'center' }}>
												<Typography variant="h5" gutterBottom>
													No Reading Found
												</Typography>
											</Box>
										)
							}
						</Card>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Card sx={{ pb: 2, pt: 2 }}>
							{
								loading ? <Loader />
								: getAllMeterReading ? (
									<TableCard
										pagination={getAllMeterReading.pagination}
										getAllMeterReading={getAllMeterReading.users.filter(reading => reading.approved === true)}
										handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
										page={page}
										rowsPerPage={rowsPerPage}
									/>
										) : (
											<Box sx={{ display: 'flex', justifyContent: 'center' }}>
												<Typography variant="h5" gutterBottom>
													No Reading Found
												</Typography>
											</Box>
											)
							}
						</Card>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<Card sx={{ pb: 2, pt: 2 }}>
							{
								loading ? <Loader />
								: getAllMeterReading ? (
									<TableCard
										pagination={getAllMeterReading.pagination}
										getAllMeterReading={getAllMeterReading.users.filter(reading => reading.approved === false)}
										handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
										page={page}
										rowsPerPage={rowsPerPage}
									/>
										) : (
											<Box sx={{ display: 'flex', justifyContent: 'center' }}>
												<Typography variant="h5" gutterBottom>
													No Reading Found
												</Typography>
											</Box>
											)
							}
						</Card>
					</TabPanel>
				</Box>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, MeterReading }) => {
	const { token } = Authentication;
	const {
		isGetAllMeterReadingLoading, getAllMeterReading,
	} = MeterReading;
	return {
		token,
		isGetAllMeterReadingLoading,
		getAllMeterReading,
	};
};

export default connect(mapStateToProps, { getAllMeterReadingRequest })(MeterReading);
