export type RolesAndPermissionsState = {
	isGetRolesLoading: boolean;
	getRoles: any;
	isGetPermisionsLoading: boolean;
	getPermisions: any;
	isCreateRoleLoading: boolean;
	createRole: any;
	error?: null | object;
}

export const initialRolesAndPermissionsState: RolesAndPermissionsState = {
	isGetRolesLoading: false,
	getRoles: null,
	isGetPermisionsLoading: false,
	getPermisions: null,
	isCreateRoleLoading: false,
	createRole: null,
};
