/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	Container,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { API_BASE_URL } from '../Environment';
import Page from '../components/Page';

function PrepaidMeteringReport({ token }) {
	const [downloadLoading, setDownloadLoading] = useState(false);
	const downloaLink = `${API_BASE_URL}/analytics/prepaid-metering-report`;

	const handleDownloadVendingSalesReport = async () => {
		setDownloadLoading(true);
		const result = await fetch(downloaLink, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		const blob = await result.blob();
		const href = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.download = 'prepaid-metering-report.xlsx';
		link.href = href;
		document.body.appendChild(link);
		setDownloadLoading(false);
		link.click();
	};

	return (
		<Page title="Prepaid Metering Report">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
					<Typography variant="h4" gutterBottom>
						Download Prepaid Metering Report
					</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="end" mb={3}>
					<Box ml={2}>
						<LoadingButton
							onClick={handleDownloadVendingSalesReport}
							variant="outlined"
							loading={downloadLoading}
							color="primary"
						>
							Download Prepaid Metering Report
						</LoadingButton>
					</Box>

				</Stack>
				<Divider />
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication }) => {
	const { token } = Authentication;
	return { token };
};

export default connect(mapStateToProps)(PrepaidMeteringReport);
