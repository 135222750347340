/* eslint-disable camelcase */
import axios from '../../../../configs/Axios';

export const getAllReportTitles = async (token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get('/report/title', config);

	return response.data;
};
