/* eslint-disable no-multi-assign */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import {
	Card,
	Table,
	Stack,
	Button,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	FormGroup,
	Select,
	MenuItem,
	Box,
	TextField,
} from '@mui/material';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { getRolesRequest, getAllUsersRequest } from '../Store';
import Iconify from '../components/Iconify';
import Label from '../components/Label';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'fullname', label: 'Name', alignRight: false },
	{ id: 'email', label: 'Email', alignRight: false },
	{ id: 'phone', label: 'Phone', alignRight: false },
	{ id: 'gender', label: 'Gender', alignRight: false },
	{ id: 'role', label: 'Role', alignRight: false },
	{ id: 'isActive', label: 'Active Status', alignRight: false },
	{ id: 'isSuspended', label: 'Suspension Status', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => {
			const searched = _user.fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1
			|| _user.phone.toLowerCase().indexOf(query.toLowerCase()) !== -1
			|| _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1;
			return searched;
		});
	}
	return stabilizedThis.map((el) => el[0]);
}

function User({
	token,
	user,
	getAllUsers,
	isGetAllUsersLoading,
	getAllUsersRequest,
	getRoles,
	isGetRolesLoading,
	getRolesRequest,
}) {
	const [page, setPage] = React.useState(0);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [roles, setRoles] = React.useState([]);
	const [role, setRole] = React.useState('');
	const [queryField, setQueryField] = React.useState('fullname');
	const [searchFields, setSearchedFields] = React.useState('');

	React.useEffect(() => {
		if (token) {
			getRolesRequest(token);
			getAllUsersRequest(token, role, searchFields, queryField);
		}
	}, [token]);

	React.useEffect(() => {
		if (getRoles) {
			getRoles.length && setRoles([...getRoles]);
			// getRoles.length && setRole(getRoles[0].name);
		}
	}, [getRoles]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getAllUsers?.map((n) => n.fullname);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getAllUsers?.length ?? 0) : 0;

	const filteredUsers = getAllUsers && applySortFilter(getAllUsers, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const removeSpecialCharacter = (string) => (string ? string.replace(/[^a-zA-Z ]/g, ' ') : string);
	const capitalizeFirstLetter = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : string);

	const loading = isGetAllUsersLoading || isGetRolesLoading;

	const handleChange = (event) => {
		setRole(event.target.value);
		getAllUsersRequest(token, event.target.value, searchFields, queryField);
	};

	const selectUser = () => (
		<FormGroup row sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
			<Typography sx={{ mr: 2 }} variant="subtitle2" gutterBottom>
				Select a role to filter users
			</Typography>
			<Select
				shrink="true"
				disabled={loading}
				value={role}
				onChange={handleChange}
			>
				{
					roles?.map((r) => (
						<MenuItem key={r.name} value={r.name}>
							{r.name.replace(/[^a-zA-Z ]/g, ' ')}
						</MenuItem>
					))
				}
			</Select>
		</FormGroup>
	);
	return (
		<Page title="User">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box>
						<Typography variant="h4" gutterBottom>
							View All Users
						</Typography>
					</Box>
					<Stack direction="row">
						<FormGroup row sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
							<Typography sx={{ mr: 2 }} variant="subtitle2" gutterBottom>
								Select a control to filter
							</Typography>
							<Select
								shrink="true"
								disabled={loading}
								value={queryField}
								onChange={e => setQueryField(e.target.value)}
								sx={{ mr: 2 }}
							>
								<MenuItem value="fullname">
									Name
								</MenuItem>
								<MenuItem value="phone">
									Phone Number
								</MenuItem>
								<MenuItem value="account_id">
									Account Number
								</MenuItem>
							</Select>
						</FormGroup>
						<TextField
							id="district-field"
							label="Filter Users"
							shrink
							placeholder="Filter..."
							value={searchFields}
							onChange={(event) => {
								getAllUsersRequest(token, role, event.target.value, queryField);
								setSearchedFields(event.target.value);
							}}
							sx={{ mr: 2 }}
						/>
						{
							user?.role === 'superadmin'
								&& (
								<Button
									variant="contained"
									component={RouterLink}
									to="/dashboard/admin/user-management/create"
									startIcon={<Iconify icon="eva:plus-fill" />}
								>
									Add New User
								</Button>
								)

						}

					</Stack>
				</Stack>
				<Card sx={{ pb: 2 }}>
					<UserListToolbar
						loading={loading}
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
						SelectUser={selectUser}
						selectUserFlag
					/>
					{
						loading ? <Loader />
						: getAllUsers ? (
							<Box>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											rowCount={getAllUsers?.length}
											numSelected={selected.length}
											onRequestSort={handleRequestSort}
											onSelectAllClick={handleSelectAllClick}
										/>
										<TableBody>
											{
											filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
												const {
													id,
													fullname,
													gender,
													email,
													phone,
													role,
													isActive,
													isSuspended,
												} = row;
												const isItemSelected = selected.indexOf(fullname) !== -1;

												return (
													<TableRow
														hover
														key={id}
														tabIndex={-1}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														selected={isItemSelected}
														aria-checked={isItemSelected}
													>
														<TableCell align="left">
															<Typography variant="subtitle2" noWrap>
																{capitalizeFirstLetter(fullname)}
															</Typography>
														</TableCell>
														<TableCell align="left">{email}</TableCell>
														<TableCell align="left">{phone}</TableCell>
														<TableCell align="left">{capitalizeFirstLetter(gender)}</TableCell>
														<TableCell align="left">{removeSpecialCharacter(capitalizeFirstLetter(role))}</TableCell>
														<TableCell align="left">
															<Label
																variant="ghost"
																color={isActive ? 'success' : 'error'}
															>
																{isActive ? 'Active' : 'Not Active'}
															</Label>
														</TableCell>
														<TableCell align="left">
															<Label
																variant="ghost"
																color={isSuspended ? 'error' : 'success'}
															>
																{isSuspended ? 'Suspended' : 'Not Suspended'}
															</Label>
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																component={RouterLink}
																to={`/dashboard/admin/user-management/${id}`}
															>
																View
															</Button>
														</TableCell>
													</TableRow>
												);
											})
										}
											{emptyRows > 0 && (
											<TableRow style={{ height: 53 * emptyRows }}>
												<TableCell colSpan={12} />
											</TableRow>
										)}
										</TableBody>

										{isUserNotFound && (
										<TableBody>
											<TableRow>
												<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
													<SearchNotFound searchQuery={filterName} />
												</TableCell>
											</TableRow>
										</TableBody>
									)}
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={getAllUsers?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Users Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, UserManagement, RolesPermissions }) => {
	const { token, user } = Authentication;
	const { isGetRolesLoading, getRoles } = RolesPermissions;
	const { isGetAllUsersLoading, getAllUsers } = UserManagement;

	return {
		token,
		user,
		isGetAllUsersLoading,
		getAllUsers,
		isGetRolesLoading,
		getRoles,
	};
};

export default connect(mapStateToProps, { getRolesRequest, getAllUsersRequest })(User);
