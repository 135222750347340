/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type UserData = {
	reportId: number,
	status: string
};

export const updateReportStatus = async (userData: UserData, token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.put('/report/update-status', userData, config);

	return response.data;
};
