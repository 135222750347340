/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const uploadFile = async (userData: any, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.post('/billing/create', userData, config);

	return response.data;
};
