/* eslint-disable react/jsx-filename-extension */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	IconButton,
	InputAdornment,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	resetPassword,
	clearCache,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

export default function NewPassword() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const Authentication = useSelector((state) => state.Authentication);

	const [showPassword, setShowPassword] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const ResetPasswordSchema = Yup.object().shape({
		password: Yup.string().required('Password is required').min(6, 'Too Short!').max(20, 'Too Long!'),
	});

	const methods = useForm({
		resolver: yupResolver(ResetPasswordSchema),
		defaultValues: { email: Authentication.confirmPasswordRequestOTP?.data?.email },
	});

	React.useEffect(() => {
		if (Authentication.resetPassword) {
			enqueueSnackbar('Password updated', {
				variant: 'success',
			});
			navigate('/login');
		}

		if (Authentication.error) {
			enqueueSnackbar(Authentication.error, {
				variant: 'error',
			});
		}

		return () => dispatch(clearCache());
	}, [Authentication.error, Authentication.resetPassword]);

	const { handleSubmit } = methods;

	const onSubmit = async (data) => {
		dispatch(resetPassword({ email: Authentication.confirmPasswordRequestOTP?.data?.email, ...data }));
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				{/* <RHFTextField name="email" label="Email address" /> */}

				<RHFTextField
					name="password"
					label="Password"
					type={showPassword ? 'text' : 'password'}
					InputProps={{
            endAdornment: (
	<InputAdornment position="end">
		<IconButton
			onClick={() => setShowPassword(!showPassword)}
			edge="end"
		>
			<Iconify
				icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
			/>
		</IconButton>
	</InputAdornment>
            ),
          }}
				/>

				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					loading={Authentication.isResetPasswordLoading}
				>
					Change Password
				</LoadingButton>
			</Stack>
		</FormProvider>
	);
}
