/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getSalesReport = async (token: any, query: {month, year, startDate, endDate}):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			month: query.month,
			year: query.year,
			startDate: query.startDate,
			endDate: query.endDate,
		},
	};

	const response = await axios.get('/analytics/sales-report', config);
	return response.data;
};
