/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type UserData = {
	email: string,
}

export const passwordResetRequest = async (userData: UserData):
Promise<any> => {
	const response = await axios.post('/auth/password-request', userData);

	return response.data;
};
