/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import {
	Navigate, useRoutes,
} from 'react-router-dom';

import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import DashboardLayout from './layouts/dashboard';
import BillPayment from './pages/BillPayment';
import BillPaymentReceipt from './pages/BillReceipt';
import BroadcastCreate from './pages/BroadcastCreate';
import BroadcastList from './pages/BroadcastList';
import SingleBroadcast from './pages/BroadcastSingle';
import Chat from './pages/Chat';
import AccountStatement from './pages/ConsumerAccountStatement';
import CreateConsumer from './pages/CreateConsumer';
import CreateFeedersAndSubstation from './pages/CreateFeedersAndSubstation';
import CreateRole from './pages/CreateRole';
import DebtStatus from './pages/DebtStatus';
import DisconnectedList from './pages/DisconnectedList';
import Feeders from './pages/Feeders';
import GetAllRecords from './pages/GetAllRecords';
import Home from './pages/Home';
import IssueCreditToken from './pages/IssueCreditToken';
import Logs from './pages/Logs';
import MeterReading from './pages/MeterReading';
import NescoConsumerList from './pages/NescoConsumerList';
import NewsCreate from './pages/NewsCreate';
import NewsList from './pages/NewsList';
import NewsSingle from './pages/NewsSingle';
import PUBConsumerList from './pages/PUBConsumerList';
import NotFound from './pages/Page404';
import AddNewMeter from './pages/Prepaid/AddNewMeter';
import GetMeters from './pages/Prepaid/GetMeters';
import IssueKCT from './pages/Prepaid/IssueKCT';
import IssueTamperCodeToken from './pages/Prepaid/IssueTamperCodeToken';
import TokenPurchaseHistory from './pages/Prepaid/TokenPurchaseHistory';
import PrepaidMeteringReport from './pages/PrepaidMeteringReport';
import PreviewBills from './pages/PreviewBills';
import PrintPreview from './pages/PrintPreview';
import PrintReceiptFromArray from './pages/PrintReceiptFromArray';
import RecordBillPayment from './pages/RecordBillPayment';
import ReportTitleCreate from './pages/ReportTitleCreate';
import ReportTitleList from './pages/ReportTitleList';
import Reporting from './pages/Reporting';
import RolesList from './pages/RolesList';
import SalesReport from './pages/SalesReport';
import SingleConsumer from './pages/SingleConsumer';
import SingleMeterReading from './pages/SingleMeterReading';
import SingleReport from './pages/SingleReport';
import SingleUser from './pages/SingleUser';
import SubmitReport from './pages/SubmitReport';
import Substations from './pages/Substations';
import Tariffs from './pages/Tariffs';
import TransactionsList from './pages/TransactionsList';
import UncompletedTransactions from './pages/UncompletedTransactions';
import UserCreate from './pages/UserCreate';
import UserList from './pages/UserList';
import VendingSalesReport from './pages/VendingSalesReport';
import CompleteRegisteration from './pages/auth/CompleteRegisteration';
import Login from './pages/auth/Login';
import NewPassword from './pages/auth/NewPassword';
import Register from './pages/auth/Register';
import ResetPassword from './pages/auth/ResetPassword';
import VerifyAccount from './pages/auth/VerifyAccount';
import VerifyCode from './pages/auth/VerifyCode';

export function Router() {
	return useRoutes([
		{
			path: '/',
			element: <LogoOnlyLayout />,
			children: [
				{ path: '/', element: <Navigate to="/login" /> },
				{ path: 'login', element: <Login /> },
				{ path: 'forgot-password', element: <ResetPassword /> },
				{ path: 'confirm-reset-otp', element: <VerifyCode /> },
				{ path: 'new-password', element: <NewPassword /> },
				{ path: 'register', element: <Register /> },
				{ path: 'complete-registeration', element: <CompleteRegisteration /> },
				{ path: 'verify-account', element: <VerifyAccount /> },
				{ path: '404', element: <NotFound /> },
				{ path: '*', element: <Navigate to="/404" /> },
			],
		},
		{
			path: '/dashboard',
			element: <DashboardLayout />,
			children: [
				{ element: <Navigate to="/dashboard/home" replace />, index: true },
				{ path: 'home', element: <Home /> },
				{
					path: 'admin',
					children: [
						{ element: <Navigate to="/dashboard/admin/user-management" replace />, index: true },
						{
							path: 'user-management',
							children: [
								{ element: <Navigate to="/dashboard/admin/user-management/list" replace />, index: true },
								{ path: 'list', element: <UserList /> },
								{ path: 'create', element: <UserCreate /> },
								{ path: ':userId', element: <SingleUser /> },
								{ path: ':userId/bill-payment/:billId', element: <BillPayment /> },
							],
						},
						{
							path: 'notification',
							children: [
								{ element: <Navigate to="/dashboard/admin/notification/list" replace />, index: true },
								{ path: 'list', element: <BroadcastList /> },
								{ path: 'create', element: <BroadcastCreate /> },
								{ path: ':broadcastId', element: <SingleBroadcast /> },
							],
						},
						{
							path: 'logs',
							children: [
								{ element: <Navigate to="/dashboard/admin/logs/list" replace />, index: true },
								{ path: 'list', element: <Logs /> },
							],
						},
						{
							path: 'roles',
							children: [
								{ element: <Navigate to="/dashboard/admin/roles/list" replace />, index: true },
								{ path: 'list', element: <RolesList /> },
								{ path: 'create', element: <CreateRole /> },
							],
						},
					],
				},
				{
					path: 'billing',
					children: [
						{ element: <Navigate to="/dashboard/billing/meter-reading" replace />, index: true },
						{
							path: 'meter-reading',
							children: [
								{ element: <Navigate to="/dashboard/billing/meter-reading/all-readings" replace />, index: true },
								{ path: 'all-readings', element: <MeterReading /> },
								{ path: ':readingID/:customerID', element: <SingleMeterReading /> },
							],
						},
						{
							path: 'bill-records',
							children: [
								{ element: <Navigate to="/dashboard/billing/bill-records/meter-reading-report" replace />, index: true },
								{ path: 'meter-reading-report', element: <PrintPreview /> },
								{ path: 'print-receipts', element: <PrintReceiptFromArray /> },
								{ path: 'preview-bills', element: <PreviewBills /> },

							],
						},
						{
							path: 'tariff',
							children: [
								{ element: <Navigate to="/dashboard/billing/tariff/all-tariffs" replace />, index: true },
								{ path: 'all-tariffs', element: <Tariffs /> },
							],
						},
					],
				},
				{
					path: 'customer-support',
					children: [
						{ element: <Navigate to="/dashboard/customer-support/reports" replace />, index: true },
						{
							path: 'fault-reports',
							children: [
								{ element: <Navigate to="/dashboard/customer-support/fault-reports/all-opened" replace />, index: true },
								{
									path: 'all-opened',
									element: <Reporting />,
								},
								{
									path: 'submit-report',
									element: <SubmitReport />,
								},
								{ path: 'chat/channel/:id', element: <SingleReport /> },
								{ path: 'chat/channel/:id/:channelId', element: <Chat /> },
								{ path: ':id', element: <SingleReport /> },
							],
						},
						{
							path: 'report-title',
							children: [
								{ element: <Navigate to="/dashboard/customer-support/report-title/all" replace />, index: true },
								{ path: 'all', element: <ReportTitleList /> },
								{ path: 'create', element: <ReportTitleCreate /> },
							],
						},
					],
				},
				{
					path: 'accounts',
					children: [
						{ element: <Navigate to="/dashboard/accounts/transactions" replace />, index: true },
						{
							path: 'transactions',
							children: [
								{ element: <Navigate to="/dashboard/accounts/transactions/all-transactions" replace />, index: true },
								{ path: 'all-transactions', element: <TransactionsList /> },
								{ path: 'uncompleted-transactions', element: <UncompletedTransactions /> },
							],
						},
						{
							path: 'consumer-management',
							children: [
								{ element: <Navigate to="/dashboard/accounts/consumer-management/nesco" replace />, index: true },
								{ path: 'create', element: <CreateConsumer /> },
								{ path: 'nesco', element: <NescoConsumerList /> },
								{ path: 'pub', element: <PUBConsumerList /> },
								{ path: 'statement/:accountNumber', element: <AccountStatement /> },
								{ path: ':consumerId', element: <SingleConsumer /> },
							],
						},
						{
							path: 'bill',
							children: [
								{ element: <Navigate to="/dashboard/accounts/bill/all-records" replace />, index: true },
								{ path: 'all-records', element: <GetAllRecords /> },
								{ path: 'disconnection-list', element: <Substations /> },
								{ path: 'record-bill-payment', element: <RecordBillPayment /> },
								{ path: 'debt-statuses', element: <DebtStatus /> },
								{ path: ':billId', element: <BillPaymentReceipt /> },
							],
						},
						{
							path: 'meter',
							children: [
								{ element: <Navigate to="/dashboard/accounts/meter/create-feeders-substations" replace />, index: true },
								{ path: 'create-feeders-substations', element: <CreateFeedersAndSubstation /> },
								{ path: 'substations-list', element: <Substations /> },
								{ path: 'feeders-list', element: <Feeders /> },
							],
						},
						{
							path: 'reports-analytics',
							children: [
								{ element: <Navigate to="/dashboard/accounts/reports-analytics/disconnection-list" replace />, index: true },
								{ path: 'disconnection-list', element: <DisconnectedList /> },
								{ path: 'debt-statuses', element: <DebtStatus /> },
								{ path: 'sales-report', element: <SalesReport /> },
								{ path: 'vending-sales-report', element: <VendingSalesReport /> },
								{ path: 'prepaid-metering-report', element: <PrepaidMeteringReport /> },
							],
						},
					],
				},
				{
					path: 'settings',
					children: [
						{ element: <Navigate to="/dashboard/settings/news" replace />, index: true },
						{
							path: 'news',
							children: [
								{ element: <Navigate to="/dashboard/settings/news/list" replace />, index: true },
								{ path: 'list', element: <NewsList /> },
								{ path: 'create', element: <NewsCreate /> },
								{ path: ':newsId', element: <NewsSingle /> },
							],
						},
					],
				},
				{
					path: 'prepaid',
					children: [
						{ element: <Navigate to="/dashboard/prepaid/vending" replace />, index: true },
						{
							path: 'vending',
							children: [
								{ element: <Navigate to="/dashboard/prepaid/vending/add-meter" replace />, index: true },
								{ path: 'add-meter', element: <AddNewMeter /> },
								{ path: 'all-meters', element: <GetMeters /> },
								{ path: 'issue-credit-token', element: <IssueCreditToken /> },
								{ path: 'token-purchase-history', element: <TokenPurchaseHistory /> },
								{ path: 'issue-KCT', element: <IssueKCT /> },
								{ path: 'issue-tamper-code-token', element: <IssueTamperCodeToken /> },
							],
						},
					],
				},
				{ path: '*', element: <Navigate to="/404" /> },
			],
		},
		{ path: '*', element: <Navigate to="/404" replace /> },
	]);
}
