/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const editMeter = async (token: any, meterNumber: any, meterData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.put(`/vending/meter/${meterNumber}`, meterData, config);
	return response.data;
};
