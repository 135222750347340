/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	Grid,
	TextField,
	Alert,
	MenuItem,
	Box,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	completeUserRegisteration,
} from '../../../Store';

const titles = [
	{
		value: 'Mr',
		label: 'Mr',
	},
	{
		value: 'Mrs',
		label: 'Mrs',
	},
];

const genders = [
	{
		value: 'Male',
		label: 'Male',
	},
	{
		value: 'Female',
		label: 'Female',
	},
];

export default function CompleteRegisterationForm() {
	const dispatch = useDispatch();
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	const CompleteRegisterationSchema = Yup.object().shape({
		fullname: Yup.string().min(3, 'Too Short!').max(20, 'Too Long!').required('meter number is required'),
		title: Yup.string().required(),
		email: Yup.string().email('ID must be a valid email address').required('ID is required'),
		password: Yup.string().min(4, 'Too Short!').max(20, 'Too Long!').required('Password is required'),
		gender: Yup.string().required(),
	});

	const formik = useFormik({
		initialValues: {
			fullname: '',
			title: 'Mr',
			email: '',
			password: '',
			gender: 'Male',
			phone: Authentication?.isRegistered?.phone,
			account_id: Authentication?.isRegistered?.account_id,
		},
		validationSchema: CompleteRegisterationSchema,
		onSubmit: (values) => {
			dispatch(completeUserRegisteration(values));
		},
	});

	const {
		errors, touched, handleSubmit, getFieldProps,
	} = formik;

	if (Authentication.completeUserRegistration) {
		navigate('/verify-account', { replace: true });
	}

	return (
		<>
			<motion.div
				initial={{ opacity: 0, marginBottom: 0 }}
				animate={{
				opacity: Authentication.error ? 1 : 0,
				marginBottom: Authentication.error ? 20 : 0,
				}}
			>
				<Alert severity="error" variant="filled">
					{Authentication.error && Authentication.error}
				</Alert>
			</motion.div>
			<FormikProvider value={formik}>
				<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<Grid item xs={12} md={10}>
						<Stack spacing={3}>
							<Box
								sx={{
								display: 'grid',
								columnGap: 2,
								rowGap: 3,
								gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
							}}
							>
								<TextField
									fullWidth
									label="Full Name"
									{...getFieldProps('fullname')}
									error={Boolean(touched.fullname && errors.fullname)}
									helperText={touched.fullname && errors.fullname}
								/>

								<TextField
									fullWidth
									label="Email"
									{...getFieldProps('email')}
									error={Boolean(touched.email && errors.email)}
									helperText={touched.email && errors.email}
								/>

								<TextField
									id="outlined-select-currency"
									select
									label="Title"
									{...getFieldProps('title')}
									helperText="Please select your title"
									error={Boolean(touched.title && errors.title)}
								>
									{titles.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
							))}
								</TextField>

								<TextField
									id="outlined-select-currency"
									select
									label="Gender"
									{...getFieldProps('gender')}
									helperText="Please select your gender"
									error={Boolean(touched.gender && errors.gender)}
								>
									{genders.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
							))}
								</TextField>

								<TextField
									fullWidth
									label="Password"
									{...getFieldProps('password')}
									error={Boolean(touched.password && errors.password)}
									helperText={touched.password && errors.password}
								/>
							</Box>

							<LoadingButton
								fullWidth
								size="large"
								type="submit"
								variant="contained"
								loading={Authentication.isCompleteUserRegistrationLoading}
							>
								Complete Registration
							</LoadingButton>
						</Stack>
					</Grid>
				</Form>
			</FormikProvider>
		</>
	);
}
