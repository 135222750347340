export type FaultReportingState = {
	submitFaultReport: null | object;
	isSubmitFaultReportLoading: boolean;
	error?: null | object;
}

export const initialFaultReportingState: FaultReportingState = {
	submitFaultReport: null,
	isSubmitFaultReportLoading: false,
	error: null,
};
