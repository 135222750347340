/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import {
	Link, Container, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import Page from '../../components/Page';
import useResponsive from '../../hooks/useResponsive';
import { RegisterForm } from '../../sections/auth/register';

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

const HeaderStyle = styled('header')(({ theme }) => ({
	top: 0,
	zIndex: 9,
	lineHeight: 0,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	padding: theme.spacing(3),
	justifyContent: 'flex-end',
	[theme.breakpoints.up('md')]: {
		alignItems: 'flex-start',
		padding: theme.spacing(7, 5, 0, 7),
	},
}));

const ContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

export default function Register() {
	const smUp = useResponsive('up', 'sm');

	return (
		<Page title="Register">
			<RootStyle>
				<HeaderStyle>
					{smUp && (
					<Typography variant="body2" sx={{ mt: { md: -2 } }}>
						Already have an account?
						{' '}
						<Link variant="subtitle2" component={RouterLink} to="/login">
							Login
						</Link>
					</Typography>
					)}
				</HeaderStyle>

				<Container>
					<ContentStyle>
						<Typography variant="h4" gutterBottom>
							Register With Us.
						</Typography>

						<Typography
							sx={{ color: 'text.secondary', mb: 5 }}
						>
							Enter your meter number and acount number to register.
						</Typography>

						<RegisterForm />

						<Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
							By registering, I agree to
							{' '}
							<Link underline="always" color="text.primary" href="#">
								Terms of Service
							</Link>
							{' '}
							and
							{' '}
							<Link underline="always" color="text.primary" href="#">
								Privacy Policy
							</Link>
							.
						</Typography>

						{!smUp && (
							<Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
								Already have an account?
								{' '}
								<Link variant="subtitle2" to="/login" component={RouterLink}>
									Login
								</Link>
							</Typography>
						)}
					</ContentStyle>
				</Container>
			</RootStyle>
		</Page>
	);
}
