/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Box,
} from '@mui/material';
import { filter } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	getMeterReportRequest,
	getDistrictRequest,
} from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import {
	RHFSelect, FormProvider,
} from '../components/hook-form';
import { UserListHead } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'account_name', label: 'Account Name', alignRight: false },
	{ id: 'account_number', label: 'Account Number', alignRight: false },
	// { id: 'account_type', label: 'Account type', alignRight: false },
	// { id: 'district', label: 'Districts', alignRight: false },
	{ id: 'previous', label: 'Previous', alignRight: false },
	{ id: 'previous_reading_mode', label: 'Previous Reading Mode', alignRight: false },
	{ id: 'current', label: 'Current', alignRight: false },
	{ id: 'current_reading_mode', label: 'Current Reading Mode', alignRight: false },
	{ id: 'billed_units', label: 'Billed Units', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.meter_number.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function AllRecords({
	token,
	getDistricts,
	isGetDistrictsLoading,
	getMeterReadingReport,
	isGetMeterReadingReport,
	getMeterReportRequest,
	getDistrictRequest,
}) {
	const [district, setDistrict] = React.useState([]);

	React.useEffect(() => {
		if (token) {
			getDistrictRequest(token);
		}
	}, [token]);

	React.useEffect(() => {
		if (getDistricts) {
			setDistrict([...getDistricts]);
		}
	}, [getDistricts]);

	const [page, setPage] = React.useState(0);

	const [order, setOrder] = React.useState('asc');

	const [selected, setSelected] = React.useState([]);

	const [orderBy, setOrderBy] = React.useState('name');

	const [filterName] = React.useState('');

	const [rowsPerPage, setRowsPerPage] = React.useState(15);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getMeterReadingReport?.readings?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getMeterReadingReport?.readings?.length ?? 0) : 0;

	const filteredUsers = getMeterReadingReport?.readings && applySortFilter(getMeterReadingReport?.readings, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const loading = isGetMeterReadingReport;

	const NewUserSchema = Yup.object().shape({
		district: Yup.string().required(),
	});

	const defaultValues = React.useMemo(
		() => ({
			district: district ? district[0]?.code : '',
		}),
		[getDistricts, district],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		handleSubmit,
	} = methods;

	const onSubmit = async (data) => {
		const previewData = {
			...data,
		};
		getMeterReportRequest(token, previewData.district);
	};

	return (
		<Page title="Meter Reading">
			<Container maxWidth="xl">
				<Box>
					{isGetDistrictsLoading ? (
						<Typography variant="h6" gutterBottom>
							Fetching Districts...
						</Typography>
                                ) : (
	<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
		<Stack direction="row" alignItems="center">
			<Box mr={3}>
				<RHFSelect
					name="district"
					label="District"
					disabled={isGetDistrictsLoading}
					placeholder="District"
				>
					<option value="">{' '}</option>
					{district?.map((option, id) => (
						<option key={id} value={option?.code}>
							{option?.code.replace(/[^a-zA-Z ]/g, ' ')}
						</option>
                                                                    ))}
				</RHFSelect>
			</Box>
			<Box>
				<LoadingButton
					type="submit"
					variant="contained"
					disabled={isGetDistrictsLoading}
					loading={loading}
					color="primary"
				>
					Get Report
				</LoadingButton>
			</Box>
		</Stack>
	</FormProvider>
                                )}
				</Box>

				<Stack direction="row" justifyContent="space-between" mt={2}>
					<Typography variant="h4" gutterBottom>
						Meter Reading Report
					</Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{loading ? <Loader /> : getMeterReadingReport?.readings ? (
						<Box>
							<TableContainer sx={{ minWidth: 900 }}>
								<Table>
									<UserListHead
										order={order}
										orderBy={orderBy}
										headLabel={TABLE_HEAD}
										rowCount={getMeterReadingReport?.readings?.length}
										numSelected={selected.length}
										onRequestSort={handleRequestSort}
										onSelectAllClick={handleSelectAllClick}
									/>
									<TableBody>
										{filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                                                const {
                                                    id,
                                                    account_name,
                                                    account_number,
                                                    // account_type,
													previous_reading_mode,
													current_reading_mode,
                                                    // district,
                                                    previous,
                                                    current,
                                                    billed_units,
                                                } = row;
                                                return (
	<TableRow
		hover
		key={idx}
		tabIndex={-1}
		role="checkbox"
	>
		<TableCell align="left">
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="subtitle2" noWrap>
					{account_name}
				</Typography>
			</Stack>
		</TableCell>
		<TableCell align="left">
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="subtitle2" noWrap>
					{account_number}
				</Typography>
			</Stack>
		</TableCell>
		{/* <TableCell align="left">
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="subtitle2" noWrap>
					{account_type}
				</Typography>
			</Stack>
		</TableCell>
		<TableCell align="left">{district}</TableCell> */}
		<TableCell align="left">{previous}</TableCell>
		<TableCell align="left">{previous_reading_mode}</TableCell>
		<TableCell align="left">{current}</TableCell>
		<TableCell align="left">{current_reading_mode}</TableCell>
		{/* previous_reading_mode(pin):"UNITS"
														previous(pin):-1322
														current_reading_mode(pin):"UNITS"
														current(pin):-1322 */}
		<TableCell align="left">{billed_units}</TableCell>
		<TableCell align="left">
			<Button
				variant="outlined"
				component={RouterLink}
				to={`/dashboard/accounts/consumer-management/${id}`}
			>
				View Consumer
			</Button>
		</TableCell>
	</TableRow>
                                                                                );
                                                                            })}
										{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={12} />
										</TableRow>
                                                                    )}
									</TableBody>
									{isUserNotFound && (
									<TableBody>
										<TableRow>
											<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
												<SearchNotFound searchQuery={filterName} />
											</TableCell>
										</TableRow>
									</TableBody>
                                                                )}
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[15, 30, 50]}
								component="div"
								count={getMeterReadingReport?.readings?.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Box>
                        ) : (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Typography variant="h5" gutterBottom>
			No Report Found
		</Typography>
	</Box>
                        )}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, ConsumerManagement, Billing }) => {
	const { token } = Authentication;
	const { getDistricts, isGetDistrictsLoading } = ConsumerManagement;
	const {
		getMeterReadingReport,
		isGetMeterReadingReport,
	} = Billing;
	return {
		token,
		getDistricts,
		isGetDistrictsLoading,
		getMeterReadingReport,
		isGetMeterReadingReport,
	};
};

export default connect(mapStateToProps, {
	getMeterReportRequest,
	getDistrictRequest,
})(AllRecords);
