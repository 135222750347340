/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../../components/Page';
import { AddNewMeterForm } from '../../sections/@dashboard/prepaid';

export default function AddNewMeter() {
	return (
		<Page title="Prepaid Vending: Add a meter">
			<Container>
				<AddNewMeterForm />
			</Container>
		</Page>
	);
}
