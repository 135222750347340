/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import {
	Box, Button, Container, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import { VerifyCodeForm } from '../../sections/auth/verify-code';

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	height: '100%',
	alignItems: 'center',
	padding: theme.spacing(12, 0),
}));

export default function VerifyCode() {
	return (
		<Page title="Verify" sx={{ height: 1 }}>
			<RootStyle>
				<LogoOnlyLayout />

				<Container>
					<Box sx={{ maxWidth: 480, mx: 'auto' }}>
						<Button
							size="small"
							component={RouterLink}
							to="/login"
							startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={20} height={20} />}
							sx={{ mb: 3 }}
						>
							Back
						</Button>
						<Typography variant="h3" paragraph>
							Please check your email!
						</Typography>
						<Typography sx={{ color: 'text.secondary' }}>
							We have emailed a 6-digit confirmation code to your email. Please enter the code in below box to verify
							your email.
						</Typography>
						<Box sx={{ mt: 5, mb: 3 }}>
							<VerifyCodeForm />
						</Box>
					</Box>
				</Container>
			</RootStyle>
		</Page>
	);
}
