/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../../components/Page';
import { IssueKCTForm } from '../../sections/@dashboard/prepaid';

export default function AddNewMeter() {
	return (
		<Page title="Prepaid Vending: issue KCT">
			<Container>
				<IssueKCTForm />
			</Container>
		</Page>
	);
}
