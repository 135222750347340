/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import {
	MenuItem,
	InputLabel,
	Container,
	Typography,
	Card,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Table,
	Select,
	Box,
	Grid,
	Button,
	FormControl,
	Stack,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { getDisconnectionListRequest, getDistrictRequest } from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import PrintDisConnectedList from './PrintDisConnectedList';

function DisconnectedList(
	{
		token,
		isGetDisconnectionListLoading,
		getDisconnectionList,
		isGetDistrictsLoading,
		getDistricts,
		// printDisconnectionList,
		isPrintDisconnectionListLoading,
	},
) {
	const dispatch = useDispatch();
	const loading = isGetDisconnectionListLoading;

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [district, setDistrict] = useState('');

	const componentRef = useRef();
	const totalPageCount = Math.floor(getDisconnectionList?.list.length / rowsPerPage);
	const isLastPage = page === totalPageCount;
	const isDisconnectionListNotFound = getDisconnectionList?.list.length === 0;

	const TABLE_HEAD = [
		{ id: 'account_number', label: 'Account Number', alignRight: false },
		{ id: 'account_name', label: 'Account Name', alignRight: false },
		{ id: 'pre_last_bill_debt', label: 'Pre Last Bill Debt', alignRight: false },
		{ id: 'last_bill_debt', label: 'Last Bill Debt', alignRight: false },
		{ id: 'payments_to_date', label: 'Payment To Date', alignRight: false },
		{ id: 'current Balance', label: 'Current Balance', alignRight: false },
		// { id: 'edit', label: '', alignRight: false },
		{ id: 'last_payment_date', label: 'Last Payment Date', alignRight: false },
	];

	const handleDistrictChange = (e) => {
		dispatch(getDisconnectionListRequest(token, e.target.value));
		setDistrict(e.target.value);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: `
			@page {
				size: A4;
				margin: 10px 0 0 0;
			}
			@media print {
				h4.class-name
				@page{
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 0px;
					margin-bottom: 0px;
				}
				.page {
					margin: 0;
					padding: 0;
					border: initial;
					border-radius: initial;
					width: initial;
					min-height: initial;
					box-shadow: initial;
					background: initial;
					overflow: hidden;
					height: 100vh;
				}
			}
		`,
	});

	useEffect(() => {
		if (token && getDistricts == null) {
			dispatch(getDistrictRequest(token));
		}
	}, [token, loading]);

	return (
		<Page title="Disconnection List">
			<Container maxWidth="xl">
				<Box>
					{isGetDistrictsLoading ? (
						<Typography variant="h6" gutterBottom>
							Fetching Districts...
						</Typography>
					) : (
						<FormControl sx={{ minWidth: 120 }}>
							<Grid container>
								<Grid xs={12} sm={12} md={12} mb={2}>
									<InputLabel id="district-label">District</InputLabel>
									<Select
										name="district"
										label="District"
										defaultValue="NBJ"
										labelId="district-label"
										disabled={isGetDisconnectionListLoading}
										placeholder="District"
										sx={{ minWidth: 50 }}
										onChange={handleDistrictChange}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{getDistricts?.map((option, id) => (
											// eslint-disable-next-line react/no-array-index-key
											<MenuItem key={id} value={option?.code}>
												{option?.code.replace(/[^a-zA-Z ]/g, ' ')}
											</MenuItem>
										))}
									</Select>
									<Button
										onClick={() => {
											handlePrint();
										}}
										variant="outlined"
										color="inherit"
										sx={{ height: 1, marginLeft: 2 }}
										loading={isPrintDisconnectionListLoading}
										disabled={isGetDisconnectionListLoading}
									>
										Download Report

									</Button>
								</Grid>
							</Grid>

						</FormControl>
					)}

					<Card>

						<Grid container justifyContent="center" p={5}>
							<Grid item xm={24} sm={24} md={24} justifyContent="center">
								<Typography variant="h4" sx={{ textAlign: 'center' }}>NESCO (NIGERIA) LIMITED</Typography>
							</Grid>
							<Grid item xm={24} sm={24} md={24}>
								<Typography variant="h4" sx={{ textAlign: 'center', textDecoration: 'underline' }}>{getDisconnectionList?.title}</Typography>
							</Grid>
							<Grid item xm={24} sm={24} md={24}>
								<Typography variant="h6">
									District:
									{getDisconnectionList?.district}
								</Typography>
							</Grid>
						</Grid>

						{isGetDisconnectionListLoading ? <Loader /> : getDisconnectionList ? (
							<Box>
								<div style={{ display: 'none' }}>
									<PrintDisConnectedList disConnectionObj={getDisconnectionList} ref={componentRef} />
								</div>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<UserListHead
											headLabel={TABLE_HEAD}
											rowCount={getDisconnectionList?.list.length}
											onRequestSort={() => { }}
										/>
										<TableBody>
											{
												getDisconnectionList.list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
													const {
														account_number,
														account_name,
														pre_last_bill_debt,
														last_bill_debt,
														payments_to_date,
														current_balance,
														last_payment_date,
													} = row;

													return (
														<TableRow
															hover
															key={account_name}
															tabIndex={-1}
															sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{account_number}
																</Typography>
															</TableCell>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{account_name}
																</Typography>
															</TableCell>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{pre_last_bill_debt}
																</Typography>
															</TableCell>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{last_bill_debt}
																</Typography>
															</TableCell>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{payments_to_date}
																</Typography>
															</TableCell>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{current_balance}
																</Typography>
															</TableCell>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{last_payment_date}
																</Typography>
															</TableCell>

														</TableRow>
													);
												})

											}
											{isLastPage && (
												<TableRow>
													<TableCell colSpan={7}>
														<Box sx={{ minHeight: '100px', border: '1px solid grey', borderRadius: '5px' }}>
															<Typography variant="h4" textAlign="center">OVERALL SUMMARY</Typography>
															<Stack direction="row" spacing={2} margin={2} justifyContent="space-between">
																<Typography variant="subtitle2" noWrap>
																	PRE LAST BILL DEBT
																</Typography>

																<Typography variant="subtitle2" noWrap>
																	LAST BILL DEBT
																</Typography>

																<Typography variant="subtitle2" noWrap>
																	PAYMENTS TO DATE
																</Typography>

																<Typography variant="subtitle2" noWrap>
																	CURRENT BALANCE
																</Typography>

															</Stack>
															<Stack direction="row" spacing={2} margin={2} justifyContent="space-between">

																<Typography variant="subtitle2" noWrap>
																	{getDisconnectionList?.totals.pre_last_bill_debt}
																</Typography>

																<Typography variant="subtitle2" noWrap>
																	{getDisconnectionList?.totals.pre_last_bill_debt}
																</Typography>

																<Typography variant="subtitle2" noWrap>
																	{getDisconnectionList?.totals.payments_to_date}
																</Typography>

																<Typography variant="subtitle2" noWrap>
																	{getDisconnectionList?.totals.current_balance}
																</Typography>
															</Stack>
															<Typography variant="h6" display="inline" paddingLeft={2}>No. Of Customers: </Typography>
															<Typography variant="h6" display="inline">{getDisconnectionList?.list_total}</Typography>
														</Box>

													</TableCell>
												</TableRow>
											)}

										</TableBody>

										{isDisconnectionListNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={district} />
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</TableContainer>

								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={getDisconnectionList.list?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>

							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No DisconnectedList Found
								</Typography>
							</Box>
						)}
					</Card>
				</Box>
			</Container>
		</Page>

	);
}

const mapStateToProps = ({ Authentication, User, ConsumerManagement }) => {
	const { token } = Authentication;
	const {
		getDisconnectionList,
		isGetDisconnectionListLoading,
		printDisconnectionList,
		isPrintDisconnectionListLoading,
	} = User;
	const { getDistricts, isGetDistrictsLoading } = ConsumerManagement;
	return {
		token,
		getDisconnectionList,
		isGetDisconnectionListLoading,
		getDistricts,
		isGetDistrictsLoading,
		printDisconnectionList,
		isPrintDisconnectionListLoading,
	};
};

export default connect(mapStateToProps)(DisconnectedList);
