/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import {
	Container, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Page from '../../components/Page';
import { VerifyAccountForm } from '../../sections/auth/verify';

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

const ContentStyle = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

export default function Register() {
	return (
		<Page title="Verify Account">
			<RootStyle>
				<Container>
					<ContentStyle>
						<Typography variant="h4" gutterBottom>
							Verify Account
						</Typography>

						<VerifyAccountForm />
					</ContentStyle>
				</Container>
			</RootStyle>
		</Page>
	);
}
