/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type VerifyAccountResponse ={
	status: string,
	message: string,
	data: {
		user: {
			id: 14,
			fullname: string,
			title: string,
			email: string,
			phone: string,
			password: string,
			device_token: string,
			gender: string,
			role: string,
			account_id: number,
			isActive: boolean,
			isSuspended: boolean,
			activation_code: any,
			activation_code_expires_in: any,
			createdAt: string,
			updatedAt: string,
			deletedAt: any,
			consumer_account: {
				id: 4,
				user_id: 14,
				fullname: string,
				phone: string,
				address: string,
				account_number: string,
				account_type: string,
				account_type_code: string,
				account_type_number: string,
				meter_type: string,
				postpaid_meter_number: string,
				prepaid_meter_number: any,
				tariff: string,
				feeder: any,
				substation: any,
				has_updated: boolean,
				createdAt: string,
				updatedAt: string,
				deletedAt: any
			}
		},
		authorization: {
			token: string,
			expiresIn: 2592000
		}
	}
}
export const verifyAccount = async (verificationCode: number):
Promise<VerifyAccountResponse> => {
	const response = await axios.get(`/auth/verify/${verificationCode}`);

	return response.data;
};
