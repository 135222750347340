export type NotificationState = {
	isGetAllBroadcastsLoading: boolean;
	getAllBroadcasts: null | object;
	isGetSingleBroadcastLoading: boolean;
	getSingleBroadcast: null | object;
	isCreateBroadcastLoading: boolean;
	createBroadcast: null | object;
	isEditBroadcastLoading: boolean,
	editBroadcast: null | object,
	isResendBroadcastLoading: boolean,
	resendBroadcast: null | object,
	isDeleteBroadcastLoading: boolean,
	deleteBroadcast: null | object,
	error?: null | object;
}

export const initialNotificationState: NotificationState = {
	isGetAllBroadcastsLoading: false,
	getAllBroadcasts: null,
	isGetSingleBroadcastLoading: false,
	getSingleBroadcast: null,
	isCreateBroadcastLoading: false,
	createBroadcast: null,
	isEditBroadcastLoading: false,
	editBroadcast: null,
	isResendBroadcastLoading: false,
	resendBroadcast: null,
	isDeleteBroadcastLoading: false,
	deleteBroadcast: null,
};
