/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Card, CardHeader } from '@mui/material';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui

// utils
import { BaseOptionChart } from '../../../components/chart';
import { fNumber } from '../../../utils/formatNumber';

AppConversionRates.propTypes = {
	title: PropTypes.string,
	subheader: PropTypes.string,
	chartData: PropTypes.array.isRequired,
};

export default function AppConversionRates({
	title, subheader, chartData, ...other
}) {
	const chartLabels = chartData.map((i) => i.label);

	const chartSeries = chartData.map((i) => i.value);

	const chartOptions = merge(BaseOptionChart(), {
		tooltip: {
			marker: { show: false },
			y: {
				formatter: (seriesName) => fNumber(seriesName),
				title: {
					formatter: () => '',
				},
			},
		},
		plotOptions: {
			bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
		},
		xaxis: {
			categories: chartLabels,
		},
	});

	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />
			<Box sx={{ mx: 3 }} dir="ltr">
				<ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
			</Box>
		</Card>
	);
}
