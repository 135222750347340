/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */

import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui

// ----------------------------------------------------------------------

export function RHFCheckbox({ name, ...other }) {
	const { control } = useFormContext();

	return (
		<FormControlLabel
			control={(
				<Controller
					name={name}
					control={control}
					render={({ field }) => <Checkbox {...field} checked={field.value} />}
				/>
      )}
			{...other}
		/>
	);
}

// ----------------------------------------------------------------------

export function RHFMultiCheckbox({ name, options, ...other }) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => {
        const onSelected = (option) => (field.value.includes(option)
         ? field.value.filter((value) => value !== option) : [...field.value, option]);

        return (
	<FormGroup>
		{options.map((option) => (
			<FormControlLabel
				key={option}
				control={(
					<Checkbox
						checked={field.value.includes(option)}
						onChange={() => field.onChange(onSelected(option))}
					/>
                )}
				label={option}
				{...other}
			/>
            ))}
	</FormGroup>
        );
      }}
		/>
	);
}

RHFCheckbox.propTypes = {
	name: PropTypes.string,
};
RHFMultiCheckbox.propTypes = {
	name: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.string),
};
