/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import {
	Typography,
	Card,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	Table,
	Box,
	Grid,
	Stack,
} from '@mui/material';
import React from 'react';

import { UserListHead } from '../sections/@dashboard/user';

const PrintDisConnectedList = React.forwardRef(({ disConnectionObj }, ref) => {
	const TABLE_HEAD = [
		{ id: 'account_number', label: 'Account Number', alignRight: false },
		{ id: 'account_name', label: 'Account Name', alignRight: false },
		{ id: 'pre_last_bill_debt', label: 'Pre Last Bill Debt', alignRight: false },
		{ id: 'last_bill_debt', label: 'Last Bill Debt', alignRight: false },
		{ id: 'payments_to_date', label: 'Payment To Date', alignRight: false },
		{ id: 'current Balance', label: 'Current Balance', alignRight: false },
		{ id: 'Last Payment Date', label: 'Last Payment Date', alignRight: false },

	];

	return (
		<Card ref={ref}>

			<Grid container justifyContent="center" p={5}>
				<Grid item xm={24} sm={24} md={24} justifyContent="center">
					<Typography variant="h4" sx={{ textAlign: 'center' }}>NESCO (NIGERIA) LIMITED</Typography>
				</Grid>
				<Grid item xm={24} sm={24} md={24}>
					<Typography variant="h4" sx={{ textAlign: 'center', textDecoration: 'underline' }}>{disConnectionObj?.title}</Typography>
				</Grid>
				<Grid item xm={24} sm={24} md={24}>
					<Typography variant="h6">
						District:
						{disConnectionObj?.district}
					</Typography>
				</Grid>
			</Grid>

			{ disConnectionObj ? (
				<Box>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<UserListHead
								headLabel={TABLE_HEAD}
								rowCount={disConnectionObj?.list.length}
								onRequestSort={() => {}}
							/>
							<TableBody>
								{
        disConnectionObj.list.map((row) => {
          const {
            account_number,
            account_name,
            pre_last_bill_debt,
            last_bill_debt,
            payments_to_date,
            current_balance,
            last_payment_date
          } = row
          return (
	<TableRow
		hover
		key={account_name}
		tabIndex={-1}
		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
	>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{account_number}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2">
				{account_name}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{pre_last_bill_debt}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{last_bill_debt}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2">
				{payments_to_date}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2">
				{current_balance}
			</Typography>
		</TableCell>
        <TableCell align="left">
			<Typography variant="subtitle2">
				{last_payment_date}
			</Typography>
		</TableCell>

	</TableRow>
          );
      })

    }

								<TableRow>
									<TableCell colSpan={7}>
										<Box sx={{ minHeight: '100px', border: '1px solid grey', borderRadius: '5px' }}>
											<Typography variant="h4" textAlign="center">OVERALL SUMMARY</Typography>
											<Stack direction="row" spacing={2} margin={2} justifyContent="space-between">
												<Typography variant="subtitle2" noWrap>
													PRE LAST BILL DEBT
												</Typography>

												<Typography variant="subtitle2" noWrap>
													LAST BILL DEBT
												</Typography>

												<Typography variant="subtitle2" noWrap>
													PAYMENTS TO DATE
												</Typography>

												<Typography variant="subtitle2" noWrap>
													CURRENT BALANCE
												</Typography>

											</Stack>
											<Stack direction="row" spacing={2} margin={2} justifyContent="space-between">

												<Typography variant="subtitle2" noWrap>
													{disConnectionObj?.totals.pre_last_bill_debt}
												</Typography>

												<Typography variant="subtitle2" noWrap>
													{disConnectionObj?.totals.pre_last_bill_debt}
												</Typography>

												<Typography variant="subtitle2" noWrap>
													{disConnectionObj?.totals.payments_to_date}
												</Typography>

												<Typography variant="subtitle2" noWrap>
													{disConnectionObj?.totals.current_balance}
												</Typography>
											</Stack>
											<Typography variant="h6" display="inline" paddingLeft={2}>No. Of Customers: </Typography>
											<Typography variant="h6" display="inline">{disConnectionObj?.list_total}</Typography>
										</Box>

									</TableCell>
								</TableRow>

							</TableBody>

						</Table>
					</TableContainer>

				</Box>
         ) : (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Typography variant="h5" gutterBottom>
			No DisconnectedList Found
		</Typography>
	</Box>
         )}
		</Card>
	);
});

export default PrintDisConnectedList;
