/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { NewFeederSubstationForm } from '../sections/@dashboard/FeederSubstation';

export default function CreateFeedersAndSubstations() {
	return (
		<Page title="News: Create feeders and substations">
			<Container>
				<NewFeederSubstationForm />
			</Container>
		</Page>
	);
}
