/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createRoleRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFTextField,
} from '../../../components/hook-form';

export default function RoleForm() {
	const dispatch = useDispatch();

	const Role = useSelector((state) => state.RolesPermissions);
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		name: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			name: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Role.createRole) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/admin/roles/list/');
		}

		if (Role.error) {
			enqueueSnackbar(Role.error, {
				variant: 'error',
			});
		}
	}, [Role.createRole, Role.error]);

	const onSubmit = async (data) => {
		const payload = {
			...data,
		};
		dispatch(createRoleRequest(payload, Authentication.token));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create a New Role
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/admin/roles/list"
					startIcon={<Iconify icon="eva:plus-fill" />}
				>
					View Roles
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3, justifyContent: 'center' }}>
							<Box
								sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                }}
							>
								<RHFTextField
									name="name"
									label="name"
								/>
								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<LoadingButton
										type="submit"
										variant="contained"
										loading={Role.isCreateRoleLoading}
									>
										Create Role
									</LoadingButton>
								</Stack>
							</Box>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
