/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getRoles = async (token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get('/roles', config);

	return response.data;
};
