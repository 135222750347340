/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import { Card, CardHeader, Box } from '@mui/material';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui

// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
	title: PropTypes.string,
	subheader: PropTypes.string,
	chartData: PropTypes.array.isRequired,
	chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({
	title, subheader, chartLabels, chartData, ...other
}) {
	const chartOptions = merge(BaseOptionChart(), {
		plotOptions: { bar: { columnWidth: '16%' } },
		fill: { type: chartData.map((i) => i.fill) },
		labels: chartLabels,
		xaxis: { type: 'datetime' },
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: (y) => {
					if (typeof y !== 'undefined') {
						return `${y.toFixed(0)} visits`;
					}
					return y;
				},
			},
		},
	});

	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />

			<Box sx={{ p: 3, pb: 1 }} dir="ltr">
				<ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
			</Box>
		</Card>
	);
}
