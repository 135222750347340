import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { TariffState, initialTariffState } from './Types';

const TariffSlice = createSlice({
	name: 'TariffSlice',
	initialState: initialTariffState,
	reducers: {
		setState(state: TariffState, action: PayloadAction<Partial<TariffState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllTariffsRequest(
			state: TariffState,
			action: PayloadAction<{
				updateTariff: Partial<TariffState['updateTariff']>,
				isGetAllTariffsLoading: Partial<TariffState['isGetAllTariffsLoading']>,
				getAllTariffs: Partial<TariffState['getAllTariffs']>,
			}>,
		) {
			const {
				updateTariff,
				isGetAllTariffsLoading,
				getAllTariffs,
			} = action.payload;
			return {
				...state,
				updateTariff,
				isGetAllTariffsLoading,
				getAllTariffs,
			};
		},
		updateTarrifRequest(
			state: TariffState,
			action: PayloadAction<{
				// saveUpdatedTempTariffs?: Partial<TariffState['saveUpdatedTempTariffs']>,
				isUpdateTariffLoading: Partial<TariffState['isUpdateTariffLoading']>,
				updateTariff: Partial<TariffState['updateTariff']>,
				error?: Partial<TariffState['error']> | undefined,
			}>,
		) {
			const {
				// saveUpdatedTempTariffs,
				isUpdateTariffLoading,
				updateTariff,
				error,
			} = action.payload;
			return {
				...state,
				// saveUpdatedTempTariffs,
				isUpdateTariffLoading,
				updateTariff,
				error,
			};
		},
		saveUpdatedTariffTempRequest(
			state: TariffState,
			action: PayloadAction<{
				saveUpdatedTempTariffs: Partial<TariffState['saveUpdatedTempTariffs']>,
				error?: Partial<TariffState['error']> | undefined,
			}>,
		) {
			const {
				saveUpdatedTempTariffs,
				error,
			} = action.payload;
			return {
				...state,
				saveUpdatedTempTariffs,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllTariffsRequest: _getAllTariffsRequest,
	updateTarrifRequest: _updateTarrifRequest,
	saveUpdatedTariffTempRequest: _saveUpdatedTariffTempRequest,
} = TariffSlice.actions;

export const getAllTariffsRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllTariffsRequest({
		...initialTariffState,
		isGetAllTariffsLoading: true,
	}));
	try {
		const apiResponseData = await API.getTariffs(token);
		dispatch(_getAllTariffsRequest({
			updateTariff: null,
			isGetAllTariffsLoading: false,
			getAllTariffs: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllTariffsRequest({
			...initialTariffState,
			isGetAllTariffsLoading: false,
		}));
		dispatch(_setState<Partial<TariffState>>(
			{
				error: error.message ?? 'Unable to get all tariffs. Please try again.',
			},
		));
	}
};

export const updateTarrifRequest = (
	token: string,
	tariffData:any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_updateTarrifRequest({
		...initialTariffState,
		isUpdateTariffLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.updateTariffs(
			token,
			tariffData,
		);
		if (apiResponseData) {
			dispatch(_updateTarrifRequest({
				isUpdateTariffLoading: false,
				updateTariff: apiResponseData.message,
				// saveUpdatedTempTariffs: [],
			}));
		}
	} catch (error) {
		dispatch(_updateTarrifRequest({
			...initialTariffState,
			isUpdateTariffLoading: false,
		}));
		dispatch(_setState<Partial<TariffState>>(
			{
				error: error.message ?? 'Unable to update tariff. Please try again.',
			},
		));
	}
};

export const saveUpdatedTariffTempRequest = (
	savedTariffs: any,
) : AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_saveUpdatedTariffTempRequest({
		...initialTariffState,
		error: null,
	}));
	try {
		// if (!savedTariffs.length) {
		// 	dispatch(_saveUpdatedTariffTempRequest({
		// 		saveUpdatedTempTariffs: [payload],
		// 	}));
		// }

		// if (savedTariffs.length) {
		// 	const isFound = savedTariffs.some(element => {
		// 		if (element.class === payload.class || element.rate === payload.rate) {
		// 			return true;
		// 		}

		// 		return false;
		// 	});

		// 	if (!isFound) {
		// 		// const tt = savedTariffs.map(t => {
		// 		// 	if (t.class === payload.class) {
		// 		// 		t.rate = payload.rate;
		// 		// 		t.class = payload.class;

		// 		// 		return t;
		// 		// 	}
		// 		// 	return t;
		// 		// });
		// 		console.log([...savedTariffs, payload]);
		// 		dispatch(_saveUpdatedTariffTempRequest({
		// 			saveUpdatedTempTariffs: [...savedTariffs, payload],
		// 		}));
		// 	}
		// }
		dispatch(_saveUpdatedTariffTempRequest({
			saveUpdatedTempTariffs: savedTariffs,
		}));
	} catch (error) {
		dispatch(_saveUpdatedTariffTempRequest({
			...initialTariffState,
		}));
		dispatch(_setState<Partial<TariffState>>(
			{
				error: error.message ?? 'Unable to edit tariff. Please try again.',
			},
		));
	}
};

export const { reducer: Tariff } = TariffSlice;
