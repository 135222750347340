export type ChatState = {
	startChannel: null | object;
	isStartChannelLoading: boolean;
	sendMessage: null | object;
	isSendMessageLoading: boolean;
	getReportChannels: null | object;
	isGetReportChannelsLoading: boolean;
	fetchChannelMessages: null | object;
	isFetchChannelMessagesLoading: boolean;
	getToken: null | object;
	isGetTokenLoading: boolean;
	error?: null | object;
}

export const initialChatState: ChatState = {
	startChannel: null,
	isStartChannelLoading: false,
	sendMessage: null,
	isSendMessageLoading: false,
	getReportChannels: null,
	isGetReportChannelsLoading: false,
	fetchChannelMessages: null,
	isFetchChannelMessagesLoading: false,
	getToken: null,
	isGetTokenLoading: false,
	error: null,
};
