/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	editBroadcastRequest,
} from '../../../Store';
import {
	FormProvider, RHFTextField,
} from '../../../components/hook-form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function BroadcastEditForm({
	view,
	id,
	details,
	handleCloseEditDialog,
	isEditBroadcastLoading,
}) {
	const dispatch = useDispatch();

	const Notification = useSelector((state) => state.Notification);
	const Authentication = useSelector((state) => state.Authentication);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		title: Yup.string().required(),
		body: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			title: details?.title,
			body: details?.body,
		}),
		[details],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Notification.createBroadcast) {
			reset();
			enqueueSnackbar('Edit success!');
		}

		if (Notification.error) {
			enqueueSnackbar(Notification.error, {
				variant: 'error',
			});
		}
	}, [Notification.createBroadcast, Notification.error]);

	const onSubmit = async (data) => {
		const payload = {
			...data,
			isDraft: false,
		};
		dispatch(editBroadcastRequest(Authentication.token, id, payload));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Edit
					{' '}
					{details[0]?.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update the title and body of the broadcast and click the edit button to submit your changes.
					</DialogContentText>
				</DialogContent>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Card sx={{ p: 3 }}>
								<Box
									sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                }}
								>
									<RHFTextField
										name="title"
										label="Title"
									/>
									<RHFTextField
										id="outlined-multiline-static"
										name="body"
										multiline
										rows={3}
										maxRows={10}
										label="Body"
									/>
								</Box>

								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											loading={isEditBroadcastLoading}
										>
											Edit Broadcast
										</LoadingButton>
									</DialogActions>
								</Stack>
							</Card>
						</Grid>
					</Grid>
				</FormProvider>
			</Box>
		</Dialog>
	);
}
