/* eslint-disable camelcase */
import axios from '../../../../configs/Axios';

export const getSingleMeterReading = async (readingId: number, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`/meter-reading/${readingId}`, config);

	return response.data;
};
