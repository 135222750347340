/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Box,
	Button,
} from '@mui/material';
import { filter } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';

import {
	getMeterReadingHistoryRequest,
	getSingleMeterReadingRequest,
	approveMeterReadingRequest,
} from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'meter_number', label: 'Meter Number', alignRight: false },
	{ id: 'account_number', label: 'Account Number', alignRight: false },
	{ id: 'reading_mode', label: 'Reading Mode', alignRight: false },
	{ id: 'units', label: 'Units', alignRight: false },
	{ id: 'reader', label: 'Meter Reader', alignRight: false },
	{ id: 'low_volt_reading', label: 'Low Volt Reading', alignRight: false },
	{ id: 'high_volt_reading', label: 'High Volt Reading', alignRight: false },
	{ id: 'substation', label: 'Substation', alignRight: false },
	{ id: 'feeder', label: 'Feader', alignRight: false },
	{ id: 'remarks', label: 'Remarks', alignRight: false },
	{ id: 'meter_status', label: 'Meter Status', alignRight: false },
	{ id: 'account_status', label: 'Account Status', alignRight: false },
	{ id: 'approved', label: 'Approved', alignRight: false },
	{ id: 'createdAt', label: 'Created', alignRight: false },
	{ id: 'updatedAt', label: 'Updated', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.meter_number.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function SingleMeterReading({
	token,
	user,
	getMeterReadingHistory,
	isGetMeterReadingHistoryLoading,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getSingleMeterReading,
	isGetSingleMeterReadingLoading,
	approveMeterReading,
	isApproveMeterReadingLoading,
	getMeterReadingHistoryRequest,
	getSingleMeterReadingRequest,
	approveMeterReadingRequest,
}) {
	const {
		readingID,
		customerID,
	} = useParams();

	const [currentID, setCurrentID] = React.useState(null);

	React.useEffect(() => {
		if (token) {
			getMeterReadingHistoryRequest(customerID, token);
			getSingleMeterReadingRequest(readingID, token);
		}
	}, [token, approveMeterReading]);

	const [page, setPage] = React.useState(0);

	const [order, setOrder] = React.useState('asc');

	const [selected, setSelected] = React.useState([]);

	const [orderBy, setOrderBy] = React.useState('name');

	const [filterName] = React.useState('');

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getMeterReadingHistory?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const disallowedRoles = ['auditor'];

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getMeterReadingHistory?.length ?? 0) : 0;

	const filteredUsers = getMeterReadingHistory && applySortFilter(getMeterReadingHistory, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const loading = isGetMeterReadingHistoryLoading || isGetSingleMeterReadingLoading;
	return (
		<Page title="Meter Reading">
			<Container maxWidth="xl">
				<Stack direction="row" alignItems="start" justifyContent="space-between" mb={2}>
					<Box>
						<Typography variant="h4" gutterBottom>
							Account Details
						</Typography>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account Number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{getMeterReadingHistory?.length ? getMeterReadingHistory[0].account_number : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Meter Number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{getMeterReadingHistory?.length ? getMeterReadingHistory[0].meter_number : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Meter Status:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{getMeterReadingHistory?.length ? getMeterReadingHistory[0].meter_status : ''}
							</Typography>
						</Stack>
					</Box>
					{/* <Box>
						<Typography variant="h4" gutterBottom>
							Meter Reader
						</Typography>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Name:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{getSingleMeterReading ? getSingleMeterReading?.reader?.fullname : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Phone Number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{getSingleMeterReading ? getSingleMeterReading?.reader?.phone : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Email:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{getSingleMeterReading ? getSingleMeterReading?.reader?.email : ''}
							</Typography>
						</Stack>
					</Box> */}
					<Button
						variant="contained"
						component={RouterLink}
						to="/dashboard/billing/meter-reading"
						startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
					>
						Go back to all readings
					</Button>
				</Stack>
				<Stack direction="row" justifyContent="end">
					<Typography variant="subtitle1" sx={{ mb: 1 }}> Reading History </Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{
						loading ? <Loader /> : getMeterReadingHistory ? (
							<Box>
								<TableContainer sx={{ minWidth: 900 }}>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											rowCount={getMeterReadingHistory?.length}
											numSelected={selected.length}
											onRequestSort={handleRequestSort}
											onSelectAllClick={handleSelectAllClick}
										/>

										{/* fullname(pin):"Abdul Fatai"
title(pin):"mr"
email(pin):"talk2burb@yahoo.com"
phone(pin):"08107117522" */}
										<TableBody>
											{
												filteredUsers
												.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
												.map((row) => {
																const {
																		id,
																		meter_number,
																		account_number,
																		reading_mode,
										units,
																		reader,
																		low_volt_reading,
																		high_volt_reading,
																		substation,
																		feeder,
																		remarks,
																		meter_status,
																		account_status,
																		approved,
																		createdAt,
																		updatedAt,
																	} = row;
																return (
																	<TableRow
																		hover
																		key={id}
																		tabIndex={-1}
																		role="checkbox"
																	>
																		<TableCell align="left">
																			<Stack direction="row" alignItems="center" spacing={2}>
																				<Typography variant="subtitle2" noWrap>
																					{meter_number}
																				</Typography>
																			</Stack>
																		</TableCell>
																		<TableCell align="left">{account_number}</TableCell>
																		<TableCell align="left">{reading_mode}</TableCell>
																		<TableCell align="left">{units}</TableCell>
																		<TableCell align="left">{reader.fullname}</TableCell>
																		<TableCell align="left">{low_volt_reading}</TableCell>
																		<TableCell align="left">{high_volt_reading}</TableCell>
																		<TableCell align="left">{substation}</TableCell>
																		<TableCell align="left">{feeder}</TableCell>
																		<TableCell align="left">{remarks}</TableCell>
																		<TableCell align="left">{meter_status}</TableCell>
																		<TableCell align="left">{account_status}</TableCell>
																		<TableCell align="left">{approved ? 'Yes' : 'No'}</TableCell>
																		<TableCell align="left">{moment(createdAt).format('D/M/YY')}</TableCell>
																		<TableCell align="left">{moment(updatedAt).format('D/M/YY')}</TableCell>
																		<TableCell align="right">
																			{
																				!disallowedRoles.includes(user?.role) && !approved && (
																					<LoadingButton
																						type="submit"
																						variant="contained"
																						color="primary"
																						loading={(id === currentID) && isApproveMeterReadingLoading}
																						onClick={
																							() => {
																								setCurrentID(id);
																								approveMeterReadingRequest(
																									id,
																									token,
																								);
																							}
																						}
																					>
																						Approve
																					</LoadingButton>
																				)
																			}
																		</TableCell>
																	</TableRow>
																);
															})
											}
											{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={12} />
												</TableRow>
											)}
										</TableBody>
										{isUserNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={filterName} />
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={getMeterReadingHistory?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Reading Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, MeterReading }) => {
	const { token, user } = Authentication;
	const {
		getMeterReadingHistory,
		isGetMeterReadingHistoryLoading,
		getSingleMeterReading,
		isGetSingleMeterReadingLoading,
		approveMeterReading,
		isApproveMeterReadingLoading,
	} = MeterReading;
	return {
		user,
		token,
		getMeterReadingHistory,
		isGetMeterReadingHistoryLoading,
		getSingleMeterReading,
		isGetSingleMeterReadingLoading,
		approveMeterReading,
		isApproveMeterReadingLoading,
	};
};

export default connect(mapStateToProps, {
	getMeterReadingHistoryRequest,
	getSingleMeterReadingRequest,
	approveMeterReadingRequest,
})(SingleMeterReading);
