import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { FeederState, initialFeederState } from './Types';

const Feederslice = createSlice({
	name: 'Feederslice',
	initialState: initialFeederState,
	reducers: {
		setState(state: FeederState, action: PayloadAction<Partial<FeederState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllFeedersRequest(
			state: FeederState,
			action: PayloadAction<{
				isGetAllFeedersLoading: Partial<FeederState['isGetAllFeedersLoading']>,
				getAllFeeders: Partial<FeederState['getAllFeeders']>,
				createFeeder?: Partial<FeederState['createFeeder']> | undefined,
				error?: Partial<FeederState['error']> | undefined,
			}>,
		) {
			const {
				isGetAllFeedersLoading,
				getAllFeeders,
				createFeeder,
				error,
			} = action.payload;
			return {
				...state,
				isGetAllFeedersLoading,
				createFeeder,
				getAllFeeders,
				error,
			};
		},
		createFeederRequest(
			state: FeederState,
			action: PayloadAction<{
				isCreateFeederLoading: Partial<FeederState['isCreateFeederLoading']>,
				createFeeder: Partial<FeederState['createFeeder']>,
				error?: Partial<FeederState['error']> | undefined,
			}>,
		) {
			const {
				isCreateFeederLoading,
				createFeeder,
				error,
			} = action.payload;
			return {
				...state,
				isCreateFeederLoading,
				createFeeder,
				error,
			};
		},
		editFeederRequest(
			state: FeederState,
			action: PayloadAction<{
				isEditFeederLoading: Partial<FeederState['isEditFeederLoading']>,
				editFeeder: Partial<FeederState['editFeeder']>,
				error?: Partial<FeederState['error']> | undefined,
			}>,
		) {
			const {
				isEditFeederLoading,
				editFeeder,
				error,
			} = action.payload;
			return {
				...state,
				isEditFeederLoading,
				editFeeder,
				error,
			};
		},
		deleteFeederRequest(
			state: FeederState,
			action: PayloadAction<{
				isDeleteFeederLoading: Partial<FeederState['isDeleteFeederLoading']>,
				getAllFeeders?: Partial<FeederState['getAllFeeders']> | undefined,
				deleteFeeder: Partial<FeederState['deleteFeeder']>,
				error?: Partial<FeederState['error']> | undefined,
			}>,
		) {
			const {
				isDeleteFeederLoading,
				getAllFeeders,
				deleteFeeder,
				error,
			} = action.payload;
			return {
				...state,
				isDeleteFeederLoading,
				getAllFeeders,
				deleteFeeder,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllFeedersRequest: _getAllFeedersRequest,
	createFeederRequest: _createFeederRequest,
	editFeederRequest: _editFeederRequest,
	deleteFeederRequest: _deleteFeederRequest,
} = Feederslice.actions;

export const getAllFeedersRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllFeedersRequest({
		...initialFeederState,
		isGetAllFeedersLoading: true,
		createFeeder: null,
	}));
	try {
		const apiResponseData = await API.getFeeders(token);
		dispatch(_getAllFeedersRequest({
			isGetAllFeedersLoading: false,
			getAllFeeders: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllFeedersRequest({
			...initialFeederState,
			isGetAllFeedersLoading: false,
		}));
		dispatch(_setState<Partial<FeederState>>(
			{
				error: error.message ?? 'Unable to get all Feeders. Please try again.',
			},
		));
	}
};

export const createFeederRequest = (
	token: string,
	feederData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createFeederRequest({
		...initialFeederState,
		isCreateFeederLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.createFeeders(
			token,
			feederData,
		);
		if (apiResponseData) {
			dispatch(_createFeederRequest({
				isCreateFeederLoading: false,
				createFeeder: apiResponseData.message,
			}));
		}
	} catch (error) {
		dispatch(_createFeederRequest({
			...initialFeederState,
			isCreateFeederLoading: false,
		}));
		dispatch(_setState<Partial<FeederState>>(
			{
				error: error.message ?? 'Unable to create feeder. Please try again.',
			},
		));
	}
};

export const editFeederRequest = (
	token: string,
	feederId: string,
	feederData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_editFeederRequest({
		...initialFeederState,
		isEditFeederLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.editFeeders(
			token,
			feederId,
			feederData,
		);
		if (apiResponseData) {
			dispatch(_editFeederRequest({
				isEditFeederLoading: false,
				editFeeder: apiResponseData.message,
			}));
		}
	} catch (error) {
		dispatch(_editFeederRequest({
			...initialFeederState,
			isEditFeederLoading: false,
		}));
		dispatch(_setState<Partial<FeederState>>(
			{
				error: error.message ?? 'Unable to edit feeder. Please try again.',
			},
		));
	}
};

export const deleteFeederRequest = (
	token: string,
	feederId: string,
	feeders?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_deleteFeederRequest({
		...initialFeederState,
		isDeleteFeederLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.deleteFeeders(
			token,
			feederId,
		);
		if (feeders && apiResponseData) {
			dispatch(_deleteFeederRequest({
				isDeleteFeederLoading: false,
				getAllFeeders: feeders.filter((feeder: any) => feeder.id !== feederId),
				deleteFeeder: apiResponseData.message,
			}));
		}

		if (!feeders) {
			dispatch(_deleteFeederRequest({
				isDeleteFeederLoading: false,
				deleteFeeder: apiResponseData.message,
			}));
		}
	} catch (error) {
		dispatch(_deleteFeederRequest({
			...initialFeederState,
			isDeleteFeederLoading: false,
		}));
		dispatch(_setState<Partial<FeederState>>(
			{
				error: error.message ?? 'Unable to delete feeder. Please try again.',
			},
		));
	}
};

export const { reducer: Feeder } = Feederslice;
