import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../API';
import { AppDispatch, AppThunk } from '../Store';
import { ChatState, initialChatState } from './Types';

const ChatSlice = createSlice({
	name: 'ChatSlice',
	initialState: initialChatState,
	reducers: {
		setState(state: ChatState, action: PayloadAction<Partial<ChatState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		startChannelRequest(
			state: ChatState,
			action: PayloadAction<{
				isStartChannelLoading: Partial<ChatState['isStartChannelLoading']>,
				startChannel: Partial<ChatState['startChannel']>,
			}>,
		) {
			const {
				isStartChannelLoading,
				startChannel,
			} = action.payload;
			return {
				...state,
				isStartChannelLoading,
				startChannel,

			};
		},
		clearChatCache(
			state: ChatState,
			action: PayloadAction<{
				startChannel: Partial<ChatState['startChannel']>,
			}>,
		) {
			const {
				startChannel,
			} = action.payload;
			return {
				...state,
				startChannel,
			};
		},
		sendMessageRequest(
			state: ChatState,
			action: PayloadAction<{
				isSendMessageLoading: Partial<ChatState['isSendMessageLoading']>,
				sendMessage: Partial<ChatState['sendMessage']>,
			}>,
		) {
			const {
				isSendMessageLoading,
				sendMessage,
			} = action.payload;
			return {
				...state,
				isSendMessageLoading,
				sendMessage,

			};
		},

		getReportChannelsRequest(
			state: ChatState,
			action: PayloadAction<{
				isGetReportChannelsLoading: Partial<ChatState['isGetReportChannelsLoading']>,
				getReportChannels: Partial<ChatState['getReportChannels']>,
			}>,
		) {
			const {
				isGetReportChannelsLoading,
				getReportChannels,
			} = action.payload;
			return {
				...state,
				isGetReportChannelsLoading,
				getReportChannels,

			};
		},

		fetchChannelMessagesRequest(
			state: ChatState,
			action: PayloadAction<{
				isFetchChannelMessagesLoading: Partial<ChatState['isFetchChannelMessagesLoading']>,
				fetchChannelMessages: Partial<ChatState['fetchChannelMessages']>,
			}>,
		) {
			const {
				isFetchChannelMessagesLoading,
				fetchChannelMessages,
			} = action.payload;
			return {
				...state,
				isFetchChannelMessagesLoading,
				fetchChannelMessages,

			};
		},

		getTokenRequest(
			state: ChatState,
			action: PayloadAction<{
				isGetTokenLoading: Partial<ChatState['isGetTokenLoading']>,
				getToken: Partial<ChatState['getToken']>,
			}>,
		) {
			const {
				isGetTokenLoading,
				getToken,
			} = action.payload;
			return {
				...state,
				isGetTokenLoading,
				getToken,

			};
		},
	},
});

const {
	setState: _setState,
	startChannelRequest: _startChannelRequest,
	clearChatCache: _clearChatCache,
	sendMessageRequest: _sendMessageRequest,
	getReportChannelsRequest: _getReportChannelsRequest,
	fetchChannelMessagesRequest: _fetchChannelMessagesRequest,
	getTokenRequest: _getTokenRequest,
} = ChatSlice.actions;

export const startChannelRequest = (token: any, reportId: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_startChannelRequest({
		...initialChatState,
		isStartChannelLoading: true,
	}));
	try {
		const apiResponseData = await API.startChannel(token, reportId);
		dispatch(_startChannelRequest({
			isStartChannelLoading: false,
			startChannel: apiResponseData,
		}));
	} catch (error) {
		dispatch(_startChannelRequest({
			...initialChatState,
			isStartChannelLoading: false,
		}));
		dispatch(_setState<Partial<ChatState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to start channel. Please try again.',
			},
		));
	}
};

export const clearChatCache = (): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_clearChatCache({
		...initialChatState,
		startChannel: null,
	}));
};

export const sendMessageRequest = (token: any, messageData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_sendMessageRequest({
		...initialChatState,
		isSendMessageLoading: true,
	}));
	try {
		const apiResponseData = await API.sendMessage(token, messageData);
		dispatch(_sendMessageRequest({
			isSendMessageLoading: false,
			sendMessage: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_sendMessageRequest({
			...initialChatState,
			isSendMessageLoading: false,
		}));
		dispatch(_setState<Partial<ChatState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to send message. Please try again.',
			},
		));
	}
};

export const getReportChannelsRequest = (token: any, reportId: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getReportChannelsRequest({
		...initialChatState,
		isGetReportChannelsLoading: true,
	}));
	try {
		const apiResponseData = await API.getReportChannels(token, reportId);
		dispatch(_getReportChannelsRequest({
			isGetReportChannelsLoading: false,
			getReportChannels: apiResponseData,
		}));
	} catch (error) {
		dispatch(_getReportChannelsRequest({
			...initialChatState,
			isGetReportChannelsLoading: false,
		}));
		dispatch(_setState<Partial<ChatState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to get report channel. Please try again.',
			},
		));
	}
};

export const fetchChannelMessagesRequest = (
	token: any,
	id: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_fetchChannelMessagesRequest({
		...initialChatState,
		isFetchChannelMessagesLoading: true,
	}));
	try {
		const apiResponseData = await API.fetchChannelMessages(token, id);
		dispatch(_fetchChannelMessagesRequest({
			isFetchChannelMessagesLoading: false,
			fetchChannelMessages: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_fetchChannelMessagesRequest({
			...initialChatState,
			isFetchChannelMessagesLoading: false,
		}));
		dispatch(_setState<Partial<ChatState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to fetch channel messages. Please try again.',
			},
		));
	}
};

export const getTokenRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getTokenRequest({
		...initialChatState,
		isGetTokenLoading: true,
	}));
	try {
		const apiResponseData = await API.getToken(token);
		dispatch(_getTokenRequest({
			isGetTokenLoading: false,
			getToken: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getTokenRequest({
			...initialChatState,
			isGetTokenLoading: false,
		}));
		dispatch(_setState<Partial<ChatState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to get token. Please try again.',
			},
		));
	}
};

export const { reducer: Chat } = ChatSlice;
