/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const updateReconciliation = async (token: string, reconciliationData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.put('/user/update/consumer/reconciliation', reconciliationData, config);

	return response.data;
};
