/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getSingleReport = async (reportId: any, token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`/report/${reportId}`, config);

	return response.data;
};
