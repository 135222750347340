/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type RoleData = {
	name: string,
}

export const createRole = async (token: string, roleDate: RoleData):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.post('/roles/create', roleDate, config);

	return response.data;
};
