/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	Container,
	Typography,
	Divider,
	Box,
	TextField,
	Button,
	FormControl,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { connect, useDispatch } from 'react-redux';

import { API_BASE_URL } from '../Environment';
import { getVendingSalesReportRequest } from '../Store';
import Page from '../components/Page';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function VendingSalesReport({
	token,
}) {
	const dispatch = useDispatch();

	const [filterType, setFilterType] = React.useState('monthAndYear');

	const [nescoMonthValue, setNescoMonthValue] = useState(dayjs().subtract(1, 'month'));
	const [nescoYearValue, setNescoYearValue] = useState(dayjs().format('YYYY'));

	const [downloadLoading, setDownloadLoading] = React.useState(false);
	const [downloadLoading2, setDownloadLoading2] = React.useState(false);

	const [rangeView, setRangeView] = React.useState(false);
	const [rangeState, setRangeState] = React.useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: 'selection',
		},
	]);

	const downloaLink = `${API_BASE_URL}/analytics/vending-report?month=${dayjs(nescoMonthValue).format('MMMM')}&year=${nescoYearValue}`;

	const handleSetRangeView = () => {
		setRangeView(true);
	};

	const handleCloseRangeDialog = () => {
		setRangeView(false);
	};
	const handleFilterBy = (event) => {
		setFilterType(event.target.value);
	};

	const formatAndSetNescoYearValue = (newValue) => {
		setNescoYearValue(dayjs(newValue).format('YYYY'));
	};

	const formatAndSetNescoMonthValue = (newValue) => {
		setNescoMonthValue(dayjs(newValue).format('M'));
	};

	const { startDate, endDate } = rangeState[0];
	const startDateValue = dayjs(startDate).format('YYYY-MM-DD');
	const endDateValue = dayjs(endDate).format('YYYY-MM-DD');

	const dateValues = startDateValue === endDateValue ? {
		startDateValue,
		endDateValue: null,
	} : {
		startDateValue,
		endDateValue,
	};

	const downloaLink2 = `${API_BASE_URL}/analytics/vending-report?startDate=${dateValues.startDateValue}&endDate=${dateValues.endDateValue}`;

	const handleDownloadVendingSalesReport = async () => {
		// await dispatch(getVendingSalesReportRequest(token, { month: dayjs(nescoMonthValue).format('MMMM'), year: nescoYearValue }));
		setDownloadLoading(true);
		const result = await fetch(downloaLink, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		const blob = await result.blob();
		const href = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.download = `vending-sales-report-${dayjs(nescoMonthValue).format('MMMM')}-${nescoYearValue}.xlsx`;
		link.href = href;
		document.body.appendChild(link);
		link.click();
		setDownloadLoading(false);
	};

	const handleSearchDaily = async () => {
		setDownloadLoading2(true);
		try {
			const result = await fetch(downloaLink2, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const blob = await result.blob();
			const href = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.download = `vending-sales-report-${dateValues.startDateValue}-${dateValues.endDateValue}.xlsx`;
			link.href = href;
			document.body.appendChild(link);
			link.click();
			setDownloadLoading2(false);
		} catch (error) {
			setDownloadLoading2(false);
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(getVendingSalesReportRequest(token, { month: 'January', year: 2023 }));
		}
	}, [token]);

	return (
		<Page title="Vending Sales Report">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
					<Typography variant="h4" gutterBottom>
						Download Vending Sales Report
					</Typography>
					<Typography variant="h6" gutterBottom>
						Select the month and year you want to download reports for.
					</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
					<Box>
						<FormControl>
							<Select
								value={filterType}
								onChange={handleFilterBy}
							>
								<MenuItem value="dateRange">Get report per date range</MenuItem>
								<MenuItem value="monthAndYear">Get report per month and year</MenuItem>
							</Select>
						</FormControl>
					</Box>
					{
						filterType === 'dateRange' && (
							<Stack alignItems="end">
								<Box mb={2}>
									<Button onClick={handleSetRangeView} variant="outlined" disabled={downloadLoading2} color="primary">Open date picker</Button>
								</Box>
								<Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
									<Typography marginRight={1}>
										<strong>Start Date:</strong>
										{' '}
										{startDateValue}
										,
									</Typography>
									<Typography>
										{' '}
										<strong>End Date:</strong>
										{' '}
										{endDateValue}
									</Typography>
								</Box>
							</Stack>
						)
					}
					{
						filterType === 'monthAndYear' && (
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Box>
									<DesktopDatePicker
										disableHighlightToday
										openTo="year"
										disabled={downloadLoading}
										inputFormat="YYYY"
										views={['year']}
										label="Select Year"
										value={nescoYearValue}
										onChange={(newValue) => formatAndSetNescoYearValue(newValue)}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										renderInput={(params) => (
											<TextField
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...params}
												inputProps={{ ...params.inputProps, readOnly: true }}
												sx={{
													'& .MuiInputBase-input': {
														caretColor: 'transparent',
													},
												}}
											/>
										)}
									/>
								</Box>
								<Box ml={2}>
									<DesktopDatePicker
										disableHighlightToday
										openTo="month"
										disabled={downloadLoading}
										inputFormat="MMMM"
										views={['month']}
										label="Select Month"
										value={nescoMonthValue}
										onChange={(newValue) => formatAndSetNescoMonthValue(newValue)}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										renderInput={(params) => (
											<TextField
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...params}
												inputProps={{ ...params.inputProps, readOnly: true }}
												sx={{
													'& .MuiInputBase-input': {
														caretColor: 'transparent',
													},
												}}
											/>
										)}
									/>
								</Box>
								<Box ml={2}>
									<LoadingButton
										onClick={handleDownloadVendingSalesReport}
										variant="outlined"
										loading={downloadLoading}
										disabled={downloadLoading}
										color="primary"
									>
										Download Vending Sales Report
									</LoadingButton>
								</Box>
							</Stack>
						)
					}
				</Stack>
				<Divider />
				<Dialog
					open={rangeView}
					TransitionComponent={Transition}
					fullWidth
					maxWidth="lg"
					keepMounted
					onClose={handleCloseRangeDialog}
					aria-describedby="alert-dialog-slide-description"
				>
					<Box />
					<DialogTitle>
						Select Date Range
					</DialogTitle>
					<DialogContent>
						<DateRangePicker
							onChange={item => setRangeState([item.selection])}
							showSelectionPreview
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={rangeState}
							direction="horizontal"
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseRangeDialog}>Cancel</Button>
						<LoadingButton
							onClick={handleSearchDaily}
							variant="outlined"
							disabled={downloadLoading2}
							loading={downloadLoading2}
							color="primary"
						>
							Download Vending Sales Report
						</LoadingButton>
					</DialogActions>
				</Dialog>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, User }) => {
	const { token } = Authentication;
	const { isGetVendingSalesReportLoading } = User;

	return {
		token,
		isGetVendingSalesReportLoading,
	};
};

export default connect(mapStateToProps)(VendingSalesReport);
