/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getSubstations = async (token: any, feederSlug: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get(`/meter/substations?feeder_slug=${feederSlug}`, config);
	return response.data;
};
