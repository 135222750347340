/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */

import { Switch, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui

// ----------------------------------------------------------------------

export default function RHFSwitch({ name, ...other }) {
	const { control } = useFormContext();

	return (
		<FormControlLabel
			control={(
				<Controller
					name={name}
					control={control}
					render={({ field }) => <Switch {...field} checked={field.value} />}
    />
  )}
			{...other}
		/>
	);
}

RHFSwitch.propTypes = {
	name: PropTypes.string,
};
