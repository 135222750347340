/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type BroadcastData = {
	title: string,
	body: string,
	isDraft?: boolean,
}

export const editBroadcast = async (token: any, brodcastId: string, broadcastData: BroadcastData):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.put(`/notification/broadcast/${brodcastId}`, broadcastData, config);
	return response.data;
};
