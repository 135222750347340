import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { Provider } from 'react-redux';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
	checkAuthState,
} from './Store';
import Store from './Store/Store';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import { Router } from './routes';
import ThemeProvider from './theme';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function App(): JSX.Element {
	React.useEffect(() => {
		Store.dispatch(checkAuthState());
	}, []);

	return (
		<Provider store={Store}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ThemeProvider>
					<NotistackProvider>
						<ScrollToTop />
						<Router />
					</NotistackProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</Provider>
	);
}
