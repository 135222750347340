/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getOpenFaultReport = async (token: string, status: string = 'open'):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`/report/all?page=1&pageSize=2000&status=${status}`, config);

	return response.data;
};
