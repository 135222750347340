/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	editSubstationRequest,
} from '../../../Store';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SubstationEditForm({
	view,
	id,
	details,
	feeders,
	handleCloseEditDialog,
	isEditSubstationLoading,
}) {
	const dispatch = useDispatch();

	const Substation = useSelector((state) => state.Substation);
	const Authentication = useSelector((state) => state.Authentication);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		name: Yup.string().required(),
		feeder_slug: Yup.string().required(),
		voltage: Yup.string().required(),
		connected_plant: Yup.string().required(),
		area: Yup.string().required(),
		capacity: Yup.number().required(),
		rating: Yup.string().required(),
		latitude: Yup.string().required(),
		longitude: Yup.string().required(),
		ownership: Yup.string().required(),
		metering_status: Yup.string().required(),
		dt_status: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			name: details?.name,
			feeder_slug: details?.feeder_slug ?? feeders[0]?.slug,
			voltage: details?.voltage,
			connected_plant: details?.connected_plant,
			area: details?.area,
			capacity: details?.capacity,
			rating: details?.rating,
			latitude: details?.latitude,
			longitude: details?.longitude,
			ownership: details?.ownership,
			metering_status: details?.metering_status,
			dt_status: details?.dt_status,
			reason: details?.reason,
			remarks: details?.remarks,
		}),
		[details],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Substation.editSubstation) {
			reset();
			enqueueSnackbar('Edit success!');
		}

		if (Substation.error) {
			enqueueSnackbar(Substation.error, {
				variant: 'error',
			});
		}
	}, [Substation.editSubstation, Substation.error]);

	const onSubmit = async (data) => {
		const payload = {
			...data,
		};

		return dispatch(editSubstationRequest(Authentication.token, id, payload));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Edit
					{' '}
					{details?.name}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update and submit your changes to save
					</DialogContentText>
				</DialogContent>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Card sx={{ p: 3 }}>
								<Box
									sx={{
                                        display: 'grid',
                                        columnGap: 2,
                                        rowGap: 3,
										gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                    }}
								>
									<RHFTextField
										name="name"
										label="Name"
									/>
									<RHFSelect
										name="feeder_slug"
										label="Feeder"
										placeholder="Feeder"
										helperText="Feeder is required"
									>
										<option value="">{' '}</option>
										{feeders?.map((option, idx) => (
											// eslint-disable-next-line react/no-array-index-key
											<option key={idx} value={option?.slug}>
												{option?.name}
											</option>
										))}
									</RHFSelect>
									<RHFTextField
										name="voltage"
										label="Voltage"
									/>
									<RHFTextField
										helperText="Connected plant is required"
										name="connected_plant"
										label="Connected plant"
									/>
									<RHFTextField
										name="area"
										label="Area"
									/>
									<RHFTextField
										name="capacity"
										type="number"
										label="Capacity"
									/>
									<RHFTextField
										name="rating"
										label="Rating"
									/>
									<RHFTextField
										name="latitude"
										label="Latitude"
									/>
									<RHFTextField
										name="longitude"
										label="Longitude"
									/>
									<RHFTextField
										name="ownership"
										label="Ownership"
									/>
									<RHFTextField
										helperText="Metering status is required"
										name="metering_status"
										label="Metering status"
									/>
									<RHFTextField
										helperText="DT status is required"
										name="dt_status"
										label="DT status"
									/>
									<RHFTextField
										name="reason"
										label="Reason"
									/>
									<RHFTextField
										name="remarks"
										label="Remarks"
									/>
								</Box>
								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											loading={isEditSubstationLoading}
										>
											Edit Substation
										</LoadingButton>
									</DialogActions>
								</Stack>
							</Card>
						</Grid>
					</Grid>
				</FormProvider>
			</Box>
		</Dialog>
	);
}
