/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import {
	Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, ListSubheader,
} from '@mui/material';
import { alpha, useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

import Iconify from './Iconify';

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
	({ theme }) => ({
		...theme.typography.overline,
		paddingTop: theme.spacing(3),
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		color: theme.palette.text.primary,
		transition: theme.transitions.create('opacity', {
			duration: theme.transitions.duration.shorter,
		}),
	}),
);

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
	...theme.typography.body2,
	height: 48,
	position: 'relative',
	textTransform: 'capitalize',
	color: theme.palette.text.secondary,
	borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
	width: 22,
	height: 22,
	color: 'inherit',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

function NavItem({ item, active, user }) {
	const theme = useTheme();

	const isActiveRoot = active(item.path);

	const {
		title, path, icon, info, children,
	} = item;

	const [open, setOpen] = useState(isActiveRoot);

	const handleOpen = () => {
		setOpen((prev) => !prev);
	};

	const activeRootStyle = {
		color: 'primary.main',
		fontWeight: 'fontWeightMedium',
		bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
	};

	const activeSubStyle = {
		color: 'text.primary',
		fontWeight: 'fontWeightMedium',
	};

	if (children) {
		return (
			<>
				<ListItemStyle
					onClick={handleOpen}
					sx={{ ...(isActiveRoot && activeRootStyle) }}
				>
					<ListItemIconStyle>{icon && icon}</ListItemIconStyle>
					<ListItemText disableTypography primary={title} />
					{info && info}
					<Iconify
						icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
						sx={{ width: 16, height: 16, ml: 1 }}
					/>
				</ListItemStyle>

				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{
							children.map((item) => {
							const { title, path } = item;
							const isActiveSub = active(path);

							if (item?.roles?.includes(user?.role)) {
								return (
									<ListItemStyle
										key={title}
										component={RouterLink}
										to={path}
										sx={{ ...(isActiveSub && activeSubStyle) }}
									>
										<ListItemIconStyle>
											<Box
												component="span"
												sx={{
														width: 4,
														height: 4,
														display: 'flex',
														borderRadius: '50%',
														alignItems: 'center',
														justifyContent: 'center',
														bgcolor: 'text.disabled',
														transition: (theme) => theme
														.transitions.create('transform'),
														...(isActiveSub && {
														transform: 'scale(2)',
														bgcolor: 'primary.main',
														}),
													}}
											/>
										</ListItemIconStyle>
										<ListItemText disableTypography primary={title} />
									</ListItemStyle>
								);
							}
							})
						}
					</List>
				</Collapse>
			</>
		);
	}

	return (
		<ListItemStyle
			component={RouterLink}
			to={path}
			sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
		>
			<ListItemIconStyle>{icon && icon}</ListItemIconStyle>
			<ListItemText disableTypography primary={title} />
			{info && info}
		</ListItemStyle>
	);
}

NavItem.propTypes = {
	item: PropTypes.object,
	active: PropTypes.func,
};

function NavSection({ navConfig, user, ...other }) {
	const { pathname } = useLocation();

	const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

	return (
		<Box {...other}>
			{
				navConfig.map((group) => {
					if (group?.roles?.includes(user?.role)) {
						return (
							<List key={group.subheader} disablePadding sx={{ px: 2 }}>
								<ListSubheaderStyle>
									{group.subheader}
								</ListSubheaderStyle>
								{
									group.items.map((list) => {
										if (list.roles.includes(user?.role)) {
											return <NavItem key={list.title} item={list} active={match} user={user} />;
										}
									})
								}
							</List>
						);
					}
				})
			}
		</Box>
	);
}

NavSection.propTypes = {
	navConfig: PropTypes.array,
};

const mapStateToProps = ({ Authentication }) => {
	const { user } = Authentication;
	return { user };
};

export default connect(mapStateToProps)(NavSection);
