/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Tab,
	Tabs,
	TextField,
	Box,
	FormControl,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';

import { API_BASE_URL } from '../Environment';
import {
	getAllRecordsRequest,
	approveRecordsRequest,
	declineRecordsRequest,
} from '../Store';
import Label from '../components/Label';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// receipt_no

const TABLE_HEAD = [
	{ id: 'account_number', label: 'Account Number', alignRight: false },
	{ id: 'receipt_no', label: 'Receipt Number', alignRight: false },
	{ id: 'date', label: 'Date', alignRight: false },
	{ id: 'amount', label: 'Amount', alignRight: false },
	{ id: 'cashier', label: 'Cashier', alignRight: false },
	{ id: 'payment_type', label: 'Payment Type', alignRight: false },
	{ id: 'approver', label: 'Approver', alignRight: false },
	{ id: 'verified', label: 'Verified', alignRight: false },
	{ id: 'declined', label: 'Declined', alignRight: false },
	{ id: 'createdAt', label: 'Created', alignRight: false },
	{ id: 'updatedAt', label: 'Date verified', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_record) => _record?.account_number?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function TableCard({
	pagination,
	getAllRecords,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
	token,
	user,
	isApproveRecordLoading,
	approveRecordsRequest,
	declineRecordsRequest,
	isDeclineRecordLoading,
	pendingRecords,
	allRecords,
}) {
	const [currentID, setCurrentID] = React.useState(null);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getAllRecords?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagination.pageSize ?? 0) : 0;

	const filteredUsers = getAllRecords && applySortFilter(getAllRecords, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const disallowedRoles = ['auditor'];

	return (
		<Box>
			<UserListToolbar placeholder="Search Account Number" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
			<TableContainer sx={{ minWidth: 900 }}>
				<Table>
					<UserListHead
						order={order}
						orderBy={orderBy}
						headLabel={TABLE_HEAD}
						rowCount={getAllRecords?.length}
						numSelected={selected.length}
						onRequestSort={handleRequestSort}
						onSelectAllClick={handleSelectAllClick}
					/>
					<TableBody>
						{
							filteredUsers
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									const {
										id,
										account_number,
										receipt_no,
										date,
										amount,
										cashier,
										payment_type,
										approver,
										verified,
										declined,
										createdAt,
										updatedAt,
									} = row;
									return (
										<TableRow
											hover
											key={id}
											tabIndex={-1}
											role="checkbox"
										>
											<TableCell align="left">
												<Stack direction="row" alignItems="center" spacing={2}>
													<Typography variant="subtitle2" noWrap>
														{account_number}
													</Typography>
												</Stack>
											</TableCell>
											<TableCell align="left">
												<Stack direction="row" alignItems="center" spacing={2}>
													<Typography variant="subtitle2" noWrap>
														{receipt_no}
													</Typography>
												</Stack>
											</TableCell>
											<TableCell align="left">{moment(date).format('D/M/YY')}</TableCell>
											<TableCell align="left">{amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
											<TableCell align="left">{cashier?.fullname}</TableCell>
											<TableCell align="left">{payment_type}</TableCell>
											<TableCell align="left">{approver?.fullname}</TableCell>
											<TableCell align="left">
												<Label variant="ghost" color={!verified ? 'error' : 'success'}>
													{!verified ? 'No' : 'Yes'}
												</Label>
											</TableCell>
											<TableCell align="left">
												<Label variant="ghost" color={!declined ? 'error' : 'success'}>
													{!declined ? 'No' : 'Yes'}
												</Label>
											</TableCell>
											<TableCell align="left">{moment(createdAt).format('D/M/YY')}</TableCell>
											<TableCell align="left">{moment(updatedAt).format('D/M/YY')}</TableCell>

											{
												!disallowedRoles.includes(user?.role) && (
													<>
														<TableCell align="right">
															{
																((allRecords && !declined && !verified) || (pendingRecords)) && (
																	<LoadingButton
																		type="submit"
																		variant="contained"
																		color="primary"
																		loading={(id === currentID) && isApproveRecordLoading}
																		onClick={
																			() => {
																				setCurrentID(id);
																				approveRecordsRequest(
																					id,
																					token,
																				);
																			}
																		}
																	>
																		Approve
																	</LoadingButton>
																)
															}
														</TableCell>
														<TableCell align="right">
															{
																((allRecords && !declined && !verified) || (pendingRecords)) && (
																	<LoadingButton
																		type="submit"
																		variant="contained"
																		color="error"
																		loading={(id === currentID) && isDeclineRecordLoading}
																		onClick={
																			() => {
																				setCurrentID(id);
																				declineRecordsRequest(
																					id,
																					token,
																				);
																			}
																		}
																	>
																		Decline
																	</LoadingButton>
																)
															}
														</TableCell>
													</>
												)
											}
										</TableRow>
									);
								})
						}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={12} />
							</TableRow>
						)}
					</TableBody>
					{isUserNotFound && (
						<TableBody>
							<TableRow>
								<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
									<SearchNotFound searchQuery={filterName} />
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={filteredUsers?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>

	);
}

function TabPanel(props) {
	const {
		children, value, index, ...other
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function AllRecords({
	token,
	user,
	getAllRecords,
	isGetAllRecordsLoading,
	approveRecord,
	declineRecord,
	isDeclineRecordLoading,
	isApproveRecordLoading,
	getAllRecordsRequest,
	approveRecordsRequest,
	declineRecordsRequest,
}) {
	const [dayValue, setDayValue] = React.useState(dayjs());
	const [accountNumber, setAccountNumber] = React.useState('');
	const [downloadLoading, setDownloadLoading] = React.useState(false);
	const [receiptNumber, setReceiptNumber] = React.useState('');

	const [filterType, setFilterType] = React.useState('all');

	const downloaLink = `${API_BASE_URL}/billing/daily/record-list?date=${dayjs(dayValue).format('YYYY-MM-DD')}`;

	const handleFilterBy = (event) => {
		setFilterType(event.target.value);
	};

	React.useEffect(() => {
		if (token) {
			getAllRecordsRequest(token, null, '', '');
		}
	}, [token]);

	React.useEffect(() => {
		if (token && filterType === 'all') {
			getAllRecordsRequest(token, null, '', '');
		}
	}, [token, filterType]);

	React.useEffect(() => {
		if (token && (approveRecord || declineRecord)) {
			filterType === 'date' && getAllRecordsRequest(token, dayjs(dayValue).format('YYYY-MM-DD'), '', '');
			filterType === 'accountNumber' && getAllRecordsRequest(token, null, accountNumber, '');
			filterType === 'all' && getAllRecordsRequest(token, null, '');
			filterType === 'receiptNumber' && getAllRecordsRequest(token, null, '', receiptNumber);
		}
	}, [token, approveRecord, declineRecord]);

	const [value, setValue] = React.useState(0);

	const [page, setPage] = React.useState(0);

	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearchDaily = () => {
		getAllRecordsRequest(token, dayjs(dayValue).format('YYYY-MM-DD'), '', '');
	};

	const searchByAccountNumber = () => {
		getAllRecordsRequest(token, null, accountNumber, '');
	};

	const searchByReceiptNumber = () => {
		getAllRecordsRequest(token, null, '', receiptNumber);
	};

	const handleDownloadReport = async () => {
		setDownloadLoading(true);
		const result = await fetch(downloaLink, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		const blob = await result.blob();
		const href = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.download = `daily-records-${dayjs(dayValue).format('YYYY-MM-DD')}`;
		link.href = href;
		document.body.appendChild(link);
		link.click();
		setDownloadLoading(false);
	};

	return (
		<Page title="Bill Records">
			<Container maxWidth="xl">
				<Stack mb={1}>
					<Typography variant="h6" gutterBottom>
						Select the ideal parameter you want to search by in the box below (All, Account Number or Date).
					</Typography>
				</Stack>
				<Stack direction="row" mb={2} alignItems="start" justifyContent="space-between">
					<Box>
						<FormControl>
							<Select
								value={filterType}
								onChange={handleFilterBy}
							>
								<MenuItem value="all">Get all records</MenuItem>
								<MenuItem value="accountNumber">Get records per account number</MenuItem>
								<MenuItem value="date">Get records per date</MenuItem>
								<MenuItem value="receiptNumber">Get records by receipt number</MenuItem>
							</Select>
						</FormControl>
					</Box>
					{
						filterType === 'date' && (
							<Stack direction="row" alignItems="center">
								<Box mr={3}>
									<DesktopDatePicker
										label="Select a date to get records"
										inputFormat="MM/DD/YYYY"
										value={dayValue}
										onChange={(newValue) => {
											setDayValue(newValue);
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Box>
								<Box mr={3}>
									<LoadingButton onClick={handleSearchDaily} variant="outlined" disabled={isGetAllRecordsLoading} color="primary">Get Bill Records</LoadingButton>
								</Box>

								<LoadingButton
									onClick={handleDownloadReport}
									variant="contained"
									disabled={isGetAllRecordsLoading || downloadLoading}
									loading={downloadLoading}
									color="primary"
								>
									Download Report
								</LoadingButton>
							</Stack>
						)
					}

					{
						filterType === 'accountNumber' && (
							<Stack direction="row" alignItems="center">
								<LoadingButton onClick={searchByAccountNumber} variant="outlined" disabled={isGetAllRecordsLoading} color="primary">Get Bill Records</LoadingButton>
								<Box ml={3}>
									<TextField
										id="district-field"
										label="Account Number"
										shrink
										placeholder="Enter Account Number"
										value={accountNumber}
										onChange={(event) => {
											setAccountNumber(event.target.value);
										}}
										sx={{ mr: 2 }}
									/>
								</Box>
							</Stack>
						)
					}
					{
						filterType === 'receiptNumber' && (
							<Stack direction="row" alignItems="center">
								<LoadingButton onClick={searchByReceiptNumber} variant="outlined" disabled={isGetAllRecordsLoading} color="primary">Get Bill Records</LoadingButton>
								<Box ml={3}>
									<TextField
										id="district-field"
										label="Receipt Number"
										shrink
										placeholder="Enter Receipt Number"
										value={receiptNumber}
										onChange={(event) => {
											setReceiptNumber(event.target.value);
										}}
										sx={{ mr: 2 }}
									/>
								</Box>
							</Stack>
						)
					}
				</Stack>

				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h4" gutterBottom>
						All Records
					</Typography>
					<Typography variant="h6" gutterBottom>
						Total Amount:
						{' '}
						{getAllRecords?.totalAmount}
					</Typography>
					<Typography variant="h6" gutterBottom>
						Total Unverified:
						{' '}
						{getAllRecords?.total_unverified}
					</Typography>
					<Typography variant="h6" gutterBottom>
						Total Records:
						{' '}
						{getAllRecords?.total}
					</Typography>
				</Stack>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleChange} aria-label="meter reading">
							<Tab label="All" {...a11yProps(0)} />
							<Tab label="Verified" {...a11yProps(1)} />
							<Tab label="Declined" {...a11yProps(2)} />
							<Tab label="Pending" {...a11yProps(3)} />
						</Tabs>
					</Box>
				</Box>
				<TabPanel value={value} index={0}>
					<Card sx={{ pb: 2, pt: 2 }}>
						{
							isGetAllRecordsLoading === true ? <Loader /> : getAllRecords?.records ? (
								<TableCard
									token={token}
									user={user}
									pagination={getAllRecords?.pagination}
									allRecords
									getAllRecords={getAllRecords?.records}
									handleChangePage={handleChangePage}
									handleChangeRowsPerPage={handleChangeRowsPerPage}
									page={page}
									rowsPerPage={rowsPerPage}
									isApproveRecordLoading={isApproveRecordLoading}
									isDeclineRecordLoading={isDeclineRecordLoading}
									approveRecordsRequest={approveRecordsRequest}
									declineRecordsRequest={declineRecordsRequest}
								/>
								) : (
									<Box sx={{ display: 'flex', justifyContent: 'center' }}>
										<Typography variant="h5" gutterBottom>
											No record found
										</Typography>
									</Box>
								)
						}
					</Card>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Card sx={{ pb: 2, pt: 2 }}>
						{
							isGetAllRecordsLoading === true ? <Loader /> : getAllRecords?.records ? (
								<TableCard
									token={token}
									pagination={getAllRecords?.pagination}
									getAllRecords={getAllRecords?.records.filter(record => record.verified === true)}
									handleChangePage={handleChangePage}
									handleChangeRowsPerPage={handleChangeRowsPerPage}
									page={page}
									rowsPerPage={rowsPerPage}
									isApproveRecordLoading={isApproveRecordLoading}
									isDeclineRecordLoading={isDeclineRecordLoading}
									approveRecordsRequest={approveRecordsRequest}
									declineRecordsRequest={declineRecordsRequest}
								/>
								) : (
									<Box sx={{ display: 'flex', justifyContent: 'center' }}>
										<Typography variant="h5" gutterBottom>
											No record found
										</Typography>
									</Box>
								)
						}
					</Card>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<Card sx={{ pb: 2, pt: 2 }}>
						{
							isGetAllRecordsLoading === true ? <Loader /> : getAllRecords?.records ? (
								<TableCard
									token={token}
									pagination={getAllRecords?.pagination}
									getAllRecords={getAllRecords?.records.filter(record => record.declined === true)}
									handleChangePage={handleChangePage}
									handleChangeRowsPerPage={handleChangeRowsPerPage}
									page={page}
									rowsPerPage={rowsPerPage}
									isApproveRecordLoading={isApproveRecordLoading}
									isDeclineRecordLoading={isDeclineRecordLoading}
									approveRecordsRequest={approveRecordsRequest}
									declineRecordsRequest={declineRecordsRequest}
								/>
								) : (
									<Box sx={{ display: 'flex', justifyContent: 'center' }}>
										<Typography variant="h5" gutterBottom>
											No record found
										</Typography>
									</Box>
								)
						}
					</Card>
				</TabPanel>
				<TabPanel value={value} index={3}>
					<Card sx={{ pb: 2, pt: 2 }}>
						{
							isGetAllRecordsLoading === true ? <Loader /> : getAllRecords?.records ? (
								<TableCard
									token={token}
									pagination={getAllRecords?.pagination}
									getAllRecords={getAllRecords?.records.filter(record => record.declined === false && record.verified === false)}
									handleChangePage={handleChangePage}
									pendingRecords
									handleChangeRowsPerPage={handleChangeRowsPerPage}
									page={page}
									rowsPerPage={rowsPerPage}
									isApproveRecordLoading={isApproveRecordLoading}
									isDeclineRecordLoading={isDeclineRecordLoading}
									approveRecordsRequest={approveRecordsRequest}
									declineRecordsRequest={declineRecordsRequest}
								/>
							) : (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography variant="h5" gutterBottom>
										No record found
									</Typography>
								</Box>
							)
						}
					</Card>
				</TabPanel>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Billing }) => {
	const { token, user } = Authentication;
	const {
		getAllRecords,
		isGetAllRecordsLoading,
		approveRecord,
		isApproveRecordLoading,
		declineRecord,
		isDeclineRecordLoading,
	} = Billing;
	return {
		token,
		user,
		getAllRecords,
		isGetAllRecordsLoading,
		approveRecord,
		isApproveRecordLoading,
		declineRecord,
		isDeclineRecordLoading,
	};
};

export default connect(mapStateToProps, {
	getAllRecordsRequest,
	approveRecordsRequest,
	declineRecordsRequest,
})(AllRecords);

// id(pin):4
// staff_id(pin):4
// account_number(pin):"NBJ0002"
// bill_id(pin):384
// date(pin):"2022-11-11T00:00:00.000Z"
// amount(pin):1000
// verified(pin):false
// createdAt(pin):"2022-11-11T07:23:36.000Z"
// updatedAt(pin):"2022-11-11T07:23:36.000Z"
// deletedAt(pin):null
