export type RecordBillPaymentState = {
	recordBillPayment: null | object;
	isRecordBillPaymentLoading: boolean;
	getPaymentCategories: null | object;
	isGetPaymentCategoriesLoading: boolean;
	getUserByAccountNumber: null | object;
	isGetUserByAccountNumberLoading: boolean;
	error?: null | object;
}

export const initialRecordBillPaymentState: RecordBillPaymentState = {
	recordBillPayment: null,
	isRecordBillPaymentLoading: false,
	getPaymentCategories: null,
	isGetPaymentCategoriesLoading: false,
	getUserByAccountNumber: null,
	isGetUserByAccountNumberLoading: false,
	error: null,
};
