// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyC10MBRKvRp1Q8uISwl_CSayF64Cym-O10',
	authDomain: 'nesco-vending.firebaseapp.com',
	projectId: 'nesco-vending',
	storageBucket: 'nesco-vending.appspot.com',
	messagingSenderId: '269990657960',
	appId: '1:269990657960:web:69beeadd8fb3ff5c9d679d',
	measurementId: 'G-91N18TWRCE',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);
