/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */

import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Card,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Table,
	TextField,
	Box,
	Grid,
	FormControl,
	Button,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { getDebtStatusRequest } from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

function DebtStatus({
	token,
	getDebtStatus,
	isGetDebtStatusLoading,
}) {
	const dispatch = useDispatch();
	const [accountNumber, setAccountNumber] = useState('');
	const [reload, setReload] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const loading = isGetDebtStatusLoading;
	const componentRef = useRef();

	const isDebtStatusNotFound = getDebtStatus?.payment_list.length === 0;

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getDebtStatus?.payment_list.length ?? 0) : 0;

	const TABLE_HEAD = [
		{ id: 'account_number', label: 'Account Number', alignRight: false },
		{ id: 'account_name', label: 'Account Name', alignRight: false },
		{ id: 'receipt_no', label: 'Receipt No', alignRight: false },
		{ id: 'payment_date', label: 'Payment Date', alignRight: false },
		{ id: 'paid_amount', label: 'Amount Paid', alignRight: false },
	];

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSubmit = () => {
		dispatch(getDebtStatusRequest(token, accountNumber));
		setReload(true);
	};

	const handleAccountChange = (event) => {
		setAccountNumber(event.target.value);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		if (token && !reload) {
			dispatch(getDebtStatusRequest(token, accountNumber));
		}
	}, [token, reload]);

	return (
		<Page title="Debt Status">
			<Container maxWidth="xl">
				<Box>

					<FormControl sx={{ minWidth: 120, margin: 1 }}>
						<Grid container>
							<Grid alignItems="center">

								<TextField
									id="account-field"
									label="Account Number"
									shrink
									placeholder="Filter..."
									value={accountNumber}
									onChange={handleAccountChange}
									sx={{ mr: 2 }}
								/>
								<LoadingButton
									type="submit"
									variant="contained"
									disabled={isGetDebtStatusLoading}
									loading={loading}
									color="primary"
									sx={{ height: 1, mr: 2 }}
									onClick={handleSubmit}
								>
									Get Report
								</LoadingButton>
								<Button
									onClick={handlePrint}
									variant="outlined"
									color="inherit"
									sx={{ height: 1 }}
									disabled={isGetDebtStatusLoading}
								>
									Print Report

								</Button>
							</Grid>

						</Grid>
					</FormControl>
					<Card ref={componentRef}>
						{isGetDebtStatusLoading ? <Loader /> : getDebtStatus ? (
							<Box>
								<Grid container p={5}>
									<Grid item xm={24} sm={24} md={24} justifyContent="center">
										<Typography variant="h2" sx={{ textAlign: 'center' }}>NESCO (NIGERIA) LIMITED</Typography>
									</Grid>
									<Grid item xm={24} sm={24} md={24}>
										<Typography variant="h5" sx={{ textAlign: 'center' }}>
											DEBT STATUS REPORT FOR -
											{' '}
											{getDebtStatus?.status_report.account_name}
										</Typography>
									</Grid>
									<Grid item xm={24} sm={24} md={24} pt={2}>
										<Typography variant="h5">
											Account Status:
											{' '}
											{getDebtStatus?.status_report.account_status}
										</Typography>
										<hr />
									</Grid>
									<Grid container justifyContent="right" alignItems="center" sx={{ mt: 2 }}>
										<Grid
											xm={12}
											sm={6}
											md={6}
											sx={{
											height: 1,
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'space-between',
											}}
										>
											<Typography variant="h3">
												Debt Status Summary
											</Typography>
											<Typography variant="h6">
												Account Number:
												{getDebtStatus?.status_report.account_number}
											</Typography>
											<Typography variant="h6">
												Account Status:
												{getDebtStatus?.status_report.account_status}
											</Typography>
										</Grid>
										<Grid xm={12} sm={6} md={6}>
											<Table>
												<tr>
													<td>Last Billed Amount:</td>
													<td align="right">{getDebtStatus?.status_report.last_billed_amount}</td>
												</tr>
												<tr>
													<td>Current Debits:</td>
													<td align="right">{getDebtStatus?.status_report.current_debits}</td>
												</tr>
												<tr>
													<td>Applied payments:</td>
													<td align="right">{getDebtStatus?.status_report.applied_payments}</td>
												</tr>
												<tr>
													<td>Adjustments:</td>
													<td align="right">
														{getDebtStatus?.status_report.adjustments}
														<hr />
													</td>

												</tr>
												<tr>
													<td>Amount Billed:</td>
													<td align="right">{getDebtStatus?.status_report.amount_billed}</td>
												</tr>
												<tr>
													<td>Unproccessed Payments:</td>
													<td align="right">
														{getDebtStatus?.status_report.unprocessed_payments}
														<hr />
													</td>
												</tr>
												<tr>
													<td>Closing Balance:</td>
													<td align="right">
														{getDebtStatus?.status_report.closing_balance}
														<hr />
													</td>
												</tr>
												<br />
												<tr>
													<td>Total Paid Amount:</td>
													<td align="right">
														{getDebtStatus?.total_payment_amount}
													</td>
												</tr>
											</Table>
											<hr />
										</Grid>
										<hr />
									</Grid>

								</Grid>
								<Typography pr={5} pl={5}>
									<hr />
								</Typography>

								<TableContainer sx={{ minWidth: 600 }}>
									<Table>
										<UserListHead
											headLabel={TABLE_HEAD}
											rowCount={getDebtStatus?.payment_list.length}
											onRequestSort={() => {}}
										/>
										<TableBody>
											{
        getDebtStatus?.payment_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const {
            account_number,
            account_name,
            receipt_no,
            payment_date,
			paid_amount,
          } = row;

          return (
	<TableRow
		hover
		key={account_name}
		tabIndex={-1}
		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
	>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{account_number}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{account_name}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{receipt_no}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{dayjs(payment_date).format('YYYY-MM-DD')}
			</Typography>
		</TableCell>
		<TableCell align="left">
			<Typography variant="subtitle2" noWrap>
				{paid_amount}
			</Typography>
		</TableCell>

	</TableRow>
          );
      })
    }
											{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={12} />
												</TableRow>
    )}
										</TableBody>

										{isDebtStatusNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={accountNumber} />
													</TableCell>
												</TableRow>
											</TableBody>
  )}
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={getDebtStatus?.payment_list.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</Box>
         ) : (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Typography variant="h5" gutterBottom>
			No Debt Status Found
		</Typography>
	</Box>
         )}
					</Card>

				</Box>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, User }) => {
	const { token } = Authentication;
	const { getDebtStatus, isGetDebtStatusLoading } = User;
	return {
		token,
		getDebtStatus,
		isGetDebtStatusLoading,
	};
};

export default connect(mapStateToProps)(DebtStatus);
