/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	editMetersRequest,
} from '../../../Store';
import {
	FormProvider, RHFSelect, RHFTextField,
} from '../../../components/hook-form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EditMeterForm({
	view,
	details,
	handleCloseEditDialog,
	isEditMeterLoading,
}) {
	const dispatch = useDispatch();

	const Vending = useSelector((state) => state.Vending);
	const Authentication = useSelector((state) => state.Authentication);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
	});

	const defaultValues = useMemo(
		() => ({
			decoderReferenceNumber: details?.decoderReferenceNumber ?? '',
			allowKrnUpdate: details?.allowKrnUpdate ?? 0,
			tariffIndex: details?.tariffIndex ?? '',
			supplyGroupCode: details?.supplyGroupCode ?? '',
		}),
		[details],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Vending.editMeter) {
			reset();
			enqueueSnackbar('Edit success!');
		}

		if (Vending.error) {
			enqueueSnackbar(Vending.error, {
				variant: 'error',
			});
		}
	}, [Vending.editMeter, Vending.error]);

	const onSubmit = async (data) => {
		dispatch(editMetersRequest(Authentication.token, details.decoderReferenceNumber, data));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Edit
					{' '}
					{details?.decoderReferenceNumber}
					{' '}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update the fields and click the buttons below to save or cancel your changes
					</DialogContentText>
				</DialogContent>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Card sx={{ p: 3 }}>
								<Box
									sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                            }}
								>
									<RHFTextField
										name="decoderReferenceNumber"
										label="Decoder Reference Number"
									/>
									<RHFTextField
										name="tariffIndex"
										label="Tariff Index"
									/>
									<RHFSelect name="allowKrnUpdate" label="Allow KRN Update" placeholder="Allow KRN Update">
										<option value="1">True</option>
										<option value="0">False</option>
									</RHFSelect>
									<RHFTextField
										name="supplyGroupCode"
										label="Supply Group Code"
									/>
								</Box>
								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											disabled={isEditMeterLoading}
											loading={isEditMeterLoading}
										>
											Edit Meter
										</LoadingButton>
									</DialogActions>
								</Stack>
							</Card>
						</Grid>
					</Grid>
				</FormProvider>
			</Box>
		</Dialog>
	);
}
