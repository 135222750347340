/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createBroadcastRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFTextField,
} from '../../../components/hook-form';

export default function BroadcastNewForm() {
	const dispatch = useDispatch();

	const Notification = useSelector((state) => state.Notification);
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		title: Yup.string().required(),
		body: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			title: '',
			body: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Notification.createBroadcast) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/admin/notification/list/');
		}

		if (Notification.error) {
			enqueueSnackbar(Notification.error, {
				variant: 'error',
			});
		}
	}, [Notification.createBroadcast, Notification.error]);

	const onSubmit = async (data) => {
		const payload = {
			...data,
			isDraft: false,
		};
		dispatch(createBroadcastRequest(payload, Authentication.token));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create a New Broadcast
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/admin/notification/list"
					startIcon={<Iconify icon="eva:plus-fill" />}
				>
					View Broadcast
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                }}
							>
								<RHFTextField
									name="title"
									label="Title"
								/>
								<RHFTextField
									id="outlined-multiline-static"
									name="body"
									multiline
									rows={3}
									maxRows={10}
									label="Body"
								/>
							</Box>

							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								{
									Authentication?.user?.role !== 'auditor' && (
										<LoadingButton
											type="submit"
											variant="contained"
											loading={Notification.isCreateBroadcastLoading}
										>
											Create Broadcast
										</LoadingButton>
									)
								}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
