/* eslint-disable react/jsx-filename-extension */
import { Box } from '@mui/material';
import * as React from 'react';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

const Page = forwardRef(({
	children, title = '', meta, ...other
}, ref) => (
	<>
		<Helmet>
			<title>{`${title}`}</title>
			{meta}
		</Helmet>

		<Box ref={ref} {...other}>
			{children}
		</Box>
	</>
));

export default Page;
