export type SubstationState = {
	isGetAllSubstationLoading: boolean;
	getAllSubstation: null | object;
	isCreateSubstationLoading: boolean;
	createSubstation: null | object;
	isEditSubstationLoading: boolean;
	editSubstation: null | object;
	isDeleteSubstationLoading: boolean;
	deleteSubstation: null | object;

	error?: null | object;
}

export const initialSubstationState: SubstationState = {
	isGetAllSubstationLoading: false,
	getAllSubstation: null,
	isCreateSubstationLoading: false,
	createSubstation: null,
	isEditSubstationLoading: false,
	editSubstation: null,
	isDeleteSubstationLoading: false,
	deleteSubstation: null,
};
