/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */

import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';

export default function RHFTextField({ name, ...other }) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...field}
					fullWidth
					error={!!error}
					helperText={error?.message}
					{...other}
				/>
				)}
		/>
	);
}

RHFTextField.propTypes = {
	name: PropTypes.string,
};
