import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { NotificationState, initialNotificationState } from './Types';

const NotificationSlice = createSlice({
	name: 'NotificationSlice',
	initialState: initialNotificationState,
	reducers: {
		setState(state: NotificationState, action: PayloadAction<Partial<NotificationState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllBroadcastRequest(
			state: NotificationState,
			action: PayloadAction<{
				isGetAllBroadcastsLoading: Partial<NotificationState['isGetAllBroadcastsLoading']>,
				getAllBroadcasts: Partial<NotificationState['getAllBroadcasts']>,
				createBroadcast?: Partial<NotificationState['createBroadcast']> | undefined,
			}>,
		) {
			const {
				isGetAllBroadcastsLoading,
				getAllBroadcasts,
				createBroadcast,
			} = action.payload;
			return {
				...state,
				isGetAllBroadcastsLoading,
				getAllBroadcasts,
				createBroadcast,
			};
		},
		getSingleBroadcastRequest(
			state: NotificationState,
			action: PayloadAction<{
				isGetSingleBroadcastLoading: Partial<NotificationState['isGetSingleBroadcastLoading']>,
				getSingleBroadcast: Partial<NotificationState['getSingleBroadcast']>,
			}>,
		) {
			const {
				isGetSingleBroadcastLoading,
				getSingleBroadcast,
			} = action.payload;
			return {
				...state,
				isGetSingleBroadcastLoading,
				getSingleBroadcast,
			};
		},
		createBroadcastRequest(
			state: NotificationState,
			action: PayloadAction<{
				isCreateBroadcastLoading: Partial<NotificationState['isCreateBroadcastLoading']>,
				createBroadcast: Partial<NotificationState['createBroadcast']>,
				error?: Partial<NotificationState['error']> | undefined,
			}>,
		) {
			const {
				isCreateBroadcastLoading,
				createBroadcast,
				error,
			} = action.payload;
			return {
				...state,
				isCreateBroadcastLoading,
				createBroadcast,
				error,
			};
		},
		editBroadcastRequest(
			state: NotificationState,
			action: PayloadAction<{
				isEditBroadcastLoading: Partial<NotificationState['isEditBroadcastLoading']>,
				editBroadcast: Partial<NotificationState['editBroadcast']>,
				error?: Partial<NotificationState['error']> | undefined,
			}>,
		) {
			const {
				isEditBroadcastLoading,
				editBroadcast,
				error,
			} = action.payload;
			return {
				...state,
				isEditBroadcastLoading,
				editBroadcast,
				error,
			};
		},
		resendBroadcastRequest(
			state: NotificationState,
			action: PayloadAction<{
				isResendBroadcastLoading: Partial<NotificationState['isResendBroadcastLoading']>,
				resendBroadcast: Partial<NotificationState['resendBroadcast']>,
				error?: Partial<NotificationState['error']> | undefined,
			}>,
		) {
			const {
				isResendBroadcastLoading,
				resendBroadcast,
				error,
			} = action.payload;
			return {
				...state,
				isResendBroadcastLoading,
				resendBroadcast,
				error,
			};
		},
		deleteBroadcastRequest(
			state: NotificationState,
			action: PayloadAction<{
				isDeleteBroadcastLoading: Partial<NotificationState['isDeleteBroadcastLoading']>,
				getAllBroadcasts?: Partial<NotificationState['getAllBroadcasts']>,
				deleteBroadcast: Partial<NotificationState['deleteBroadcast']>,
				error?: Partial<NotificationState['error']> | undefined,
			}>,
		) {
			const {
				isDeleteBroadcastLoading,
				getAllBroadcasts,
				deleteBroadcast,
				error,
			} = action.payload;
			return {
				...state,
				isDeleteBroadcastLoading,
				getAllBroadcasts,
				deleteBroadcast,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllBroadcastRequest: _getAllBroadcastRequest,
	getSingleBroadcastRequest: _getSingleBroadcastRequest,
	createBroadcastRequest: _createBroadcastRequest,
	editBroadcastRequest: _editBroadcastRequest,
	resendBroadcastRequest: _resendBroadcastRequest,
	deleteBroadcastRequest: _deleteBroadcastRequest,
} = NotificationSlice.actions;

export const getAllBroadcastRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllBroadcastRequest({
		...initialNotificationState,
		isGetAllBroadcastsLoading: true,
		createBroadcast: null,
	}));
	try {
		const apiResponseData = await API.getAllBroadcast(token);
		dispatch(_getAllBroadcastRequest({
			isGetAllBroadcastsLoading: false,
			getAllBroadcasts: apiResponseData?.data?.broadcasts,
		}));
	} catch (error) {
		dispatch(_getAllBroadcastRequest({
			...initialNotificationState,
			isGetAllBroadcastsLoading: false,
		}));
		dispatch(_setState<Partial<NotificationState>>(
			{
				error: error.message ?? 'Unable to get all broadcast. Please try again.',
			},
		));
	}
};

export const getSingleBroadcastRequest = (token: any, broadcastId: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSingleBroadcastRequest({
		...initialNotificationState,
		isGetSingleBroadcastLoading: true,
		getSingleBroadcast: null,
	}));
	try {
		const apiResponseData = await API.getSingleBroadcast(token, broadcastId);
		dispatch(_getSingleBroadcastRequest({
			isGetSingleBroadcastLoading: false,
			getSingleBroadcast: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getSingleBroadcastRequest({
			...initialNotificationState,
			isGetSingleBroadcastLoading: false,
		}));
		dispatch(_setState<Partial<NotificationState>>(
			{
				error: error.message ?? 'Unable to get broadcast. Please try again.',
			},
		));
	}
};

export const createBroadcastRequest = (userData: any, token: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createBroadcastRequest({
		...initialNotificationState,
		isCreateBroadcastLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.createBroadcast(token, userData);
		dispatch(_createBroadcastRequest({
			isCreateBroadcastLoading: false,
			createBroadcast: apiResponseData,
		}));
	} catch (error) {
		dispatch(_createBroadcastRequest({
			...initialNotificationState,
			isCreateBroadcastLoading: false,
		}));
		dispatch(_setState<Partial<NotificationState>>(
			{
				error: error.message ?? 'Unable to create broadcast. Please try again.',
			},
		));
	}
};

export const resendBroadcastRequest = (
	token: string,
	broadcastId:any,
	broadcastData:any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_resendBroadcastRequest({
		...initialNotificationState,
		isResendBroadcastLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.resendBroadcast(
			token,
			broadcastId,
			broadcastData,
		);
		dispatch(_resendBroadcastRequest({
			isResendBroadcastLoading: false,
			resendBroadcast: apiResponseData,
		}));
	} catch (error) {
		dispatch(_resendBroadcastRequest({
			...initialNotificationState,
			isResendBroadcastLoading: false,
		}));
		dispatch(_setState<Partial<NotificationState>>(
			{
				error: error.message ?? 'Unable to resend broadcast. Please try again.',
			},
		));
	}
};

export const editBroadcastRequest = (
	token: string,
	broadcastId:any,
	broadcastData:any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_editBroadcastRequest({
		...initialNotificationState,
		isEditBroadcastLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.editBroadcast(
			token,
			broadcastId,
			broadcastData,
		);
		dispatch(_editBroadcastRequest({
			isEditBroadcastLoading: false,
			editBroadcast: apiResponseData,
		}));
	} catch (error) {
		dispatch(_editBroadcastRequest({
			...initialNotificationState,
			isEditBroadcastLoading: false,
		}));
		dispatch(_setState<Partial<NotificationState>>(
			{
				error: error.message ?? 'Unable to edit broadcast. Please try again.',
			},
		));
	}
};

export const deleteBroadcastRequest = (
	token: string,
	broadcastId: any,
	broadcasts?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_deleteBroadcastRequest({
		...initialNotificationState,
		isDeleteBroadcastLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.deleteBroadcast(
			token,
			broadcastId,
		);
		if (broadcasts) {
			dispatch(_deleteBroadcastRequest({
				isDeleteBroadcastLoading: false,
				getAllBroadcasts: broadcasts.filter((broadcast: any) => broadcast.id !== broadcastId),
				deleteBroadcast: apiResponseData,
			}));
		}

		if (!broadcasts) {
			dispatch(_deleteBroadcastRequest({
				isDeleteBroadcastLoading: false,
				deleteBroadcast: apiResponseData,
			}));
		}
	} catch (error) {
		dispatch(_deleteBroadcastRequest({
			...initialNotificationState,
			isDeleteBroadcastLoading: false,
		}));
		dispatch(_setState<Partial<NotificationState>>(
			{
				error: error.message ?? 'Unable to delete broadcast. Please try again.',
			},
		));
	}
};

export const { reducer: Notification } = NotificationSlice;
