/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */

import {
	Radio, RadioGroup, FormHelperText, FormControlLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui

// ----------------------------------------------------------------------

export default function RHFRadioGroup({
	name, options, getOptionLabel, ...other
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<div>
					<RadioGroup {...field} row {...other}>
						{options.map((option, index) => (
							<FormControlLabel
								key={option}
								value={option}
								control={<Radio />}
								label={getOptionLabel?.length ? getOptionLabel[index] : option}
							/>
            ))}
					</RadioGroup>

					{!!error && (
					<FormHelperText error sx={{ px: 2 }}>
						{error.message}
					</FormHelperText>
          )}
				</div>
      )}
		/>
	);
}

RHFRadioGroup.propTypes = {
	name: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.string),
	getOptionLabel: PropTypes.arrayOf(PropTypes.string),
};
