/* eslint-disable react/jsx-filename-extension */
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

export function Loader() {
	return (
		<Box>
			<Box
				sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			>
				<CircularProgress />
			</Box>
		</Box>

	);
}
