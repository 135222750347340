import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { LogsState, initialLogsState } from './Types';

const LogsSlice = createSlice({
	name: 'LogsSlice',
	initialState: initialLogsState,
	reducers: {
		setState(state: LogsState, action: PayloadAction<Partial<LogsState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllLogsRequest(
			state: LogsState,
			action: PayloadAction<{
				isGetAllLogsLoading: Partial<LogsState['isGetAllLogsLoading']>,
				getAllLogs: Partial<LogsState['getAllLogs']>,
			}>,
		) {
			const {
				isGetAllLogsLoading,
				getAllLogs,
			} = action.payload;
			return {
				...state,
				isGetAllLogsLoading,
				getAllLogs,
			};
		},
	},
});

const {
	setState: _setState,
	getAllLogsRequest: _getAllLogsRequest,
} = LogsSlice.actions;

export const getAllLogsRequest = (token: any, type?: string | undefined): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllLogsRequest({
		...initialLogsState,
		isGetAllLogsLoading: true,
	}));
	try {
		const apiResponseData = await API.getAllLogs(token, type);
		dispatch(_getAllLogsRequest({
			isGetAllLogsLoading: false,
			getAllLogs: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllLogsRequest({
			...initialLogsState,
			isGetAllLogsLoading: false,
		}));
		dispatch(_setState<Partial<LogsState>>(
			{
				error: error.message ?? 'Unable to get all logs. Please try again.',
			},
		));
	}
};

export const { reducer: Logs } = LogsSlice;
