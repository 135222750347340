/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
import {
	Box,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// @mui

// hooks
import Image from '../../../components/Image';
// components

// ----------------------------------------------------------------------

const OverlayStyle = styled('h1')(({ theme }) => ({
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: 9,
	position: 'absolute',
	backgroundColor: alpha(theme.palette.grey[900], 0.72),
}));

const TitleStyle = styled('h1')(({ theme }) => ({
	...theme.typography.h2,
	top: 0,
	zIndex: 10,
	width: '100%',
	position: 'absolute',
	padding: theme.spacing(3),
	color: theme.palette.common.white,
	[theme.breakpoints.up('lg')]: {
		padding: theme.spacing(10),
	},
}));

// ----------------------------------------------------------------------

export default function BlogPostHero({ news }) {
	const {
		cover_image, title,
	} = news;

	return (
		<Box sx={{ position: 'relative' }}>
			<TitleStyle>{title}</TitleStyle>
			<OverlayStyle />
			<Image alt="cover image" src={cover_image} ratio="16/9" />
		</Box>
	);
}
