import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { SubstationState, initialSubstationState } from './Types';

const Substationslice = createSlice({
	name: 'Substationslice',
	initialState: initialSubstationState,
	reducers: {
		setState(state: SubstationState, action: PayloadAction<Partial<SubstationState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllSubstationRequest(
			state: SubstationState,
			action: PayloadAction<{
				isGetAllSubstationLoading: Partial<SubstationState['isGetAllSubstationLoading']>,
				getAllSubstation: Partial<SubstationState['getAllSubstation']>,
			}>,
		) {
			const {
				isGetAllSubstationLoading,
				getAllSubstation,
			} = action.payload;
			return {
				...state,
				isGetAllSubstationLoading,
				getAllSubstation,
			};
		},
		createSubstationRequest(
			state: SubstationState,
			action: PayloadAction<{
				isCreateSubstationLoading: Partial<SubstationState['isCreateSubstationLoading']>,
				createSubstation: Partial<SubstationState['createSubstation']>,
				error?: Partial<SubstationState['error']> | undefined,
			}>,
		) {
			const {
				isCreateSubstationLoading,
				createSubstation,
				error,
			} = action.payload;
			return {
				...state,
				isCreateSubstationLoading,
				createSubstation,
				error,
			};
		},
		editSubstationRequest(
			state: SubstationState,
			action: PayloadAction<{
				isEditSubstationLoading: Partial<SubstationState['isEditSubstationLoading']>,
				editSubstation: Partial<SubstationState['editSubstation']>,
				error?: Partial<SubstationState['error']> | undefined,
			}>,
		) {
			const {
				isEditSubstationLoading,
				editSubstation,
				error,
			} = action.payload;
			return {
				...state,
				isEditSubstationLoading,
				editSubstation,
				error,
			};
		},
		deleteSubstationRequest(
			state: SubstationState,
			action: PayloadAction<{
				isDeleteSubstationLoading: Partial<SubstationState['isDeleteSubstationLoading']>,
				deleteSubstation: Partial<SubstationState['deleteSubstation']>,
				getAllSubstation?: Partial<SubstationState['getAllSubstation']> | undefined,
				error?: Partial<SubstationState['error']> | undefined,
			}>,
		) {
			const {
				isDeleteSubstationLoading,
				deleteSubstation,
				getAllSubstation,
				error,
			} = action.payload;
			return {
				...state,
				isDeleteSubstationLoading,
				deleteSubstation,
				getAllSubstation,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllSubstationRequest: _getAllSubstationRequest,
	createSubstationRequest: _createSubstationRequest,
	editSubstationRequest: _editSubstationRequest,
	deleteSubstationRequest: _deleteSubstationRequest,
} = Substationslice.actions;

export const getAllSubstationRequest = (token: any, feederSlug: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllSubstationRequest({
		...initialSubstationState,
		isGetAllSubstationLoading: true,
	}));
	try {
		const apiResponseData = await API.getSubstations(token, feederSlug);
		dispatch(_getAllSubstationRequest({
			isGetAllSubstationLoading: false,
			getAllSubstation: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllSubstationRequest({
			...initialSubstationState,
			isGetAllSubstationLoading: false,
		}));
		dispatch(_setState<Partial<SubstationState>>(
			{
				error: error.message ?? 'Unable to get substations. Please try again.',
			},
		));
	}
};

export const createSubstationRequest = (
	token: string,
	substationData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createSubstationRequest({
		...initialSubstationState,
		isCreateSubstationLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.createSubstations(
			token,
			substationData,
		);
		if (apiResponseData) {
			dispatch(_createSubstationRequest({
				isCreateSubstationLoading: false,
				createSubstation: apiResponseData.message,
			}));
		}
	} catch (error) {
		dispatch(_createSubstationRequest({
			...initialSubstationState,
			isCreateSubstationLoading: false,
		}));
		dispatch(_setState<Partial<SubstationState>>(
			{
				error: error.message ?? 'Unable to create substation. Please try again.',
			},
		));
	}
};

export const editSubstationRequest = (
	token: string,
	substationId: string,
	substationData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_editSubstationRequest({
		...initialSubstationState,
		isEditSubstationLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.editSubstation(
			token,
			substationId,
			substationData,
		);
		if (apiResponseData) {
			dispatch(_editSubstationRequest({
				isEditSubstationLoading: false,
				editSubstation: apiResponseData.message,
			}));
		}
	} catch (error) {
		dispatch(_editSubstationRequest({
			...initialSubstationState,
			isEditSubstationLoading: false,
		}));
		dispatch(_setState<Partial<SubstationState>>(
			{
				error: error.message ?? 'Unable to edit substation. Please try again.',
			},
		));
	}
};

export const deleteSubstationRequest = (
	token: string,
	substationId: any,
	substations?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_deleteSubstationRequest({
		...initialSubstationState,
		isDeleteSubstationLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.deleteSubstation(
			token,
			substationId,
		);
		if (apiResponseData) {
			dispatch(_deleteSubstationRequest({
				isDeleteSubstationLoading: false,
				deleteSubstation: apiResponseData.message,
				getAllSubstation: substations.filter((substation: any) => substation.id !== substationId),
			}));
		}
	} catch (error) {
		dispatch(_deleteSubstationRequest({
			...initialSubstationState,
			isDeleteSubstationLoading: false,
		}));
		dispatch(_setState<Partial<SubstationState>>(
			{
				error: error.message ?? 'Unable to delete substation. Please try again.',
			},
		));
	}
};

export const { reducer: Substation } = Substationslice;
