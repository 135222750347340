/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { CreateConsumerForm } from '../sections/@dashboard/consumer';

export default function CreateConsumer() {
	return (
		<Page title="User: Create a new consumer">
			<Container>
				<CreateConsumerForm />
			</Container>
		</Page>
	);
}
