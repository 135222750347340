/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { messageToDisplay } from '../../functions';
import { VendingState, initialVendingState } from './Types';

const vendingSlice = createSlice({
	name: 'vendingSlice',
	initialState: initialVendingState,
	reducers: {
		setState(state: VendingState, action: PayloadAction<Partial<VendingState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		addNewMetersRequest(
			state: VendingState,
			action: PayloadAction<{
				isAddNewMeterLoading: Partial<VendingState['isAddNewMeterLoading']>,
				addNewMeter: Partial<VendingState['addNewMeter']> | undefined,
			}>,
		) {
			const {
				isAddNewMeterLoading,
				addNewMeter,
			} = action.payload;
			return {
				...state,
				isAddNewMeterLoading,
				addNewMeter,
			};
		},
		editMetersRequest(
			state: VendingState,
			action: PayloadAction<{
				isEditMeterLoading: Partial<VendingState['isEditMeterLoading']>,
				editMeter: Partial<VendingState['editMeter']> | undefined,
			}>,
		) {
			const {
				isEditMeterLoading,
				editMeter,
			} = action.payload;
			return {
				...state,
				isEditMeterLoading,
				editMeter,
			};
		},
		getMetersRequest(
			state: VendingState,
			action: PayloadAction<{
				isGetMetersLoading: Partial<VendingState['isGetMetersLoading']>,
				getMeter: Partial<VendingState['getMeter']> | undefined,
			}>,
		) {
			const {
				isGetMetersLoading,
				getMeter,
			} = action.payload;
			return {
				...state,
				isGetMetersLoading,
				getMeter,
			};
		},
		getConsumerByMeterNumberRequest(
			state: VendingState,
			action: PayloadAction<{
				isGetConsumerByMeterNumberLoading: Partial<VendingState['isGetConsumerByMeterNumberLoading']>,
				getConsumerByMeterNumber: Partial<VendingState['getConsumerByMeterNumber']>,
			}>,
		) {
			const {
				isGetConsumerByMeterNumberLoading,
				getConsumerByMeterNumber,

			} = action.payload;
			return {
				...state,
				isGetConsumerByMeterNumberLoading,
				getConsumerByMeterNumber,
			};
		},
		issueCreditTokenRequest(
			state: VendingState,
			action: PayloadAction<{
				isIssueCreditTokenLoading: Partial<VendingState['isIssueCreditTokenLoading']>,
				issueCreditToken: Partial<VendingState['issueCreditToken']>,
				error?: Partial<VendingState['error']>,
			}>,
		) {
			const { isIssueCreditTokenLoading, issueCreditToken, error } = action.payload;
			return {
				...state,
				isIssueCreditTokenLoading,
				issueCreditToken,
				error,
			};
		},
		getTokenPurchaseHistoryRequest(
			state: VendingState,
			action: PayloadAction<{
				isGetTokenPurchaseHistoryLoading: Partial<VendingState['isGetTokenPurchaseHistoryLoading']>,
				getTokenPurchaseHistory: Partial<VendingState['getTokenPurchaseHistory']> | undefined,
			}>,
		) {
			const {
				isGetTokenPurchaseHistoryLoading,
				getTokenPurchaseHistory,
			} = action.payload;
			return {
				...state,
				isGetTokenPurchaseHistoryLoading,
				getTokenPurchaseHistory,
			};
		},
		issueKCTRequest(
			state: VendingState,
			action: PayloadAction<{
				isIssueKCTLoading: Partial<VendingState['isIssueKCTLoading']>,
				issueKCT: Partial<VendingState['issueKCT']>,
			}>,
		) {
			const { isIssueKCTLoading, issueKCT } = action.payload;
			return {
				...state,
				isIssueKCTLoading,
				issueKCT,
			};
		},
		issueTamperCodeToken(
			state: VendingState,
			action: PayloadAction<{
				isIssueTamperCodeTokenLoading: Partial<VendingState['isIssueTamperCodeTokenLoading']>,
				issueTamperCodeToken: Partial<VendingState['issueTamperCodeToken']>,
				error?: Partial<VendingState['error']>,
			}>,
		) {
			const { isIssueTamperCodeTokenLoading, issueTamperCodeToken, error } = action.payload;
			return {
				...state,
				isIssueTamperCodeTokenLoading,
				issueTamperCodeToken,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	addNewMetersRequest: _addNewMetersRequest,
	editMetersRequest: _editMetersRequest,
	getMetersRequest: _getMetersRequest,
	getConsumerByMeterNumberRequest: _getConsumerByMeterNumberRequest,
	issueCreditTokenRequest: _issueCreditTokenRequest,
	issueTamperCodeToken: _issueTamperCodeToken,
	getTokenPurchaseHistoryRequest: _getTokenPurchaseHistoryRequest,
	issueKCTRequest: _issueKCTRequest,
} = vendingSlice.actions;

export const addNewMetersRequest = (
	token: any,
	meterData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_addNewMetersRequest({
		...initialVendingState,
		isAddNewMeterLoading: true,
	}));
	try {
		const apiResponseData = await API.addNewMeter(token, meterData);
		dispatch(_addNewMetersRequest({
			isAddNewMeterLoading: false,
			addNewMeter: apiResponseData,
		}));
	} catch (error) {
		dispatch(_addNewMetersRequest({
			...initialVendingState,
			isAddNewMeterLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to add new meter. Please try again.',
			},
		));
	}
};

export const editMetersRequest = (
	token: any,
	meterNumber: any,
	meterData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_editMetersRequest({
		...initialVendingState,
		isEditMeterLoading: true,
	}));
	try {
		const apiResponseData = await API.editMeter(token, meterNumber, meterData);
		dispatch(_editMetersRequest({
			isEditMeterLoading: false,
			editMeter: apiResponseData,
		}));
	} catch (error) {
		dispatch(_editMetersRequest({
			...initialVendingState,
			isEditMeterLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to edit meter. Please try again.',
			},
		));
	}
};

export const getMetersRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getMetersRequest({
		...initialVendingState,
		isGetMetersLoading: true,
	}));
	try {
		const apiResponseData = await API.getMeters(token);
		dispatch(_getMetersRequest({
			isGetMetersLoading: false,
			getMeter: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getMetersRequest({
			...initialVendingState,
			isGetMetersLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: error.message ?? 'Unable to get meters. Please try again.',
			},
		));
	}
};

export const getConsumerByMeterNumberRequest = (
	token: any,
	decoderReferenceNumber: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getConsumerByMeterNumberRequest({
		...initialVendingState,
		isGetConsumerByMeterNumberLoading: true,
		getConsumerByMeterNumber: null,
	}));
	try {
		const apiResponseData = await API.getConsumerByMeterNumber(token, decoderReferenceNumber);
		dispatch(_getConsumerByMeterNumberRequest({
			isGetConsumerByMeterNumberLoading: false,
			getConsumerByMeterNumber: apiResponseData,
		}));
	} catch (error) {
		dispatch(_getConsumerByMeterNumberRequest({
			...initialVendingState,
			isGetConsumerByMeterNumberLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: error.message ?? 'Unable to find consumer. Please try again.',
			},
		));
	}
};
export const issueCreditTokenRequest = (
	token: any,
	creditTokenData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_issueCreditTokenRequest({
		...initialVendingState,
		isIssueCreditTokenLoading: true,
		issueCreditToken: null,
		error: null,
	}));
	try {
		const apiResponseData = await API.issueCreditToken(
			token,
			creditTokenData,
		);
		dispatch(_issueCreditTokenRequest({
			isIssueCreditTokenLoading: false,
			issueCreditToken: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_issueCreditTokenRequest({
			...initialVendingState,
			isIssueCreditTokenLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to issue credit token. Please try again.',
			},
		));
	}
};

export const issueTamperCodeTokenRequest = (
	token: any,
	meterNumber: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_issueTamperCodeToken({
		...initialVendingState,
		isIssueTamperCodeTokenLoading: true,
		issueTamperCodeToken: null,
		error: null,
	}));
	try {
		const apiResponseData = await API.issueTamperCodeToken(
			token,
			meterNumber,
		);
		dispatch(_issueTamperCodeToken({
			isIssueTamperCodeTokenLoading: false,
			issueTamperCodeToken: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_issueTamperCodeToken({
			...initialVendingState,
			isIssueTamperCodeTokenLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to issue tamper code/token. Please try again.',
			},
		));
	}
};

export const getTokenPurchaseHistoryRequest = (
	token: any,
	date?: any,
	meterNumber?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getTokenPurchaseHistoryRequest({
		...initialVendingState,
		isGetTokenPurchaseHistoryLoading: true,
	}));
	try {
		const apiResponseData = await API.getTokenPurchaseHistory(token, date, meterNumber);
		dispatch(_getTokenPurchaseHistoryRequest({
			isGetTokenPurchaseHistoryLoading: false,
			getTokenPurchaseHistory: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getTokenPurchaseHistoryRequest({
			...initialVendingState,
			isGetTokenPurchaseHistoryLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: error.message ?? 'Unable to get history. Please try again.',
			},
		));
	}
};

export const issueKCTRequest = (
	token: any,
	kctData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_issueKCTRequest({
		...initialVendingState,
		isIssueKCTLoading: true,
		issueKCT: null,
	}));
	try {
		const apiResponseData = await API.issueKCT(
			token,
			kctData,
		);
		dispatch(_issueKCTRequest({
			isIssueKCTLoading: false,
			issueKCT: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_issueKCTRequest({
			...initialVendingState,
			isIssueKCTLoading: false,
		}));
		dispatch(_setState<Partial<VendingState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to issue KCT. Please try again.',
			},
		));
	}
};

export const { reducer: Vending } = vendingSlice;
