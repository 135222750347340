/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const printBillfromArray = async (token: any, month?: string, year?: string, district?: string, accountNumber?: string, status?: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const queryParams = [
		{ label: 'month', value: month },
		{ label: 'year', value: year },
		{ label: 'district', value: district },
		{ label: 'account_number', value: accountNumber },
		{ label: 'status', value: status },
	];

	let link = '/billing/bill/print?';

	queryParams.forEach(({ label, value }) => {
		if (value) {
			link += `${label}=${value}&`;
		}
	});

	const response = await axios.get(link, config);
	return response.data;
};
