/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getAllDistricts = async (token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get('/meter-reading/districts', config);
	return response.data;
};

export const getMeterReadingReport = async (token: any, districtCode: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get(`/meter-reading/report/${districtCode}`, config);
	return response.data;
};

export const getPrintPreviews = async (token: any, districtCode: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get(`billing/bill/view?district=${districtCode}`, config);
	return response.data;
};
