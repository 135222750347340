/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Tab,
	Tabs,
	Box,
} from '@mui/material';
import { filter } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';

import { API_BASE_URL } from '../../Environment';
import {
	getMetersRequest,
} from '../../Store';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import { Loader } from '../../components/Loader';
import Page from '../../components/Page';
import SearchNotFound from '../../components/SearchNotFound';
import { EditMeterForm } from '../../sections/@dashboard/prepaid';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'decoderReferenceNumber', label: 'Decoder Ref Number', alignRight: false },
	{ id: 'encryptionAlgorithm', label: 'Encryption Algorithm', alignRight: false },
	{ id: 'tokenCarrierType', label: 'Token Carrier Type', alignRight: false },
	{ id: 'supplyGroupCode', label: 'Supply Group Code', alignRight: false },
	{ id: 'dateOfExpiry', label: 'Date of Expiry', alignRight: false },
	{ id: 'keyRevisionNumber', label: 'Key Revision Number', alignRight: false },
	{ id: 'tariffIndex', label: 'Tarrif Index', alignRight: false },
	{ id: 'allowKrnUpdate', label: 'Allow KRN Update', alignRight: false },
	{ id: 'keyExpiryNumber', label: 'Key Expiry Number', alignRight: false },
	{ id: 'isAllocated', label: 'Allocated', alignRight: false },
	{ id: 'createdAt', label: 'Created', alignRight: false },
	{ id: 'updatedAt', label: 'Updated', alignRight: false },
	{ id: '', label: 'Actions', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.decoderReferenceNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function TableCard({
	pagination,
	getMeter,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
	isEditMeterLoading,
	editView,
	setEditView,
	editDetails,
	setEditDetails,
	user,
	isAllocated,
	isPending,
	token,
}) {
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');

	const [downloadPendingLoading, setDownloadPendingLoading] = React.useState(false);
	const [downloadAllocatedLoading, setDownloadAllocatedLoading] = React.useState(false);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getMeter?.map((n) => n.decoderReferenceNumber);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagination.pageSize ?? 0) : 0;

	const filteredUsers = getMeter && applySortFilter(getMeter, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const handleSetEditView = (id) => {
		setEditView(true);
		setEditDetails(filteredUsers.filter(account => account.id === id));
	};

	const handleCloseEditDialog = () => setEditView(false);

	const downloadAllocatedLink = `${API_BASE_URL}/vending/meter/excel/?allocated=true`;
	const downloadPendingLink = `${API_BASE_URL}/vending/meter/excel/?allocated=false`;

	const handleDownloadAllocatedExcel = async () => {
		setDownloadAllocatedLoading(true);
		try {
			const result = await fetch(downloadAllocatedLink, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const blob = await result.blob();
			const href = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.download = 'allocated.xlsx';
			link.href = href;
			document.body.appendChild(link);
			link.click();
			setDownloadAllocatedLoading(false);
		} catch (error) {
			setDownloadAllocatedLoading(false);
		}
	};

	const handleDownloadPendingExcel = async () => {
		setDownloadPendingLoading(true);
		try {
			const result = await fetch(downloadPendingLink, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const blob = await result.blob();
			const href = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.download = 'pending.xlsx';
			link.href = href;
			document.body.appendChild(link);
			link.click();
			setDownloadPendingLoading(false);
		} catch (error) {
			setDownloadPendingLoading(false);
		}
	};

	return (
		<Box>
			<Stack direction="row" mb={2} alignItems="center" justifyContent="space-between" marginRight={2}>
				<UserListToolbar placeholder="Search Decoder Reference Number" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
				{
					isAllocated && (
						<LoadingButton
							onClick={handleDownloadAllocatedExcel}
							variant="outlined"
							color="inherit"
							sx={{ height: 1 }}
							loading={downloadAllocatedLoading}
							disabled={downloadAllocatedLoading}
						>
							Download Excel

						</LoadingButton>
					)
				}
				{
					isPending && (
						<LoadingButton
							onClick={handleDownloadPendingExcel}
							variant="outlined"
							color="inherit"
							sx={{ height: 1 }}
							loading={downloadPendingLoading}
							disabled={downloadPendingLoading}
						>
							Download Excel

						</LoadingButton>
					)
				}
			</Stack>
			<TableContainer sx={{ minWidth: 900 }}>
				<Table>
					<UserListHead
						order={order}
						orderBy={orderBy}
						headLabel={TABLE_HEAD}
						rowCount={getMeter?.meters?.length}
						numSelected={selected.length}
						onRequestSort={handleRequestSort}
						onSelectAllClick={handleSelectAllClick}
					/>
					<TableBody>
						{
							filteredUsers
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									const {
										id,
										decoderReferenceNumber,
										dateOfExpiry,
										encryptionAlgorithm,
										tokenCarrierType,
										supplyGroupCode,
										keyRevisionNumber,
										tariffIndex,
										allowKrnUpdate,
										keyExpiryNumber,
										isAllocated,
										createdAt,
										updatedAt,
									} = row;
									return (
										<TableRow
											hover
											key={id}
											tabIndex={-1}
											role="checkbox"
										>
											<TableCell align="left">
												<Stack direction="row" alignItems="center" spacing={2}>
													<Typography variant="subtitle2" noWrap>
														{decoderReferenceNumber}
													</Typography>
												</Stack>
											</TableCell>
											<TableCell align="left">{encryptionAlgorithm}</TableCell>
											<TableCell align="left">{tokenCarrierType}</TableCell>
											<TableCell align="left">{supplyGroupCode}</TableCell>
											<TableCell align="left">{moment(dateOfExpiry).format('D/M/YY')}</TableCell>
											<TableCell align="left">{keyRevisionNumber}</TableCell>
											<TableCell align="left">{tariffIndex}</TableCell>
											<TableCell align="left">{allowKrnUpdate}</TableCell>
											<TableCell align="left">{keyExpiryNumber}</TableCell>
											<TableCell align="left">
												<Label variant="ghost" color={!isAllocated ? 'error' : 'success'}>
													{!isAllocated ? 'No' : 'Yes'}
												</Label>
											</TableCell>
											<TableCell align="left">{moment(createdAt).format('D/M/YY')}</TableCell>
											<TableCell align="left">{moment(updatedAt).format('D/M/YY')}</TableCell>
											{/* '', */}
											{
												((user?.role === 'store') || (user?.role === 'superadmin') || (user?.role === 'meter_engineer') || (user?.role === 'revenue_officer') || (user?.role === 'area_distribution_engineer') || (user?.role === 'revenue_protection')) ? (
													<TableCell align="left">
														<LoadingButton
															type="submit"
															variant="outlined"
															color="success"
															startIcon={<Iconify icon="bxs:edit" />}
															onClick={() => handleSetEditView(id)}
														>
															Edit
														</LoadingButton>
													</TableCell>
													) : <Box />
											}
										</TableRow>
									);
								})
						}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={12} />
							</TableRow>
						)}
					</TableBody>
					{isUserNotFound && (
						<TableBody>
							<TableRow>
								<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
									<SearchNotFound searchQuery={filterName} />
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={getMeter?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			{
				editView && (
					<EditMeterForm
						view={editView}
						details={editDetails[0]}
						handleCloseEditDialog={handleCloseEditDialog}
						isEditMeterLoading={isEditMeterLoading}
					/>
				)
			}
		</Box>

	);
}

function TabPanel(props) {
	const {
		children, value, index, ...other
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function AllRecords({
	token,
	getMeter,
	isGetMetersLoading,
	getMetersRequest,
	isEditMeterLoading,
	editMeter,
	user,
}) {
	React.useEffect(() => {
		if (token) {
			getMetersRequest(token);
		}
	}, [token]);

	const [page, setPage] = React.useState(0);

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const [value, setValue] = React.useState(0);

	const [editView, setEditView] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState(null);

	React.useEffect(() => {
		if (editMeter) {
			setEditView(false);
			setEditDetails(null);
			getMetersRequest(token);
		}
	}, [editMeter]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const loading = isGetMetersLoading;

	return (
		<Page title="Get Meters">
			<Container maxWidth="xl">
				{
						loading ? <Loader /> : getMeter?.meters ? (
							<Box>
								<Tabs value={value} onChange={handleChange} aria-label="meter reading">
									<Tab label="All" {...a11yProps(0)} />
									<Tab label="Allocated" {...a11yProps(1)} />
									<Tab label="Pending" {...a11yProps(2)} />
								</Tabs>
								<TabPanel value={value} index={0}>
									<Card sx={{ pb: 2, pt: 2 }}>
										{
											loading ? <Loader />
												: getMeter ? (
													<TableCard
														pagination={getMeter.pagination}
														getMeter={getMeter.meters}
														handleChangePage={handleChangePage}
														handleChangeRowsPerPage={handleChangeRowsPerPage}
														page={page}
														rowsPerPage={rowsPerPage}
														isEditMeterLoading={isEditMeterLoading}
														editMeter={editMeter}
														editView={editView}
														setEditView={setEditView}
														editDetails={editDetails}
														setEditDetails={setEditDetails}
														user={user}
														token={token}
													/>
												) : (
													<Box sx={{ display: 'flex', justifyContent: 'center' }}>
														<Typography variant="h5" gutterBottom>
															No Meter Found
														</Typography>
													</Box>
												)
										}
									</Card>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<Card sx={{ pb: 2, pt: 2 }}>
										{
											loading ? <Loader />
												: getMeter ? (
													<TableCard
														pagination={getMeter.pagination}
														getMeter={getMeter.meters.filter(reading => reading.isAllocated === true)}
														handleChangePage={handleChangePage}
														handleChangeRowsPerPage={handleChangeRowsPerPage}
														page={page}
														rowsPerPage={rowsPerPage}
														isEditMeterLoading={isEditMeterLoading}
														editMeter={editMeter}
														editView={editView}
														setEditView={setEditView}
														editDetails={editDetails}
														setEditDetails={setEditDetails}
														user={user}
														isAllocated
														token={token}
													/>
												) : (
													<Box sx={{ display: 'flex', justifyContent: 'center' }}>
														<Typography variant="h5" gutterBottom>
															No Reading Found
														</Typography>
													</Box>
												)
										}
									</Card>
								</TabPanel>
								<TabPanel value={value} index={2}>
									<Card sx={{ pb: 2, pt: 2 }}>
										{
											loading ? <Loader />
												: getMeter ? (
													<TableCard
														pagination={getMeter.pagination}
														getMeter={getMeter.meters.filter(reading => reading.isAllocated === false)}
														handleChangePage={handleChangePage}
														handleChangeRowsPerPage={handleChangeRowsPerPage}
														page={page}
														rowsPerPage={rowsPerPage}
														isEditMeterLoading={isEditMeterLoading}
														editMeter={editMeter}
														editView={editView}
														setEditView={setEditView}
														editDetails={editDetails}
														setEditDetails={setEditDetails}
														user={user}
														isPending
														token={token}
													/>
												) : (
													<Box sx={{ display: 'flex', justifyContent: 'center' }}>
														<Typography variant="h5" gutterBottom>
															No Meter Found
														</Typography>
													</Box>
												)
										}
									</Card>
								</TabPanel>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Reading Found
								</Typography>
							</Box>
						)
					}
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Vending }) => {
	const { token, user } = Authentication;
	const {
		getMeter,
		isGetMetersLoading,
		isEditMeterLoading,
		editMeter,
	} = Vending;
	return {
		token,
		getMeter,
		isGetMetersLoading,
		isEditMeterLoading,
		editMeter,
		user,
	};
};

export default connect(mapStateToProps, {
	getMetersRequest,
})(AllRecords);
