/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type UserData = {
	fullname: string,
	title: string,
	email: string,
	password: string,
	gender: string,
	phone: string,
	account_id: number
}
type CompleteRegistrationResponse = {
	status: string,
	message: string,
}
export const completeRegistration = async (userData: UserData):
Promise<CompleteRegistrationResponse> => {
	const response = await axios.post('/auth/register/update', userData);

	return response.data;
};
