/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	Box,
	Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { API_BASE_URL } from '../Environment';
import { getAllSubstationRequest, getAllFeedersRequest, deleteSubstationRequest } from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import {
	RHFSelect, FormProvider,
} from '../components/hook-form';
import { SubstationEditForm } from '../sections/@dashboard/FeederSubstation';
import { UserListHead } from '../sections/@dashboard/user';

dayjs.extend(advancedFormat);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DeleteSubstation({
	view,
	details,
	handleCloseDeleteDialog,
	isDeleteSubstationLoading,
	onDelete,
}) {
	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDeleteDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Delete
					{' '}
					{details?.name}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete this substation. It cannot be reversed.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseDeleteDialog}>Cancel</Button>
					<LoadingButton
						variant="contained"
						loading={isDeleteSubstationLoading}
						onClick={onDelete}
					>
						Continue
					</LoadingButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

dayjs.extend(advancedFormat);

const TABLE_HEAD = [
	{ id: 'name', label: 'Substation', alignRight: false },
	{ id: 'voltage', label: 'Voltage', alignRight: false },
	{ id: 'connected_plant', label: 'Connected Plant', alignRight: false },
	{ id: 'area', label: 'Area', alignRight: false },
	{ id: 'capacity', label: 'Capacity', alignRight: false },
	{ id: 'rating', label: 'Rating', alignRight: false },
	{ id: 'latitude', label: 'Latitude', alignRight: false },
	{ id: 'longitude', label: 'Longitude', alignRight: false },
	{ id: 'ownership', label: 'Ownership', alignRight: false },
	{ id: 'metering_status', label: 'Metering Status', alignRight: false },
	{ id: 'dt_status', label: 'DT Status', alignRight: false },
	{ id: 'reason', label: 'Reason', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
	{ id: 'updatedAt', label: 'Updated Created', alignRight: false },
	{ id: 'edit', label: '', alignRight: false },
	{ id: 'delete', label: '', alignRight: false },
];

function Substation({
	token,
	user,
	getAllSubstation,
	isGetAllSubstationLoading,
	getAllSubstationRequest,
	getAllFeedersRequest,
	isGetAllFeedersLoading,
	getAllFeeders,
	isEditSubstationLoading,
	editSubstation,
	deleteSubstationRequest,
	isDeleteSubstationLoading,
	deleteSubstation,
}) {
	const [feeders, setFeeders] = React.useState([]);

	const [editView, setEditView] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState([]);
	const [editId, setEditId] = React.useState(null);
	const [deleteView, setDeleteView] = React.useState(false);
	const [deleteDetails, setDeleteDetails] = React.useState([]);
	const [deleteId, setDeleteId] = React.useState(null);

	const [downloadLoading, setDownloadLoading] = React.useState(false);

	React.useEffect(() => {
		if (token) {
			getAllFeedersRequest(token);
		}
	}, [token]);

	React.useEffect(() => {
		if (getAllFeeders) {
			setFeeders([...getAllFeeders]);
		}
	}, [getAllFeeders]);

	React.useEffect(() => {
		if (editSubstation || deleteSubstation) {
			setEditView(false);
			setEditDetails({});
			setEditId(null);
			getAllFeedersRequest(token);
		}
	}, [editSubstation, deleteSubstation]);

	const handleDelete = (id) => {
		setDeleteId(id);
		deleteSubstationRequest(token, id, getAllSubstation);
	};
	const handleSetEditView = (id) => {
		setEditView(true);
		setEditDetails(getAllSubstation.filter(substation => substation.id === id));
		setEditId(id);
	};
	const handleCloseEditDialog = () => setEditView(false);

	const handleSetDeleteView = (id) => {
		setDeleteView(true);
		setDeleteDetails(getAllSubstation.filter(substation => substation.id === id));
		setDeleteId(id);
	};
	const handleCloseDeleteDialog = () => setDeleteView(false);

	const defaultValues = React.useMemo(
		() => ({
			feeders: feeders ? feeders[0]?.slug : '',
		}),
		[getAllFeeders, feeders],
	);

	const NewUserSchema = Yup.object().shape({
		feeder: Yup.string().required(),
	});

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		handleSubmit,
		watch,
	} = methods;

	const onSubmit = async (data) => {
		const previewData = {
			...data,
		};
		getAllSubstationRequest(token, previewData.feeder);
	};

	const watchFeeder = watch('feeder') ?? '';

	const loading = isGetAllSubstationLoading;

	const handleDownloadExcel = async () => {
		const downloadLink = watchFeeder?.length
			? `${API_BASE_URL}/meter/substations/download?feeder_slug=${watchFeeder}`
			: `${API_BASE_URL}/meter/substations/download`;
		setDownloadLoading(true);
		try {
			const result = await fetch(downloadLink, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const blob = await result.blob();
			const href = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.download = `Distribution-List-${watchFeeder ?? 'All'}.xlsx`;
			link.href = href;
			document.body.appendChild(link);
			link.click();
			setDownloadLoading(false);
		} catch (error) {
			setDownloadLoading(false);
		}
	};

	return (
		<Page title="Substation">
			<Container>
				<Stack direction="row" mb={2} alignItems="start" justifyContent="space-between">
					<Box mb={3}>
						{isGetAllFeedersLoading ? (
							<Typography variant="h6" gutterBottom>
								Fetching Feeders...
							</Typography>
					) : (
						<FormProvider
							methods={methods}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Stack direction="row" alignItems="center">
								<Box mr={3}>
									<RHFSelect
										name="feeder"
										label="Select Feeder"
										disabled={isGetAllFeedersLoading}
										placeholder="Select Feeder"
									>
										<option value="">{' '}</option>
										{feeders?.map((option, id) => (
											<option key={id} value={option?.slug}>
												{option?.name.replace(/[^a-zA-Z ]/g, ' ')}
											</option>
										))}
									</RHFSelect>
								</Box>
								<Box>
									<LoadingButton
										type="submit"
										variant="contained"
										disabled={isGetAllFeedersLoading}
										loading={loading}
										color="primary"
									>
										Get Substations
									</LoadingButton>
								</Box>
							</Stack>
						</FormProvider>
					)}
					</Box>
					<Box>
						<LoadingButton
							onClick={handleDownloadExcel}
							variant="outlined"
							color="inherit"
							sx={{ height: 1, marginBottom: 2 }}
							loading={downloadLoading}
							disabled={downloadLoading}
						>
							Download Excel

						</LoadingButton>
					</Box>
				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
					<Typography variant="h4" gutterBottom>
						All Substations
					</Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{
						loading ? <Loader />
						: getAllSubstation ? (
							<Box>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<UserListHead
											headLabel={TABLE_HEAD}
											rowCount={getAllSubstation?.length}
										/>
										<TableBody>
											{getAllSubstation.map((row) => {
														const {
															id,
															name,
															voltage,
															connected_plant,
															area,
															capacity,
															rating,
															latitude,
															longitude,
															ownership,
															metering_status,
															dt_status,
															reason,
															createdAt,
															updatedAt,
															} = row;
														return (
															<TableRow
																hover
																key={id}
																tabIndex={-1}
																role="checkbox"
															>
																<TableCell align="left">{name}</TableCell>
																<TableCell align="left">{voltage}</TableCell>
																<TableCell align="left">{connected_plant}</TableCell>
																<TableCell align="left">{area}</TableCell>
																<TableCell align="left">{capacity}</TableCell>
																<TableCell align="left">{rating}</TableCell>
																<TableCell align="left">{latitude}</TableCell>
																<TableCell align="left">{longitude}</TableCell>
																<TableCell align="left">{ownership}</TableCell>
																<TableCell align="left">{metering_status}</TableCell>
																<TableCell align="left">{dt_status}</TableCell>
																<TableCell align="left">{reason}</TableCell>
																<TableCell align="left">{createdAt && dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
																<TableCell align="left">{updatedAt && dayjs(updatedAt).format('DD/MM/YYYY')}</TableCell>
																{
																	user.role !== 'auditor' && (
																		<>
																			<TableCell align="left">
																				<LoadingButton
																					type="submit"
																					variant="outlined"
																					color="success"
																					startIcon={<Iconify icon="bxs:edit" />}
																					onClick={() => handleSetEditView(id)}
																				>
																					Edit
																				</LoadingButton>
																			</TableCell>
																			<TableCell align="left">
																				<LoadingButton
																					type="submit"
																					variant="outlined"
																					color="error"
																					loading={isDeleteSubstationLoading && id === deleteId}
																					startIcon={<Iconify icon="ep:delete-filled" />}
																					onClick={() => handleSetDeleteView(id)}
																				>
																					Delete
																				</LoadingButton>
																			</TableCell>
																		</>
																	)
																}
															</TableRow>
														);
												})}
										</TableBody>
									</Table>
								</TableContainer>
								{
										editView && (
											<SubstationEditForm
												view={editView}
												id={editId}
												details={editDetails[0]}
												feeders={feeders}
												handleCloseEditDialog={handleCloseEditDialog}
												isEditSubstationLoading={isEditSubstationLoading}
											/>
										)
									}
								{
										deleteView && (
											<DeleteSubstation
												view={deleteView}
												id={deleteId}
												details={deleteDetails[0]}
												handleCloseDeleteDialog={handleCloseDeleteDialog}
												isDeleteSubstationLoading={isDeleteSubstationLoading}
												onDelete={() => handleDelete(deleteId)}
											/>
										)
									}
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Substation Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Substation, Feeder }) => {
	const { token, user } = Authentication;
	const {
		isGetAllSubstationLoading,
		getAllSubstation,
		isEditSubstationLoading,
		editSubstation,
		isDeleteSubstationLoading,
		deleteSubstation,
	} = Substation;
	const {
		isGetAllFeedersLoading, getAllFeeders,
	} = Feeder;
	return {
		token,
		user,
		isGetAllSubstationLoading,
		getAllSubstation,
		isGetAllFeedersLoading,
		getAllFeeders,
		isEditSubstationLoading,
		editSubstation,
		isDeleteSubstationLoading,
		deleteSubstation,
	};
};

export default connect(mapStateToProps, {
	getAllSubstationRequest,
	getAllFeedersRequest,
	deleteSubstationRequest,
})(Substation);

// id(pin): 454
// name(pin): "jantar-bisichi-feeder"
// feeder_slug(pin): "jantar-bisichi-feeder"
// voltage(pin): "66/33"
// connected_plant(pin): "33/66"
// area(pin): "Maraba Foron"
// capacity(pin): 4000
// rating(pin): "66/33"
// latitude(pin): "9.688173"
// longitude(pin): "8.893648"
// ownership(pin): "NESCO"
// metering_status(pin): "Metered"
// dt_status(pin): "In service"
// reason(pin): "ok"
// remarks(pin): null
// createdAt(pin): "2023-11-28T16:50:44.000Z"
// updatedAt(pin): "2023-11-28T16:50:44.000Z"
// deletedAt(pin): null
// feeder(pin): null
