/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Box, MenuItem,
	InputLabel,
	Select,
} from '@mui/material';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { getAllLogsRequest } from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'consumer_id', label: 'Consumer ID', alignRight: false },
	{ id: 'fields', label: 'Fields', alignRight: false },
	{ id: 'type', label: 'Type', alignRight: false },
	{ id: 'description', label: 'Description', alignRight: false },
	{ id: 'details', label: 'Details', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
	{ id: 'updatedAt', label: 'Date Updated', alignRight: false },
	{ id: 'deletedAt', label: 'Date Deleted', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user?.description?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1)
			|| filter(array, (_user) => _user?.details?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function TableCard({
	token,
	getAllLogsRequest,
	pagination,
	getAllLogs,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	type,
	setType,
	rowsPerPage,
}) {
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getAllLogs?.map((n) => n.consumer_id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagination.pageSize ?? 0) : 0;

	const filteredLogs = getAllLogs && applySortFilter(getAllLogs, getComparator(order, orderBy), filterName);

	const isLogsNotFound = filteredLogs?.length === 0;

	const handleStatusChange = (e) => {
		setType(e.target.value);
		getAllLogsRequest(token, e.target.value);
	};

	return (
		<Box>
			<Stack direction="row" justifyContent="space-between">
				<UserListToolbar placeholder="Search Description" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
				<Box sx={{ mr: 2 }}>
					<InputLabel id="status-label">Type</InputLabel>
					<Select
						defaultValue="Account"
						value={type}
						sx={{ minWidth: 50 }}
						onChange={handleStatusChange}
					>
						<MenuItem value="All">
							All
						</MenuItem>
						<MenuItem value="Account">
							Account
						</MenuItem>
						<MenuItem value="Bill">
							Bill
						</MenuItem>
						<MenuItem value="Consumer">
							Consumer
						</MenuItem>
						<MenuItem value="Store">
							Store
						</MenuItem>
						<MenuItem value="Tamper">
							Tamper
						</MenuItem>
						<MenuItem value="Prepaid">
							Prepaid
						</MenuItem>
						<MenuItem value="Tariff">
							Tariff
						</MenuItem>
					</Select>
				</Box>
			</Stack>
			<TableContainer sx={{ minWidth: 800 }}>
				<Table>
					<UserListHead
						order={order}
						orderBy={orderBy}
						headLabel={TABLE_HEAD}
						rowCount={getAllLogs?.length}
						numSelected={selected.length}
						onRequestSort={handleRequestSort}
						onSelectAllClick={handleSelectAllClick}
					/>
					<TableBody>
						{
                            filteredLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const {
                                        id,
                                        consumer_id,
                                        fields,
                                        type,
                                        description,
                                        details,
                                        createdAt,
                                        updatedAt,
                                        deletedAt,
                                        user,
                                    } = row;
                                    return (
	<TableRow
		hover
		key={id}
		tabIndex={-1}
		role="checkbox"
	>
		<TableCell align="left">
			<Typography variant="subtitle2">
				{consumer_id}
			</Typography>
		</TableCell>
		<TableCell align="left">{fields}</TableCell>
		<TableCell align="left">{type}</TableCell>
		<TableCell align="left">{description}</TableCell>
		<TableCell align="left">{details}</TableCell>
		<TableCell align="left">{dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
		<TableCell align="left">{dayjs(updatedAt).format('DD/MM/YYYY')}</TableCell>
		<TableCell align="left">{deletedAt ? dayjs(deletedAt).format('DD/MM/YYYY') : ''}</TableCell>
		<TableCell align="right">
			<LoadingButton
				variant="contained"
				component={RouterLink}
				to={`/dashboard/admin/user-management/${user.id}`}
			>
				View User
			</LoadingButton>
		</TableCell>
	</TableRow>
                                    );
                                })
                        }
						{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={12} />
						</TableRow>
                        )}
					</TableBody>
					{isLogsNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
								<SearchNotFound searchQuery={filterName} />
							</TableCell>
						</TableRow>
					</TableBody>
                    )}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[15, 30, 50]}
				component="div"
				count={filteredLogs?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newpage) => handleChangePage(event, newpage)}
				onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
			/>
		</Box>

	);
}

function Logs({
	token,
	getAllLogs,
	isGetAllLogsLoading,
	getAllLogsRequest,
}) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);

	const [type, setType] = React.useState('All');

	React.useEffect(() => {
		if (token) {
			getAllLogsRequest(token);
		}
	}, [token]);

	const loading = isGetAllLogsLoading;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Page title="Activity Logs">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>
						All Logs
					</Typography>
				</Stack>

				<Card sx={{ pb: 2, pt: 2 }}>
					{
                        loading ? <Loader />
                            : getAllLogs ? (
	<TableCard
		token={token}
		getAllLogsRequest={getAllLogsRequest}
		pagination={getAllLogs.pagination}
		getAllLogs={getAllLogs.logs}
		handleChangePage={handleChangePage}
		handleChangeRowsPerPage={handleChangeRowsPerPage}
		page={page}
		type={type}
		setType={setType}
		rowsPerPage={rowsPerPage}
	/>
                                                            ) : (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Typography variant="h5" gutterBottom>
			No Logs Found
		</Typography>
	</Box>
                            )
                    }
				</Card>

			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Logs }) => {
	const { token } = Authentication;
	const {
		isGetAllLogsLoading, getAllLogs,
	} = Logs;
	return {
		token,
		isGetAllLogsLoading,
		getAllLogs,
	};
};

export default connect(mapStateToProps, { getAllLogsRequest })(Logs);

// id(pin): 17583
// user_id(pin): 9
// consumer_id(pin): "NBJ0733"
// fields(pin): "["type to ACTUAL","opening_balance to 48438.19"]"
// type(pin): "Bill"
// description(pin): "Badung Barnabas updated NBJ0733's February 2024 bill"
// details(pin): "bill with invoice no NBJ0733-NaN was updated"
// createdAt(pin): "2024-03-13T07:38:11.000Z"
// updatedAt(pin): "2024-03-13T07:38:11.000Z"
// deletedAt(pin): null
// id(pin): 9
// fullname(pin): "Badung Barnabas"
// email(pin): "teyeihope1@gmail.com"
// phone(pin): "09033017510"
// role(pin): "billing_officer"
// gender(pin): "Male"
