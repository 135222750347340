/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
// import DateFnsUtils from '@date-io/date-fns';
// import {
// 	MuiPickersUtilsProvider,
// 	KeyboardDatePicker,
// } from '@material-ui/pickers';

import { LoadingButton } from '@mui/lab';
import {
	Stack,
	Container,
	Typography,
	Box,
	TextField,
	MenuItem,
	Select,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import {
	getConsumerByMeterNumberRequest,
	issueCreditTokenRequest,
} from '../Store';
import Page from '../components/Page';

const paymentType = [
	{
		value: 'cash',
		label: 'Cash',
	},
	{
		value: 'transfer',
		label: 'Transfer',
	},
	{
		value: 'cheque',
		label: 'Cheque',
	},
];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function IssueCreditToken({
	token,
	getConsumerByMeterNumberRequest,
	issueCreditTokenRequest,
	isGetConsumerByMeterNumberLoading,
	getConsumerByMeterNumber,
	isIssueCreditTokenLoading,
	issueCreditToken,
	error,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const componentRef = React.useRef();

	const [decoderReferenceNumber, setDecoderReferenceNumber] = React.useState('');
	const [creditTokenAmount, setCreditTokenAmount] = React.useState('');
	const [queryField, setQueryField] = React.useState('cash');
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		if (issueCreditToken) {
			enqueueSnackbar('Token issued successfully!');
			setOpen(true);
		}

		if (error) {
			enqueueSnackbar(error, {
				variant: 'error',
			});
		}
	}, [issueCreditToken, error]);

	const handlePrint = useReactToPrint({
		documentTitle: 'Token Receipt',
		content: () => componentRef.current,
		pageStyle: `
			@page {
				size: A4;
				margin: 10px 0 0 0;
			}
			@media print {
				h4.class-name
				@page{
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 0px;
					margin-bottom: 0px;
				}
				.page {
					margin: 0;
					padding: 0;
					border: initial;
					border-radius: initial;
					width: initial;
					min-height: initial;
					box-shadow: initial;
					background: initial;
					overflow: hidden;
					height: 100vh;
				}
			}
		`,
	});

	const handleGetConsumerByMeterNumberRequest = () => {
		getConsumerByMeterNumberRequest(token, decoderReferenceNumber);
	};

	const handleIssueCreditTokenRequest = () => {
		issueCreditTokenRequest(token, {
			meter_number: getConsumerByMeterNumber?.prepaid_meter_number,
			amount: Number(creditTokenAmount),
			payment_type: queryField,
		});
	};

	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
		setOpen(false);
	};

	return (
		<Page title="Print all bills">
			<Container>
				<Stack mb={1} mt={3}>
					<Typography variant="h6" gutterBottom>
						Enter a consumers decoder reference number to issue token
					</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" mb={5}>
					<TextField
						label="Decoder Reference Number"
						shrink
						disabled={isGetConsumerByMeterNumberLoading}
						placeholder="Enter Decoder Reference Number"
						value={decoderReferenceNumber}
						onChange={(event) => {
                            setDecoderReferenceNumber(event.target.value);
                        }}
						sx={{ mr: 2, width: '20rem' }}
					/>
					<Box>
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							loading={isGetConsumerByMeterNumberLoading}
							disabled={isGetConsumerByMeterNumberLoading}
							onClick={handleGetConsumerByMeterNumberRequest}
						>
							Enter Consumer Meter Number
						</LoadingButton>
					</Box>
				</Stack>
				{getConsumerByMeterNumber && (
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Box>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account Name:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.account_name : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Phone Number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.phone : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Prepaid Meter Number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.prepaid_meter_number : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account Type:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.account_type : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account Number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.account_number : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Tarrif
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.tariff : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Meter Type:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.meter_type : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								District:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.district : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Feeder
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.feeder : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Substation
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.substation : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account Status
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.account_status : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Meter Status
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.meter_status : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Residential Address 1:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.res_address1 : ''}
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Residential Address 2:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
								{' '}
								{getConsumerByMeterNumber ? getConsumerByMeterNumber.con_address1 : ''}
							</Typography>
						</Stack>
					</Box>
					<Stack>
						<Typography variant="subtitle1">
							Amount
						</Typography>
						<TextField
							label="Amount"
							shrink
							disabled={isGetConsumerByMeterNumberLoading}
							placeholder="Enter credit token amount"
							value={creditTokenAmount}
							type="number"
							onChange={(event) => {
                                    setCreditTokenAmount(event.target.value);
                                }}
							sx={{ mb: 2, width: '20rem' }}
						/>
						<Typography variant="subtitle1">
							Payment Type
						</Typography>
						<Select
							shrink="true"
							disabled={isGetConsumerByMeterNumberLoading}
							value={queryField}
							onChange={e => setQueryField(e.target.value)}
							sx={{ mb: 2 }}
						>
							{
									paymentType.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))
							}
						</Select>
						<LoadingButton
							type="submit"
							variant="contained"
							color="success"
							loading={isIssueCreditTokenLoading}
							disabled={isIssueCreditTokenLoading}
							onClick={handleIssueCreditTokenRequest}
						>
							Issue Credit Token for
							{' '}
							{getConsumerByMeterNumber?.account_name}
						</LoadingButton>
					</Stack>
				</Stack>
                )}
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					TransitionComponent={Transition}
					keepMounted
				>
					<div ref={componentRef}>
						<DialogTitle id="alert-dialog-title">
							<Stack sx={{ width: '100%' }} spacing={2}>
								<Alert severity="success">
									<AlertTitle>Token Issued Successfully</AlertTitle>
								</Alert>
							</Stack>
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<TableContainer component={Paper}>
									<Table aria-label="simple table" size="small">
										<TableBody>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Customer:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.customer : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Meter Number:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.meter_number : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Token:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.token : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Cost of electricity:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.costOfElectricity : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Vat:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.vat : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Transaction reference:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.transactionReference : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Description:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.description : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1">
														Number of units:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.numberOfUnits : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1" gutterBottom>
														Unit value:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.UnitValue : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1" gutterBottom>
														Payment type:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.payment_type : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1" gutterBottom>
														Payment date:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? dayjs(issueCreditToken?.payment_date).format('DD/MM/YYYY') : ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="subtitle1" gutterBottom>
														Customer address:
													</Typography>
												</TableCell>
												<TableCell align="right">
													{issueCreditToken ? issueCreditToken.customer_address : ''}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</DialogContentText>
						</DialogContent>
					</div>
					<DialogActions>
						<Button onClick={handlePrint}>Print receipt</Button>
						<Button onClick={handleClose}>OK</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Vending }) => {
	const { token } = Authentication;
	const {
		isGetConsumerByMeterNumberLoading,
		getConsumerByMeterNumber,
		isIssueCreditTokenLoading,
		issueCreditToken,
		error,
	} = Vending;
	return {
		token,
		isGetConsumerByMeterNumberLoading,
		getConsumerByMeterNumber,
		isIssueCreditTokenLoading,
		issueCreditToken,
		error,
	};
};

export default connect(mapStateToProps, {
	getConsumerByMeterNumberRequest,
	issueCreditTokenRequest,
})(IssueCreditToken);

// id(pin): 74144
// user_id(pin): null
// account_name(pin): "Salisu Burby"
// phone(pin): "08100612345"
// account_number(pin): "NBJ3025"
// account_type(pin): "NES"
// district(pin): "BJ"
// account_type_number(pin): null
// meter_type(pin): "POSTPAID"
// postpaid_meter_number(pin): null
// prepaid_meter_number(pin): "600727000000000009"
// class(pin): null
// category(pin): null
// res_address1(pin): "new commmercial layout bukuru"
// res_address2(pin): null
// res_address3(pin): null
// con_address1(pin): "new commercial layout bukuru"
// con_address2(pin): null
// con_address3(pin): null
// month_count(pin): 0
// reading_mode(pin): null
// prev_reading_mode(pin): null
// cur_high_reading(pin): 0
// cur_low_reading(pin): 0
// prev_high_reading(pin): 0
// prev_low_reading(pin): 0
// less_units_low(pin): 0
// less_units_high(pin): null
// units(pin): null
// estimated_units(pin): null
// multiplying_factor(pin): 0
// payments(pin): "0.00"
// opening_balance(pin): "0.00"
// current_debit(pin): "0.00"
// percentage_discount(pin): null
// discount_allowed(pin): null
// vat(pin): "0.00"
// total_current_debit(pin): "0.00"
// adjustment(pin): null
// closing_balance(pin): "0.00"
// prev_closing_balance(pin): "0.00"
// prev_closing_balance1(pin): null
// prev_closing_balance2(pin): 0
// prev_closing_balance3(pin): 0
// reconnection_fee(pin): 0
// tariff(pin): "R2"
// feeder(pin): "bukuru"
// substation(pin): "southern area"
// meter_status(pin): "okay"
// account_status(pin): "ACTIVE"
// has_updated(pin): false
// reading_month(pin): null
// reading_year(pin): null
// previous_reading_date(pin): null
// present_reading_date(pin): null
// createdAt(pin): "2023-03-15T19:28:25.000Z"
// updatedAt(pin): "2023-03-15T19:28:25.000Z"
// deletedAt(pin): null

// { /* <Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Customer:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.customer : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Meter Number:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.meter_number : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Token:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.token : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Cost of electricity:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.costOfElectricity : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Vat:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.vat : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Transaction reference:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.transactionReference : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Description:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.description : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Number of units:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.numberOfUnits : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Unit value:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.UnitValue : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Tarrif:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.tariff : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Payment type:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.payment_type : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Payment date:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.payment_date : ''}
// 								</Typography>
// 							</Stack>
// 							<Stack direction="row" alignItems="start">
// 								<Typography variant="subtitle1" gutterBottom>
// 									Customer address:
// 								</Typography>
// 								<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
// 									{' '}
// 									{issueCreditToken ? issueCreditToken.customer_address : ''}
// 								</Typography>
// 							</Stack> */ }
