/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const editSubstation = async (token: string, substationId: string, substationData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.put(`/meter/substations/${substationId}`, substationData, config);

	return response.data;
};

export const deleteSubstation = async (token: string, substationId: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.delete(`/meter/substations/${substationId}`, config);

	return response.data;
};
