/* eslint-disable camelcase */
import axios from '../../../../configs/Axios';

export const getMeterReadingHistory = async (customer_id: number, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`meter-reading/user/${customer_id}/history?page=1&pageSize=2000&`, config);

	return response.data;
};
