/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Grid,
	Table,
	Card,
	Stack,
	Box,
	Button,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';

import {
	useDispatch,
	getSingleUserRequest,
	getUserBillPaymenthistoryRequest,
	suspendUserRequest,
	deleteUserRequest,
} from '../Store';
import Iconify from '../components/Iconify';
import Label from '../components/Label';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

const TABLE_HEAD = [
	{ id: 'reference', label: 'Reference', alignRight: false },
	{ id: 'payment_type', label: 'Payment Type', alignRight: false },
	{ id: 'amount', label: 'Amount', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.reference.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function SingleUser({
	user,
	token,
	getSingleUser,
	isGetSingleUserLoading,
	isSuspendUserLoading,
	suspendUser,
	isDeleteUserLoading,
	deleteUser,
	isGetUserBillPaymentHistory,
	getUserBillPaymentHistory,
}) {
	const {
		userId,
	} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [page, setPage] = React.useState(0);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	React.useEffect(() => {
		if (token) {
			dispatch(getSingleUserRequest(token, userId));
			dispatch(getUserBillPaymenthistoryRequest(token, userId));
		}
	}, [token]);

	React.useEffect(() => {
		if (suspendUser) {
			dispatch(getSingleUserRequest(token, userId));
			dispatch(getUserBillPaymenthistoryRequest(token, userId));
		}
	}, [suspendUser]);

	if (deleteUser) {
		navigate('/dashboard/admin/user-management/list/', { replace: true });
	}

	const handleSuspendUser = (id) => {
		dispatch(suspendUserRequest(token, id));
	};

	const handleDeleteUser = (id) => {
		dispatch(deleteUserRequest(token, id));
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getUserBillPaymentHistory?.map((n) => n.reference);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getUserBillPaymentHistory?.bills?.length ?? 0) : 0;

	const filteredBillPaymentHistory = getUserBillPaymentHistory?.bills && applySortFilter(getUserBillPaymentHistory?.bills, getComparator(order, orderBy), filterName);

	const isBillPaymentHistoryNotFound = filteredBillPaymentHistory?.length === 0;

	const loading = isGetSingleUserLoading || isGetUserBillPaymentHistory?.bills;
	return (
		<Page title="Meter Reading">
			<Container maxWidth="xl">
				<Stack direction="row" alignItems="start" justifyContent="space-between" mb={2}>
					<Box>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle2" gutterBottom>
								Full name:
								{' '}
								<strong>{getSingleUser ? getSingleUser.reference : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle2" gutterBottom>
								Email:
								{' '}
								<strong>{getSingleUser ? getSingleUser.email : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle2" gutterBottom>
								Phone:
								{' '}
								<strong>{getSingleUser ? getSingleUser.phone : ''}</strong>
							</Typography>
						</Stack>
					</Box>
					<Button
						variant="contained"
						component={RouterLink}
						to="/dashboard/admin/user-management/list/"
						startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
					>
						Go back to all users
					</Button>
				</Stack>
				<Stack direction="row" justifyContent="end">
					<Typography variant="h4" sx={{ mb: 1 }}> User Actions </Typography>
				</Stack>
				{
						loading ? <Loader /> : getSingleUser ? (
							<>
								<Grid container spacing={3}>
									<Grid item xs={24} sm={24} md={8}>
										<Card sx={{ p: 2 }}>
											<Box>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Title:
														{' '}
														{getSingleUser ? getSingleUser.title : ''}
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Gender:
														{' '}
														{getSingleUser ? getSingleUser.gender : ''}
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Role:
														{' '}
														{getSingleUser ? getSingleUser?.role : ''}
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Activation Status:
														{' '}
														<Label
															variant="ghost"
															color={getSingleUser.isActive ? 'error' : 'success'}
														>
															{getSingleUser.isActive ? 'Account is Not Active' : 'Account is Active'}
														</Label>
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Suspension Status:
														{' '}
														<Label
															variant="ghost"
															color={getSingleUser.isSuspended ? 'error' : 'success'}
														>
															{getSingleUser.isSuspended ? 'Account is Suspended' : 'Account is not Suspended '}
														</Label>
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Account Activation Code:
														{' '}
														{getSingleUser.activation_code}
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Activation Code Expiration Date:
														{' '}
														{ getSingleUser.activation_code_expires_in
														? dayjs.utc(getSingleUser.activation_code_expires_in).format('YYYY-MM-DD') : ''}
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Date of Account Creation:
														{' '}
														{dayjs.utc(getSingleUser.createdAt).format('YYYY-MM-DD')}
													</Typography>
												</Stack>
												<Stack direction="row" alignItems="start">
													<Typography variant="subtitle2" gutterBottom>
														Date of Account Update:
														{' '}
														{dayjs.utc(getSingleUser.updatedAt).format('YYYY-MM-DD')}
													</Typography>
												</Stack>
											</Box>
										</Card>
										<Box />
									</Grid>
									{
										user?.role === 'superadmin' && (
										<Grid item xs={16} sm={24} md={4}>
											<Card sx={{ pb: 2, pt: 2 }}>
												<Box>
													<Stack direction="row" alignItems="start" justifyContent="center" sx={{ mb: 2 }}>
														<LoadingButton
															variant="contained"
															color="info"
															loading={isSuspendUserLoading}
															disabled={getSingleUser?.role === user?.role}
															onClick={() => handleSuspendUser(getSingleUser.id)}
														>
															{getSingleUser.isSuspended ? 'Activate User' : 'Suspend User'}
														</LoadingButton>
													</Stack>
													<Stack direction="row" alignItems="start" justifyContent="center" sx={{ mb: 2 }}>
														<LoadingButton
															variant="contained"
															color="error"
															loading={isDeleteUserLoading}
															disabled={getSingleUser?.role === user?.role}
															onClick={() => handleDeleteUser(getSingleUser.id)}
														>
															Delete User
														</LoadingButton>
													</Stack>
												</Box>
											</Card>
										</Grid>
										)
									}
								</Grid>
								<Stack direction="row" justifyContent="start">
									<Typography variant="h4" sx={{ mb: 1, mt: 3 }}> User Payment History </Typography>
								</Stack>
								<Card sx={{ pb: 2 }}>
									<UserListToolbar
										loading={loading}
										numSelected={selected.length}
										filterName={filterName}
										onFilterName={handleFilterByName}
									/>
									{getUserBillPaymentHistory?.bills.length ? (
										<Box>
											<TableContainer sx={{ minWidth: 800 }}>
												<Table>
													<UserListHead
														order={order}
														orderBy={orderBy}
														headLabel={TABLE_HEAD}
														rowCount={getUserBillPaymentHistory?.bills?.length}
														numSelected={selected.length}
														onRequestSort={handleRequestSort}
														onSelectAllClick={handleSelectAllClick}
													/>
													<TableBody>
														{
															filteredBillPaymentHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
																const {
																		id,
																		reference,
																		payment_type,
																		amount,
																		createdAt,
																	} = row;
																const isItemSelected = selected.indexOf(reference) !== -1;

																return (
																	<TableRow
																		hover
																		key={reference}
																		tabIndex={-1}
																		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																		selected={isItemSelected}
																		aria-checked={isItemSelected}
																	>
																		<TableCell align="left">{reference}</TableCell>
																		<TableCell align="left">{payment_type}</TableCell>
																		<TableCell align="left">{amount}</TableCell>
																		<TableCell align="left">{dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
																		<TableCell align="left">
																			<Button
																				type="submit"
																				variant="outlined"
																				component={RouterLink}
																				color="info"
																				startIcon={<Iconify icon="carbon:view-filled" />}
																				to={`/dashboard/admin/user-management/${userId}/bill-payment/${id}`}
																			>
																				View Payments Details
																			</Button>
																		</TableCell>
																	</TableRow>
																);
															})
														}
														{emptyRows > 0 && (
														<TableRow style={{ height: 53 * emptyRows }}>
															<TableCell colSpan={12} />
														</TableRow>
														)}
													</TableBody>
													{isBillPaymentHistoryNotFound && (
														<TableBody>
															<TableRow>
																<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
																	<SearchNotFound searchQuery={filterName} />
																</TableCell>
															</TableRow>
														</TableBody>
													)}
												</Table>
											</TableContainer>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25, 40]}
												component="div"
												count={getUserBillPaymentHistory?.bills?.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</Box>
							) : (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography variant="h5" gutterBottom>
										No History Found
									</Typography>
								</Box>
							)}
								</Card>
							</>
						) : (
							<Card sx={{ pb: 2, pt: 2 }}>
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography variant="h5" gutterBottom>
										No User Found
									</Typography>
								</Box>
							</Card>
						)
					}
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, UserManagement, User }) => {
	const { token, user } = Authentication;
	const {
		isGetUserBillPaymentHistory,
		getUserBillPaymentHistory,
	} = User;
	const {
		getSingleUser,
		isGetSingleUserLoading,
		isSuspendUserLoading,
		suspendUser,
		isDeleteUserLoading,
		deleteUser,

	} = UserManagement;
	return {
		user,
		token,
		getSingleUser,
		isGetSingleUserLoading,
		isSuspendUserLoading,
		suspendUser,
		isDeleteUserLoading,
		deleteUser,
		isGetUserBillPaymentHistory,
		getUserBillPaymentHistory,
	};
};

export default connect(mapStateToProps)(SingleUser);

// id(pin):134
// user_id(pin):274
// reference(pin):"NESCO|20220726|1379282169223"
// account_number(pin):"NCS0002"
// payment_type(pin):"CONSUMPTION"
// amount(pin):2000
// balance_before(pin):0
// balance_after(pin):2000
// units_low(pin):null
// units_high(pin):null
// billed_units(pin):null
// tax(pin):null
// previousDate(pin):null
// presentDate(pin):null
// presentKva(pin):null
// previousLowReading(pin):null
// previousHighReading(pin):null
// presentLowReading(pin):null
// presentHighReading(pin):null
// previousKva(pin):null
// total(pin):null
// reading_mode(pin):null
// createdAt(pin):"2022-07-26T12:42:11.000Z"
// updatedAt(pin):"2022-07-26T12:42:11.000Z"
// deletedAt(pin):null
