/* eslint-disable camelcase */
import axios from '../../configs/Axios';

export const getAllNews = async (token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get('/news/list?page=1&pageSize=2000', config);
	return response.data;
};
