/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getConsumerByMeterNumber = async (token: any, decoderReferenceNumber: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get(`vending/meter/${decoderReferenceNumber}/consumer`, config);
	return response.data;
};
