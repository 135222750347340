/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getDebtStatus = async (token: any, accountNumber: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get(`/user/debt-status/${accountNumber}`, config);
	return response.data;
};
