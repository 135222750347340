/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const searchUser = async (token: any, fullname?: string, role?: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (role) {
		const response = await axios.get(`/user/list/?page=1&pageSize=2000&fullname=${fullname}&role=${role}`, config);
		return response.data;
	}

	const response = await axios.get(`/user/list/?page=1&pageSize=2000&fullname=${fullname}`, config);
	return response.data;
};
