import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { TransactionState, initialTransactionState } from './Types';

const TransactionSlice = createSlice({
	name: 'TransactionSlice',
	initialState: initialTransactionState,
	reducers: {
		setState(state: TransactionState, action: PayloadAction<Partial<TransactionState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllTransactionRequest(
			state: TransactionState,
			action: PayloadAction<{
				isGetAllTransactionsLoading: Partial<TransactionState['isGetAllTransactionsLoading']>,
				getAllTransactions: Partial<TransactionState['getAllTransactions']>,
			}>,
		) {
			const {
				isGetAllTransactionsLoading,
				getAllTransactions,
			} = action.payload;
			return {
				...state,
				isGetAllTransactionsLoading,
				getAllTransactions,
			};
		},
		getUncompletedTransactionsRequest(
			state: TransactionState,
			action: PayloadAction<{
				isGetUncompletedTransactionsLoading: Partial<TransactionState['isGetUncompletedTransactionsLoading']>,
				getUncompletedTransactions: Partial<TransactionState['getUncompletedTransactions']>,
			}>,
		) {
			const {
				isGetUncompletedTransactionsLoading,
				getUncompletedTransactions,
			} = action.payload;
			return {
				...state,
				isGetUncompletedTransactionsLoading,
				getUncompletedTransactions,
			};
		},
	},
});

const {
	setState: _setState,
	getAllTransactionRequest: _getAllTransactionRequest,
	getUncompletedTransactionsRequest: _getUncompletedTransactionsRequest,
} = TransactionSlice.actions;

export const getAllTransactionRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllTransactionRequest({
		...initialTransactionState,
		isGetAllTransactionsLoading: true,
	}));
	try {
		const apiResponseData = await API.getAllTransactions(token);
		dispatch(_getAllTransactionRequest({
			isGetAllTransactionsLoading: false,
			getAllTransactions: apiResponseData?.data?.transactions,
		}));
	} catch (error) {
		dispatch(_getAllTransactionRequest({
			...initialTransactionState,
			isGetAllTransactionsLoading: false,
		}));
		dispatch(_setState<Partial<TransactionState>>(
			{
				error: error.message ?? 'Unable to get all transactions. Please try again.',
			},
		));
	}
};

export const getUncompletedTransactionsRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getUncompletedTransactionsRequest({
		...initialTransactionState,
		isGetUncompletedTransactionsLoading: true,
		getUncompletedTransactions: null,
	}));
	try {
		const apiResponseData = await API.getUncompletedTransactions(token);
		dispatch(_getUncompletedTransactionsRequest({
			isGetUncompletedTransactionsLoading: false,
			getUncompletedTransactions: apiResponseData?.data?.transactions,
		}));
	} catch (error) {
		dispatch(_getUncompletedTransactionsRequest({
			...initialTransactionState,
			isGetUncompletedTransactionsLoading: false,
		}));
		dispatch(_setState<Partial<TransactionState>>(
			{
				error: error.message ?? 'Unable to get uncompleted transactions. Please try again.',
			},
		));
	}
};

export const { reducer: Transactions } = TransactionSlice;
