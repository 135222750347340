export type FeederState = {
	isGetAllFeedersLoading: boolean;
	getAllFeeders: null | object;
	isCreateFeederLoading: boolean;
	createFeeder: null | object;
	isDeleteFeederLoading: boolean;
	deleteFeeder: null | object;
	isEditFeederLoading: boolean;
	editFeeder: null | object;
	error?: null | object;
}

export const initialFeederState: FeederState = {
	isGetAllFeedersLoading: false,
	getAllFeeders: null,
	isCreateFeederLoading: false,
	createFeeder: null,
	isDeleteFeederLoading: false,
	deleteFeeder: null,
	isEditFeederLoading: false,
	editFeeder: null,
};
