/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createUser,
	getRolesRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFSelect, RHFTextField,
} from '../../../components/hook-form';

const titles = [
	{
		value: 'Mr',
		label: 'Mr',
	},
	{
		value: 'Mrs',
		label: 'Mrs',
	},
];

const genders = [
	{
		value: 'Male',
		label: 'Male',
	},
	{
		value: 'Female',
		label: 'Female',
	},
];

export default function UserNewEditForm() {
	const dispatch = useDispatch();

	const [roles, setRoles] = React.useState([]);

	const RolesPermissions = useSelector((state) => state.RolesPermissions);
	const UserManagement = useSelector((state) => state.UserManagement);
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	React.useEffect(() => {
		dispatch(getRolesRequest(Authentication.token));
	}, []);

	React.useEffect(() => {
		if (RolesPermissions.getRoles) {
			setRoles([...RolesPermissions.getRoles]);
		}
	}, [RolesPermissions.getRoles]);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		fullname: Yup.string().required('full name is required').min(3, 'Too Short!').max(20, 'Too Long!'),
		title: Yup.string().required(),
		phone: Yup.string().min(11, 'Invalid phone number').required(),
		email: Yup.string().email('email must be a valid email address').required('email is required'),
		password: Yup.string().required('Password is required').min(4, 'Too Short!').max(20, 'Too Long!'),
		gender: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			fullname: '',
			title: 'Mr',
			email: '',
			password: '',
			role: 'vendor',
			gender: 'Male',
			phone: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (UserManagement.createUser) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/admin/user-management/list/');
		}

		if (UserManagement.error) {
			enqueueSnackbar(UserManagement.error, {
				variant: 'error',
			});
		}
	}, [UserManagement.createUser, UserManagement.error]);

	const onSubmit = async (data) => {
		dispatch(createUser(data, Authentication.token));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create a New User
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/admin/user-management/list"
					startIcon={<Iconify icon="eva:plus-fill" />}
				>
					View Users
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
							>
								<RHFTextField name="fullname" label="Full Name" />
								<RHFTextField name="email" label="Email Address" />
								<RHFTextField name="phone" label="Phone Number" />
								<RHFSelect name="title" label="Title" placeholder="Title">
									{titles.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
							))}
								</RHFSelect>
								<RHFSelect name="gender" label="Gender" placeholder="Gender">
									{genders.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
							))}
								</RHFSelect>
								<RHFSelect name="role" label="Role" placeholder="Role">
									{roles?.map((option) => (
										<option key={option?.name} value={option?.name}>
											{option?.name.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								<RHFTextField name="password" label="Password" />
							</Box>

							{
								Authentication?.user?.role === 'superadmin' && (
									<Stack alignItems="flex-end" sx={{ mt: 3 }}>
										<LoadingButton
											type="submit"
											variant="contained"
											loading={UserManagement.isCreateUserLoading}
										>
											Create User
										</LoadingButton>
									</Stack>
								)
							}

						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
