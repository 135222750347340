export type VendingState = {
	isAddNewMeterLoading: boolean;
	addNewMeter: null | string;
	isEditMeterLoading: boolean;
	editMeter: null | string;
	isGetMetersLoading: boolean;
	getMeter: null | string;
	isIssueCreditTokenLoading: boolean;
	issueCreditToken: null | string;
	getConsumerByMeterNumber: null|object;
	isGetConsumerByMeterNumberLoading: boolean;
	isIssueKCTLoading: boolean;
	issueKCT: null | string;
	getTokenPurchaseHistory: null | object;
	isGetTokenPurchaseHistoryLoading: boolean;
	isIssueTamperCodeTokenLoading: boolean;
	issueTamperCodeToken: null | string;
	error?: any;
}

export const initialVendingState: VendingState = {
	isAddNewMeterLoading: false,
	addNewMeter: null,
	isEditMeterLoading: false,
	editMeter: null,
	isGetMetersLoading: false,
	getMeter: null,
	isIssueCreditTokenLoading: false,
	issueCreditToken: null,
	isGetConsumerByMeterNumberLoading: false,
	getConsumerByMeterNumber: null,
	isIssueKCTLoading: false,
	issueKCT: null,
	getTokenPurchaseHistory: null,
	isGetTokenPurchaseHistoryLoading: false,
	isIssueTamperCodeTokenLoading: false,
	issueTamperCodeToken: null,
	error: null,
};
