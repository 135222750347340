/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TextField,
	TableCell,
	TableContainer,
	TablePagination,
	Button,
	Box,
	FormControl,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { API_BASE_URL } from '../../Environment';
import {
	getTokenPurchaseHistoryRequest,
} from '../../Store';
import { Loader } from '../../components/Loader';
import Page from '../../components/Page';
import SearchNotFound from '../../components/SearchNotFound';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import TokenPurchaseReceipt from './TokenPurchaseReceipt';

const TABLE_HEAD = [
	{ id: 'account_number', label: 'Account Number', alignRight: false },
	{ id: 'meter_number', label: 'Meter Number', alignRight: false },
	{ id: 'payment_type', label: 'Payment Type', alignRight: false },
	{ id: 'customer', label: 'Customer', alignRight: false },
	{ id: 'tariff', label: 'Tarrif', alignRight: false },
	{ id: 'feeder', label: 'Feeder', alignRight: false },
	{ id: 'amount', label: 'Amount', alignRight: false },
	{ id: 'charged_amount', label: 'Charged Amount', alignRight: false },
	{ id: 'vat', label: 'VAT', alignRight: false },
	{ id: 'kwh', label: 'KWH', alignRight: false },
	{ id: 'token', label: 'Token', alignRight: false },
	{ id: 'issuerName', label: 'Issuer', alignRight: false },
	{ id: 'date', label: 'Date', alignRight: false },
	{ id: '', label: 'Actions', alignRight: false },

];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user?.meter_number?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1);

		// || _user.account_number.toLowerCase().indexOf(query.toLowerCase()) !== -1
		// 	|| _user.account_number.toLowerCase().indexOf(query.toLowerCase()) !== -1
	}
	return stabilizedThis.map((el) => el[0]);
}

function TokenPurchaseHistory({
	token,
	getTokenPurchaseHistory,
	isGetTokenPurchaseHistoryLoading,
	getTokenPurchaseHistoryRequest,
}) {
	const [filterType, setFilterType] = React.useState('all');
	const [meterNumber, setMeterNumber] = React.useState('');

	const [rangeView, setRangeView] = React.useState(false);
	const [rangeState, setRangeState] = React.useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: 'selection',
		},
	]);

	const { startDate, endDate } = rangeState[0];
	const startDateValue = dayjs(startDate).format('YYYY-MM-DD');
	const endDateValue = dayjs(endDate).format('YYYY-MM-DD');

	const dateValues = startDateValue === endDateValue ? {
		startDateValue,
		endDateValue: null,
	} : {
		startDateValue,
		endDateValue,
	};

	const handleSetRangeView = () => {
		setRangeView(true);
	};

	const handleCloseRangeDialog = () => {
		setRangeView(false);
	};

	const handleFilterBy = (event) => {
		setFilterType(event.target.value);
	};

	React.useEffect(() => {
		if (token && filterType === 'all') {
			getTokenPurchaseHistoryRequest(token, null, '');
		}
	}, [token, filterType]);

	React.useEffect(() => {
		handleCloseRangeDialog();
	}, [getTokenPurchaseHistory]);

	React.useEffect(() => {
		if (token) {
			filterType === 'date' && getTokenPurchaseHistoryRequest(token, dateValues, '');
			filterType === 'meterNumber' && getTokenPurchaseHistoryRequest(token, null, meterNumber);
			filterType === 'all' && getTokenPurchaseHistoryRequest(token, null, '');
		}
	}, [token]);

	const [page, setPage] = React.useState(0);

	const [order, setOrder] = React.useState('asc');

	const [selected, setSelected] = React.useState([]);

	const [orderBy, setOrderBy] = React.useState('name');

	const [filterName, setFilterName] = React.useState('');

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const [currentID, setCurrentID] = React.useState(null);

	const [receiptData, setReceiptData] = React.useState(null);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getTokenPurchaseHistory?.users?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const handleSearchDaily = () => {
		const { startDate, endDate } = rangeState[0];

		const startDateValue = dayjs(startDate).format('YYYY-MM-DD');
		const endDateValue = dayjs(endDate).format('YYYY-MM-DD');

		const dateValues = startDateValue === endDateValue ? {
			startDateValue,
			endDateValue: null,
		} : {
			startDateValue,
			endDateValue,
		};

		getTokenPurchaseHistoryRequest(token, dateValues, '');
	};

	const searchByMeterNumber = () => {
		getTokenPurchaseHistoryRequest(token, null, meterNumber);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getTokenPurchaseHistory?.users?.length ?? 0) : 0;

	const filteredUsers = getTokenPurchaseHistory?.users && applySortFilter(getTokenPurchaseHistory?.users, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const loading = isGetTokenPurchaseHistoryLoading;

	const downloaLink = (transaction_reference) => `${API_BASE_URL}/vending/meter/token/${transaction_reference}/details`;
	const [downloadLoading, setDownloadLoading] = React.useState(false);
	const componentRef = React.useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleDownload = async (transaction_reference) => {
		setDownloadLoading(true);
		const result = await fetch(downloaLink(transaction_reference), {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		const data = await result.json();
		setReceiptData(data.data);
		// const blob = await result.blob();
		// const href = window.URL.createObjectURL(blob);

		// const link = document.createElement('a');
		// link.download = `Token-Receipt-${transaction_reference}.pdf`;
		// link.href = href;
		// document.body.appendChild(link);
		// link.click();
		handlePrint();
		setDownloadLoading(false);
		// const response = await axios.get(`/user/disconnection-list/${district}/download`, config);
		// const handlePrint = useReactToPrint({
		// 	content: () => componentRef.current,
		// });
		// dispatc(token, district));
	};

	return (
		<Page title="Token Purchase History">
			<Container maxWidth="xl">
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box>
						<Typography variant="h4" gutterBottom>
							Token Purchase History
						</Typography>
					</Box>
				</Stack>
				<Stack direction="row" mb={2} alignItems="start" justifyContent="space-between">
					<Box>
						<FormControl>
							<Select
								value={filterType}
								onChange={handleFilterBy}
							>
								<MenuItem value="all">Get all history</MenuItem>
								<MenuItem value="meterNumber">Get history per meter number</MenuItem>
								<MenuItem value="date">Get history per date</MenuItem>
							</Select>
						</FormControl>
					</Box>
					{
						filterType === 'date' && (
							<Stack alignItems="end">
								<Box mb={2}>
									<Button onClick={handleSetRangeView} variant="outlined" disabled={loading} color="primary">Open date picker</Button>
								</Box>
								<Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
									<Typography marginRight={1}>
										<strong>Start Date:</strong>
										{' '}
										{startDateValue}
										,
									</Typography>
									<Typography>
										{' '}
										<strong>End Date:</strong>
										{' '}
										{endDateValue}
									</Typography>
								</Box>
							</Stack>
						)
					}
					{
						filterType === 'meterNumber' && (
							<Stack direction="row" alignItems="center">
								<LoadingButton onClick={searchByMeterNumber} variant="outlined" disabled={loading || !meterNumber} color="primary">Get Bill Records</LoadingButton>
								<Box ml={3}>
									<TextField
										id="district-field"
										label="Meter Number"
										shrink
										placeholder="Enter Meter Number"
										value={meterNumber}
										onChange={(event) => {
											setMeterNumber(event.target.value);
										}}
										sx={{ mr: 2 }}
									/>
								</Box>
							</Stack>
						)
					}
				</Stack>

				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h4" gutterBottom>
						All Records
					</Typography>
					<Typography variant="h6" gutterBottom>
						Total Amount:
						{' '}
						{getTokenPurchaseHistory?.totalAmount}
					</Typography>
					<Box>
						<Typography variant="h6">
							Total Energy:
							{' '}
							{getTokenPurchaseHistory?.totalEnergy}
						</Typography>
					</Box>
					<Typography variant="h6" gutterBottom>
						Total Records:
						{' '}
						{getTokenPurchaseHistory?.total}
					</Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{
						loading ? <Loader /> : getTokenPurchaseHistory?.users ? (
							<Box>
								<div style={{ display: 'none' }}>
									<TokenPurchaseReceipt data={receiptData} ref={componentRef} />
								</div>
								<Stack direction="row" mb={2} ml={2} marginRight={2} alignItems="center" justifyContent="space-between">
									{/* <Box>
										<UserListToolbar placeholder="Search Meter Number" numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
									</Box> */}
									<Box>
										<Typography variant="h6">
											Search Total:
											{' '}
											{getTokenPurchaseHistory?.searchTotal}
										</Typography>
									</Box>
								</Stack>

								<TableContainer>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											rowCount={getTokenPurchaseHistory?.users?.length}
											numSelected={selected.length}
											onRequestSort={handleRequestSort}
											onSelectAllClick={handleSelectAllClick}
										/>
										<TableBody>
											{
												filteredUsers
												.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
												.map((row) => {
																const {
																		id,
																		account_number,
                                                                        meter_number,
																		payment_type,
																		customer,
																		tariff,
																		feeder,
																		amount,
																		charged_amount,
																		vat,
                                                                        kwh,
																		token,
																		issuer,
																		date,
																		transaction_reference,
																	} = row;

																return (
																	<TableRow
																		hover
																		key={id}
																		tabIndex={-1}
																		role="checkbox"
																	>
																		<TableCell align="left">
																			<Stack direction="row" alignItems="center" spacing={2}>
																				<Typography variant="subtitle2" noWrap>
																					{account_number}
																				</Typography>
																			</Stack>
																		</TableCell>
																		<TableCell align="left">{meter_number}</TableCell>
																		<TableCell align="left">{payment_type}</TableCell>
																		<TableCell align="left">{customer}</TableCell>
																		<TableCell align="left">{tariff}</TableCell>
																		<TableCell align="left">{feeder}</TableCell>
																		<TableCell align="left">{amount}</TableCell>
																		<TableCell align="left">{charged_amount}</TableCell>
																		<TableCell align="left">{vat}</TableCell>
																		<TableCell align="left">{kwh}</TableCell>
																		<TableCell align="left">{token }</TableCell>
																		<TableCell align="left">{issuer?.fullname}</TableCell>
																		<TableCell align="left">{moment(date).format('D/M/YY')}</TableCell>
																		<TableCell align="left">
																			<LoadingButton
																				onClick={() => {
																					setCurrentID(id);
																					handleDownload(transaction_reference);
																				}}
																				variant="outlined"
																				color="primary"
																				loading={loading || ((id === currentID) && downloadLoading)}
																				disabled={loading || downloadLoading}
																			>
																				Download Receipt
																			</LoadingButton>
																		</TableCell>
																	</TableRow>
																);
															})
											}
											{emptyRows > 0 && (
											<TableRow style={{ height: 53 * emptyRows }}>
												<TableCell colSpan={12} />
											</TableRow>
											)}
										</TableBody>
										{isUserNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={filterName} />
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={getTokenPurchaseHistory?.users?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
								<Dialog
									open={rangeView}
									TransitionComponent={Transition}
									fullWidth
									maxWidth="lg"
									keepMounted
									onClose={handleCloseRangeDialog}
									aria-describedby="alert-dialog-slide-description"
								>
									<Box />
									<DialogTitle>
										Select Date Range
									</DialogTitle>
									<DialogContent>
										<DateRangePicker
											onChange={item => setRangeState([item.selection])}
											showSelectionPreview
											moveRangeOnFirstSelection={false}
											months={2}
											ranges={rangeState}
											direction="horizontal"
										/>
									</DialogContent>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseRangeDialog}>Cancel</Button>
										<LoadingButton onClick={handleSearchDaily} variant="outlined" disabled={loading} color="primary">Get Bill Records</LoadingButton>
									</DialogActions>
								</Dialog>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No History Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Vending }) => {
	const { token } = Authentication;
	const {
		getTokenPurchaseHistory,
		isGetTokenPurchaseHistoryLoading,
	} = Vending;
	return {
		token,
		getTokenPurchaseHistory,
		isGetTokenPurchaseHistoryLoading,
	};
};

export default connect(mapStateToProps, {
	getTokenPurchaseHistoryRequest,
})(TokenPurchaseHistory);

// "id": 4,
// "transaction_id": 15934872,
//         "transaction_reference": "0",
//         "issuer_id": 4,
//         "account_number": "NBJ3025",
//         "meter_number": "600727000000000009",
//         "payment_type": "CASH",
//         "customer": "Salisu Burby",
//         "account_type": null,
//         "tariff": "R2",
//         "feeder": "bukuru",
//         "amount": "300.00",
//         "charged_amount": "278.00",
//         "vat": "22.50",
//         "kwh": 28,
//         "token": "44464369319218298620",
//         "date": "2023-04-19T19:40:56.000Z",
//         "createdAt": "2023-04-19T19:40:56.000Z",
//         "updatedAt": "2023-04-19T19:40:56.000Z",
//         "issuer": {
//           "id": 4,
//           "fullname": "super admin",
//           "title": null,
//           "email": "chukwuka@ixnoteservices.com",
//           "phone": "08153010637",
//           "device_token": "12345",
//           "gender": "male",
//           "role": "superadmin",
//           "account_id": null,
//           "isActive": true,
//           "isSuspended": false,
//           "activation_code": null,
//           "activation_code_expires_in": null,
//           "notification_count": 13,
//           "isDeleted": false,
//           "createdAt": "2022-04-09T11:55:37.000Z",
//           "updatedAt": "2022-07-22T09:35:13.000Z",
//           "deletedAt": null
//         }
