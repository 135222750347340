/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { RolesAndPermissionsState, initialRolesAndPermissionsState } from './Types';

const RolesAndPermissionsSlice = createSlice({
	name: 'RolesAndPermissionsStateSlice',
	initialState: initialRolesAndPermissionsState,
	reducers: {
		setState(state: RolesAndPermissionsState, action: PayloadAction<Partial<RolesAndPermissionsState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getRolesRequest(
			state: RolesAndPermissionsState,
			action: PayloadAction<{
				isGetRolesLoading: Partial<RolesAndPermissionsState['isGetRolesLoading']>,
				getRoles: Partial<RolesAndPermissionsState['getRoles']>,
			}>,
		) {
			const {
				isGetRolesLoading,
				getRoles,
			} = action.payload;
			return {
				...state,
				isGetRolesLoading,
				getRoles,

			};
		},
		getPermissionsRequest(
			state: RolesAndPermissionsState,
			action: PayloadAction<{
				isGetPermisionsLoading: Partial<RolesAndPermissionsState['isGetPermisionsLoading']>,
				getPermisions: Partial<RolesAndPermissionsState['getPermisions']>,
			}>,
		) {
			const {
				isGetPermisionsLoading,
				getPermisions,
			} = action.payload;
			return {
				...state,
				isGetPermisionsLoading,
				getPermisions,

			};
		},
		createRoleRequest(
			state: RolesAndPermissionsState,
			action: PayloadAction<{
				isCreateRoleLoading: Partial<RolesAndPermissionsState['isCreateRoleLoading']>,
				createRole: Partial<RolesAndPermissionsState['createRole']>,
			}>,
		) {
			const {
				isCreateRoleLoading,
				createRole,
			} = action.payload;
			return {
				...state,
				isCreateRoleLoading,
				createRole,

			};
		},
	},
});

const {
	setState: _setState,
	getRolesRequest: _getRolesRequest,
	getPermissionsRequest: _getPermissionsRequest,
	createRoleRequest: _createRoleRequest,
} = RolesAndPermissionsSlice.actions;

export const getRolesRequest = (token: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getRolesRequest({
		...initialRolesAndPermissionsState,
		isGetRolesLoading: true,
	}));
	try {
		const apiResponseData = await API.getRoles(token);
		dispatch(_getRolesRequest({
			isGetRolesLoading: false,
			getRoles: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getRolesRequest({
			...initialRolesAndPermissionsState,
			isGetRolesLoading: false,
		}));
		dispatch(_setState<Partial<RolesAndPermissionsState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get roles. Please try again.',
			},
		));
	}
};

export const getPermissionsRequest = (token: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getPermissionsRequest({
		...initialRolesAndPermissionsState,
		isGetPermisionsLoading: true,
	}));
	try {
		const apiResponseData = await API.getPermissions(token);
		dispatch(_getPermissionsRequest({
			isGetPermisionsLoading: false,
			getPermisions: apiResponseData,
		}));
	} catch (error) {
		dispatch(_getPermissionsRequest({
			...initialRolesAndPermissionsState,
			isGetPermisionsLoading: false,
		}));
		dispatch(_setState<Partial<RolesAndPermissionsState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get permissions. Please try again.',
			},
		));
	}
};

export const createRoleRequest = (roleData: any, token: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createRoleRequest({
		...initialRolesAndPermissionsState,
		isCreateRoleLoading: true,
	}));
	try {
		const apiResponseData = await API.createRole(token, roleData);
		dispatch(_createRoleRequest({
			isCreateRoleLoading: false,
			createRole: apiResponseData,
		}));
	} catch (error) {
		dispatch(_createRoleRequest({
			...initialRolesAndPermissionsState,
			isCreateRoleLoading: false,
		}));
		dispatch(_setState<Partial<RolesAndPermissionsState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to create role. Please try again.',
			},
		));
	}
};

export const { reducer: RolesPermissions } = RolesAndPermissionsSlice;
