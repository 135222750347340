import { CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';

import {
	TransactionState,
	Transactions,
	ConsumerManagementState,
	ConsumerManagement,
	RecordBillPayment,
	RecordBillPaymentState,
} from './Accounts';
import {
	Billing,
	BillingState,
	UserManagement,
	UserManagementState,
	Notification,
	NotificationState,
	MeterReading,
	MeterReadingState,
	RolesPermissions,
	RolesAndPermissionsState,
	Tariff,
	TariffState,
	Feeder,
	FeederState,
	Substation,
	SubstationState,
	Logs,
	LogsState,
} from './Admin';
import { Authentication, AuthenticationState } from './Authentication';
import { Chat, ChatState	} from './Chat';
import { Control, ControlState } from './Control';
import {
	News, NewsState,
} from './News';
import {
	Vending, VendingState,
} from './Prepaid';
import {
	User, UserState, FaultReporting, FaultReportingState,
} from './User';

const RootReducer: Reducer<CombinedState<{
	Authentication: AuthenticationState,
	Billing: BillingState,
	RecordBillPayment: RecordBillPaymentState,
	UserManagement: UserManagementState,
	ConsumerManagement: ConsumerManagementState,
	User: UserState,
	FaultReporting: FaultReportingState,
	Notification: NotificationState,
	News: NewsState,
	MeterReading: MeterReadingState,
	Tariff: TariffState,
	Feeder: FeederState,
	Transactions: TransactionState,
	RolesPermissions: RolesAndPermissionsState,
	Control: ControlState,
	Chat: ChatState,
	Vending: VendingState,
	Substation: SubstationState,
	Logs: LogsState
}>, any> = combineReducers({
	Authentication,
	Billing,
	RecordBillPayment,
	Notification,
	News,
	UserManagement,
	ConsumerManagement,
	User,
	FaultReporting,
	MeterReading,
	Tariff,
	Feeder,
	Substation,
	Transactions,
	RolesPermissions,
	Control,
	Chat,
	Vending,
	Logs,
});

export type RootState = ReturnType<typeof RootReducer>

export default RootReducer;
