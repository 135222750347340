/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import * as moment from 'moment';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function ComponentToPreview({
	id,
	view,
	details,
	handleClosePreviewDialog,
	index,
}) {
	const pageLength = details?.length;

	const getData = (bill) => {
		const {
			accountName,
			accountNumber,
			district,
			invoiceId,
			previousDate,
			presentDate,
			presentKva,
			previousLowReading,
			previousHighReading,
			presentLowReading,
			presentHighReading,
			tax,
			total,
			tariff,
			discount_allowed,
			adjustments,
			previousKva,
			reading_mode,
			meterNumber,
			bbf,
			current_debit,
			current_less_discount,
			total_current_debit,
			outstanding,
			ocbf,
			payments,
			lowVoltAmount,
			account_type,
			units,
			multiplyingFactor,
			previous_reading_mode,
			billMonth,
			billYear,
			address,
			feeder,
			period,
			account_status,
		} = bill;

		return (
			<div className="page" id="page-wrap-two">
				<div className="title grid-container-title">
					<div>
						<div className="title-image">
							<h3 className="title-text">NESCO (NIGERIA) LIMITED</h3>
							<img
								id="image"
								src="https://nesconigeria.com/images/Nesco_LOGO.png"
								alt="logo"
								height="50px"
							/>
							<span
								style={{
									position: 'absolute',
									right: '157px',
									fontSize: '14px',
								}}
							>
								<strong>
									{index + 1}
									{' '}
									/
									{' '}
									{pageLength}
								</strong>
							</span>
						</div>
						<p style={{}} />
					</div>
				</div>
				<div className="grid-container" style={{ alignItems: 'center' }}>
					<div className="item1">
						<div className="item1-details">
							<p>
								<b>Bill for the Month of : </b>

								{billMonth}
								,
								{billYear}
							</p>
							<p>
								<b>Group/District : </b>

								{account_type}
								{' '}
								consumers (
								{district}
								{' '}
								)
							</p>
							<p>
								<b>Feeder : </b>

								{feeder}
							</p>
							<p>
								<b>Meter Number : </b>

								{meterNumber}
							</p>
							<p>
								<b>Acount No : </b>

								{accountNumber}
							</p>
							<p>
								<b>Acount Name : </b>

								{accountName}
							</p>
							<p>
								<b>Address (Residential/Supply) : </b>

								{address}
							</p>
							<p>
								<b>Invoice No. : </b>

								{invoiceId}
							</p>
							<p>
								<b>Tariff : </b>

								{tariff}
							</p>
							<p>
								<b>
									Account Status :
									{' '}
									{account_status}
								</b>
							</p>
						</div>
					</div>
					<div className="item2">
						<div className="item2-details">
							<p>
								Date:
								{moment(new Date()).format('YYYY-MM-DD h:mm:ss a')}
							</p>
							<p>
								Period Ending:
								{period}
							</p>
							Vat Reg, No: JCV100026461
							{/* <div className="transfer-details">
								<p>
									<b>
										You may also transfer
										{' '}
										<br />
										Electronically as follows:
									</b>
								</p>
								<p><b>Name: NESCO (Nig) Limited</b></p>
								<p>
									<b>Bank: </b>
									{' '}
									FIRST BANK (NIG) PLC
								</p>
								<p>
									<b>Bank Acct: </b>
									{' '}
									{account_type.includes('PUB') ? 2001815038 : 2032989043}
								</p>
								<p>
									<b>Note: Please Call or send SMS</b>
									<br />
									to
									{' '}
									<b>0703-368-8247</b>
									<br />
									for Confirmation / Details
								</p>
							</div> */}
							<div className="transfer-details">
								<p>
									<b> You may also transfer as follows: </b>
								</p>
								<p>
									<b>Name: NESCO (Nig) Limited</b>
									<br />
									<b>Bank: </b>
									{' '}
									FIRST BANK (NIG) PLC
									<br />
									<b>Bank Acct: </b>
									{' '}
									{/* {accountNumber.substring(0, 2) === 'PI' ? '2001815038' : '2032989043'} */}
									{account_type === 'NES' ? '2032989043' : '2001815038'}
								</p>
								<p>
									<b>Name: NESCO (Nig) Limited</b>
									<br />
									<b>Bank: </b>
									{' '}
									ZENITH BANK PLC
									<br />
									<b>Bank Acct: </b>
									{' '}
									1011298471
								</p>
								<p>
									<b>Note: Please Call or send SMS</b>
									<br />
									to
									{' '}
									<b>0703-368-8247</b>
									<br />
									for Confirmation / Details
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="table">
					<table>
						<tr>
							<th rowSpan="2">reading details</th>
							<th rowSpan="2">reading date</th>
							<th>kva</th>
							<th colSpan="3">reading / units consumed in kw/hrs</th>
							<th rowSpan="2">
								Readings
								{' '}
								<br />
								Mode/ Satus
							</th>
						</tr>
						<tr>
							<td>Max Demand</td>
							<td>High - Volt</td>
							<td>Low - Volt</td>
							<td />
						</tr>
						<tr>
							<td>Present</td>
							<td>

								{presentDate ?? null}
							</td>
							<td>

								{presentKva ?? 0}
							</td>
							<td>

								{presentHighReading ?? 0}
							</td>
							<td>

								{presentLowReading ?? 0}
							</td>
							<td />
							<td>

								{reading_mode}
							</td>
						</tr>
						<tr>
							<td>Previous</td>
							<td>

								{previousDate ?? null}
							</td>
							<td>

								{previousKva ?? 0}
							</td>
							<td>

								{previousHighReading ?? 0}
							</td>
							<td>

								{previousLowReading ?? 0}
							</td>
							<td />
							<td>

								{previous_reading_mode ?? 0}
							</td>
						</tr>
						<tr>
							<td colSpan="2">Multiplying Factor:</td>
							<td>0</td>
							<td>0</td>
							<td>

								{multiplyingFactor ?? 1}
							</td>
							<td style={{ width: '50px' }} />
							<td />
						</tr>
						<tr>
							<td colSpan="2">Current billed unit:</td>
							<td>0</td>
							<td>0</td>
							<td>
								{units}
							</td>
							<td />
							<td>{reading_mode}</td>
						</tr>
					</table>
				</div>

				<div className="grid-container2">
					<div className="notice">
						<div className="notice-note">
							<h2>
								Notice To
								{' '}
								<br />
								Consumers
							</h2>
							<hr />
							<p>
								Standard Condition Of
								{' '}
								<br />
								Payment Apply.
							</p>
							<p>
								For 24 Hours Fault
								{' '}
								<br />
								Complaints And BreakDown
								{' '}
								<br />
								Reports.
							</p>
							<p>
								Please Call - Control
								{' '}
								<br />
								Room On 07039232009 or
								{' '}
								<br />
								08033093212
							</p>
							<hr />
						</div>
					</div>
					<div className="amount item-amount">
						<h2>Bill Details</h2>
						<div className="bill-details">
							<p>Previous Charges- Balance Brought Forward</p>
							<p>Less Payment Made</p>
							<hr style={{ visibility: 'hidden' }} />
							<p>Outstanding Charges Brought Forward</p>
							<p>minus Adjustments</p>
							<hr style={{ visibility: 'hidden' }} />
							<p>Net Outstanding Balance Brought Forward</p>
						</div>
						<div className="details">
							<div className="current-changes" style={{ marginTop: '1px' }}>
								<h3>Current Charges</h3>
								<hr />
								<p>Maximum-Demand Charge</p>
								<p>charge - High - Voltage</p>
								<p>Low - Voltage</p>
								<p>Miscellaneous charges</p>
								<hr style={{ backgroundColor: 'red', borderWidth: 1 }} />
								<p>Current Debits</p>
								<p>Less Discount Allowed</p>
								<hr style={{ backgroundColor: 'red', borderWidth: 1 }} />
								<br />
								<br />
								<p>Value- Added- Tax</p>
							</div>
							<div className="amount1">
								<h3>Amount (Naira)</h3>
								<hr />
								<p>0.00</p>
								<p>0.00</p>
								<p>
									{lowVoltAmount}
								</p>
								<p>0.00</p>
								<hr />
								<p>
									<b>
										{current_debit}
									</b>
								</p>
								<p>{discount_allowed}</p>
								<hr />
								<p>
									<b>
										{current_less_discount}
									</b>
								</p>
								<p>
									{tax}
								</p>
							</div>
						</div>
						<div className="bill-details">
							<p>Overall Current Debits</p>
							<hr style={{ visibility: 'hidden' }} />
							<p>Please Pay The Following Amoount</p>
						</div>
					</div>
					<div className="amount2">
						<div className="item-amount">
							<h3 style={{ borderBottom: '2px solid black' }}>Amount (Naira)</h3>
							<p>

								{bbf}
							</p>
							<p>

								{payments ?? 0}
							</p>
							<hr />
							<p>

								{ocbf}
							</p>
							<p>{adjustments}</p>
							<hr />
							<p>
								{outstanding}
							</p>
							<div className="black-box" />
							<p>
								{total_current_debit}
							</p>
							<hr />
							<p>
								<strong>{total}</strong>
							</p>
						</div>
					</div>
				</div>
				<div className="grid-container3">
					<div className="news">
						<div className="right">
							Payment
							{' '}
							<br />
							Notice / Terms
							{' '}
							<br />
							of payment
						</div>
						<div className="left">
							<p>
								Please Note - That Payment Made After -
								{period}
								{' '}
								- will
								{' '}
								<br />
								Be Reflected on Succeeding Month&#39;s Bill.
							</p>
							<hr />
							<p>
								Pay Within 10 Day(s), From The Date On This Invoice Or Face
								{' '}
								<br />
								Immediate DISCONNECTION Thereafter
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const receipt = details.find((element) => (element?.id ? element?.id === id : element?.bill_id === id));

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			onClose={handleClosePreviewDialog}
			fullWidth
			maxWidth="lg"
		>
			<DialogContent>
				{
					receipt && getData(receipt)
				}
			</DialogContent>
		</Dialog>
	);
}

export default ComponentToPreview;
