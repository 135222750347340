/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import {
	Box, Container, Typography, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import { NewPasswordForm } from '../../sections/auth/new-password';

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(12, 0),
}));

export default function NewPassword() {
	return (
		<Page title="Reset Password" sx={{ height: 1 }}>
			<RootStyle>
				<LogoOnlyLayout />
				<Container>
					<Box sx={{ maxWidth: 480, mx: 'auto' }}>
						<>
							<Typography variant="h3" paragraph>
								Reset your password?
							</Typography>
							<Typography sx={{ color: 'text.secondary', mb: 5 }}>
								Please enter your new password to reset.
							</Typography>
							<NewPasswordForm />
							<Button fullWidth size="large" component={RouterLink} to="/login" sx={{ mt: 1 }}>
								Back to login
							</Button>
						</>
					</Box>
				</Container>
			</RootStyle>
		</Page>
	);
}
