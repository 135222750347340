/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
// import DateFnsUtils from '@date-io/date-fns';
// import {
// 	MuiPickersUtilsProvider,
// 	KeyboardDatePicker,
// } from '@material-ui/pickers';

import { LoadingButton } from '@mui/lab';
import {
	// Grid,
	MenuItem,
	Card,
	Table,
	Stack,
	TableRow,
	InputLabel,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Divider,
	Box,
	TextField,
	Select,
	Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import {
	printBillFromArrayRequest,
	generateBillForNescoRequest,
	updateBillRequest,
	generateBillForPUBRequest,
} from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import { UpdateBillsForm } from '../sections/@dashboard/bill';
import { UserListHead } from '../sections/@dashboard/user';
import ComponentToPreview from './ComponentToPreview';
import ComponentToPrint from './ComponentToPrint';

const TABLE_HEAD = [
	{ id: 'accountName', label: 'Account Name', alignRight: false },
	{ id: 'accountNumber', label: 'Account Number', alignRight: false },
	{ id: 'billMonth', label: 'Bill Month', alignRight: false },
	{ id: 'billYear', label: 'Bill Year', alignRight: false },
	{ id: 'units', label: 'Units', alignRight: false },
	{ id: 'current_debit', label: 'Current Debit', alignRight: false },
	{ id: 'total_current_debit', label: 'Total Current Debit', alignRight: false },
	{ id: 'edit', label: '', alignRight: false },
	{ id: 'preview', label: '', alignRight: false },
];

function TableCard({
	user,
	pagination,
	printBillFromArray,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
	isUpdateBillLoading,
	editView,
	editDetails,
	editId,
	handleSetEditView,
	handleCloseEditDialog,
}) {
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [preview, setPreview] = React.useState(false);
	const [ID, setPreviewID] = React.useState(null);
	const [index, setIndex] = React.useState(null);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = printBillFromArray?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagination?.pageSize ?? 0) : 0;

	const handleClosePreviewDialog = () => setPreview(false);

	return (
		<Box>
			<TableContainer sx={{ minWidth: 800 }}>
				<Table>
					<UserListHead
						order={order}
						orderBy={orderBy}
						headLabel={TABLE_HEAD}
						rowCount={printBillFromArray?.length}
						numSelected={selected.length}
						onRequestSort={handleRequestSort}
						onSelectAllClick={handleSelectAllClick}
					/>
					<TableBody>
						{
							printBillFromArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row) => {
									const {
										bill_id,
										accountName,
										accountNumber,
										billMonth,
										billYear,
										units,
current_debit,
total_current_debit,
										} = row;

									return (
										<TableRow
											hover
											key={bill_id}
											tabIndex={-1}
											role="checkbox"
										>
											<TableCell align="left">
												<Typography variant="subtitle2">
													{accountName}
												</Typography>
											</TableCell>
											<TableCell align="left">{accountNumber}</TableCell>
											<TableCell align="left">{billMonth}</TableCell>
											<TableCell align="left">{billYear}</TableCell>
											<TableCell align="left">{units}</TableCell>
											<TableCell align="left">{current_debit}</TableCell>
											<TableCell align="left">{total_current_debit}</TableCell>
											<TableCell align="left">
												<LoadingButton
													type="submit"
													variant="contained"
													color="primary"
													onClick={() => {
					setIndex(printBillFromArray.findIndex(item => item.accountName === accountName));
                                                                setPreviewID(bill_id);
                                                                setPreview(true);
                                                            }}
												>
													Preview bill
												</LoadingButton>
											</TableCell>
											<TableCell align="left">
												{
													((user?.role !== 'monitoring_officer') || (user?.role !== 'auditor')) && (
														<LoadingButton
															type="submit"
															variant="contained"
															color="info"
															startIcon={<Iconify icon="bxs:edit" />}
															onClick={() => handleSetEditView(bill_id)}
														>
															Edit Bill
														</LoadingButton>
													)
												}
											</TableCell>
										</TableRow>
									);
							})
						}
						{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={12} />
						</TableRow>
									)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[15, 30, 50]}
				component="div"
				count={printBillFromArray?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newpage) => handleChangePage(event, newpage)}
				onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
			/>
			<ComponentToPreview
				index={index}
				id={ID}
				view={preview}
				details={printBillFromArray}
				handleClosePreviewDialog={handleClosePreviewDialog}
			/>
			{
				editView && (
					<UpdateBillsForm
						view={editView}
						id={editId}
						details={editDetails[0]}
						handleCloseEditDialog={handleCloseEditDialog}
						isUpdateBillLoading={isUpdateBillLoading}
					/>
				)
			}
		</Box>

	);
}

function TabPanel(props) {
	const {
		children, value, index, ...other
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
			<Box sx={{ p: 3 }}>
				<Typography>{children}</Typography>
			</Box>
      )}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function MeterReading({
	token,
	user,
	printBillFromArray,
	printBillFromArrayLoading,
	printBillFromArrayRequest,
	generateBillForNescoRequest,
	generateBillForPUBRequest,
	isGenerateBillForNescoLoading,
	generateBillForNesco,
	isGenerateBillForPUBLoading,
	generateBillForPUB,
	isUpdateBillLoading,
	updateBill,
	// error,
}) {
	const componentRef = React.useRef();
	const [district, setDistrict] = React.useState('MPA');
	const [accountNumber, setAccountNumber] = React.useState('');
	const [monthValue, setMonthValue] = React.useState(dayjs().subtract(1, 'month'));
	const [yearValue, setYearValue] = React.useState(dayjs());
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [status, setStatus] = React.useState('ACTIVE');

	const [nescoMonthValue, setNescoMonthValue] = React.useState(dayjs().subtract(1, 'month'));
	const [nescoYearValue, setNescoYearValue] = React.useState(dayjs());
	const [pubMonthValue, setPubMonthValue] = React.useState(dayjs().subtract(1, 'month'));
	const [pubYearValue, setPubYearValue] = React.useState(dayjs());

	const [editView, setEditView] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState([]);
	const [editId, setEditId] = React.useState(null);

	const [nescoOpen, setNescoDialogOpen] = React.useState(false);
	const [pubOpen, setPUBDialogOpen] = React.useState(false);

	const handleNescoDialogOpen = () => {
		setNescoDialogOpen(true);
	};
	const handleNescoDialogClose = () => {
		setNescoDialogOpen(false);
	};

	const handlePUBDialogClickOpen = () => {
		setPUBDialogOpen(true);
	};
	const handlePUBDialogClose = () => {
		setPUBDialogOpen(false);
	};

	React.useEffect(() => {
		if (token && (generateBillForNesco || generateBillForPUB)) {
			printBillFromArrayRequest(token, dayjs(monthValue).format('MMMM'), dayjs(yearValue).format('YYYY'), district, accountNumber, status);
			handleNescoDialogClose();
			handlePUBDialogClose();
		}
	}, [token, generateBillForNesco, generateBillForPUB]);

	React.useEffect(() => {
		if (updateBill) {
			setEditView(false);
			setEditDetails({});
			setEditId(null);
			printBillFromArrayRequest(token, dayjs(monthValue).format('MMMM'), dayjs(yearValue).format('YYYY'), district, accountNumber, status);
		}
	}, [updateBill]);

	const loading = printBillFromArrayLoading;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSetEditView = (id) => {
		setEditView(true);
		setEditDetails(printBillFromArray?.bills.filter(account => account.bill_id === id));
		setEditId(id);
	};
	const handleCloseEditDialog = () => setEditView(false);

	const handlePrint = useReactToPrint({
		documentTitle:	'NESCO-Bills',
		content: () => componentRef.current,
		pageStyle: `
			@page {
				size: A4;
				margin: 10px 0 0 0;
			}
			@media print {
				h4.class-name
				@page{
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 0px;
					margin-bottom: 0px;
				}
				.page {
					margin: 0;
					padding: 0;
					border: initial;
					border-radius: initial;
					width: initial;
					min-height: initial;
					box-shadow: initial;
					background: initial;
					overflow: hidden;
					height: 100vh;
				}
			}
		`,
	});

	React.useEffect(() => {
		if (accountNumber) {
			const delayDebounceFn = setTimeout(() => {
				printBillFromArrayRequest(token, dayjs(monthValue).format('MMMM'), dayjs(yearValue).format('YYYY'), district, accountNumber, status);
			}, 1000);

			return () => clearTimeout(delayDebounceFn);
		}
	}, [accountNumber]);

	React.useEffect(() => {
		if (district) {
			const delayDebounceFn = setTimeout(() => {
				printBillFromArrayRequest(token, dayjs(monthValue).format('MMMM'), dayjs(yearValue).format('YYYY'), district, accountNumber, status);
			}, 1000);
			return () => clearTimeout(delayDebounceFn);
		}
	}, [district]);

	const handleGenerateBillForNesco = () => {
		generateBillForNescoRequest({
			month: dayjs(nescoMonthValue).format('MMMM'),
			year: dayjs(nescoYearValue).format('YYYY'),
		}, token);
	};

	const handleGenerateBillForPub = () => {
		generateBillForPUBRequest({
			month: dayjs(pubMonthValue).format('MMMM'),
			year: dayjs(pubYearValue).format('YYYY'),
		}, token);
	};

	const handleStatusChange = (e) => {
		printBillFromArrayRequest(token, dayjs(monthValue).format('MMMM'), dayjs(yearValue).format('YYYY'), district, accountNumber, e.target.value);
		setStatus(e.target.value);
	};

	return (
		<Page title="Print all bills">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
					<Typography variant="h4" gutterBottom>
						Print All Bills
					</Typography>
					<Typography variant="h6" gutterBottom>
						Select the month and year you want to generate bills for.
					</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="end" mb={3}>
					<Box>
						<DesktopDatePicker
							disableHighlightToday
							openTo="year"
							inputFormat="YYYY"
							views={['year']}
							label="Select Year"
							value={nescoYearValue}
							onChange={(newValue) => setNescoYearValue(newValue)}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{ ...params.inputProps, readOnly: true }}
									sx={{
										'& .MuiInputBase-input': {
											caretColor: 'transparent',
										},
									}}
								/>
							)}
						/>
					</Box>
					<Box ml={2}>
						<DesktopDatePicker
							disableHighlightToday
							openTo="month"
							inputFormat="MMMM"
							views={['month']}
							label="Select Month"
							value={nescoMonthValue}
							onChange={(newValue) => setNescoMonthValue(newValue)}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{ ...params.inputProps, readOnly: true }}
									sx={{
										'& .MuiInputBase-input': {
											caretColor: 'transparent',
										},
									}}
								/>
							)}
						/>
					</Box>
					<Box ml={2}>
						<LoadingButton
							onClick={handleNescoDialogOpen}
							variant="outlined"
							color="primary"
						>
							Generate Bill for Neco Consumers
						</LoadingButton>
					</Box>

				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="end" mb={3}>
					<Box>
						<DesktopDatePicker
							disableHighlightToday
							openTo="year"
							inputFormat="YYYY"
							views={['year']}
							label="Select Year"
							value={pubYearValue}
							onChange={(newValue) => {
								setPubYearValue(newValue);
							}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{ ...params.inputProps, readOnly: true }}
									sx={{
										'& .MuiInputBase-input': {
											caretColor: 'transparent',
										},
									}}
								/>
							)}
						/>
					</Box>
					<Box ml={2}>
						<DesktopDatePicker
							disableHighlightToday
							openTo="month"
							inputFormat="MMMM"
							views={['month']}
							label="Select Month"
							value={pubMonthValue}
							onChange={(newValue) => {
								setPubMonthValue(newValue);
							}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{ ...params.inputProps, readOnly: true }}
									sx={{
										'& .MuiInputBase-input': {
											caretColor: 'transparent',
										},
									}}
								/>
							)}
						/>
					</Box>
					<Box ml={2}>
						<LoadingButton
							onClick={handlePUBDialogClickOpen}
							variant="outlined"
							color="primary"
						>
							Generate Bill for PUB Consumers
						</LoadingButton>
					</Box>
				</Stack>
				<Divider />
				<Stack mb={1} mt={3}>
					<Typography variant="h6" gutterBottom>
						Search by district, account number, month or year to get receipts
					</Typography>
				</Stack>
				<Stack direction="row" justifyContent="space-between" mb={3}>
					<TextField
						id="district-field"
						label="District"
						shrink
						placeholder="Enter District"
						value={district}
						onChange={(event) => {
								setDistrict(event.target.value);
							}}
						sx={{ mr: 2 }}
					/>
					<TextField
						id="district-account"
						label="Account Number"
						shrink
						placeholder="Enter Account Number"
						value={accountNumber}
						onChange={(event) => {
								setAccountNumber(event.target.value);
							}}
						sx={{ mr: 2 }}
					/>
					<Box sx={{ mr: 2 }}>
						<DesktopDatePicker
							disableHighlightToday
							openTo="month"
							inputFormat="MMMM"
							views={['month']}
							label="Select Month"
							value={monthValue}
							onChange={(newValue) => {
									printBillFromArrayRequest(token, dayjs(newValue).format('MMMM'), dayjs(yearValue).format('YYYY'), district, accountNumber, status);
								setMonthValue(newValue);
							}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{ ...params.inputProps, readOnly: true }}
									sx={{
										'& .MuiInputBase-input': {
											caretColor: 'transparent',
										},
									}}
								/>
							)}
						/>
					</Box>
					<DesktopDatePicker
						disableHighlightToday
						openTo="year"
						inputFormat="YYYY"
						views={['year']}
						label="Select Year"
						value={yearValue}
						onChange={(newValue) => {
								printBillFromArrayRequest(token, dayjs(monthValue).format('MMMM'), dayjs(newValue).format('YYYY'), district, accountNumber, status);
								setYearValue(newValue);
							}}
						KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
						renderInput={(params) => (
							<TextField
								{...params}
								inputProps={{ ...params.inputProps, readOnly: true }}
								sx={{
										'& .MuiInputBase-input': {
											caretColor: 'transparent',
										},
									}}
							/>
							)}
					/>
				</Stack>
				<Stack direction="row" sx={{ mb: 2 }} justifyContent="space-between">
					<Button onClick={handlePrint} variant="outlined" color="inherit">Print All Bills</Button>
					<Box>
						<InputLabel id="status-label">Status</InputLabel>
						<Select
							defaultValue="ACTIVE"
							sx={{ minWidth: 50 }}
							onChange={handleStatusChange}
						>
							<MenuItem value="ACTIVE">
								ACTIVE
							</MenuItem>
							<MenuItem value="DORMANT">
								DORMANT
							</MenuItem>
							<MenuItem value="DISCONNECTED">
								DISCONNECTED
							</MenuItem>
							<MenuItem value="CLOSED">
								CLOSED
							</MenuItem>
						</Select>
					</Box>
				</Stack>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Card sx={{ pb: 2, pt: 2 }}>
							{
								loading ? <Loader />
									: printBillFromArray ? (
										<>
											<div style={{ display: 'none' }}>
												<ComponentToPrint ref={componentRef} printBillFromArray={printBillFromArray?.bills} />
											</div>

											<TableCard
												pagination={printBillFromArray.pagination}
												printBillFromArray={printBillFromArray?.bills}
												handleChangePage={handleChangePage}
												handleChangeRowsPerPage={handleChangeRowsPerPage}
												page={page}
												rowsPerPage={rowsPerPage}
												isUpdateBillLoading={isUpdateBillLoading}
												editView={editView}
												editDetails={editDetails}
												editId={editId}
												handleSetEditView={handleSetEditView}
												handleCloseEditDialog={handleCloseEditDialog}
												user={user}
											/>
										</>

									) : (
										<Box sx={{ display: 'flex', justifyContent: 'center' }}>
											<Typography variant="h5" gutterBottom>
												No Bill Found
											</Typography>
										</Box>
									)
							}
						</Card>
					</Box>
				</Box>
			</Container>
			<Dialog
				open={nescoOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleNescoDialogClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					Generate Nesco Bills
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to generate Nesco bills for
						<strong>{` ${dayjs(nescoMonthValue).format('MMMM')} ${dayjs(nescoYearValue).format('YYYY')}.`}</strong>
					</DialogContentText>
					<DialogContentText>
						<strong>Please note this action cannot be reversed.</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus color="error" onClick={handleNescoDialogClose}>
						Cancel
					</Button>
					<LoadingButton
						onClick={handleGenerateBillForNesco}
						loading={isGenerateBillForNescoLoading}
						color="primary"
					>
						Generate Bill for Neco Consumers
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Dialog
				open={pubOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={handlePUBDialogClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					Generate PUB Bills
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to generate PUB bills for
						<strong>{` ${dayjs(pubMonthValue).format('MMMM')} ${dayjs(pubYearValue).format('YYYY')}.`}</strong>
					</DialogContentText>
					<DialogContentText>
						<strong>Please note this action cannot be reversed.</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus color="error" onClick={handlePUBDialogClose}>
						Cancel
					</Button>
					<LoadingButton
						onClick={handleGenerateBillForPub}
						loading={isGenerateBillForPUBLoading}
						color="primary"
					>
						Generate Bill for PUB Consumers
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Billing }) => {
	const { token, user } = Authentication;
	const {
		printBillFromArrayLoading,
		printBillFromArray,
		isGenerateBillForNescoLoading,
		generateBillForNesco,
		isGenerateBillForPUBLoading,
		generateBillForPUB,
		error,
		isUpdateBillLoading,
		updateBill,
	} = Billing;
	return {
		token,
		user,
		printBillFromArrayLoading,
		printBillFromArray,
		isGenerateBillForNescoLoading,
		generateBillForNesco,
		isGenerateBillForPUBLoading,
		generateBillForPUB,
		error,
		isUpdateBillLoading,
		updateBill,
	};
};

export default connect(mapStateToProps, {
	printBillFromArrayRequest,
	generateBillForNescoRequest,
	generateBillForPUBRequest,
	updateBillRequest,
})(MeterReading);

// accountName(pin): "HAJIYA ZAINAP IBRAHIM GOBIR"
// accountNumber(pin): "PBI0002"
// invoiceId(pin): "PBI0002-4"
// createdAt(pin): "2023-01-10"
// previousDate(pin): "2023-01-10"
// presentDate(pin): "2023-01-10"
// previousLowReading(pin): 27030
// previousHighReading(pin): 0
// presentLowReading(pin): 27050
// presentHighReading(pin): 0
// tax(pin): "6.00"
// reading_mode(pin): "ACTUAL"
// previous_reading_mode(pin): "ACTUAL"
// tariff(pin): "R1"
// bbf(pin): "35978.75"
// bcf(pin): "36064.75"
// district(pin): "BI"
// meterNumber(pin): "75705088"
// current_debit(pin): "80.00"
// total_current_debit(pin): "86.00"
// outstanding(pin): 35978.75
// ocbf(pin): 35978.75
// payments(pin): "0.00"
// lowVoltAmount(pin): "80.00"
// units(pin): 20
// multiplyingFactor(pin): 1
// billMonth(pin): "January"
// billYear(pin): "2023"
// address(pin): null
// total(pin): "36064.75"
// account_type(pin): "PUB"
// period(pin): "31/01/2023"

// <Box ref={componentRef}>
// 	<div
// 		style={{
// 			width: '210mm',
// 			height: '279mm',
// 			border: '1px #D3D3D3 solid',
// 			borderRadius: '5px',
// 			background: 'white',
// 			boxShadow: ' 0 0 5px rgba(0, 0, 0, 0.1)',
// 			// margin: '10mm auto',
// 		}}
// 		dangerouslySetInnerHTML={{ __html: getPaymentReceipt }}
// 	/>
// 	<div
// 		style={{
// 			width: '210mm',
// 			height: '279mm',
// 			border: '1px #D3D3D3 solid',
// 			borderRadius: '5px',
// 			background: 'white',
// 			boxShadow: ' 0 0 5px rgba(0, 0, 0, 0.1)',
// 			// margin: '10mm auto',
// 		}}
// 		dangerouslySetInnerHTML={{ __html: getPaymentReceipt }}
// 	/>
// 	<div
// 		style={{
// 			width: '210mm',
// 			height: '279mm',
// 			border: '1px #D3D3D3 solid',
// 			borderRadius: '5px',
// 			background: 'white',
// 			boxShadow: ' 0 0 5px rgba(0, 0, 0, 0.1)',
// 			// margin: '10mm auto',
// 		}}
// 		dangerouslySetInnerHTML={{ __html: getPaymentReceipt }}
// 	/>

// </Box>
