/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const approveRecord = async (recordID: string, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.put(`/billing/approve/record/${recordID}`, {}, config);

	return response.data;
};
