/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const generateBillsForNesco = async (generationData: any, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.post('/billing/generate-bills/nes', generationData, config);
	return response.data;
};

export const generateBillsForPub = async (generationData: any, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.post('/billing/generate-bills/pub', generationData, config);
	return response.data;
};
