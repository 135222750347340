/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui

// ----------------------------------------------------------------------

export default function RHFSelect({ name, children, ...other }) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...field}
					select
					fullWidth
					SelectProps={{ native: true, shrink: true }}
					error={!!error}
					helperText={error?.message}
					{...other}
				>
					{children}
				</TextField>
      )}
		/>
	);
}
RHFSelect.propTypes = {
	children: PropTypes.node,
	name: PropTypes.string,
};
