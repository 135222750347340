import axios from 'axios';

import { API_BASE_URL } from '../Environment';
import history from '../history';

const instance = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.response.use(
	(res) => res,
	(err) => {
		if (err.response) {
			if (err.response?.data?.statusCode === '401' || err?.response?.status === 401) {
				localStorage.removeItem('nesco-pre-paid_token_expiration_date');
				localStorage.removeItem('nesco-pre-paid_user');
				localStorage.removeItem('nesco-prepaid-user');
				localStorage.removeItem('nesco-pre-paid_token');
				history.push('/login');
			}
		}
		return Promise.reject(err);
	},
);

export default instance;
