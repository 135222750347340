/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { messageToDisplay } from '../../functions';
import { UserManagementState, initialUserManagementState } from './Types';

const UserManagementSlice = createSlice({
	name: 'UserManagementSlice',
	initialState: initialUserManagementState,
	reducers: {
		setState(state: UserManagementState, action: PayloadAction<Partial<UserManagementState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllUsersRequest(
			state: UserManagementState,
			action: PayloadAction<{
				isGetAllUsersLoading: Partial<UserManagementState['isGetAllUsersLoading']>,
				getAllUsers: Partial<UserManagementState['getAllUsers']>,
				createUser?: Partial<UserManagementState['createUser']>,
				suspendUser?: Partial<UserManagementState['suspendUser']>,
				deleteUser?: Partial<UserManagementState['deleteUser']> | undefined,
			}>,
		) {
			const {
				isGetAllUsersLoading,
				getAllUsers,
				createUser,
				deleteUser,
				suspendUser,
			} = action.payload;
			return {
				...state,
				isGetAllUsersLoading,
				getAllUsers,
				createUser,
				deleteUser,
				suspendUser,
			};
		},
		searchUserRequest(
			state: UserManagementState,
			action: PayloadAction<{
				isSearchUserLoading: Partial<UserManagementState['isSearchUserLoading']>,
				searchUser: Partial<UserManagementState['searchUser']>| undefined,
			}>,
		) {
			const {
				isSearchUserLoading,
				searchUser,
			} = action.payload;
			return {
				...state,
				isSearchUserLoading,
				searchUser,
			};
		},
		getSingleUserRequest(
			state: UserManagementState,
			action: PayloadAction<{
				isGetSingleUserLoading: Partial<UserManagementState['isGetSingleUserLoading']>,
				getSingleUser: Partial<UserManagementState['getSingleUser']> | undefined,
			}>,
		) {
			const {
				isGetSingleUserLoading,
				getSingleUser,
			} = action.payload;
			return {
				...state,
				isGetSingleUserLoading,
				getSingleUser,
			};
		},
		suspendUserRequest(
			state: UserManagementState,
			action: PayloadAction<{
				isSuspendUserLoading: Partial<UserManagementState['isSuspendUserLoading']>,
				suspendUser: Partial<UserManagementState['suspendUser']> | undefined,
			}>,
		) {
			const {
				isSuspendUserLoading,
				suspendUser,
			} = action.payload;
			return {
				...state,
				isSuspendUserLoading,
				suspendUser,
			};
		},
		deleteUserRequest(
			state: UserManagementState,
			action: PayloadAction<{
				isDeleteUserLoading: Partial<UserManagementState['isDeleteUserLoading']>,
				deleteUser: Partial<UserManagementState['deleteUser']> | undefined,
			}>,
		) {
			const {
				isDeleteUserLoading,
				deleteUser,
			} = action.payload;
			return {
				...state,
				isDeleteUserLoading,
				deleteUser,
			};
		},
		getUsersByRoleRequest(
			state: UserManagementState,
			action: PayloadAction<{
				isGetUsersByRoleLoading: Partial<UserManagementState['isGetUsersByRoleLoading']>,
				getUsersByRole: Partial<UserManagementState['getUsersByRole']>,
			}>,
		) {
			const {
				isGetUsersByRoleLoading,
				getUsersByRole,
			} = action.payload;
			return {
				...state,
				isGetUsersByRoleLoading,
				getUsersByRole,
			};
		},
		createUser(
			state: UserManagementState,
			action: PayloadAction<{
				isCreateUserLoading: Partial<UserManagementState['isCreateUserLoading']>,
				createUser: Partial<UserManagementState['createUser']>,
				error?: Partial<UserManagementState['error']> | undefined,
			}>,
		) {
			const {
				isCreateUserLoading,
				createUser,
				error,
			} = action.payload;
			return {
				...state,
				isCreateUserLoading,
				createUser,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllUsersRequest: _getAllUsersRequest,
	searchUserRequest: _searchUserRequest,
	getSingleUserRequest: _getSingleUserRequest,
	deleteUserRequest: _deleteUserRequest,
	suspendUserRequest: _suspendUserRequest,
	getUsersByRoleRequest: _getUsersByRoleRequest,
	createUser: _createUser,
} = UserManagementSlice.actions;

export const getAllUsersRequest = (token: any, role?: string, searchFields?: string, queryField?: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllUsersRequest({
		...initialUserManagementState,
		isGetAllUsersLoading: true,
		createUser: null,
		deleteUser: null,
		suspendUser: null,
	}));
	try {
		const apiResponseData = await API.getAllUsers(token, role, searchFields, queryField);
		dispatch(_getAllUsersRequest({
			isGetAllUsersLoading: false,
			getAllUsers: apiResponseData?.data?.users,
		}));
	} catch (error) {
		dispatch(_getAllUsersRequest({
			...initialUserManagementState,
			isGetAllUsersLoading: false,
		}));
		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: error.response ? error.response.message : error.message ?? 'Unable to get all users. Please try again.',
			},
		));
	}
};

export const searchUserRequest = (token: any, fullname?: string, role?: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_searchUserRequest({
		...initialUserManagementState,
		isSearchUserLoading: true,
	}));
	try {
		const apiResponseData = await API.searchUser(token, fullname, role);

		const searchedUsersArray = apiResponseData?.data?.users.map((users: any) => {
			const container: any = {};

			container.id = users.id;
			container.label = users.fullname;
			container.role = users.role;

			return container;
		});

		dispatch(_searchUserRequest({
			isSearchUserLoading: false,
			searchUser: searchedUsersArray,
		}));
	} catch (error) {
		dispatch(_searchUserRequest({
			...initialUserManagementState,
			isSearchUserLoading: false,
		}));
		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: error.response ? error.response.message : error.message ?? 'Unable to find user. Please try again.',
			},
		));
	}
};

export const getSingleUserRequest = (token: any, id?: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSingleUserRequest({
		...initialUserManagementState,
		isGetSingleUserLoading: true,
	}));
	try {
		const apiResponseData = await API.getSingleUser(token, id);
		dispatch(_getSingleUserRequest({
			isGetSingleUserLoading: false,
			getSingleUser: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getSingleUserRequest({
			...initialUserManagementState,
			isGetSingleUserLoading: false,
		}));
		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: error.message ?? 'Unable to get user. Please try again.',
			},
		));
	}
};

export const deleteUserRequest = (token: any, id?: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_deleteUserRequest({
		...initialUserManagementState,
		isDeleteUserLoading: true,
	}));
	try {
		const apiResponseData = await API.deleteUser(token, id);
		dispatch(_deleteUserRequest({
			isDeleteUserLoading: false,
			deleteUser: apiResponseData,
		}));
	} catch (error) {
		dispatch(_deleteUserRequest({
			...initialUserManagementState,
			isDeleteUserLoading: false,
		}));
		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: error.message ?? 'Unable to delete user. Please try again.',
			},
		));
	}
};

export const suspendUserRequest = (token: any, id?: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_suspendUserRequest({
		...initialUserManagementState,
		isSuspendUserLoading: true,
	}));
	try {
		const apiResponseData = await API.suspendUser(token, id);
		dispatch(_suspendUserRequest({
			isSuspendUserLoading: false,
			suspendUser: apiResponseData,
		}));
	} catch (error) {
		dispatch(_suspendUserRequest({
			...initialUserManagementState,
			isSuspendUserLoading: false,
		}));
		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: error.message ?? 'Unable to suspend user. Please try again.',
			},
		));
	}
};

export const getUsersByRoleRequest = (role, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getUsersByRoleRequest({
		...initialUserManagementState,
		isGetUsersByRoleLoading: true,
		getUsersByRole: null,
	}));
	try {
		const apiResponseData = await API.getUsersByRole(role, token);
		dispatch(_getUsersByRoleRequest({
			isGetUsersByRoleLoading: false,
			getUsersByRole: apiResponseData?.data?.users,
		}));
	} catch (error) {
		dispatch(_getUsersByRoleRequest({
			...initialUserManagementState,
			isGetUsersByRoleLoading: false,
		}));
		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: error.message ?? 'Unable to get users by this role. Please try again.',
			},
		));
	}
};

export const createUser = (userData: any, token: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createUser({
		...initialUserManagementState,
		isCreateUserLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.createUser(userData, token);
		dispatch(_createUser({
			isCreateUserLoading: false,
			createUser: apiResponseData,
		}));
	} catch (error) {
		dispatch(_createUser({
			...initialUserManagementState,
			isCreateUserLoading: false,
		}));

		dispatch(_setState<Partial<UserManagementState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to create user. Please try again.',
			},
		));
	}
};

export const { reducer: UserManagement } = UserManagementSlice;
