export type AuthenticationState = {
	token: null | string;
	isLoginLoading: boolean;
	isAuthenticated: boolean;
	isRegistered: null | object;
	isRegisterLoading: boolean;
	completeUserRegistration: null | object;
	verificationMessage: string | null,
	isCompleteUserRegistrationLoading: boolean;
	user: null | object;
	verifyAccount: null | string;
	isVerifyAccountLoading: boolean;
	resendVerificationLink: null | string;
	isResendVerificationLinkLoading: boolean;
	isPasswordResetRequestLoading: boolean;
	passwordResetRequest: any;
	isConfirmPasswordRequestOTPLoading: boolean;
	confirmPasswordRequestOTP: any;
	isResetPasswordLoading: boolean;
	resetPassword: any;
	error?: null | object;
}

export const initialAuthenticationState: AuthenticationState = {
	token: localStorage?.getItem('nesco-pre-paid-token') ?? null,
	isLoginLoading: false,
	isAuthenticated: false,
	isRegistered: null,
	verificationMessage: null,
	isRegisterLoading: false,
	completeUserRegistration: null,
	isCompleteUserRegistrationLoading: false,
	user: null,
	verifyAccount: null,
	isVerifyAccountLoading: false,
	resendVerificationLink: null,
	isResendVerificationLinkLoading: false,
	isPasswordResetRequestLoading: false,
	passwordResetRequest: null,
	isConfirmPasswordRequestOTPLoading: false,
	confirmPasswordRequestOTP: null,
	isResetPasswordLoading: false,
	resetPassword: null,
	error: null,
};
