/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { BroadcastNewForm } from '../sections/@dashboard/broadcast';

export default function BroadcastCreate() {
	return (
		<Page title="Broadcast: Create a new broadcast">
			<Container>
				<BroadcastNewForm />
			</Container>
		</Page>
	);
}
