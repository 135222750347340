/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Card,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// @mui
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	issueTamperCodeTokenRequest,
} from '../../Store';
import {
	FormProvider, RHFTextField,
} from '../../components/hook-form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function IssueTamperCodeToken() {
	const dispatch = useDispatch();

	const Vending = useSelector((state) => state.Vending);
	const Authentication = useSelector((state) => state.Authentication);

	const [open, setOpen] = React.useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const componentRef = React.useRef();

	const NewUserSchema = Yup.object().shape({
		meter_number: Yup.string().required(),
	});

	const handlePrint = useReactToPrint({
		documentTitle: 'Token Receipt',
		content: () => componentRef.current,
		pageStyle: `
			@page {
				size: A4;
				margin: 10px 0 0 0;
			}
			@media print {
				h4.class-name
				@page{
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 0px;
					margin-bottom: 0px;
				}
				.page {
					margin: 0;
					padding: 0;
					border: initial;
					border-radius: initial;
					width: initial;
					min-height: initial;
					box-shadow: initial;
					background: initial;
					overflow: hidden;
					height: 100vh;
				}
			}
		`,
	});

	const defaultValues = useMemo(
		() => ({
			meter_number: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Vending.issueTamperCodeToken) {
			reset();
			enqueueSnackbar('Tamper Code Issued!');
			setOpen(true);
		}

		if (Vending.error) {
			enqueueSnackbar(Vending.error, {
				variant: 'error',
			});
		}
	}, [Vending.issueTamperCodeToken, Vending.error]);

	const onSubmit = async (data) => {
		const kctData = data.meter_number;
		dispatch(issueTamperCodeTokenRequest(Authentication.token, kctData));
	};

	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
		setOpen(false);
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Issue Tamper Code/Token
					</Typography>
				</Box>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
                                    display: 'grid',
                                    columnGap: 1,
                                    rowGap: 1,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                }}
							>
								<RHFTextField
									name="meter_number"
									label="Meter Number"
								/>
							</Box>
							<Stack alignItems="flex-start" sx={{ mt: 3 }}>
								{
									Authentication?.user?.role !== 'auditor' && (
										<LoadingButton
											type="submit"
											variant="contained"
											loading={Vending.isIssueTamperCodeTokenLoading}
										>
											Issue Tamper Code/Token
										</LoadingButton>
									)
								}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
				keepMounted
			>
				<div ref={componentRef}>
					<DialogTitle id="alert-dialog-title">
						<Stack sx={{ width: '100%' }} spacing={2}>
							<Alert severity="success">
								<AlertTitle>Tamper Code/Token generated!</AlertTitle>
							</Alert>
						</Stack>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<TableContainer component={Paper}>
								<Table aria-label="simple table" size="small">
									<TableBody>
										<TableRow>
											<TableCell>
												<Typography variant="subtitle1">
													Meter Number:
												</Typography>
											</TableCell>
											<TableCell align="right">
												{Vending.issueTamperCodeToken ? Vending.issueTamperCodeToken.meter_number : ''}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography variant="subtitle1">
													Token
												</Typography>
											</TableCell>
											<TableCell align="right">
												{Vending.issueTamperCodeToken ? Vending.issueTamperCodeToken.token : ''}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography variant="subtitle1">
													Description
												</Typography>
											</TableCell>
											<TableCell align="right">
												{Vending.issueTamperCodeToken ? Vending.issueTamperCodeToken.description : ''}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContentText>
					</DialogContent>
				</div>
				<DialogActions>
					<Button onClick={handlePrint}>Print receipt</Button>
					<Button onClick={handleClose}>OK</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
