/* eslint-disable max-len */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { ConsumerManagementState, initialConsumerManagementState } from './Types';

const ConsumerManagementSlice = createSlice({
	name: 'ConsumerManagementSlice',
	initialState: initialConsumerManagementState,
	reducers: {
		setState(state: ConsumerManagementState, action: PayloadAction<Partial<ConsumerManagementState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllNESCOConsumerAccountsRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetAllNescoConsumerAccountsLoading: Partial<
				ConsumerManagementState['isGetAllNescoConsumerAccountsLoading']
				>,
				getAllNescoConsumerAccounts: Partial<ConsumerManagementState['getAllNescoConsumerAccounts']>,
				createConsumerAccount: Partial<ConsumerManagementState['createConsumerAccount']>,
			}>,
		) {
			const {
				isGetAllNescoConsumerAccountsLoading,
				getAllNescoConsumerAccounts,
				createConsumerAccount,
			} = action.payload;
			return {
				...state,
				isGetAllNescoConsumerAccountsLoading,
				getAllNescoConsumerAccounts,
				createConsumerAccount,
			};
		},
		getAllPUBConsumerAccountsRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetAllPUBConsumerAccountsLoading: Partial<
				ConsumerManagementState['isGetAllPUBConsumerAccountsLoading']
				>,
				getAllPUBConsumerAccounts: Partial<ConsumerManagementState['getAllPUBConsumerAccounts']>,
				createConsumerAccount: Partial<ConsumerManagementState['createConsumerAccount']>,
			}>,
		) {
			const {
				isGetAllPUBConsumerAccountsLoading,
				getAllPUBConsumerAccounts,
				createConsumerAccount,
			} = action.payload;
			return {
				...state,
				isGetAllPUBConsumerAccountsLoading,
				getAllPUBConsumerAccounts,
				createConsumerAccount,
			};
		},
		getSingleConsumerAccountsRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetSingleConsumerLoading: Partial<
				ConsumerManagementState['isGetSingleConsumerLoading']
				>,
				getSingleConsumerAccounts: Partial<ConsumerManagementState['getSingleConsumerAccounts']>,
				getUpdateLogs?: Partial<ConsumerManagementState['getUpdateLogs']>,
			}>,
		) {
			const {
				isGetSingleConsumerLoading,
				getSingleConsumerAccounts,
				getUpdateLogs,
			} = action.payload;
			return {
				...state,
				isGetSingleConsumerLoading,
				getSingleConsumerAccounts,
				getUpdateLogs,
			};
		},
		getConsumerAccountStatementRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetCustomerAccountStatementLoading: Partial<
				ConsumerManagementState['isGetConsumerAccountStatementLoading']
				>,
				getConsumerAccountStatement: Partial<ConsumerManagementState['getConsumerAccountStatement']>,
			}>,
		) {
			const {
				isGetCustomerAccountStatementLoading,
				getConsumerAccountStatement,
			} = action.payload;
			return {
				...state,
				isGetCustomerAccountStatementLoading,
				getConsumerAccountStatement,
			};
		},
		getUpdateLogs(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isUpdateLogsLoading: Partial<
				ConsumerManagementState['isUpdateLogsLoading']
				>,
				getUpdateLogs: Partial<ConsumerManagementState['getUpdateLogs']>,
			}>,
		) {
			const {
				isUpdateLogsLoading,
				getUpdateLogs,
			} = action.payload;
			return {
				...state,
				isUpdateLogsLoading,
				getUpdateLogs,
			};
		},
		updateReconciliation(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isReconciliationUpdating: Partial<
				ConsumerManagementState['isReconciliationUpdating']
				>,
				updateReconciliation: Partial<ConsumerManagementState['updateReconciliation']>,
			}>,
		) {
			const {
				isReconciliationUpdating,
				updateReconciliation,
			} = action.payload;
			return {
				...state,
				isReconciliationUpdating,
				updateReconciliation,
			};
		},
		getDistrictRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetDistrictsLoading: Partial<
				ConsumerManagementState['isGetDistrictsLoading']
				>,
				getDistricts: Partial<ConsumerManagementState['getDistricts']>,
			}>,
		) {
			const {
				isGetDistrictsLoading,
				getDistricts,
			} = action.payload;
			return {
				...state,
				isGetDistrictsLoading,
				getDistricts,
			};
		},
		getAcountStatusesRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetAccountStatusesLoading: Partial<
				ConsumerManagementState['isGetAccountStatusesLoading']
				>,
				getAccountStatuses: Partial<ConsumerManagementState['getAccountStatuses']>,
			}>,
		) {
			const {
				isGetAccountStatusesLoading,
				getAccountStatuses,
			} = action.payload;
			return {
				...state,
				isGetAccountStatusesLoading,
				getAccountStatuses,
			};
		},
		getMeterStatusValuesRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isGetMeterStatusesValuesLoading: Partial<
				ConsumerManagementState['isGetMeterStatusesValuesLoading']
				>,
				getMeterStatusesValues: Partial<ConsumerManagementState['getMeterStatusesValues']>,
			}>,
		) {
			const {
				isGetMeterStatusesValuesLoading,
				getMeterStatusesValues,
			} = action.payload;
			return {
				...state,
				isGetMeterStatusesValuesLoading,
				getMeterStatusesValues,
			};
		},
		createConsumerAccountRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isCreateConsumerAccountLoading: Partial<
				ConsumerManagementState['isCreateConsumerAccountLoading']
				>,
				createConsumerAccount: Partial<ConsumerManagementState['createConsumerAccount']>,
			}>,
		) {
			const {
				isCreateConsumerAccountLoading,
				createConsumerAccount,
			} = action.payload;
			return {
				...state,
				isCreateConsumerAccountLoading,
				createConsumerAccount,
			};
		},
		editConsumerAccountRequest(
			state: ConsumerManagementState,
			action: PayloadAction<{
				isEditConsumerAccountLoading: Partial<ConsumerManagementState['isEditConsumerAccountLoading']>,
				editConsumerAccount: Partial<ConsumerManagementState['editConsumerAccount']>,
				error?: Partial<ConsumerManagementState['error']> | undefined,
			}>,
		) {
			const {
				isEditConsumerAccountLoading,
				editConsumerAccount,
				error,
			} = action.payload;
			return {
				...state,
				isEditConsumerAccountLoading,
				editConsumerAccount,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllNESCOConsumerAccountsRequest: _getAllNESCOConsumerAccountsRequest,
	getAllPUBConsumerAccountsRequest: _getAllPUBConsumerAccountsRequest,
	getSingleConsumerAccountsRequest: _getSingleConsumerAccountsRequest,
	getDistrictRequest: _getDistrictRequest,
	getAcountStatusesRequest: _getAcountStatusesRequest,
	getMeterStatusValuesRequest: _getMeterStatusValuesRequest,
	createConsumerAccountRequest: _createConsumerAccountRequest,
	editConsumerAccountRequest: _editConsumerAccountRequest,
	getConsumerAccountStatementRequest: _getConsumerAccountStatementRequest,
	getUpdateLogs: _getUpdateLogs,
	updateReconciliation: _updateReconciliation,
} = ConsumerManagementSlice.actions;

export const getAllNESCOConsumerAccountsRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllNESCOConsumerAccountsRequest({
		...initialConsumerManagementState,
		isGetAllNescoConsumerAccountsLoading: true,
		createConsumerAccount: null,
	}));
	try {
		const apiResponseData = await API.getAllNESCOConsumerAccount(token);
		dispatch(_getAllNESCOConsumerAccountsRequest({
			isGetAllNescoConsumerAccountsLoading: false,
			getAllNescoConsumerAccounts: apiResponseData?.data?.users,
			createConsumerAccount: null,
		}));
	} catch (error) {
		dispatch(_getAllNESCOConsumerAccountsRequest({
			...initialConsumerManagementState,
			isGetAllNescoConsumerAccountsLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get NESCO consumer accounts. Please try again.',
			},
		));
	}
};

export const getAllPUBConsumerAccountsRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllPUBConsumerAccountsRequest({
		...initialConsumerManagementState,
		isGetAllPUBConsumerAccountsLoading: true,
		getAllPUBConsumerAccounts: null,
		createConsumerAccount: null,
	}));
	try {
		const apiResponseData = await API.getAllPUBConsumerAccount(token);
		dispatch(_getAllPUBConsumerAccountsRequest({
			isGetAllPUBConsumerAccountsLoading: false,
			getAllPUBConsumerAccounts: apiResponseData?.data?.users,
			createConsumerAccount: null,
		}));
	} catch (error) {
		dispatch(_getAllPUBConsumerAccountsRequest({
			...initialConsumerManagementState,
			isGetAllPUBConsumerAccountsLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get all PUB consumer accounts. Please try again.',
			},
		));
	}
};

export const getSingleConsumerAccountsRequest = (token: any, id: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSingleConsumerAccountsRequest({
		...initialConsumerManagementState,
		isGetSingleConsumerLoading: true,
		getSingleConsumerAccounts: null,
		getUpdateLogs: null,
	}));
	try {
		const apiResponseData = await API.getSingleConsumerAccountDetails(token, id);
		dispatch(_getSingleConsumerAccountsRequest({
			isGetSingleConsumerLoading: false,
			getSingleConsumerAccounts: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getSingleConsumerAccountsRequest({
			...initialConsumerManagementState,
			isGetSingleConsumerLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get consumer accounts. Please try again.',
			},
		));
	}
};

export const getUpdateLogsRequest = (token: any, accountNumber: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getUpdateLogs({
		...initialConsumerManagementState,
		isUpdateLogsLoading: true,
		getUpdateLogs: null,
	}));
	try {
		const apiResponseData = await API.getUpdateLogs(token, accountNumber);
		dispatch(_getUpdateLogs({
			isUpdateLogsLoading: false,
			getUpdateLogs: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getUpdateLogs({
			...initialConsumerManagementState,
			isUpdateLogsLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get update logs. Please try again.',
			},
		));
	}
};

export const updateReconciliationRequest = (token: any, reconciliationData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_updateReconciliation({
		...initialConsumerManagementState,
		isReconciliationUpdating: true,
		updateReconciliation: null,
	}));
	try {
		const apiResponseData = await API.updateReconciliation(token, reconciliationData);
		dispatch(_updateReconciliation({
			isReconciliationUpdating: false,
			updateReconciliation: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_updateReconciliation({
			...initialConsumerManagementState,
			isReconciliationUpdating: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get update reconciliation. Please try again.',
			},
		));
	}
};

export const getDistrictRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getDistrictRequest({
		...initialConsumerManagementState,
		isGetDistrictsLoading: true,
		getDistricts: null,
	}));
	try {
		const apiResponseData = await API.getDistricts(token);
		dispatch(_getDistrictRequest({
			isGetDistrictsLoading: false,
			getDistricts: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getDistrictRequest({
			...initialConsumerManagementState,
			isGetDistrictsLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get districts. Please try again.',
			},
		));
	}
};

export const getAcountStatusesRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAcountStatusesRequest({
		...initialConsumerManagementState,
		isGetAccountStatusesLoading: true,
		getAccountStatuses: null,
	}));
	try {
		const apiResponseData = await API.getAccountStatuses(token);
		dispatch(_getAcountStatusesRequest({
			isGetAccountStatusesLoading: false,
			getAccountStatuses: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAcountStatusesRequest({
			...initialConsumerManagementState,
			isGetAccountStatusesLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get account statuses. Please try again.',
			},
		));
	}
};

export const getMeterStatusValuesRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getMeterStatusValuesRequest({
		...initialConsumerManagementState,
		isGetMeterStatusesValuesLoading: true,
		getMeterStatusesValues: null,
	}));
	try {
		const apiResponseData = await API.getMeterStatusValues(token);
		dispatch(_getMeterStatusValuesRequest({
			isGetMeterStatusesValuesLoading: false,
			getMeterStatusesValues: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getMeterStatusValuesRequest({
			...initialConsumerManagementState,
			isGetMeterStatusesValuesLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to get meter status values. Please try again.',
			},
		));
	}
};

export const createConsumerAccountRequest = (consumerData: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createConsumerAccountRequest({
		...initialConsumerManagementState,
		isCreateConsumerAccountLoading: true,
		createConsumerAccount: null,
	}));
	try {
		const apiResponseData = await API.createConsumerAccount(consumerData, token);
		dispatch(_createConsumerAccountRequest({
			isCreateConsumerAccountLoading: false,
			createConsumerAccount: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_createConsumerAccountRequest({
			...initialConsumerManagementState,
			isCreateConsumerAccountLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to create consumer accounts. Please try again.',
			},
		));
	}
};

export const getConsumerAccountStatementRequest = (token: any, accountNumber: any, dateValues: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getConsumerAccountStatementRequest({
		...initialConsumerManagementState,
		isGetCustomerAccountStatementLoading: true,
		getConsumerAccountStatement: null,
	}));
	try {
		const apiResponseData = await API.getConsumerAccountStatement(token, accountNumber, dateValues);
		dispatch(_getConsumerAccountStatementRequest({
			isGetCustomerAccountStatementLoading: false,
			getConsumerAccountStatement: apiResponseData?.data,
		}));
	} catch (err:any) {
		dispatch(_getConsumerAccountStatementRequest({
			...initialConsumerManagementState,
			isGetCustomerAccountStatementLoading: false,
		}));
	}
};

export const editConsumerAccountRequest = (
	token: string,
	accountData: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_editConsumerAccountRequest({
		...initialConsumerManagementState,
		isEditConsumerAccountLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.editConsumerAccount(
			token,
			accountData,
		);
		dispatch(_editConsumerAccountRequest({
			isEditConsumerAccountLoading: false,
			editConsumerAccount: apiResponseData,
		}));
	} catch (error) {
		dispatch(_editConsumerAccountRequest({
			...initialConsumerManagementState,
			isEditConsumerAccountLoading: false,
		}));
		dispatch(_setState<Partial<ConsumerManagementState>>(
			{
				error: error.message ?? 'Unable to edit consumer account. Please try again.',
			},
		));
	}
};

export const { reducer: ConsumerManagement } = ConsumerManagementSlice;
