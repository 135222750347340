/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
	Toolbar, OutlinedInput, InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// material

// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
	height: 96,
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
	width: 240,
	transition: theme.transitions.create(['box-shadow', 'width'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	'&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
	'& fieldset': {
		borderWidth: '1px !important',
		borderColor: `${theme.palette.grey[500_32]} !important`,
	},
}));

UserListToolbar.propTypes = {
	filterName: PropTypes.string,
	onFilterName: PropTypes.func,
};

export default function UserListToolbar({
	filterName, loading, onFilterName, SelectUser, selectUserFlag, placeholder,
}) {
	return (
		<RootStyle
			sx={{}}
		>
			<SearchStyle
				disabled={loading}
				value={filterName}
				onChange={onFilterName}
				placeholder={placeholder ?? 'Search Table'}
				startAdornment={(
					<InputAdornment position="start">
						<Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
					</InputAdornment>
				)}
			/>
			{selectUserFlag && <SelectUser />}
		</RootStyle>
	);
}
