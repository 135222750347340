/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	submitFaultReportRequest,
	getAllReportTitlesRequest,
	searchUserRequest,
	searchConsumerByAccountNumberRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFSelect, RHFTextField,
} from '../../../components/hook-form';

export default function SubmitReportForm() {
	const dispatch = useDispatch();

	const [title, setReporttitle] = React.useState([]);

	const Control = useSelector((state) => state.Control);
	const FaultReporting = useSelector((state) => state.FaultReporting);
	const Authentication = useSelector((state) => state.Authentication);
	const UserManagement = useSelector((state) => state.UserManagement);
	const [staff, setStaff] = React.useState(UserManagement.searchUser[0]);
	const [customer, setCustomer] = React.useState(Control.searchConsumerByAccountNumber[0]);
	const [inputedStaff, setInputedStaff] = React.useState('');
	const [inputedCustomer, setInputedCustomer] = React.useState('');
	const navigate = useNavigate();

	React.useEffect(() => {
		if (Authentication.token) {
			dispatch(getAllReportTitlesRequest(Authentication.token));
		}
	}, [Authentication.token]);

	React.useEffect(() => {
		if (Control.getReportTitles) {
			setReporttitle([...Control.getReportTitles]);
		}
	}, [Control.getReportTitles]);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		title: Yup.string().required(),
		description: Yup.string().required('description is required'),
	});

	const defaultValues = useMemo(
		() => ({
			title: '',
			description: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (FaultReporting.submitFaultReport) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/customer-support/fault-reports/all-opened');
		}

		if (FaultReporting.error) {
			enqueueSnackbar(FaultReporting.error, {
				variant: 'error',
			});
		}
	}, [FaultReporting.submitFaultReport, FaultReporting.error]);

	const onSubmit = async (data) => {
		const faultReportData = {
			...data,
			customer: customer.id,
			staff: staff.id,
		};
		dispatch(submitFaultReportRequest(faultReportData, Authentication.token));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create Fault Report
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/customer-support/fault-reports/all-opened"
					startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
				>
					View All Fault Reports
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
								display: 'grid',
								columnGap: 2,
								rowGap: 3,
								gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
							}}
							>

								<div>
									<Typography sx={{ mb: 2 }}>
										Search by name
									</Typography>
									<Autocomplete
										disablePortal
										value={staff}
										onChange={(event, searchedStaff) => {
										setStaff(searchedStaff);
									}}
										inputValue={inputedStaff}
										onInputChange={(event, inputedValue) => {
										setInputedStaff(inputedValue);
									}}
										id="search-staff"
										options={UserManagement.searchUser.filter(option => option.role !== 'consumer')}
										getOptionLabel={(option) => option.label}
										renderOption={(props, option) => (
											<li {...props} key={`${option.label} - ${option.id}`}>
												{option.label}
											</li>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Staff"
												onChange={(event) => dispatch(searchUserRequest(Authentication.token, event.target.value))}
												InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{UserManagement.isSearchUserLoading ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
											/>
									)}
									/>
								</div>

								<div>
									<Typography sx={{ mb: 2 }}>
										Search by account number
									</Typography>
									<Autocomplete
										disablePortal
										placeholder="search by account number"
										value={customer}
										onChange={(event, searchedCustomer) => {
										setCustomer(searchedCustomer);
									}}
										inputValue={inputedCustomer}
										onInputChange={(event, inputedValue) => {
										setInputedCustomer(inputedValue);
									}}
										id="search-customer"
										options={Control?.searchConsumerByAccountNumber}
										getOptionLabel={(option) => option?.label}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Customer"
												onChange={(event) => dispatch(searchConsumerByAccountNumberRequest(Authentication.token, event.target.value))}
												InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{Control.isSearchConsumerByAccountNumber ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
											/>
									)}
									/>
								</div>
								{/* <Autocomplete
									disablePortal
									value={customer}
									onChange={(event, searchedCustomer) => {
										setCustomer(searchedCustomer);
									}}
									inputValue={inputedCustomer}
									onInputChange={(event, inputedValue) => {
										setInputedCustomer(inputedValue);
									}}
									id="search-customer"
									options={UserManagement.searchUser.filter(option => option.role !== 'superadmin')}
									getOptionLabel={(option) => option.label}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Customer"
											onChange={(event) => dispatch(searchUserRequest(Authentication.token, event.target.value))}
										/>
									)}
								/> */}
								<RHFSelect name="title" label="Title" placeholder="Report Title">
									<option value="">{' '}</option>
									{title?.map((option) => (
										<option key={option?.title} value={option?.title}>
											{option?.title.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								<RHFTextField
									name="description"
									label="Description"
									multiline
									rows={5}
									maxRows={20}
								/>

							</Box>
							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								{
									Authentication?.user?.role !== 'auditor' && (
									<LoadingButton
										type="submit"
										variant="contained"
										disabled={!customer?.id || !staff?.id}
										loading={FaultReporting.isSubmitFaultReportLoading}
									>
										Submit Fault Report
									</LoadingButton>
									)
							}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}

// id(pin): 1244
// user_id(pin): null
// account_name(pin): "ALH. BABA ALIYU"
// phone(pin): null
// account_number(pin): "PEX0001"
// account_type(pin): "PUB"
// district(pin): "EX"
// account_type_number(pin): null
// meter_type(pin): "POSTPAID"
// postpaid_meter_number(pin): null
// prepaid_meter_number(pin): null
// class(pin): null
// category(pin): null
// res_address1(pin): "G/AKWATI"
// res_address2(pin): null
// res_address3(pin): null
// con_address1(pin): "G/AKWATI"
// con_address2(pin): null
// con_address3(pin): null
// month_count(pin): 3
// reading_mode(pin): "ACTUAL"
// prev_reading_mode(pin): "ACTUAL"
// cur_high_reading(pin): 0
// cur_low_reading(pin): 85646
// prev_high_reading(pin): 0
// prev_low_reading(pin): 85628
// less_units_low(pin): 18
// less_units_high(pin): 0
// units(pin): 18
// estimated_units(pin): 200
// multiplying_factor(pin): 1
// payments(pin): "0.00"
// opening_balance(pin): "335850.09"
// current_debit(pin): "0.00"
// percentage_discount(pin): null
// discount_allowed(pin): "0.00"
// vat(pin): "0.00"
// total_current_debit(pin): "0.00"
// adjustment(pin): null
// closing_balance(pin): "335850.09"
// prev_closing_balance(pin): "335850.09"
// prev_closing_balance1(pin): "343978.37"
// prev_closing_balance2(pin): "338602.78"
// prev_closing_balance3(pin): "335284.87"
// reconnection_fee(pin): "0.00"
// tariff(pin): "R2"
// feeder(pin): "kefiabu"
// substation(pin): "kefiabu substation"
// meter_status(pin): "OKAY"
// account_status(pin): "ACTIVE"
// has_updated(pin): false
// reading_month(pin): "May"
// reading_year(pin): "2023"
// previous_reading_date(pin): "2023-05-09T05:30:46.000Z"
// present_reading_date(pin): "2023-05-24T19:21:53.000Z"
// createdAt(pin): "2023-01-10T23:54:22.000Z"
// updatedAt(pin): "2023-05-30T13:56:12.000Z"
// deletedAt(pin): null
// user(pin): null
