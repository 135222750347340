/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	Box,
	Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import * as React from 'react';
import { connect } from 'react-redux';

import { getAllFeedersRequest, deleteFeederRequest } from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import { FeederEditForm } from '../sections/@dashboard/FeederSubstation';
import { UserListHead } from '../sections/@dashboard/user';

dayjs.extend(advancedFormat);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DeleteFeeder({
	view,
	details,
	handleCloseDeleteDialog,
	isDeleteFeederLoading,
	onDelete,
}) {
	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDeleteDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Delete
					{' '}
					{details?.name}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to delete this feeder. It cannot be reversed.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseDeleteDialog}>Cancel</Button>
					<LoadingButton
						variant="contained"
						loading={isDeleteFeederLoading}
						onClick={onDelete}
					>
						Continue
					</LoadingButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

const TABLE_HEAD = [
	{ id: 'name', label: 'Feeder', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
	{ id: 'updatedAt', label: 'Date Updated', alignRight: false },
	{ id: 'edit', label: '', alignRight: false },
	{ id: 'delete', label: '', alignRight: false },
];

function Feeder({
	token,
	user,
	getAllFeeders,
	isGetAllFeedersLoading,
	getAllFeedersRequest,
	isDeleteFeederLoading,
	deleteFeederRequest,
	deleteFeeder,
	isEditFeederLoading,
	editFeeder,
}) {
	const [editView, setEditView] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState([]);
	const [editId, setEditId] = React.useState(null);
	const [deleteView, setDeleteView] = React.useState(false);
	const [deleteDetails, setDeleteDetails] = React.useState([]);
	const [deleteId, setDeleteId] = React.useState(null);

	React.useEffect(() => {
		if (token) {
			getAllFeedersRequest(token);
		}
	}, [token]);

	React.useEffect(() => {
		if (editFeeder || deleteFeeder) {
			setEditView(false);
			setEditDetails({});
			setEditId(null);
			getAllFeedersRequest(token);
		}
	}, [editFeeder, deleteFeeder]);

	const loading = isGetAllFeedersLoading;

	const handleDelete = (id) => {
		setDeleteId(id);
		deleteFeederRequest(token, id, getAllFeeders);
	};
	const handleSetEditView = (id) => {
		setEditView(true);
		setEditDetails(getAllFeeders.filter(feeders => feeders.id === id));
		setEditId(id);
	};
	const handleCloseEditDialog = () => setEditView(false);

	const handleSetDeleteView = (id) => {
		setDeleteView(true);
		setDeleteDetails(getAllFeeders.filter(feeders => feeders.id === id));
		setDeleteId(id);
	};
	const handleCloseDeleteDialog = () => setDeleteView(false);

	return (
		<Page title="Feeder">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>
						All Feeders
					</Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{
						loading ? <Loader />
							: getAllFeeders ? (
								<Box>
									<TableContainer sx={{ minWidth: 800 }}>
										<Table>
											<UserListHead
												headLabel={TABLE_HEAD}
												rowCount={getAllFeeders?.length}
											/>
											<TableBody>
												{getAllFeeders.map((row) => {
													const {
														id,
														name,
														createdAt,
														updatedAt,
													} = row;
													return (
														<TableRow
															hover
															key={id}
															tabIndex={-1}
															role="checkbox"
														>
															<TableCell align="left">{name}</TableCell>
															<TableCell align="left">{createdAt && dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
															<TableCell align="left">
																{updatedAt && dayjs(updatedAt).format('DD/MM/YYYY')}
															</TableCell>
															{
																user?.role !== 'auditor' && (
																	<>
																		<TableCell align="left">
																			<LoadingButton
																				type="submit"
																				variant="outlined"
																				color="success"
																				startIcon={<Iconify icon="bxs:edit" />}
																				onClick={() => handleSetEditView(id)}
																			>
																				Edit
																			</LoadingButton>
																		</TableCell>
																		<TableCell align="left">
																			<LoadingButton
																				type="submit"
																				variant="outlined"
																				color="error"
																				loading={isDeleteFeederLoading && id === deleteId}
																				startIcon={<Iconify icon="ep:delete-filled" />}
																				onClick={() => handleSetDeleteView(id)}
																			>
																				Delete
																			</LoadingButton>
																		</TableCell>
																	</>
																)
															}
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
									{
										editView && (
											<FeederEditForm
												view={editView}
												id={editId}
												details={editDetails[0]}
												handleCloseEditDialog={handleCloseEditDialog}
												isEditFeederLoading={isEditFeederLoading}
											/>
										)
									}
									{
										deleteView && (
											<DeleteFeeder
												view={deleteView}
												id={deleteId}
												details={deleteDetails[0]}
												handleCloseDeleteDialog={handleCloseDeleteDialog}
												isDeleteFeederLoading={isDeleteFeederLoading}
												onDelete={() => handleDelete(deleteId)}
											/>
										)
									}
								</Box>
							) : (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography variant="h5" gutterBottom>
										No Feeder Found
									</Typography>
								</Box>
							)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Feeder }) => {
	const { token, user } = Authentication;
	const {
		isGetAllFeedersLoading,
		getAllFeeders,
		isDeleteFeederLoading,
		deleteFeeder,
		isEditFeederLoading,
		editFeeder,
	} = Feeder;
	return {
		token,
		user,
		isGetAllFeedersLoading,
		getAllFeeders,
		isDeleteFeederLoading,
		deleteFeeder,
		isEditFeederLoading,
		editFeeder,
	};
};

export default connect(mapStateToProps, {
	getAllFeedersRequest, deleteFeederRequest,
})(Feeder);
