/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getConsumerAccountStatement = async (token: any, accountNumber: any, date?: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (date) {
		if (!date.endDateValue) {
			const response = await axios.get(
				// eslint-disable-next-line max-len
				`/user/statement/${accountNumber}?startDate=${date.startDateValue}`,
				config,
			);
			return response.data;
		}

		const response = await axios.get(
			// eslint-disable-next-line max-len
			`/user/statement/${accountNumber}?startDate=${date.startDateValue}&endDate=${date.endDateValue}`,
			config,
		);

		return response.data;
	}

	const response = await axios
		.get(`/user/statement/${accountNumber}`, config);

	return response.data;
};
