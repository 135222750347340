import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { messageToDisplay } from '../../functions';
import { ControlState, initialControlState } from './Types';

const ControlSlice = createSlice({
	name: 'ControlSlice',
	initialState: initialControlState,
	reducers: {
		setState(state: ControlState, action: PayloadAction<Partial<ControlState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllReportTitlesRequest(
			state: ControlState,
			action: PayloadAction<{
				isGetReportTitlesLoading: Partial<ControlState['isGetReportTitlesLoading']>,
				getReportTitles: Partial<ControlState['getReportTitles']>,
				createReportTitle?: Partial<ControlState['createReportTitle']> | undefined,
			}>,
		) {
			const {
				isGetReportTitlesLoading,
				getReportTitles,
				createReportTitle,
			} = action.payload;
			return {
				...state,
				isGetReportTitlesLoading,
				getReportTitles,
				createReportTitle,
			};
		},
		createReportTitleRequest(
			state: ControlState,
			action: PayloadAction<{
				isCreateReportTitleLoading: Partial<ControlState['isCreateReportTitleLoading']>,
				createReportTitle: Partial<ControlState['createReportTitle']>,
				error?: Partial<ControlState['error']> | undefined,
			}>,
		) {
			const {
				isCreateReportTitleLoading,
				createReportTitle,
				error,
			} = action.payload;
			return {
				...state,
				isCreateReportTitleLoading,
				createReportTitle,
				error,
			};
		},
		deleteReportTitleRequest(
			state: ControlState,
			action: PayloadAction<{
				isDeleteReportTitleLoading: Partial<ControlState['isDeleteReportTitleLoading']>,
				getReportTitles?: Partial<ControlState['getReportTitles']>,
				deleteReportTitle: Partial<ControlState['deleteReportTitle']>,
				error?: Partial<ControlState['error']> | undefined,
			}>,
		) {
			const {
				isDeleteReportTitleLoading,
				getReportTitles,
				deleteReportTitle,
				error,
			} = action.payload;
			return {
				...state,
				isDeleteReportTitleLoading,
				getReportTitles,
				deleteReportTitle,
				error,
			};
		},
		getOpenFaultReportRequest(
			state: ControlState,
			action: PayloadAction<{
				isgetOpenFaultReportLoading: Partial<ControlState['isgetOpenFaultReportLoading']>,
				getOpenFaultReport: Partial<ControlState['getOpenFaultReport']>,

			}>,
		) {
			const {
				isgetOpenFaultReportLoading,
				getOpenFaultReport,
			} = action.payload;
			return {
				...state,
				isgetOpenFaultReportLoading,
				getOpenFaultReport,

			};
		},

		updateReportStatusRequest(
			state: ControlState,
			action: PayloadAction<{
				isUpdateportStatusLoading: Partial<ControlState['isUpdateportStatusLoading']>,
				updateportStatus: Partial<ControlState['updateportStatus']>,
			}>,
		) {
			const {
				isUpdateportStatusLoading,
				updateportStatus,
			} = action.payload;
			return {
				...state,
				isUpdateportStatusLoading,
				updateportStatus,

			};
		},

		getReportLogsRequest(
			state: ControlState,
			action: PayloadAction<{
				isGetReportLogsLoading: Partial<ControlState['isGetReportLogsLoading']>,
				getReportLogs: Partial<ControlState['getReportLogs']>,
			}>,
		) {
			const {
				isGetReportLogsLoading,
				getReportLogs,
			} = action.payload;
			return {
				...state,
				isGetReportLogsLoading,
				getReportLogs,

			};
		},

		getSingleReportRequest(
			state: ControlState,
			action: PayloadAction<{
				isGetSingleReportLoading: Partial<ControlState['isGetSingleReportLoading']>,
				getSingleReport: Partial<ControlState['getSingleReport']>,
			}>,
		) {
			const {
				isGetSingleReportLoading,
				getSingleReport,
			} = action.payload;
			return {
				...state,
				isGetSingleReportLoading,
				getSingleReport,

			};
		},
		searchConsumerByAccountNumberRequest(
			state: ControlState,
			action: PayloadAction<{
				isSearchConsumerByAccountNumber: Partial<ControlState['isSearchConsumerByAccountNumber']>,
				searchConsumerByAccountNumber: Partial<ControlState['searchConsumerByAccountNumber']>,
				error?: Partial<ControlState['error']> | undefined,
			}>,
		) {
			const {
				isSearchConsumerByAccountNumber,
				searchConsumerByAccountNumber,
				error,
			} = action.payload;
			return {
				...state,
				isSearchConsumerByAccountNumber,
				searchConsumerByAccountNumber,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	createReportTitleRequest: _createReportTitleRequest,
	getAllReportTitlesRequest: _getAllReportTitlesRequest,
	deleteReportTitleRequest: _deleteReportTitleRequest,
	getOpenFaultReportRequest: _getOpenFaultReportRequest,
	updateReportStatusRequest: _updateReportStatusRequest,
	getReportLogsRequest: _getReportLogsRequest,
	getSingleReportRequest: _getSingleReportRequest,
	searchConsumerByAccountNumberRequest: _searchConsumerByAccountNumberRequest,
} = ControlSlice.actions;

export const getAllReportTitlesRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllReportTitlesRequest({
		...initialControlState,
		isGetReportTitlesLoading: true,
		createReportTitle: null,
	}));
	try {
		const apiResponseData = await API.getAllReportTitles(token);
		dispatch(_getAllReportTitlesRequest({
			isGetReportTitlesLoading: false,
			getReportTitles: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllReportTitlesRequest({
			...initialControlState,
			isGetReportTitlesLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error.message ?? 'Unable to get all report titles. Please try again.',
			},
		));
	}
};

export const createReportTitleRequest = (
	reportTitleData: any,
	token: string,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createReportTitleRequest({
		...initialControlState,
		isCreateReportTitleLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.createReportTitle(token, reportTitleData);
		dispatch(_createReportTitleRequest({
			isCreateReportTitleLoading: false,
			createReportTitle: apiResponseData,
		}));
	} catch (error) {
		dispatch(_createReportTitleRequest({
			...initialControlState,
			isCreateReportTitleLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error.message ?? 'Unable to create report title. Please try again.',
			},
		));
	}
};

export const deleteReportTitleRequest = (
	token: string,
	reportTitleId: any,
	reportTitles?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_deleteReportTitleRequest({
		...initialControlState,
		isDeleteReportTitleLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.deleteReportTitle(
			token,
			reportTitleId,
		);

		if (reportTitles) {
			dispatch(_deleteReportTitleRequest({
				isDeleteReportTitleLoading: false,
				getReportTitles: reportTitles.filter((title: any) => title.id !== reportTitleId),
				deleteReportTitle: apiResponseData,
			}));
		}
		if (!reportTitles) {
			dispatch(_deleteReportTitleRequest({
				isDeleteReportTitleLoading: false,
				deleteReportTitle: apiResponseData,
			}));
		}
	} catch (error) {
		dispatch(_deleteReportTitleRequest({
			...initialControlState,
			isDeleteReportTitleLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error.message ?? 'Unable to delete report title. Please try again.',
			},
		));
	}
};

export const getOpenFaultReportRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getOpenFaultReportRequest({
		...initialControlState,
		isgetOpenFaultReportLoading: true,
	}));
	try {
		const apiResponseData = await API.getOpenFaultReport(token);
		dispatch(_getOpenFaultReportRequest({
			isgetOpenFaultReportLoading: false,
			getOpenFaultReport: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getOpenFaultReportRequest({
			...initialControlState,
			isgetOpenFaultReportLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to get reading. Please try again.',
			},
		));
	}
};

export const updateReportStatusRequest = (userData: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_updateReportStatusRequest({
		...initialControlState,
		isUpdateportStatusLoading: true,
	}));
	try {
		const apiResponseData = await API.updateReportStatus(userData, token);
		dispatch(_updateReportStatusRequest({
			isUpdateportStatusLoading: false,
			updateportStatus: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_updateReportStatusRequest({
			...initialControlState,
			isUpdateportStatusLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to approve reading. Please try again.',
			},
		));
	}
};

export const getReportLogsRequest = (reportId: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getReportLogsRequest({
		...initialControlState,
		isGetReportLogsLoading: true,
	}));
	try {
		const apiResponseData = await API.getReportLogs(reportId, token);
		dispatch(_getReportLogsRequest({
			isGetReportLogsLoading: false,
			getReportLogs: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getReportLogsRequest({
			...initialControlState,
			isGetReportLogsLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to get logs. Please try again.',
			},
		));
	}
};

export const getSingleReportRequest = (reportId: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSingleReportRequest({
		...initialControlState,
		isGetSingleReportLoading: true,
	}));
	try {
		const apiResponseData = await API.getSingleReport(reportId, token);
		dispatch(_getSingleReportRequest({
			isGetSingleReportLoading: false,
			getSingleReport: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getSingleReportRequest({
			...initialControlState,
			isGetSingleReportLoading: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to get report. Please try again.',
			},
		));
	}
};
// searchConsumerByAccountNumber;
// isSearchConsumerByAccountNumber;
export const searchConsumerByAccountNumberRequest = (
	token: string,
	accountNumber: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_searchConsumerByAccountNumberRequest({
		...initialControlState,
		isSearchConsumerByAccountNumber: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.searchConsumerByAccountNumber(token, accountNumber);

		const searchedUsersArray = apiResponseData?.data?.users.map((users: any) => {
			const container: any = {};

			container.id = users.id;
			container.label = `${users.account_number} - ${users.account_name}`;
			container.account_number = users.account_number;

			return container;
		});

		dispatch(_searchConsumerByAccountNumberRequest({
			isSearchConsumerByAccountNumber: false,
			searchConsumerByAccountNumber: searchedUsersArray,
		}));
	} catch (error) {
		dispatch(_searchConsumerByAccountNumberRequest({
			...initialControlState,
			isSearchConsumerByAccountNumber: false,
		}));
		dispatch(_setState<Partial<ControlState>>(
			{
				error: messageToDisplay(error) ?? 'User not found.',
			},
		));
	}
};

export const { reducer: Control } = ControlSlice;
