export type UserManagementState = {
	isGetAllUsersLoading: boolean;
	getAllUsers: null | object;
	isSearchUserLoading: boolean;
	searchUser: null | object;
	isGetSingleUserLoading: boolean;
	getSingleUser: null | object;
	isSuspendUserLoading: boolean;
	suspendUser: null | object;
	isDeleteUserLoading : boolean;
	deleteUser: null | object;
	isGetUsersByRoleLoading: boolean;
	getUsersByRole: null | object;
	isCreateUserLoading: boolean;
	createUser: null | object;
	error?: null | object;
}

export const initialUserManagementState: UserManagementState = {
	isGetAllUsersLoading: false,
	getAllUsers: null,
	isSearchUserLoading: false,
	searchUser: [{ id: 0, label: '' }],
	isGetSingleUserLoading: false,
	getSingleUser: null,
	isSuspendUserLoading: false,
	suspendUser: null,
	isDeleteUserLoading: false,
	deleteUser: null,
	isGetUsersByRoleLoading: false,
	getUsersByRole: null,
	isCreateUserLoading: false,
	createUser: null,
};
