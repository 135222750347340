import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import {
	Box,
	Typography,
	Card,
	styled,

} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

dayjs.extend(relativeTime);

const CardWrapperSecondary = styled(Card)(
	({ theme }) => `
      background: #223354;
      color: white;
      padding: ${theme.spacing(2)};
      border-radius: 16px;
      border-top-left-radius: 10px;
      max-width: 380px;
      display: inline-flex;
`,
);

export default function ChatContent({ messages }) {
	const scrollRef = React.useRef(null);

	React.useEffect(() => {
		const scrollMessagesToBottom = () => {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
			}
		};
		scrollMessagesToBottom();
	}, [messages]);

	return (
		// <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
		<Box
			sx={{
				mb: 2,
				display: 'flex',
				flexDirection: 'column',
				height: 700,
				overflow: 'hidden',
				overflowY: 'scroll',
			}}
		>
			{
				messages?.map((message) => (
					<Box
						display="flex"
						alignItems="flex-start"
						justifyContent="flex-start"
						py={3}
						key={nanoid(3)}
					>
						<Box
							display="flex"
							alignItems="flex-start"
							flexDirection="column"
							justifyContent="flex-start"
							ml={2}
						>
							<CardWrapperSecondary>
								{message.message}
							</CardWrapperSecondary>
							<Typography
								variant="subtitle1"
								sx={{
								pt: 1,
								display: 'flex',
								alignItems: 'center',
								}}
							>
								<ScheduleTwoToneIcon
									sx={{
										mr: 0.5,
									}}
									fontSize="small"
								/>
								{dayjs(message.timestamp.toDate()).fromNow()}
							</Typography>
						</Box>
					</Box>
   ))
			}
			{/* {
				messages?.map((message: any) => (message?.sender?.id !== user?.id ? (
					<Box
						display="flex"
						alignItems="flex-start"
						justifyContent="flex-start"
						py={3}
						key={nanoid(3)}
					>
						<Box
							display="flex"
							alignItems="flex-start"
							flexDirection="column"
							justifyContent="flex-start"
							ml={2}
						>
							<CardWrapperSecondary>
								{message.message}
							</CardWrapperSecondary>
							<Typography
								variant="subtitle1"
								sx={{
								pt: 1,
								display: 'flex',
								alignItems: 'center',
								}}
							>
								<ScheduleTwoToneIcon
									sx={{
										mr: 0.5,
									}}
									fontSize="small"
								/>
								{dayjs(message.timestamp.toDate()).fromNow()}
							</Typography>
						</Box>
					</Box>
				) : (
					<Box
						display="flex"
						alignItems="flex-start"
						justifyContent="flex-end"
						py={3}
						key={nanoid(3)}
					>
						<Box
							display="flex"
							alignItems="flex-end"
							flexDirection="column"
							justifyContent="flex-end"
							mr={2}
						>
							<CardWrapperPrimary>
								{message.message}
							</CardWrapperPrimary>
							<Typography
								variant="subtitle1"
								sx={{
										pt: 1,
										display: 'flex',
										alignItems: 'center',
									}}
							>
								<ScheduleTwoToneIcon
									sx={{
											mr: 0.5,
										}}
									fontSize="small"
								/>
								{dayjs(message.timestamp.toDate()).fromNow()}
							</Typography>
						</Box>
					</Box>
				)))
			} */}
		</Box>
	);
}
// {/* </Scrollbar> */}
