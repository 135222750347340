/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
// import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	Button,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Box,
} from '@mui/material';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { getAllNESCOConsumerAccountsRequest } from '../Store';
// import Iconify from '../components/Iconify';
import Label from '../components/Label';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { EditConsumerForm } from '../sections/@dashboard/consumer';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
	{ id: 'account_name', label: 'Account Name', alignRight: false },
	{ id: 'phone', label: 'Phone Number', alignRight: false },
	{ id: 'prepaid_meter_number', label: 'Prepaid Meter Number', alignRight: false },
	{ id: 'account_number', label: 'Account Number', alignRight: false },
	{ id: 'account_type', label: 'Account Type', alignRight: false },
	{ id: 'meter_type', label: 'Meter Type', alignRight: false },
	{ id: 'res_address1', label: 'Residential Address', alignRight: false },
	{ id: 'account_status', label: 'Account Status', alignRight: false },
	{ id: 'district', label: 'district', alignRight: false },
	{ id: 'edit', label: '', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => {
			if (_user.phone) {
				const searched = _user?.account_name?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1
			|| _user.phone.toLowerCase().indexOf(query.toLowerCase()) !== -1
			|| _user.account_number.toLowerCase().indexOf(query.toLowerCase()) !== -1;
				return searched;
			}

			if (_user.prepaid_meter_number) {
				const searched = _user.account_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
					|| _user.account_number.toLowerCase().indexOf(query.toLowerCase()) !== -1
					|| _user.prepaid_meter_number.toLowerCase().indexOf(query.toLowerCase()) !== -1;
				return searched;
			}

			if (_user.postpaid_meter_number) {
				const searched = _user.account_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
					|| _user.account_number.toLowerCase().indexOf(query.toLowerCase()) !== -1
					|| _user.postpaid_meter_number.toLowerCase().indexOf(query.toLowerCase()) !== -1;
				return searched;
			}

			const searched = _user.account_name.toLowerCase()?.indexOf(query.toLowerCase()) !== -1
			|| _user.account_number.toLowerCase().indexOf(query.toLowerCase()) !== -1;
			return searched;
		});
	}
	return stabilizedThis.map((el) => el[0]);
}

function NescoConsumerList({
	token,
	getAllNescoConsumerAccounts,
	isGetAllNescoConsumerAccountsLoading,
	getAllNESCOConsumerAccountsRequest,
	editConsumerAccount,
	isEditConsumerAccountLoading,
}) {
	const [page, setPage] = React.useState(0);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [editView, setEditView] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState([]);
	// const [editId, setEditId] = React.useState(null);

	React.useEffect(() => {
		if (token) {
			getAllNESCOConsumerAccountsRequest(token);
		}
	}, [token]);

	React.useEffect(() => {
		if (editConsumerAccount) {
			setEditView(false);
			setEditDetails({});
			// setEditId(null);
			getAllNESCOConsumerAccountsRequest(token);
		}
	}, [editConsumerAccount]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getAllNescoConsumerAccounts?.map((n) => n.account_name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getAllNescoConsumerAccounts?.length ?? 0) : 0;

	const filteredUsers = getAllNescoConsumerAccounts && applySortFilter(getAllNescoConsumerAccounts, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	// const removeSpecialCharacter = (string) => (string ? string?.replace(/[^a-zA-Z ]/g, ' ') : string);
	const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1);

	const loading = isGetAllNescoConsumerAccountsLoading;

	// const handleSetEditView = (id) => {
	// 	setEditView(true);
	// 	setEditDetails(getAllNescoConsumerAccounts.filter(account => account.id === id));
	// 	// setEditId(id);
	// };
	const handleCloseEditDialog = () => setEditView(false);

	return (
		<Page title="NESCO Consumers">
			<Container sx={{ mb: 3 }}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box>
						<Typography variant="h4" gutterBottom>
							View All NESCO Consumers
						</Typography>
					</Box>
				</Stack>
				<Card sx={{ pb: 2 }}>
					<UserListToolbar
						loading={loading}
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>
					{
						loading ? <Loader />
						: getAllNescoConsumerAccounts ? (
							<Box>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											rowCount={getAllNescoConsumerAccounts?.length}
											numSelected={selected.length}
											onRequestSort={handleRequestSort}
											onSelectAllClick={handleSelectAllClick}
										/>
										<TableBody>
											{
											filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
												const {
													id,
													account_name,
													phone,
													account_number,
													account_type,
													meter_type,
													district,
													res_address1,
													account_status,
													prepaid_meter_number,
												} = row;
												const isItemSelected = selected.indexOf(account_name) !== -1;

												return (
													<TableRow
														hover
														key={id}
														tabIndex={-1}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														selected={isItemSelected}
														aria-checked={isItemSelected}
													>
														<TableCell align="left">
															<Typography variant="subtitle2" noWrap>
																{capitalizeFirstLetter(account_name)}
															</Typography>
														</TableCell>
														<TableCell align="left">
															<Typography variant="subtitle2" noWrap>
																{phone}
															</Typography>
														</TableCell>
														<TableCell align="left">
															{prepaid_meter_number}
														</TableCell>
														<TableCell align="left">
															<Typography variant="subtitle2" noWrap>
																{account_number}
															</Typography>
														</TableCell>
														<TableCell align="left">
															<Typography variant="subtitle2" noWrap>
																{account_type}
															</Typography>
														</TableCell>
														<TableCell align="left">
															<Typography variant="subtitle2" noWrap>
																{meter_type}
															</Typography>
														</TableCell>
														<TableCell align="left">{res_address1}</TableCell>
														<TableCell align="left">
															<Label
																variant="ghost"
																color={account_status ? 'success' : 'error'}
															>
																{account_status ? 'Active' : 'Not Active'}
															</Label>
														</TableCell>
														<TableCell align="left">{capitalizeFirstLetter(district)}</TableCell>
														{/* <TableCell align="left">
															<LoadingButton
																type="submit"
																variant="outlined"
																color="success"
																startIcon={<Iconify icon="bxs:edit" />}
																onClick={() => handleSetEditView(id)}
															>
																Edit
															</LoadingButton>
														</TableCell> */}
														<TableCell align="left">
															<Button
																variant="outlined"
																component={RouterLink}
																to={`/dashboard/accounts/consumer-management/${id}`}
															>
																View
															</Button>
														</TableCell>
													</TableRow>
												);
											})
										}
											{emptyRows > 0 && (
											<TableRow style={{ height: 53 * emptyRows }}>
												<TableCell colSpan={12} />
											</TableRow>
										)}
										</TableBody>

										{isUserNotFound && (
										<TableBody>
											<TableRow>
												<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
													<SearchNotFound searchQuery={filterName} />
												</TableCell>
											</TableRow>
										</TableBody>
									)}
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[10, 15, 25, 40]}
									component="div"
									count={getAllNescoConsumerAccounts?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
								{
									editView && (
									<EditConsumerForm
										view={editView}
										details={editDetails[0]}
										handleCloseEditDialog={handleCloseEditDialog}
										isEditConsumerAccountLoading={isEditConsumerAccountLoading}
									/>
									)
								}
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Consumers Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, ConsumerManagement }) => {
	const { token } = Authentication;
	const {
		isGetAllNescoConsumerAccountsLoading,
		getAllNescoConsumerAccounts,
		editConsumerAccount,
		isEditConsumerAccountLoading,
	} = ConsumerManagement;

	return {
		token,
		isGetAllNescoConsumerAccountsLoading,
		getAllNescoConsumerAccounts,
		editConsumerAccount,
		isEditConsumerAccountLoading,
	};
};

export default connect(mapStateToProps, { getAllNESCOConsumerAccountsRequest })(NescoConsumerList);
