/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getUsersByAccountNumber = async (token: any, accountNumber: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const NES_URL = `/user/consumer-account/nes/list?account_number=${accountNumber}`;
	const PUB_URL = `/user/consumer-account/pub/list?account_number=${accountNumber}`;

	const url = accountNumber.toLowerCase().charAt(0) === 'n' ? NES_URL : PUB_URL;

	const response = await axios.get(url, config);
	return response.data;
};
