/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type UserData = {
	fullname: string,
	title: string,
	phone: string,
	email: string,
	password: string,
	gender: string,
	role: string,
}
export const createUser = async (userData: UserData, token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.post('/user/create', userData, config);

	return response.data;
};
