/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { UserNewEditForm } from '../sections/@dashboard/user';

export default function UserCreate() {
	return (
		<Page title="User: Create a new user">
			<Container>
				<UserNewEditForm />
			</Container>
		</Page>
	);
}
