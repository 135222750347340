/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Outlet, useNavigate, useLocation, Navigate,
} from 'react-router-dom';

import {
	useDispatch,
	logoutUser,
} from '../../Store';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
	flexGrow: 1,
	overflow: 'auto',
	minHeight: '100%',
	paddingTop: APP_BAR_MOBILE + 24,
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up('lg')]: {
		paddingTop: APP_BAR_DESKTOP + 24,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

const controlRoutes = [
	'/dashboard/admin/user-management/list',
	'/dashboard/customer-support/fault-reports/all-opened',
	'/dashboard/customer-support/report-title/all',
	'/dashboard/customer-support/report-title/create',
	'/dashboard/accounts/transactions/all-transactions',
	'/dashboard/accounts/transactions/uncompleted-transactions',
];

const customerCareRoutes = [
	'/dashboard/admin/user-management/list',
	'/dashboard/customer-support/fault-reports/all-opened',
	'/dashboard/customer-support/report-title/all',
	'/dashboard/customer-support/report-title/create',
	'/dashboard/accounts/transactions/all-transactions',
	'/dashboard/accounts/transactions/uncompleted-transactions',
];

const meterReaderRoutes = [
	'/dashboard/admin/user-management/list',
	'/dashboard/billing/meter-reading/all-readings',
	'/dashboard/customer-support/report-title/all',
	'/dashboard/customer-support/report-title/create',
	'/dashboard/accounts/transactions/all-transactions',
	'/dashboard/accounts/transactions/uncompleted-transactions',
];

const managingDirectorRoutes = [
	'/dashboard/admin/user-management/list',
	'/dashboard/admin/notification/list',
	'/dashboard/accounts/transactions/all-transactions',
	'/dashboard/accounts/transactions/uncompleted-transactions',
];

const billerRoutes = [
	'/dashboard/billing/meter-reading/all-readings',
	'/dashboard/billing/tariff/all-tariffs',
	'/dashboard/accounts/transactions/all-transactions',
	'/dashboard/accounts/transactions/uncompleted-transactions',
];

function RouteInterceptor({ children, isAuthenticated, user }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [requestedLocation, setRequestedLocation] = useState(null);

	if (!isAuthenticated) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		navigate('/', { replace: true });
	}

	useEffect(() => {
		if (user) {
			if (user?.role === 'consumer') {
				dispatch(logoutUser());
			}
			if (user?.role === 'vendor') {
				dispatch(logoutUser());
			}
			if (user?.role === 'control' && !controlRoutes.includes(pathname)) {
				navigate('/dashboard/admin/user-management/list', { replace: true });
			}
			if (user?.role === 'meter_reader' && !meterReaderRoutes.includes(pathname)) {
				navigate('/dashboard/admin/user-management/list', { replace: true });
			}
			if (user?.role === 'customer_care' && !customerCareRoutes.includes(pathname)) {
				navigate('/dashboard/admin/user-management/list', { replace: true });
			}
			if (user?.role === 'foreman') {
				dispatch(logoutUser());
			}
			if (user?.role === 'electrician_team_leader') {
				dispatch(logoutUser());
			}
			if (user?.role === 'southern_area_superintendent') {
				dispatch(logoutUser());
			}
			if (user?.role === 'nothern_area_superintendent') {
				dispatch(logoutUser());
			}
			if (user?.role === 'biller' && !billerRoutes.includes(pathname)) {
				navigate('/dashboard/billing/meter-reading/all-readings', { replace: true });
			}
			if (user?.role === 'assistant_chief_engineer_services') {
				dispatch(logoutUser());
			}
			if (user?.role === 'chief_engineer_utilities') {
				dispatch(logoutUser());
			}
			if (user?.role === 'managing_director' && !managingDirectorRoutes.includes(pathname)) {
				navigate('/dashboard/admin/user-management/list', { replace: true });
			}
			if (user?.role === 'disconnector') {
				dispatch(logoutUser());
			}
		}
	}, [user]);

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return children;
}

function DashboardLayout({ token, user }) {
	const [open, setOpen] = useState(false);

	return (
		<RouteInterceptor isAuthenticated={token} user={user}>
			<RootStyle>
				<DashboardNavbar onOpenSidebar={() => setOpen(true)} />
				<DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} user={user} />
				<MainStyle>
					<Outlet />
				</MainStyle>
			</RootStyle>
		</RouteInterceptor>
	);
}

const mapStateToProps = ({ Authentication }) => {
	const { token, user } = Authentication;
	return { token, user };
};

export default connect(mapStateToProps)(DashboardLayout);
