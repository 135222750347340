/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */

import { LoadingButton } from '@mui/lab';
import {
	Box, Container, Button, Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { API_BASE_URL } from '../Environment';
import { useDispatch, getConsumerAccountStatementRequest } from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function ConsumerAccountStatement({
	token,
	getConsumerAccountStatement,
	isGetCustomerAccountStatementLoading,
}) {
	const dispatch = useDispatch();
	const { accountNumber } = useParams();

	const [rangeView, setRangeView] = React.useState(false);
	const [downloadLoading, setDownloadLoading] = React.useState(false);
	const [rangeState, setRangeState] = React.useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 30),
			key: 'selection',
		},
	]);

	const { startDate, endDate } = rangeState[0];
	const startDateValue = dayjs(startDate).format('YYYY-MM-DD');
	const endDateValue = dayjs(endDate).format('YYYY-MM-DD');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const dateValues = startDateValue === endDateValue ? {
		startDateValue,
		endDateValue: null,
	} : {
		startDateValue,
		endDateValue,
	};

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleSetRangeView = () => {
		setRangeView(true);
	};

	const handleCloseRangeDialog = () => {
		setRangeView(false);
	};

	const handleSearchDaily = () => {
		const { startDate, endDate } = rangeState[0];

		const startDateValue = dayjs(startDate).format('YYYY-MM-DD');
		const endDateValue = dayjs(endDate).format('YYYY-MM-DD');

		const dateValues = startDateValue === endDateValue ? {
			startDateValue,
			endDateValue: null,
		} : {
			startDateValue,
			endDateValue,
		};

		dispatch(getConsumerAccountStatementRequest(token, accountNumber, dateValues));
	};

	const downloaLink = `${API_BASE_URL}/user/statement/${accountNumber}/excel`;

	const handleDownloadExcel = async () => {
		// await dispatch(getSalesReportRequest(token, { month: dayjs(nescoMonthValue).format('MMMM'), year: nescoYearValue }));
		setDownloadLoading(true);
		try {
			const result = await fetch(downloaLink, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const blob = await result.blob();
			const href = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.download = `statement-${accountNumber}.xlsx`;
			link.href = href;
			document.body.appendChild(link);
			link.click();
			setDownloadLoading(false);
		} catch (error) {
			setDownloadLoading(false);
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(getConsumerAccountStatementRequest(token, accountNumber, null));
		}
	}, [token]);

	return (
		<Page title="Account Statement">
			<Container maxWidth="xl">
				<Stack direction="row" mb={2} alignItems="start" justifyContent="space-between">
					<Box>
						<Button
							onClick={handlePrint}
							variant="outlined"
							color="inherit"
							sx={{ height: 1, marginBottom: 2, marginRight: 2 }}
							disabled={isGetCustomerAccountStatementLoading}
						>
							Print Statement

						</Button>
						<LoadingButton
							onClick={handleDownloadExcel}
							variant="outlined"
							color="inherit"
							sx={{ height: 1, marginBottom: 2 }}
							loading={downloadLoading}
							disabled={downloadLoading}
						>
							Download Excel

						</LoadingButton>
					</Box>
					<Box>
						<Button onClick={handleSetRangeView} variant="outlined" disabled={isGetCustomerAccountStatementLoading} color="primary">Select Date Range</Button>
					</Box>
				</Stack>

				{isGetCustomerAccountStatementLoading ? <Loader /> : getConsumerAccountStatement ? (
					<div ref={componentRef} id="statement-table">
						<div className="table-container">
							<div className="table-header">
								<p className="header-title">NESCO (NIGERIA) LIMITED</p>
								<p className="header-subtitle">
									Barakin-Ladi Road, Opposite Bukuru Main Market, Bukuru, Jos Plateau
									State - ( RC 6461 )
								</p>
								<p className="header-subtitle">
									Date:
									{dayjs().format('DD/MM/YYYY  H:mm:ss a')}
								</p>
							</div>
							<div className="sub-header">
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									<div className="sub-header-first">
										<p className="sub-header-text">
											Account Number:
											{getConsumerAccountStatement?.statement_details.account_number}
										</p>
										<p className="sub-header-text">
											Name:
											{getConsumerAccountStatement?.statement_details.name}
										</p>
										<p className="sub-header-text">
											Address:
											{getConsumerAccountStatement?.statement_details.address}
										</p>

										<p className="sub-header-text" style={{ marginTop: '30px' }}>
											Billing Group:
											{getConsumerAccountStatement?.statement_details.billing_group}
										</p>
										<p className="sub-header-text">
											Issuer:
											{getConsumerAccountStatement?.statement_details.issuer}
										</p>
									</div>
									<div className="sub-header-second">
										<div className="sub-header-second-inner">
											<p className="sub-header-text">
												Feeder:
												{getConsumerAccountStatement?.statement_details.feeder}
											</p>
											<p className="sub-header-text">
												Substation:
												{getConsumerAccountStatement?.statement_details.substation}
											</p>
											<div
												style={{
													display: 'flex',
													gap: '10px',
													alignItems: 'center',
												}}
											>
												<div>
													<p
														className="sub-header-text"
														style={{
															marginBottom: 0,
														}}
													>
														Distrct:
														{getConsumerAccountStatement?.statement_details.district}
													</p>
													<div className="header-table">
														<div className="item">from</div>
														<div
															className="item"
															style={{
																textAlign: 'right',
															}}
														>
															<p>

																{getConsumerAccountStatement?.statement_details.from}
															</p>
														</div>
														<div className="item">to</div>
														<div className="item" style={{ textAlign: 'right' }}>

															{getConsumerAccountStatement?.statement_details.to}
														</div>
													</div>
												</div>

												<div className="">
													<p className="sub-header-text" style={{ marginBottom: 0 }}>print</p>
													<div className="header-table2">
														<div className="item">pdf</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p style={{ fontSize: '20px', fontWeight: 800 }}>
									Customer&apos;s Statement Of Account
								</p>
							</div>

							<table className="table">
								<tr>
									<th>Invoice Date</th>
									<th>Sold Energy Units</th>
									<th>Opening Balance</th>
									<th>Tariff rate(N)</th>
									<th>Current Debit</th>
									<th>Percentage Discount</th>
									<th>Discount Allowed</th>
									<th>Vat</th>
									<th>Total Current Debit</th>
									<th>Payment</th>
									<th>payment date</th>
									<th>Adjustment</th>
									<th>Closing Balance</th>
									<th>Account Status</th>
									<th>Reading mode</th>
								</tr>

								{getConsumerAccountStatement?.bill_summary.map((item, id) => (
									// eslint-disable-next-line react/no-array-index-key
									<tr key={`${item.invoice_date}-${id}`}>
										<td>
											{item.invoice_date}
										</td>
										<td>
											{item.sold_energy_units}
										</td>
										<td>
											{item.opening_balance}
										</td>
										<td>
											{item.tariff}
										</td>
										<td>
											{item.current_debit}
										</td>
										<td>
											{item.percentage_discount}
										</td>
										<td>
											{item.discount_allowed}
										</td>
										<td>
											{item.vat}
										</td>
										<td>
											{item.total_current_debit}
										</td>
										<td>
											{item.payments}
										</td>
										<td>
											{item.payment_date}
										</td>
										<td>
											{item.adjustments}
										</td>
										<td>
											{item.closing_balance}
										</td>
										<td>
											{item.account_status}
										</td>
										<td>
											{item.reading_mode}
										</td>
									</tr>
								))}
							</table>
						</div>
					</div>
                ) : (
	<h1 style={{ display: 'none' }}>No Record</h1>
                    )}
				<Dialog
					open={rangeView}
					TransitionComponent={Transition}
					fullWidth
					maxWidth="lg"
					keepMounted
					onClose={handleCloseRangeDialog}
					aria-describedby="alert-dialog-slide-description"
				>
					<Box />
					<DialogTitle>
						Select Date Range
					</DialogTitle>
					<DialogContent>
						<DateRangePicker
							onChange={item => setRangeState([item.selection])}
							showSelectionPreview
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={rangeState}
							direction="horizontal"
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseRangeDialog}>Cancel</Button>
						<LoadingButton onClick={handleSearchDaily} variant="outlined" disabled={isGetCustomerAccountStatementLoading} loading={isGetCustomerAccountStatementLoading} color="primary">Get Statement</LoadingButton>
					</DialogActions>
				</Dialog>
			</Container>
		</Page>

	);
}

const mapStateToProps = ({ Authentication, ConsumerManagement }) => {
	const { token } = Authentication;
	const { getConsumerAccountStatement, isGetCustomerAccountStatementLoading } = ConsumerManagement;
	return {
		token,
		getConsumerAccountStatement,
		isGetCustomerAccountStatementLoading,
	};
};

export default connect(mapStateToProps)(ConsumerAccountStatement);
