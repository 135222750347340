/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { ReportTitleNewForm } from '../sections/@dashboard/report';

export default function BroadcastCreate() {
	return (
		<Page title="Report Title: Create a new title">
			<Container>
				<ReportTitleNewForm />
			</Container>
		</Page>
	);
}
