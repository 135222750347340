/* eslint-disable camelcase */
import axios from '../../configs/Axios';

export const getSingleNews = async (token: any, newsId: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`/news/${newsId}`, config);

	return response.data;
};
