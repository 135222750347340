/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getReportLogs = async (reportId: any, token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`/report/${reportId}/logs?page=1&pageSize=2000`, config);

	return response.data;
};
