/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getUsersByRole = async (role: any, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`/user/list?page=1&pageSize=2000&role=${role}`, config);

	return response.data;
};
