/* eslint-disable camelcase */
import axios from '../../configs/Axios';

export const startChannel = async (token: string, reportId: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.get(`chat/start-channel/${reportId}`, config);

	return response.data;
};
