/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const issueCreditToken = async (token: any, creditTokenData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.post('/vending/meter/token/issue', creditTokenData, config);
	return response.data;
};
