/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getAllUsers = async (token: any, role?: string, searchFields?: string, queryField?: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (searchFields && role) {
		const response = await axios.get(`/user/list/?page=1&pageSize=2000&role=${role}&${queryField}=${searchFields}`, config);
		return response.data;
	}

	if (searchFields && !role) {
		const response = await axios.get(`/user/list/?page=1&pageSize=2000&${queryField}=${searchFields}`, config);
		return response.data;
	}

	if (role && !searchFields) {
		const response = await axios.get(`/user/list/?page=1&pageSize=2000&role=${role}`, config);
		return response.data;
	}

	const response = await axios.get('/user/list?page=1&pageSize=2000&', config);
	return response.data;
};
