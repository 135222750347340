/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import {
	Box, Button, Container, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
	useDispatch,
	useSelector,
	clearCache,
} from '../../Store';
import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import { ResetPasswordForm } from '../../sections/auth/reset-password';

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(12, 0),
}));

export default function ResetPassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [, setEmail] = useState('');
	const Authentication = useSelector((state) => state.Authentication);
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		if (Authentication.passwordResetRequest) {
			navigate('/confirm-reset-otp');
		}

		if (Authentication.error) {
			enqueueSnackbar(Authentication.error, {
				variant: 'error',
			});
			dispatch(clearCache());
		}
	}, [Authentication.error, Authentication.passwordResetRequest]);

	return (
		<Page title="Reset Password" sx={{ height: 1 }}>
			<RootStyle>
				<LogoOnlyLayout />

				<Container>
					<Box sx={{ maxWidth: 480, mx: 'auto' }}>
						<>
							<Typography variant="h3" paragraph>
								Forgot your password?
							</Typography>
							<Typography sx={{ color: 'text.secondary', mb: 5 }}>
								Please enter the email address associated with your account and We will email you a link to reset your
								password.
							</Typography>

							<ResetPasswordForm onGetEmail={(value) => setEmail(value)} />

							<Button fullWidth size="large" component={RouterLink} to="/login" sx={{ mt: 1 }}>
								Back
							</Button>
						</>
					</Box>
				</Container>
			</RootStyle>
		</Page>
	);
}
