/* eslint-disable react/jsx-filename-extension */
import { MenuItem, Avatar, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	useDispatch,
	logoutUser,
} from '../../Store';
import MenuPopover from '../../components/MenuPopover';

export default function AccountPopover() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const anchorRef = useRef(null);

	const [open, setOpen] = useState(null);

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
			>
				<Avatar src="" alt="photoURL" />
			</IconButton>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
			>
				<MenuItem
					onClick={
					() => dispatch(logoutUser(navigate))
				}
					sx={{ m: 1 }}
				>
					Logout
				</MenuItem>
			</MenuPopover>
		</>
	);
}
