export type NewsState = {
	isGetAllNewsLoading: boolean;
	getAllNews: null | object;
	isGetSingleNewsLoading: boolean;
	getSingleNews: null | object;
	isCreateNewsLoading: boolean;
	createNews: null | object;
	isEditNewsLoading: boolean,
	editNews: null | object,
	isDeleteNewsLoading: boolean,
	deleteNews: null | object,
	error?: null | object;
}

export const initialNewsState: NewsState = {
	isGetAllNewsLoading: false,
	getAllNews: null,
	isGetSingleNewsLoading: false,
	getSingleNews: null,
	isCreateNewsLoading: false,
	createNews: null,
	isEditNewsLoading: false,
	editNews: null,
	isDeleteNewsLoading: false,
	deleteNews: null,
};
