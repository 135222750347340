/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getFeeders = async (token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get('/meter/feeders', config);
	return response.data;
};

export const createFeeders = async (token: string, feederData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.post('/meter/feeders', feederData, config);

	return response.data;
};

export const editFeeders = async (token: string, feederId: string, feederData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.put(`/meter/feeders/${feederId}`, feederData, config);

	return response.data;
};

export const deleteFeeders = async (token: string, feederId: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.delete(`/meter/feeder/${feederId}`, config);

	return response.data;
};
