/* eslint-disable react/jsx-indent */
// /* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const TokenPurchaseReceipt = React.forwardRef(({ data }, ref) => (
  <div ref={ref}>
    {data ? (
      <div>
        <style>
          {`
          body {
            font-size:15px
          }
          table {
            width: 90%;
            margin-left: 50px
          }
          tr {
            text-align: left;
            border: 1px solid black;
          }
          th,
          td {
            padding: 3px;
            font-size: 11px;
          }
          .no-content {
            background-color: red;
          }
          .table-div {
            align-items: center;
          }
          table {
            font-size: 15px;
            align-items: left;
          }
        `}
        </style>
        <div style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h3 style={{ margin: 0, padding: 0, display: 'inline' }}>
              NESCO (NIGERIA) LIMITED
            </h3>
            <img
              id="image"
              src="https://nesconigeria.com/images/Nesco_LOGO.png"
              alt="logo"
              height="40px"
            />
          </div>

          <h6 style={{ margin: 0, padding: 0 }}>
            10 Barakin-Ladi Road, Opposite Bukuru Main Market,
            {' '}
            <br />
            Bukuru,Jos Plateau State - ( RC 6461 )
          </h6>
        </div>
        <div className="table-div" style={{ alignItems: 'center' }}>
          <hr />
          <table>
            <tr>
              <td>
                <b>Transaction Reference:</b>
              </td>
              <td>{data.transaction_reference}</td>
            </tr>
            <tr>
              <td>
                <b>Transaction ID:</b>
              </td>
              <td>{data.transaction_id}</td>
            </tr>
            <tr>
              <td>
                <b>Account Number:</b>
              </td>
              <td>{data.account_number}</td>
            </tr>
            <tr>
              <td>
                <b>Account Type:</b>
              </td>
              <td>{data.account_type}</td>
            </tr>
            <tr>
              <td>
                <b>Meter Number:</b>
              </td>
              <td>{data.meter_number}</td>
            </tr>
            <tr>
              <td>
                <b>Address:</b>
              </td>
              <td>{data.consumer.res_address1}</td>
            </tr>
            <tr>
              <td>
                <b>Payment Type:</b>
              </td>
              <td>{data.payment_type}</td>
            </tr>
            <tr>
              <td>
                <b>Tariff:</b>
              </td>
              <td>{data.tariff}</td>
            </tr>
            <tr>
              <td>
                <b>Account Name:</b>
              </td>
              <td>{data.customer}</td>
            </tr>
            <tr>
              <td>
                <b>Amount:</b>
              </td>
              <td>
                &#x20A6;
                {Intl.NumberFormat('en-US').format(data.amount)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Convenience Charge:</b>
              </td>
              <td>
                &#x20A6;
                {Intl.NumberFormat('en-US').format(data.transaction_charge)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Debt Reconciliation</b>
              </td>
              <td>
                &#x20A6;
                {Intl.NumberFormat('en-US').format(data.debt_reconciliation)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Vat</b>
              </td>
              <td>
                &#x20A6;
                {Intl.NumberFormat('en-US').format(data.vat)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Charged Amount</b>
              </td>
              <td>
                &#x20A6;
                {Intl.NumberFormat('en-US').format(data.charged_amount)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Units (Kwh):</b>
              </td>
              <td>
                <b>{data.kwh}</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>Token</b>
              </td>
              <td>
                <b>{data.token}</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>Date:</b>
              </td>
              <td>{data.date}</td>
            </tr>
            <tr>
              <td>
                <b>Status:</b>
              </td>
              <td>{data.status}</td>
            </tr>
          </table>
        </div>
      </div>
    ) : (
      <h1>Null</h1>
    )}
  </div>
));

export default TokenPurchaseReceipt;
