/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { BillingState, initialBillingState } from './Types';

const BillingSlice = createSlice({
	name: 'BillingSlice',
	initialState: initialBillingState,
	reducers: {
		setState(state: BillingState, action: PayloadAction<Partial<BillingState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		uploadBillingFile(
			state: BillingState,
			action: PayloadAction<{
				isUploadBillingFileLoading: Partial<BillingState['isUploadBillingFileLoading']>,
				uploadBillingFile: Partial<BillingState['uploadBillingFile']>,
			}>,
		) {
			const {
				isUploadBillingFileLoading,
				uploadBillingFile,
			} = action.payload;
			return {
				...state,
				isUploadBillingFileLoading,
				uploadBillingFile,

			};
		},
		getAllRecordsRequest(
			state: BillingState,
			action: PayloadAction<{
				isGetAllRecordsLoading: Partial<BillingState['isGetAllRecordsLoading']>,
				getAllRecords: Partial<BillingState['getAllRecords']>,
			}>,
		) {
			const {
				isGetAllRecordsLoading,
				getAllRecords,
			} = action.payload;
			return {
				...state,
				isGetAllRecordsLoading,
				getAllRecords,

			};
		},
		approveRecordsRequest(
			state: BillingState,
			action: PayloadAction<{
				isApproveRecordLoading: Partial<BillingState['isApproveRecordLoading']>,
				approveRecord: Partial<BillingState['approveRecord']>,
			}>,
		) {
			const {
				isApproveRecordLoading,
				approveRecord,
			} = action.payload;
			return {
				...state,
				isApproveRecordLoading,
				approveRecord,

			};
		},
		declineRecordsRequest(
			state: BillingState,
			action: PayloadAction<{
				isDeclineRecordLoading: Partial<BillingState['isDeclineRecordLoading']>,
				declineRecord: Partial<BillingState['declineRecord']>,
			}>,
		) {
			const {
				isDeclineRecordLoading,
				declineRecord,
			} = action.payload;
			return {
				...state,
				isDeclineRecordLoading,
				declineRecord,

			};
		},
		printBillFromArrayRequest(
			state: BillingState,
			action: PayloadAction<{
				printBillFromArrayLoading: Partial<BillingState['printBillFromArrayLoading']>,
				printBillFromArray: Partial<BillingState['printBillFromArray']>,
			}>,
		) {
			const {
				printBillFromArrayLoading,
				printBillFromArray,
			} = action.payload;
			return {
				...state,
				printBillFromArrayLoading,
				printBillFromArray,

			};
		},
		generateBillForNescoRequest(
			state: BillingState,
			action: PayloadAction<{
				isGenerateBillForNescoLoading: Partial<BillingState['isGenerateBillForNescoLoading']>,
				generateBillForNesco: Partial<BillingState['generateBillForNesco']>,
			}>,
		) {
			const {
				isGenerateBillForNescoLoading,
				generateBillForNesco,
			} = action.payload;
			return {
				...state,
				isGenerateBillForNescoLoading,
				generateBillForNesco,

			};
		},
		generateBillForPUBRequest(
			state: BillingState,
			action: PayloadAction<{
				isGenerateBillForPUBLoading: Partial<BillingState['isGenerateBillForPUBLoading']>,
				generateBillForPUB: Partial<BillingState['generateBillForPUB']>,
			}>,
		) {
			const {
				isGenerateBillForPUBLoading,
				generateBillForPUB,
			} = action.payload;
			return {
				...state,
				isGenerateBillForPUBLoading,
				generateBillForPUB,

			};
		},
		getMeterReportRequest(
			state: BillingState,
			action: PayloadAction<{
				isGetMeterReadingReport: Partial<BillingState['isGetMeterReadingReport']>,
				getMeterReadingReport: Partial<BillingState['getMeterReadingReport']>,
			}>,
		) {
			const {
				isGetMeterReadingReport,
				getMeterReadingReport,
			} = action.payload;
			return {
				...state,
				isGetMeterReadingReport,
				getMeterReadingReport,

			};
		},
		getPrintPreviewsRequest(
			state: BillingState,
			action: PayloadAction<{
				isViewPrintPreviewLoading: Partial<BillingState['isViewPrintPreviewLoading']>,
				viewPrintPreview: Partial<BillingState['viewPrintPreview']>,
			}>,
		) {
			const {
				isViewPrintPreviewLoading,
				viewPrintPreview,
			} = action.payload;
			return {
				...state,
				isViewPrintPreviewLoading,
				viewPrintPreview,

			};
		},
		getAllDistrictRequest(
			state: BillingState,
			action: PayloadAction<{
				isGetAllDistrictsLoading: Partial<BillingState['isGetAllDistrictsLoading']>,
				getAllDistricts: Partial<BillingState['getAllDistricts']>,
			}>,
		) {
			const {
				isGetAllDistrictsLoading,
				getAllDistricts,
			} = action.payload;
			return {
				...state,
				isGetAllDistrictsLoading,
				getAllDistricts,

			};
		},
		updateBillRequest(
			state: BillingState,
			action: PayloadAction<{
				isUpdateBillLoading: Partial<BillingState['isUpdateBillLoading']>,
				updateBill: Partial<BillingState['updateBill']>,
			}>,
		) {
			const {
				isUpdateBillLoading,
				updateBill,
			} = action.payload;
			return {
				...state,
				isUpdateBillLoading,
				updateBill,

			};
		},
	},
});

const {
	setState: _setState,
	getAllRecordsRequest: _getAllRecordsRequest,
	approveRecordsRequest: _approveRecordsRequest,
	declineRecordsRequest: _declineRecordsRequest,
	uploadBillingFile: _uploadBillingFile,
	generateBillForNescoRequest: _generateBillForNescoRequest,
	generateBillForPUBRequest: _generateBillForPUBRequest,
	printBillFromArrayRequest: _printBillFromArrayRequest,
	getMeterReportRequest: _getMeterReportRequest,
	getAllDistrictRequest: _getAllDistrictRequest,
	getPrintPreviewsRequest: _getPrintPreviewsRequest,
	updateBillRequest: _updateBillRequest,
} = BillingSlice.actions;

export const getAllRecordsRequest = (token: any, day?: any, accountNumber?: any, receiptNumber?: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllRecordsRequest({
		...initialBillingState,
		isGetAllRecordsLoading: true,
	}));
	try {
		const apiResponseData = await API.getAllRecords(token, day, accountNumber, receiptNumber);
		dispatch(_getAllRecordsRequest({
			isGetAllRecordsLoading: false,
			getAllRecords: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllRecordsRequest({
			...initialBillingState,
			isGetAllRecordsLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get reading. Please try again.',
			},
		));
	}
};

export const approveRecordsRequest = (recordId: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_approveRecordsRequest({
		...initialBillingState,
		isApproveRecordLoading: true,
	}));
	try {
		const apiResponseData = await API.approveRecord(recordId, token);
		dispatch(_approveRecordsRequest({
			isApproveRecordLoading: false,
			approveRecord: apiResponseData,
		}));
	} catch (error) {
		dispatch(_approveRecordsRequest({
			...initialBillingState,
			isApproveRecordLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to approve record. Please try again.',
			},
		));
	}
};

export const declineRecordsRequest = (recordId: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_declineRecordsRequest({
		...initialBillingState,
		isDeclineRecordLoading: true,
	}));
	try {
		const apiResponseData = await API.declineRecord(recordId, token);
		dispatch(_declineRecordsRequest({
			isDeclineRecordLoading: false,
			declineRecord: apiResponseData,
		}));
	} catch (error) {
		dispatch(_declineRecordsRequest({
			...initialBillingState,
			isDeclineRecordLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message
					?? error?.message
					?? 'Unable to decline record. Please try again.',
			},
		));
	}
};

export const uploadBillingFile = (userData: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_uploadBillingFile({
		...initialBillingState,
		isUploadBillingFileLoading: true,
	}));
	try {
		const apiResponseData = await API.uploadFile(userData, token);
		dispatch(_uploadBillingFile({
			isUploadBillingFileLoading: false,
			uploadBillingFile: apiResponseData,
		}));
	} catch (error) {
		dispatch(_uploadBillingFile({
			...initialBillingState,
			isUploadBillingFileLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to upload file. Please try again.',
			},
		));
	}
};

export const generateBillForNescoRequest = (generationData: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_generateBillForNescoRequest({
		...initialBillingState,
		isGenerateBillForNescoLoading: true,
	}));
	try {
		const apiResponseData = await API.generateBillsForNesco(generationData, token);
		dispatch(_generateBillForNescoRequest({
			isGenerateBillForNescoLoading: false,
			generateBillForNesco: apiResponseData,
		}));
	} catch (error) {
		dispatch(_generateBillForNescoRequest({
			...initialBillingState,
			isGenerateBillForNescoLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to generate bill. Please try again.',
			},
		));
	}
};

export const generateBillForPUBRequest = (generationData: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_generateBillForPUBRequest({
		...initialBillingState,
		isGenerateBillForPUBLoading: true,
	}));
	try {
		const apiResponseData = await API.generateBillsForPub(generationData, token);
		dispatch(_generateBillForPUBRequest({
			isGenerateBillForPUBLoading: false,
			generateBillForPUB: apiResponseData,
		}));
	} catch (error) {
		dispatch(_generateBillForPUBRequest({
			...initialBillingState,
			isGenerateBillForPUBLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to generate bill. Please try again.',
			},
		));
	}
};

export const printBillFromArrayRequest = (
	token?: any,
	month?: any,
	year?: any,
	district?: any,
	accountNumber?: any,
	status?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_printBillFromArrayRequest({
		...initialBillingState,
		printBillFromArrayLoading: true,
	}));
	try {
		const apiResponseData = await API.printBillfromArray(token, month, year, district, accountNumber, status);
		dispatch(_printBillFromArrayRequest({
			printBillFromArrayLoading: false,
			printBillFromArray: apiResponseData.data,
		}));
	} catch (error) {
		dispatch(_printBillFromArrayRequest({
			...initialBillingState,
			printBillFromArrayLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get bills. Please try again.',
			},
		));
	}
};

export const getMeterReportRequest = (token: any, districtCode: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getMeterReportRequest({
		...initialBillingState,
		isGetMeterReadingReport: true,
	}));
	try {
		const apiResponseData = await API.getMeterReadingReport(token, districtCode);
		dispatch(_getMeterReportRequest({
			isGetMeterReadingReport: false,
			getMeterReadingReport: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getMeterReportRequest({
			...initialBillingState,
			isGetMeterReadingReport: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get meter report. Please try again.',
			},
		));
	}
};

export const getPrintPreviewsRequest = (token: any, districtCode: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getPrintPreviewsRequest({
		...initialBillingState,
		isViewPrintPreviewLoading: true,
	}));
	try {
		const apiResponseData = await API.getPrintPreviews(token, districtCode);
		dispatch(_getPrintPreviewsRequest({
			isViewPrintPreviewLoading: false,
			viewPrintPreview: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getPrintPreviewsRequest({
			...initialBillingState,
			isViewPrintPreviewLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get previews. Please try again.',
			},
		));
	}
};

export const getAllDistricts = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllDistrictRequest({
		...initialBillingState,
		isGetAllDistrictsLoading: true,
	}));
	try {
		const apiResponseData = await API.getDistricts(token);
		dispatch(_getAllDistrictRequest({
			isGetAllDistrictsLoading: false,
			getAllDistricts: apiResponseData.data,
		}));
	} catch (error) {
		dispatch(_getAllDistrictRequest({
			...initialBillingState,
			isGetAllDistrictsLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get meter report. Please try again.',
			},
		));
	}
};

export const updateBillRequest = (token: any, payload: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_updateBillRequest({
		...initialBillingState,
		isUpdateBillLoading: true,
	}));
	try {
		const apiResponseData = await API.updateBill(payload, token);
		dispatch(_updateBillRequest({
			isUpdateBillLoading: false,
			updateBill: apiResponseData,
		}));
	} catch (error) {
		dispatch(_updateBillRequest({
			...initialBillingState,
			isUpdateBillLoading: false,
		}));
		dispatch(_setState<Partial<BillingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to update bill. Please try again.',
			},
		));
	}
};

export const { reducer: Billing } = BillingSlice;
