/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const updateBill = async (billPayload: string, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.put('/billing/bill/update', billPayload, config);
	return response.data;
};
