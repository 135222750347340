/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Link,
	Typography,
	Stack,
	TextField,
	Alert,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { motion } from 'framer-motion';
import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	resendVerificationLinkRequest,
	verifyAccountRequest,
} from '../../../Store';

function VerifyAccountForm({
	error, token, isVerifyAccountLoading, verifyAccount, resendVerificationLink,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loginValues = JSON.parse(localStorage.getItem('nesco_login_details'));

	const VerificationSchema = Yup.object().shape({
		verification_code: Yup.string().min(3, 'Too Short!').max(20, 'Too Long!').required('meter number is required'),
	});

	const formik = useFormik({
		initialValues: {
			verification_code: '',
		},
		validationSchema: VerificationSchema,
		onSubmit: (values) => {
			dispatch(verifyAccountRequest(values.verification_code));
		},
	});

	const {
		errors, touched, handleSubmit, getFieldProps,
	} = formik;

	React.useEffect(() => {
		if (token !== null) {
			navigate('/dashboard/home', { replace: true });
		}
	}, [token]);

	React.useEffect(() => {
		if (verifyAccount) {
			navigate('/dashboard/home', { replace: true });
		}
	}, [verifyAccount]);

	const handleResendVerificationLink = () => dispatch(resendVerificationLinkRequest({ email: loginValues.id }));

	return (
		<>
			<motion.div
				initial={{ opacity: 0, marginBottom: 0 }}
				animate={{
				opacity: (error) ? 1 : 0,
				marginBottom: (error) ? 20 : 0,
				}}
			>
				<Alert severity="error" variant="filled">
					{error && error}
				</Alert>
			</motion.div>
			<motion.div
				initial={{ opacity: 0, marginBottom: 0 }}
				animate={{
				opacity: (resendVerificationLink) ? 1 : 0,
				marginBottom: (resendVerificationLink) ? 20 : 0,
				}}
			>
				<Alert severity="success" variant="filled">
					{resendVerificationLink && resendVerificationLink}
				</Alert>
			</motion.div>
			{
				!resendVerificationLink && (
				<Typography
					sx={{ color: 'text.secondary', mb: 5 }}
				>
					A verfication code has been re-sent to your registered mail and phone number to complete you registration, otp is valid for 4 hours
				</Typography>
				)
			}
			<FormikProvider value={formik}>
				<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<TextField
							fullWidth
							label="Verification code"
							{...getFieldProps('verification_code')}
							error={Boolean(touched.verification_code && errors.verification_code)}
							helperText={touched.verification_code && errors.verification_code}
						/>

						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							loading={isVerifyAccountLoading}
						>
							Verify Account
						</LoadingButton>
					</Stack>
					<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
						<Link
							variant="subtitle2"
							underline="hover"
							onClick={handleResendVerificationLink}
							href="#"
						>
							Click here to re-send verification code.
						</Link>
					</Stack>
				</Form>
			</FormikProvider>
		</>

	);
}

const mapStateToProps = ({ Authentication }) => {
	const {
		token, error, verificationMessage, isVerifyAccountLoading, resendVerificationLink,
	} = Authentication;
	return {
		token, error, verificationMessage, isVerifyAccountLoading, resendVerificationLink,
	};
};

export default connect(mapStateToProps)(VerifyAccountForm);
