/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	recordBillPaymentRequest,
	getAllReportTitlesRequest,
	getPaymentCategoriesRequest,
	getUserByAccountNumberRequest,
} from '../../../Store';
// import { NumberFormatCustom } from '../../../components/NumberFormart';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

const writtenNumber = require('written-number');

const paymentType = [
	{
		value: 'cash',
		label: 'Cash',
	},
	{
		value: 'transfer',
		label: 'Transfer',
	},
	{
		value: 'cheque',
		label: 'Cheque',
	},
];

const NumberFormatCustom = React.forwardRef((props, inputRef) => {
	const { onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			prefix="₦"
		/>
	);
});

export default function RecordBillPaymentForm() {
	const dispatch = useDispatch();

	// const navigate = useNavigate();

	const [paymentCategories, setPaymentCategories] = React.useState([]);
	const [accountNumber, setAccountNumber] = React.useState('');
	const [accountNumber2, setAccountNumber2] = React.useState('');
	const [accountName, setAccountName] = React.useState('');

	const RecordBillPayment = useSelector((state) => state.RecordBillPayment);
	const Authentication = useSelector((state) => state.Authentication);

	React.useEffect(() => {
		if (Authentication.token) {
			dispatch(getAllReportTitlesRequest(Authentication.token));
		}
	}, [Authentication.token]);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		receipt_no: Yup.string().required('receipt number is required'),
		amount: Yup.string().required('amount is required'),
		confirm_amount: Yup.string().oneOf([Yup.ref('amount'), null], 'Amount must match'),
		// account_number: Yup.string().required('account number is required'),
		payment_category: Yup.string().required('payment category is required'),
		payment_type: Yup.string().required('payment type is required'),
		payment_date: Yup.string().required('payment date is required'),
	});

	const defaultValues = useMemo(() => ({
		receipt_no: '',
		amount: '',
		confirm_amount: '',
		payment_category: 'BILLING',
		payment_type: 'cash',
		payment_date: '',
	}), []);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
		watch,
	} = methods;

	React.useEffect(() => {
		if (Authentication?.token) {
			dispatch(getPaymentCategoriesRequest(Authentication?.token));
		}
	}, [Authentication?.token]);

	React.useEffect(() => {
		if (RecordBillPayment.getPaymentCategories) {
			setPaymentCategories([...RecordBillPayment.getPaymentCategories]);
		}
	}, [
		RecordBillPayment.getPaymentCategories,
	]);

	React.useEffect(() => {
		if (RecordBillPayment.recordBillPayment) {
			enqueueSnackbar('Record success!');
			reset();
			setAccountName('');
			setAccountName('');
			setAccountNumber2('');
			// navigate('/dashboard/billing/bill-records/all');
		}

		if (RecordBillPayment.error) {
			enqueueSnackbar(RecordBillPayment.error, {
				variant: 'error',
			});
		}
	}, [RecordBillPayment.recordBillPayment, RecordBillPayment.error]);

	React.useEffect(() => {
		if (RecordBillPayment.getUserByAccountNumber) {
			enqueueSnackbar('User Found!');
			setAccountName(RecordBillPayment.getUserByAccountNumber?.account_name);
			setAccountNumber2(RecordBillPayment.getUserByAccountNumber?.account_number);
		}
	}, [RecordBillPayment.getUserByAccountNumber]);

	const onSubmit = async (data) => {
		// eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
		const { confirm_amount, amount_in_words, ...dataRest } = data;
		const payload = {
			...dataRest,
			name: accountName,
			account_number: accountNumber,
			amount: Number(data.amount),
		};
		dispatch(recordBillPaymentRequest(payload, Authentication.token));
	};

	const getAccountDetails = () => {
		dispatch(getUserByAccountNumberRequest(Authentication.token, accountNumber));
	};

	const watchedAmount = `${writtenNumber(Number(watch('amount')))} naira only`;
	const amountToWords = watchedAmount.toLowerCase().charAt(0).toUpperCase() + watchedAmount.slice(1);

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Record Bill Payment
					</Typography>
				</Box>
			</Stack>
			<Typography gutterBottom>
				Please enter the full account number to get the desired account name.
			</Typography>
			<Stack direction="row" mb={3}>
				<TextField
					id="account-number-field"
					label="Account Number"
					shrink
					placeholder="Enter Account Number"
					disabled={RecordBillPayment.isGetUserByAccountNumberLoading}
					value={accountNumber}
					onChange={(event) => {
						setAccountNumber(event.target.value);
					}}
					sx={{ mr: 3 }}
				/>

				<LoadingButton
					onClick={getAccountDetails}
					loading={RecordBillPayment.isGetUserByAccountNumberLoading}
					variant="outlined"
					disabled={RecordBillPayment.isGetUserByAccountNumberLoading || (accountNumber.length < 7)}
				>
					Get Account Name
				</LoadingButton>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
									display: 'grid',
									columnGap: 2,
									rowGap: 3,
									gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
								}}
							>
								<RHFTextField
									name="name"
									value={accountName}
									disabled
									label="Name"
								/>
								<RHFTextField
									name="account_number"
									disabled
									value={accountNumber2}
									label="Account Number"
								/>
								<RHFTextField
									name="receipt_no"
									type="text"
									label="Receipt Number"
								/>
								<RHFSelect name="payment_type" label="Payment Type" placeholder="Payment type">
									{paymentType.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</RHFSelect>
								<RHFTextField
									type="password"
									name="amount"
									label="Amount"
									InputProps={{
										inputComponent: NumberFormatCustom,
									}}
								/>
								<RHFTextField
									name="confirm_amount"
									label="Confirm Amount"
									InputProps={{
										inputComponent: NumberFormatCustom,
									}}
								/>
								<RHFTextField
									name="amount_in_words"
									label="Amount in words"
									value={amountToWords === ('Zero naira only') ? '' : amountToWords ?? ''}
									InputLabelProps={{ shrink: true }}
									disabled
								/>
								<RHFTextField
									InputLabelProps={{ shrink: true }}
									name="payment_date"
									type="date"
									label="Payment Date"
								/>
								<RHFSelect name="payment_category" label="Payment Category" placeholder="Payment Category">
									<option value="BILLING">BILLING</option>
									{paymentCategories.map((paymentCategory, id) => (
										<option key={id} value={paymentCategory?.name}>
											{paymentCategory?.name.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								{/* <RHFTextField
									helperText="Payment Category is required. Enter a payment category if it isn't listed"
									name="payment_category"
									placeholder="(Optional) Enter category if it isn't listed"
									label="Enter a payment category if it isn't listed. Ignore otherwise."
								/> */}
							</Box>
							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								{
								Authentication?.user?.role !== 'auditor' && (
								<LoadingButton
									type="submit"
									variant="contained"
									disabled={!accountNumber || !accountName}
									loading={RecordBillPayment.isRecordBillPaymentLoading}
								>
									Record Bill Payment
								</LoadingButton>
								)
							}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
