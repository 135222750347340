/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import {
	Box, Link, Drawer, Typography, Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import NavSection from '../../components/NavSection';
import useResponsive from '../../hooks/useResponsive';
import navConfig from './NavConfig';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('lg')]: {
		flexShrink: 0,
		width: DRAWER_WIDTH,
	},
}));

const AccountStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: theme.palette.grey[500_12],
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, user }) {
	const { pathname } = useLocation();

	const isDesktop = useResponsive('up', 'lg');

	useEffect(() => {
		if (isOpenSidebar) {
			onCloseSidebar();
		}
	}, [pathname]);

	const renderContent = (
		<Box
			sx={{
				mb: 2,
				display: 'flex',
				flexDirection: 'column',
				height: 1,
				overflow: 'hidden',
				overflowY: 'scroll',
			}}
		>
			<Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }} />

			<Box sx={{ mb: 2.5, mx: 2.5 }}>
				<Link underline="none" component={RouterLink} to="#">
					<AccountStyle>
						<Avatar src="" alt="photoURL" />
						<Box sx={{ ml: 2 }}>
							<Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
								{user?.fullname}
							</Typography>
							<Typography variant="body2" sx={{ color: 'text.secondary' }}>
								{user?.role}
							</Typography>
						</Box>
					</AccountStyle>
				</Link>
			</Box>
			<NavSection navConfig={navConfig} />
			<Box sx={{ flexGrow: 1 }} />
		</Box>
	);

	return (
		<RootStyle>
			{!isDesktop && (
				<Drawer
					open={isOpenSidebar}
					onClose={onCloseSidebar}
					PaperProps={{ sx: { width: DRAWER_WIDTH } }}
				>
					{renderContent}
				</Drawer>
			)}

			{isDesktop && (
			<Drawer
				open
				variant="persistent"
				PaperProps={{
					sx: {
					width: DRAWER_WIDTH,
					bgcolor: 'background.default',
					borderRightStyle: 'dashed',
					},
				}}
			>
				{renderContent}
			</Drawer>
      )}
		</RootStyle>
	);
}

DashboardSidebar.propTypes = {
	isOpenSidebar: PropTypes.bool,
	onCloseSidebar: PropTypes.func,
};
