export type MeterReadingState = {
	getAllMeterReading: null | string;
	isGetAllMeterReadingLoading: boolean;
	getSingleMeterReading: null | string;
	isGetSingleMeterReadingLoading: boolean;
	approveMeterReading: null | string;
	isApproveMeterReadingLoading: boolean;
	getMeterReadingHistory: null | string;
	isGetMeterReadingHistoryLoading: boolean;
	error?: null | object;
}

export const initialMeterReadingState: MeterReadingState = {
	getAllMeterReading: null,
	isGetAllMeterReadingLoading: false,
	getSingleMeterReading: null,
	isGetSingleMeterReadingLoading: false,
	approveMeterReading: null,
	getMeterReadingHistory: null,
	isGetMeterReadingHistoryLoading: false,
	isApproveMeterReadingLoading: false,
};
