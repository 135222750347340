/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	Button,
	Container,
	Box,
	Card,
} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';

import { getSingleNewsRequest, deleteNewsRequest } from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Markdown from '../components/Markdown';
import Page from '../components/Page';
import { NewsEditForm } from '../sections/@dashboard/news';
import BlogPostHero from '../sections/@dashboard/news/BlogPostHero';

function NewscastSingle({
	token,
	user,
	getSingleNews,
	isGetSingleNewsLoading,
	getSingleNewsRequest,
	isDeleteNewsLoading,
	deleteNews,
	deleteNewsRequest,
	editNews,
	isEditNewsLoading,
}) {
	const [editView, setEditView] = React.useState(false);
	const [editId, setEditId] = React.useState(null);
	const [deleteId, setDeleteId] = React.useState(null);

	const { newsId } = useParams();

	const navigate = useNavigate();

	React.useEffect(() => {
		if (token) {
			getSingleNewsRequest(token, newsId);
		}
	}, [token]);

	React.useEffect(() => {
		if (editNews) {
			getSingleNewsRequest(token, newsId);
			setEditView(false);
		}
	}, [editNews]);

	const handleDelete = (id) => {
		setDeleteId(id);
		deleteNewsRequest(token, id);
	};
	const handleSetEditView = (id) => {
		setEditView(true);
		setEditId(id);
	};
	const handleCloseEditDialog = () => setEditView(false);

	if (deleteNews) {
		navigate('/dashboard/settings/news/list', { replace: true });
	}

	return (
		<Page title="User">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box />
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Button
							variant="contained"
							component={RouterLink}
							to="/dashboard/settings/news/list"
							startIcon={<Iconify icon="eva:plus-fill" />}
						>
							View All
						</Button>

						{
							((user?.role !== 'monitoring_officer') || (user?.role !== 'auditor')) && (
							<LoadingButton
								sx={{ ml: 2 }}
								type="submit"
								variant="outlined"
								color="success"
								startIcon={<Iconify icon="bxs:edit" />}
								onClick={() => handleSetEditView(newsId)}
							>
								Edit
							</LoadingButton>
							)
						}
						<LoadingButton
							sx={{ ml: 2 }}
							type="submit"
							variant="outlined"
							color="error"
							loading={isDeleteNewsLoading && newsId === deleteId}
							startIcon={<Iconify icon="ep:delete-filled" />}
							onClick={() => handleDelete(newsId)}
						>
							Delete
						</LoadingButton>
					</Stack>

				</Stack>

				<Box sx={{ p: 2 }}>
					{
						isGetSingleNewsLoading ? <Loader /> : getSingleNews ? (
							<Box>
								<Card>
									<BlogPostHero news={getSingleNews} />
									<Box sx={{ p: { xs: 3, md: 5 } }}>
										<Markdown children={getSingleNews?.content} />
									</Box>
								</Card>
								{ getSingleNews?.body}
								{
									editView && (
										<NewsEditForm
											view={editView}
											id={editId}
											details={getSingleNews}
											handleCloseEditDialog={handleCloseEditDialog}
											isEditNewsLoading={isEditNewsLoading}
										/>
									)
								}
							</Box>
                    ) : <Box />
}
				</Box>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, News }) => {
	const { token, user } = Authentication;
	const {
		isGetSingleNewsLoading,
		getSingleNews,
		isDeleteNewsLoading,
		editNews,
		deleteNews,
		isEditNewsLoading,
	} = News;

	return {
		token,
		user,
		isGetSingleNewsLoading,
		getSingleNews,
		isDeleteNewsLoading,
		deleteNews,
		editNews,
		isEditNewsLoading,
	};
};

export default connect(mapStateToProps, {
	getSingleNewsRequest,
	deleteNewsRequest,
})(NewscastSingle);
