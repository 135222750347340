/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Card,
	Grid,
	Stack,
	Typography,
	Button,
	// TextField,
} from '@mui/material';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	addNewMetersRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFSelect, RHFTextField,
} from '../../../components/hook-form';

export default function AddNewMeterForm() {
	const dispatch = useDispatch();

	const Vending = useSelector((state) => state.Vending);
	const Authentication = useSelector((state) => state.Authentication);

	// const [expiryDate, setExpiryDate] = React.useState(dayjs());

	const { enqueueSnackbar } = useSnackbar();

	// const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		decoderReferenceNumber: Yup.string().required(),
		// encryptionAlgorithm: Yup.number().required(),
		// tokenCarrierType: Yup.number().required(),
		// supplyGroupCode: Yup.number().required(),
		// keyRevisionNumber: Yup.number().required(),
		tariffIndex: Yup.number().required(),
		allowKrnUpdate: Yup.number().required(),
		supplyGroupCode: Yup.number().required(),
		// keyExpiryNumber: Yup.number().required(),
	});

	const defaultValues = useMemo(
		() => ({
			decoderReferenceNumber: '',
			// encryptionAlgorithm: '',
			// tokenCarrierType: '',
			// supplyGroupCode: '',
			// keyRevisionNumber: '',
			tariffIndex: '',
			allowKrnUpdate: 0,
			supplyGroupCode: '',
			// keyExpiryNumber: '',
			// dateOfExpiry: new Date(),
			// allowKenUpdate: 0,
			// allow3Kct: 0,
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Vending.addNewMeter) {
			reset();
			enqueueSnackbar('Meter added!');
		}

		if (Vending.error) {
			enqueueSnackbar(Vending.error, {
				variant: 'error',
			});
		}
	}, [Vending.addNewMeter, Vending.error]);

	const onSubmit = async (data) => {
		const addMeterData = {
			...data,
			// dateOfExpiry: Number(dayjs(expiryDate).format('MMYY')),
		};
		// console.log({ addMeterData });
		dispatch(addNewMetersRequest(Authentication.token, addMeterData));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Add Meters
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/prepaid/vending/all-meters"
					startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
				>
					View Meters
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
								display: 'grid',
								columnGap: 2,
								rowGap: 3,
								gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
							}}
							>
								<RHFTextField
									name="decoderReferenceNumber"
									label="Decoder Reference Number"
								/>
								{/* <RHFTextField
									name="encryptionAlgorithm"
									label="Encryption Algorithm"
								/>
								<RHFTextField
									name="tokenCarrierType"
									label="Token Carrier Type"`
								/>
								<RHFTextField
									name="supplyGroupCode"
									label="Supply Group Code"
								/>
								<RHFTextField
									name="keyRevisionNumber"
									label="Key Revision Number"
								/> */}
								<RHFTextField
									name="tariffIndex"
									label="Tariff Index"
								/>
								<RHFSelect name="allowKrnUpdate" label="Allow KRN Update" placeholder="Allow KRN Update">
									<option value="1">True</option>
									<option value="0">False</option>
								</RHFSelect>
								<RHFTextField
									name="supplyGroupCode"
									label="Supply Group Code"
								/>
								{/* <RHFTextField
									name="keyExpiryNumber"
									label="Key Expiry Number"
								/> */}
								{/* <RHFTextField
									name="dateOfExpiry"
									label="Date of expiry"
									type="date"
								/> */}
								{/* <DesktopDatePicker
									disableHighlightToday
									openTo="month"
									inputFormat="MM/YY"
									views={['month', 'year']}
									label="Select Month and Year"
									value={expiryDate}
									onChange={(newValue) => setExpiryDate(newValue)}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											inputProps={{ ...params.inputProps, readOnly: true }}
											sx={{
												'& .MuiInputBase-input': {
													caretColor: 'transparent',
												},
											}}
										/>
									)}
								/>
								<RHFSelect name="allowKenUpdate" label="Allow KEN Update" placeholder="Allow KEN Update">
									<option value="1">True</option>
									<option value="0">False</option>
								</RHFSelect>
								<RHFSelect name="allow3Kct" label="Allow 3KCT Update" placeholder="Allow 3KCT Update">
									<option value="1">True</option>
									<option value="0">False</option>
								</RHFSelect> */}
							</Box>
							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								<LoadingButton
									type="submit"
									variant="contained"
									loading={Vending.isAddNewMeterLoading}
								>
									Add Meter
								</LoadingButton>
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
