/* eslint-disable react/jsx-filename-extension */
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import general from './general';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

export default function ThemeProvider({ children }) {
	const themeOptions = useMemo(
		() => ({
			palette,
			shape: { borderRadius: 8 },
			typography,
			general,
			shadows,
			customShadows,
		}),
		[],
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	);
}

ThemeProvider.propTypes = {
	children: PropTypes.node,
};
