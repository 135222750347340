/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { SubmitReportForm } from '../sections/@dashboard/report';

export default function SubmitReport() {
	return (
		<Page title="User: Create a new report">
			<Container>
				<SubmitReportForm />
			</Container>
		</Page>
	);
}
