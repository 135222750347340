/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-danger */
import {
	Container,
	Typography,
	Grid,
	Card,
	Stack,
	Box,
	Button,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import {
	useParams,
} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import {
	useDispatch,
	getSinglePaymentDetailsRequest,
	getPaymentReceiptRequest,
	clearCustomRecordBillPaymentCache,
} from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';

dayjs.extend(utc);

function SingleConsumer({
	token,
	getSinglePaymentDetails,
	isGetSinglePaymentDetailsloading,
	isGetPaymentReceiptLoading,
	getPaymentReceipt,
}) {
	const {
		billId,
	} = useParams();
	const dispatch = useDispatch();
	const componentRef = React.useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	React.useEffect(() => {
		if (token) {
			dispatch(getSinglePaymentDetailsRequest(token, billId));
			dispatch(getPaymentReceiptRequest(token, billId));
			dispatch(clearCustomRecordBillPaymentCache());
		}
	}, [token]);

	const loading = isGetSinglePaymentDetailsloading || isGetPaymentReceiptLoading;
	return (
		<Page title="Meter Reading">
			<Container maxWidth="xl">
				<Stack direction="row" alignItems="start" justifyContent="space-between" mb={2}>
					<Box>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account number:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="subtitle1" gutterBottom>
								<strong>{getSinglePaymentDetails ? getSinglePaymentDetails.account_number : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Payment Type:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="subtitle1" gutterBottom>
								<strong>{getSinglePaymentDetails ? getSinglePaymentDetails.payment_type : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Reference:
							</Typography>
							<Typography sx={{ ml: 2 }} variant="subtitle1" gutterBottom>
								<strong>{getSinglePaymentDetails ? getSinglePaymentDetails.reference : ''}</strong>
							</Typography>
						</Stack>
					</Box>
				</Stack>
				{
					loading ? <Loader /> : getSinglePaymentDetails && getPaymentReceipt ? (
						<>
							<Stack direction="row" sx={{ mb: 2 }} justifyContent="start">
								<Button onClick={handlePrint} variant="outlined" color="inherit">Print Receipt</Button>
							</Stack>
							<Stack direction="row" sx={{ mb: 2 }} justifyContent="center">
								<Box ref={componentRef}>
									<div dangerouslySetInnerHTML={{ __html: getPaymentReceipt }} />
								</Box>
							</Stack>
							<Grid container spacing={3}>
								<Grid item xs={24} sm={24} md={12}>
									<Card sx={{ p: 2 }}>
										<Box>
											<Grid container spacing={5}>
												<Grid item xs={24} sm={24} md={6}>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Amount:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{' '}
															{getSinglePaymentDetails ? getSinglePaymentDetails.amount : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Opening Balance:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.opening_balance : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Closing Balance:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.closing_balance : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Units Low:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.units_low : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Units High:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.units_high : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Billed units:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.billed_units : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Tax:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.tax : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous date:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.previousDate : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Present date:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.presentDate : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Present KVA:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.presentKva : ''}
														</Typography>
													</Stack>
												</Grid>
												<Grid item xs={24} sm={24} md={6}>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.previousLowReading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous high reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.previousHighReading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Present low reaading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.presentLowReading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Present high reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.presentHighReading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous KVA:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.previousKva : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Total:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.total : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Reading Mode
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? getSinglePaymentDetails.reading_mode : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Date Created:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? moment(getSinglePaymentDetails.createdAt).format('YYYY-MM-DD') : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Date Updated:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSinglePaymentDetails ? moment(getSinglePaymentDetails.updatedAt).format('YYYY-MM-DD') : ''}
														</Typography>
													</Stack>
												</Grid>
											</Grid>
										</Box>
									</Card>
									<Box />
								</Grid>
							</Grid>
						</>
					) : (
						<Card sx={{ pb: 2, pt: 2 }}>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography sx={{ ml: 2 }} variant="h5" gutterBottom>
									No Bill Payment Found
								</Typography>
							</Box>
						</Card>
					)
				}
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, User }) => {
	const { token } = Authentication;
	const {
		getSinglePaymentDetails,
		isGetSinglePaymentDetailsloading,
		isGetPaymentReceiptLoading,
		getPaymentReceipt,
	} = User;
	return {
		token,
		getSinglePaymentDetails,
		isGetSinglePaymentDetailsloading,
		isGetPaymentReceiptLoading,
		getPaymentReceipt,
	};
};

export default connect(mapStateToProps)(SingleConsumer);

// isGetPaymentReceiptLoading(pin):false
// getPaymentReceipt(pin):null
// isGetSinglePaymentDetailsloading(pin):false
// getSinglePaymentDetails(pin):null

// id(pin):374
// user_id(pin):24

// amount(pin):"758.50"
// (pin):"2238820.00"
// (pin):"2238061.50"
// (pin):552
// (pin):0
// (pin):552
// tax(pin):"61.50"
// previousDate(pin):"2022-07-26T16:40:13.000Z"
// presentDate(pin):"2022-10-15T12:26:55.000Z"
// presentKva(pin):0
// previousLowReading(pin):0
// previousHighReading(pin):0
// presentLowReading(pin):552
// presentHighReading(pin):0
// previousKva(pin):0
// total(pin):820
// reading_mode(pin):"UNITS"
// createdAt(pin):"2022-10-16T14:04:24.000Z"
// updatedAt(pin):"2022-10-16T14:04:24.000Z"
// deletedAt(pin):null
