/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Box,
} from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';

import { getAllTransactionRequest } from '../Store';
// import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

dayjs.extend(advancedFormat);

const TABLE_HEAD = [
	{ id: 'fullname', label: 'Full Name', alignRight: false },
	{ id: 'narration', label: 'Narration', alignRight: false },
	{ id: 'payment_type', label: 'Payment Type', alignRight: false },
	{ id: 'amount', label: 'Amount', alignRight: false },
	{ id: 'status', label: 'Status', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function TransactionsList({
	token,
	getAllTransactions,
	isGetAllTransactionsLoading,
	getAllTransactionRequest,
}) {
	const [page, setPage] = React.useState(0);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	React.useEffect(() => {
		if (token) {
			getAllTransactionRequest(token);
		}
	}, [token]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getAllTransactions?.map((n) => n.fullname);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getAllTransactions?.length ?? 0) : 0;

	const filteredTransactions = getAllTransactions && applySortFilter(getAllTransactions, getComparator(order, orderBy), filterName);

	const isTransactionsNotFound = filteredTransactions?.length === 0;

	const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

	const loading = isGetAllTransactionsLoading;

	return (
		<Page title="User">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box>
						<Typography variant="h4" gutterBottom>
							View All Transactions
						</Typography>
					</Box>
				</Stack>
				<Card sx={{ pb: 2 }}>
					<UserListToolbar
						loading={loading}
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>
					{
							loading ? <Loader />
							: getAllTransactions ? (
								<Box>
									<TableContainer sx={{ minWidth: 800 }}>
										<Table>
											<UserListHead
												order={order}
												orderBy={orderBy}
												headLabel={TABLE_HEAD}
												rowCount={getAllTransactions?.length}
												numSelected={selected.length}
												onRequestSort={handleRequestSort}
												onSelectAllClick={handleSelectAllClick}
											/>
											<TableBody>
												{
												filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
													const {
                                                        tx_ref,
														fullname,
                                                        narration,
                                                        payment_type,
                                                        amount,
                                                        status,
                                                        createdAt,
													} = row;
													const isItemSelected = selected.indexOf(fullname) !== -1;

													return (
														<TableRow
															hover
															key={tx_ref}
															tabIndex={-1}
															sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
															selected={isItemSelected}
															aria-checked={isItemSelected}
														>
															<TableCell align="left">
																<Typography variant="subtitle2" noWrap>
																	{capitalizeFirstLetter(fullname)}
																</Typography>
															</TableCell>
															<TableCell align="left">{narration}</TableCell>
															<TableCell align="left">{payment_type}</TableCell>
															<TableCell align="left">{amount}</TableCell>
															<TableCell align="left">{status}</TableCell>
															<TableCell align="left">{dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
															{/* <TableCell align="left">
																<LoadingButton
																	type="submit"
																	variant="outlined"
																	color="info"
																	component={RouterLink}
																	to={`/dashboard/admin/notification/${user}`}
																	startIcon={<Iconify icon="carbon:view-filled" />}
																>
																	View
																</LoadingButton>
															</TableCell> */}
														</TableRow>
													);
												})
											}
												{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={12} />
												</TableRow>
											)}
											</TableBody>

											{isTransactionsNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={filterName} />
													</TableCell>
												</TableRow>
											</TableBody>
										)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={getAllTransactions?.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</Box>
							) : (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography variant="h5" gutterBottom>
										No Transactions Found
									</Typography>
								</Box>
							)
						}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Transactions }) => {
	const { token } = Authentication;
	const {
		isGetAllTransactionsLoading,
		getAllTransactions,
	} = Transactions;

	return {
		token,
		isGetAllTransactionsLoading,
		getAllTransactions,
	};
};

export default connect(mapStateToProps, {
	getAllTransactionRequest,
})(TransactionsList);
