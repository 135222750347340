/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import {
	collection, setDoc, doc,
} from '@firebase/firestore';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Box,
	Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { nanoid } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { connect } from 'react-redux';
import {
	Link as RouterLink, useNavigate, useParams,
} from 'react-router-dom';

import { db } from '../Firebase';
import {
	useDispatch,
	getReportLogsRequest,
	getSingleReportRequest,
	getUsersByRoleRequest,
	getRolesRequest,
	updateReportStatusRequest,
	startChannelRequest,
	clearChatCache,
} from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const reportStatus = [
	{
		value: 'under-review',
		label: 'Under review',
	},
	{
		value: 'resolved',
		label: 'Resolved',
	},
	{
		value: 'escalated',
		label: 'Escaleted',
	},
	{
		value: 'reassigned',
		label: 'Reassigned',
	},
];

const TABLE_HEAD = [
	{ id: 'description', label: 'Description', alignRight: false },
	{ id: 'details', label: 'Details', alignRight: false },
	{ id: 'createdAt', label: 'Created', alignRight: false },
	{ id: 'updatedAt', label: 'Updated', alignRight: false },
	{ id: '' },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
				>
					<CloseIcon />
				</IconButton>
      ) : null}
		</DialogTitle>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.description.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function SingleReport({
	token,
	user,
	getReportLogs,
	isReportLogsLoading,
	getReportLogsRequest,
	getSingleReport,
	isGetSingleReportLoading,
	getSingleReportRequest,
	getRoles,
	getRolesRequest,
	getUsersByRole,
	getUsersByRoleRequest,
	isGetUsersByRoleLoading,
	updateportStatus,
	isUpdateportStatusLoading,
	startChannelRequest,
	isStartChannelLoading,
	startChannel,
	clearChatCache,
	error,
}) {
	const { id } = useParams();

	const dispatch = useDispatch();

	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	const [snapshot] = useCollection(collection(db, 'chats'));
	const chats = snapshot?.docs.map(doc => ({ id: doc.id, ...doc.data() }));
	const chatExists = chats?.find(chat => (chat.users.includes(id) && chat.users.includes(`${user?.id}`)));

	const createFirebaseChannel = async () => {
		await setDoc(doc(db, 'chats', startChannel.channel_id), { users: [id, `${user?.id}`] });
		return navigate(`/dashboard/customer-support/fault-reports/chat/channel/${id}/${startChannel.channel_id}`, { replace: true });
	};

	const handleOpenChat = async () => {
		// const channelId = getSingleReport?.user?.fullname?.replace(/\s+/g, '')?.concat('-', id);
		if (chatExists) {
			return navigate(`/dashboard/customer-support/fault-reports/chat/channel/${id}/${chatExists.id}`, { replace: true });
		}
		return startChannelRequest(token, id);
	};

	const [reportID, setReportID] = React.useState(null);
	const [value, setValues] = React.useState('under-review');
	const [role, setRole] = React.useState('');
	const [staff, setStaff] = React.useState('');
	const [reason, setReason] = React.useState('');
	const [openReportStatusModal, setOpenReportStatusModal] = React.useState(false);

	React.useEffect(() => {
		if (token) {
			getRolesRequest(token);
			getReportLogsRequest(id, token);
			getSingleReportRequest(id, token);
		}
		if (updateportStatus) {
			enqueueSnackbar('Report updated!');
			setOpenReportStatusModal(false);
		}
	}, [token, updateportStatus]);

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error, {
				variant: 'error',
			});
		}
		getRoles && setRole(getRoles[0].name);
	}, [error, getRoles]);

	React.useEffect(() => {
		role && getUsersByRoleRequest(role, token);
	}, [role]);

	React.useEffect(() => {
		getUsersByRole?.length && setStaff(getUsersByRole[0].id);
	}, [getUsersByRole]);

	React.useEffect(() => {
		if (startChannel) {
			createFirebaseChannel();
			clearChatCache();
		}
	}, [startChannel]);

	const handleUpdateReportStatus = () => {
		let payload = {
			reportId: reportID,
			status: value,
		};

		if ((value.includes('escalated')) || (value.includes('reassigned'))) {
			payload = {
				reportId: reportID,
				status: value,
				staff,
				reason,
			};
		}

		dispatch(updateReportStatusRequest(payload, token));
	};

	const handleOpenModal = (id) => {
		setReportID(Number(id));
		setOpenReportStatusModal(true);
	};

	const handleChange = (event) => setValues(event.target.value);

	const [page, setPage] = React.useState(0);

	const [order, setOrder] = React.useState('asc');

	const [selected, setSelected] = React.useState([]);

	const [orderBy, setOrderBy] = React.useState('name');

	const [filterName, setFilterName] = React.useState('');

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleRoleSelect = (event) => {
		setRole(event.target.value);
	};

	const handleStaffSelect = (event) => {
		setStaff(event.target.value);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getReportLogs?.map((n) => n.description);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getReportLogs?.length ?? 0) : 0;

	const filteredUsers = getReportLogs && applySortFilter(getReportLogs, getComparator(order, orderBy), filterName);

	const isLogNotFound = filteredUsers?.length === 0;

	const loading = isReportLogsLoading || isGetSingleReportLoading;
	return (
		<Page title="Report">
			<Container maxWidth="xl">
				<Stack direction="row" alignItems="start" justifyContent="space-between" mb={2}>
					<Box>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle2" gutterBottom>
								Report Title:
								{' '}
								<strong>{getSingleReport ? getSingleReport.title : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle2" gutterBottom>
								Report Description:
								{' '}
								<strong>{getSingleReport ? getSingleReport.description : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle2" gutterBottom>
								Comment:
								{' '}
								<strong>{getSingleReport ? getSingleReport.comment : ''}</strong>
							</Typography>
						</Stack>
					</Box>
					<Button
						variant="contained"
						component={RouterLink}
						to="/dashboard/customer-support/fault-reports/"
						startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
					>
						Go back to open fault reports
					</Button>
				</Stack>
				<Stack direction="row" justifyContent="space-between" mb={2}>
					<Box>
						{
						user?.role !== 'auditor' && (
						<>
							<LoadingButton
								variant="outlined"
								color="primary"
								sx={{ mr: 2 }}
								onClick={() => handleOpenModal(id, getSingleReport?.status)}
							>
								Update Report Status
							</LoadingButton>
							<LoadingButton
								variant="outlined"
								color="info"
								onClick={() => handleOpenChat()}
								loading={isStartChannelLoading}
							>
								Open Chat
							</LoadingButton>
						</>
						)
					}

					</Box>
					<Typography variant="subtitle1" sx={{ mb: 1 }}> Report Logs </Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{
						loading ? <Loader /> : getReportLogs ? (
							<Box>
								<UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
								<TableContainer sx={{ minWidth: 900 }}>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											rowCount={getReportLogs?.length}
											numSelected={selected.length}
											onRequestSort={handleRequestSort}
											onSelectAllClick={handleSelectAllClick}
										/>
										<TableBody>
											{
												filteredUsers
												.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
												.map((row) => {
																const {
																		id,
																		description,
																		details,
																		createdAt,
																		updatedAt,
																	} = row;
																return (
																	<TableRow
																		hover
																		key={id}
																		tabIndex={-1}
																		role="checkbox"
																	>
																		<TableCell align="left">
																			<Stack direction="row" alignItems="center" spacing={2}>
																				<Typography variant="subtitle2" noWrap>
																					{description}
																				</Typography>
																			</Stack>
																		</TableCell>
																		<TableCell align="left">{details}</TableCell>
																		<TableCell align="left">{moment(createdAt).format('D/M/YY')}</TableCell>
																		<TableCell align="left">{moment(updatedAt).format('D/M/YY')}</TableCell>
																	</TableRow>
																);
															})
											}
											{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={12} />
												</TableRow>
											)}
										</TableBody>
										{isLogNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={filterName} />
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={getReportLogs?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
								<BootstrapDialog
									onClose={() => setOpenReportStatusModal(false)}
									aria-labelledby="customized-dialog-title"
									open={openReportStatusModal}
								>
									<BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpenReportStatusModal(false)}>
										Update Report Status
									</BootstrapDialogTitle>
									<DialogContent dividers>
										<Typography gutterBottom>
											Select the status to update with and save the changes
										</Typography>
										<FormControl fullWidth sx={{ mb: 3 }}>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={value}
												defaultValue="open"
												onChange={handleChange}
											>
												{reportStatus.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
                                                    ))}
											</Select>
										</FormControl>
										{(value === 'escalated' || value === 'reassigned') && (
										<FormControl sx={{ mb: 3 }} fullWidth>
											<InputLabel id="get-roles-label">Select a role to get users</InputLabel>
											<Select
												labelId="get-roles-label"
												id="get-roles-label"
												value={role}
												defaultValue={role}
												onChange={handleRoleSelect}
												label="Select a role to get users"
											>
												{getRoles.map((option) => (
													<MenuItem key={nanoid(3)} value={option.name}>
														{option.name.replace(/[^a-zA-Z ]/g, ' ')}
													</MenuItem>
                                                    ))}
											</Select>
										</FormControl>
                                    )}
										{((value === 'escalated' || value === 'reassigned') && staff) && (
										<>
											<FormControl sx={{ mb: 3 }} fullWidth>
												{isGetUsersByRoleLoading ? <Loader /> : (
													<>
														<InputLabel id="get-staff-label">Select a staff continue</InputLabel>
														<Select
															disabled={!getUsersByRole.length}
															labelId="get-staff-label"
															id="get-staff-label"
															value={staff}
															defaultValue={staff}
															onChange={handleStaffSelect}
															label="Staff must be selected"
														>
															{getUsersByRole?.map((option) => (
																<MenuItem key={nanoid(3)} value={option.id}>
																	{option.fullname}
																</MenuItem>
                                                            ))}
														</Select>
													</>
                                                )}
											</FormControl>
											<FormControl sx={{ mb: 3 }} fullWidth>
												<TextField
													required
													id="outlined-required"
													label="Reason"
													value={reason}
													placeholder={!reason ? 'Reason must not be empty' : 'Write your reasons here'}
													onChange={event => setReason(event.target.value)}
												/>
											</FormControl>

										</>
                                    )}
									</DialogContent>
									<Stack direction="row" justifyContent="center">
										{
                                            !getUsersByRole?.length && <Typography>A Staff must be selected</Typography>
                                        }
									</Stack>
									<DialogActions>
										<LoadingButton
											disabled={isGetUsersByRoleLoading || !reason || !staff}
											type="submit"
											variant="contained"
											onClick={handleUpdateReportStatus}
											loading={isUpdateportStatusLoading}
										>
											Save changes
										</LoadingButton>
									</DialogActions>
								</BootstrapDialog>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Logs Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({
	Authentication, Control, RolesPermissions, UserManagement, Chat,
}) => {
	const { token, user } = Authentication;
	const { getUsersByRole, isGetUsersByRoleLoading } = UserManagement;
	const { getRoles } = RolesPermissions;
	const {
		getReportLogs,
		isReportLogsLoading,
		getSingleReport,
		isGetSingleReportLoading,
		updateportStatus,
		isUpdateportStatusLoading,
		error,
	} = Control;
	const {
		isStartChannelLoading,
		startChannel,
	} = Chat;
	return {
		token,
		user,
		getRoles,
		getReportLogs,
		isReportLogsLoading,
		getSingleReport,
		isGetSingleReportLoading,
		getUsersByRole,
		isGetUsersByRoleLoading,
		error,
		updateportStatus,
		isUpdateportStatusLoading,
		isStartChannelLoading,
		startChannel,
	};
};

export default connect(mapStateToProps, {
	getUsersByRoleRequest,
	getRolesRequest,
	getReportLogsRequest,
	getSingleReportRequest,
	startChannelRequest,
	clearChatCache,
})(SingleReport);
