/* eslint-disable camelcase */
import axios from '../../../../configs/Axios';

export const createConsumerAccount = async (consumerData: any, token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.post('/user/consumer-account/create', consumerData, config);

	return response.data;
};
