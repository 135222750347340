export type TariffState = {
	isGetAllTariffsLoading: boolean;
	getAllTariffs: null | object;
	isUpdateTariffLoading: boolean,
	updateTariff: null | object,
	saveUpdatedTempTariffs: any,
	error?: null | object;
}

export const initialTariffState: TariffState = {
	isGetAllTariffsLoading: false,
	getAllTariffs: null,
	isUpdateTariffLoading: false,
	saveUpdatedTempTariffs: [],
	updateTariff: null,
};
