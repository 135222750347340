import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { UserState, initialUserState } from './Types';

const UserSlice = createSlice({
	name: 'UserSlice',
	initialState: initialUserState,
	reducers: {
		setState(state: UserState, action: PayloadAction<Partial<UserState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getUserBillPaymenthistoryRequest(
			state: UserState,
			action: PayloadAction<{
				isGetUserBillPaymentHistory: Partial<UserState['isGetUserBillPaymentHistory']>,
				getUserBillPaymentHistory: Partial<UserState['getUserBillPaymentHistory']> | undefined,
			}>,
		) {
			const {
				isGetUserBillPaymentHistory,
				getUserBillPaymentHistory,
			} = action.payload;
			return {
				...state,
				isGetUserBillPaymentHistory,
				getUserBillPaymentHistory,
			};
		},
		getSinglePaymentDetailsRequest(
			state: UserState,
			action: PayloadAction<{
				isGetSinglePaymentDetailsloading: Partial<UserState['isGetSinglePaymentDetailsloading']>,
				getSinglePaymentDetails: Partial<UserState['getSinglePaymentDetails']> | undefined,
			}>,
		) {
			const {
				isGetSinglePaymentDetailsloading,
				getSinglePaymentDetails,
			} = action.payload;
			return {
				...state,
				isGetSinglePaymentDetailsloading,
				getSinglePaymentDetails,
			};
		},
		getPaymentReceiptRequest(
			state: UserState,
			action: PayloadAction<{
				isGetPaymentReceiptLoading: Partial<UserState['isGetPaymentReceiptLoading']>,
				getPaymentReceipt: Partial<UserState['getPaymentReceipt']>,
			}>,
		) {
			const {
				isGetPaymentReceiptLoading,
				getPaymentReceipt,
			} = action.payload;
			return {
				...state,
				isGetPaymentReceiptLoading,
				getPaymentReceipt,
			};
		},
		getDisconnectionListRequest(
			state: UserState,
			action: PayloadAction<{
				isGetDisconnectionListLoading: Partial<UserState['isGetDisconnectionListLoading']>,
				getDisconnectionList: Partial<UserState['getDisconnectionList']>,
			}>,
		) {
			const { isGetDisconnectionListLoading, getDisconnectionList } = action.payload;
			return {
				...state,
				isGetDisconnectionListLoading,
				getDisconnectionList,
			};
		},
		getDebtStatusRequest(
			state:UserState,
			action: PayloadAction<{
				isGetDebtStatusLoading: Partial<UserState['isGetDebtStatusLoading']>,
				getDebtStatus: Partial<UserState['getDebtStatus']>,
			}>,
		) {
			const {
				isGetDebtStatusLoading,
				getDebtStatus,
			} = action.payload;

			return {
				...state,
				isGetDebtStatusLoading,
				getDebtStatus,
			};
		},
		getSalesReportRequest(
			state:UserState,
			action: PayloadAction<{
				isGetSalesReportLoading: Partial<UserState['isGetSalesReportLoading']>,
				getSalesReport: Partial<UserState['getSalesReport']>,
			}>,
		) {
			const {
				isGetSalesReportLoading,
				getSalesReport,
			} = action.payload;

			return {
				...state,
				isGetSalesReportLoading,
				getSalesReport,
			};
		},

		getVendingSalesReportRequest(
			state: UserState,
			action: PayloadAction<{
				isGetVendingSalesReportLoading: Partial<UserState['isGetVendingSalesReportLoading']>,
				getVendingSalesReport: Partial<UserState['getVendingSalesReport']>,
			}>,
		) {
			const {
				isGetVendingSalesReportLoading,
				getVendingSalesReport,
			} = action.payload;

			return {
				...state,
				isGetVendingSalesReportLoading,
				getVendingSalesReport,
			};
		},
		printDisconnectionListRequest(
			state: UserState,
			action: PayloadAction<{
				isPrintDisconnectionListLoading: Partial<UserState['isPrintDisconnectionListLoading']>,
				printDisconnectionList: Partial<UserState['printDisconnectionList']>,
			}>,
		) {
			const { isPrintDisconnectionListLoading, printDisconnectionList } = action.payload;
			return {
				...state,
				isPrintDisconnectionListLoading,
				printDisconnectionList,
			};
		},
		clearCustomChartCache(
			state: UserState,
		) {
			return {
				...state,
				getPaymentReceipt: null,
			};
		},
	},
});

const {
	setState: _setState,
	getUserBillPaymenthistoryRequest: _getUserBillPaymenthistoryRequest,
	getSinglePaymentDetailsRequest: _getSinglePaymentDetailsRequest,
	getPaymentReceiptRequest: _getPaymentReceiptRequest,
	getDisconnectionListRequest: _getDisconnectionListRequest,
	getDebtStatusRequest: _getDebtStatusRequest,
	getSalesReportRequest: _getSalesReportRequest,
	printDisconnectionListRequest: _printDisconnectionListRequest,
	clearCustomChartCache: _clearCustomChartCache,
	getVendingSalesReportRequest: _getVendingSalesReportRequest,
} = UserSlice.actions;

export const getUserBillPaymenthistoryRequest = (
	token: any,
	userId?: string,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getUserBillPaymenthistoryRequest({
		...initialUserState,
		isGetUserBillPaymentHistory: true,
	}));
	try {
		const apiResponseData = await API.getUserBillPaymentHistory(token, userId);
		dispatch(_getUserBillPaymenthistoryRequest({
			isGetUserBillPaymentHistory: false,
			getUserBillPaymentHistory: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getUserBillPaymenthistoryRequest({
			...initialUserState,
			isGetUserBillPaymentHistory: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get user payment history. Please try again.',
			},
		));
	}
};

export const getSinglePaymentDetailsRequest = (token: any, id?: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSinglePaymentDetailsRequest({
		...initialUserState,
		isGetSinglePaymentDetailsloading: true,
	}));
	try {
		const apiResponseData = await API.getSinglePaymentDetails(token, id);
		dispatch(_getSinglePaymentDetailsRequest({
			isGetSinglePaymentDetailsloading: false,
			getSinglePaymentDetails: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getSinglePaymentDetailsRequest({
			...initialUserState,
			isGetSinglePaymentDetailsloading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get payment details. Please try again.',
			},
		));
	}
};

export const getPaymentReceiptRequest = (token: any, id: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getPaymentReceiptRequest({
		...initialUserState,
		isGetPaymentReceiptLoading: true,
		getPaymentReceipt: null,
	}));
	try {
		const apiResponseData = await API.getPaymentsReceipt(token, id);
		dispatch(_getPaymentReceiptRequest({
			isGetPaymentReceiptLoading: false,
			getPaymentReceipt: apiResponseData,
		}));
	} catch (error) {
		dispatch(_getPaymentReceiptRequest({
			...initialUserState,
			isGetPaymentReceiptLoading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get payment receipt. Please try again.',
			},
		));
	}
};
export const getDisconnectionListRequest = (token: any, district: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getDisconnectionListRequest({
		...initialUserState,
		isGetDisconnectionListLoading: true,
		getDisconnectionList: null,
	}));
	try {
		const apiResponseData = await API.getDisconnectionList(token, district);
		dispatch(_getDisconnectionListRequest({
			isGetDisconnectionListLoading: false,
			getDisconnectionList: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getDisconnectionListRequest({
			...initialUserState,
			isGetDisconnectionListLoading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get disconnection list. Please try again.',
			},
		));
	}
};

export const getDebtStatusRequest = (token: any, accountNumber: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getDebtStatusRequest({
		...initialUserState,
		isGetDebtStatusLoading: true,
		getDebtStatus: null,
	}));
	try {
		const apiResponseData = await API.getDebtStatus(token, accountNumber);
		dispatch(_getDebtStatusRequest({
			isGetDebtStatusLoading: false,
			getDebtStatus: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getDebtStatusRequest({
			...initialUserState,
			isGetDebtStatusLoading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get debt status. Please try again.',
			},
		));
	}
};
export const getSalesReportRequest = (token: any, query: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSalesReportRequest({
		...initialUserState,
		isGetSalesReportLoading: true,
		getSalesReport: null,
	}));
	try {
		const apiResponseData = await API.getSalesReport(token, query);
		dispatch(_getSalesReportRequest({
			isGetSalesReportLoading: false,
			getSalesReport: apiResponseData,
		}));
	} catch (error) {
		dispatch(_getSalesReportRequest({
			...initialUserState,
			isGetSalesReportLoading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get sales report. Please try again.',
			},
		));
	}
};

export const getVendingSalesReportRequest = (token: any, query: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getVendingSalesReportRequest({
		...initialUserState,
		isGetVendingSalesReportLoading: true,
		getVendingSalesReport: null,
	}));
	try {
		const apiResponseData = await API.getVendingSalesReport(token, query);
		dispatch(_getVendingSalesReportRequest({
			isGetVendingSalesReportLoading: false,
			getVendingSalesReport: apiResponseData,
		}));
	} catch (error) {
		dispatch(_getVendingSalesReportRequest({
			...initialUserState,
			isGetVendingSalesReportLoading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to get sales report. Please try again.',
			},
		));
	}
};

export const printDisconnectionListRequest = (token: any, district: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_printDisconnectionListRequest({
		...initialUserState,
		isPrintDisconnectionListLoading: true,
		printDisconnectionList: null,
	}));
	try {
		const apiResponseData = await API.printDisconnectionList(token, district);
		dispatch(_printDisconnectionListRequest({
			isPrintDisconnectionListLoading: false,
			printDisconnectionList: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_printDisconnectionListRequest({
			...initialUserState,
			isPrintDisconnectionListLoading: false,
		}));
		dispatch(_setState<Partial<UserState>>(
			{
				error: error.message ?? 'Unable to print disconnection list. Please try again.',
			},
		));
	}
};

export const clearReceiptCache = (): AppThunk => (dispatch: AppDispatch) => {
	dispatch(_clearCustomChartCache());
};

export const { reducer: User } = UserSlice;
