/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { RecordBillPaymentForm } from '../sections/@dashboard/bill';

export default function RecordBillPayment() {
	return (
		<Page title="Account: Record bill Payment">
			<Container>
				<RecordBillPaymentForm />
			</Container>
		</Page>
	);
}
