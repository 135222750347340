/* eslint-disable import/no-import-module-exports */
/* eslint-disable global-require */
import { configureStore, Action } from '@reduxjs/toolkit';
import {
	useDispatch as useAppDispatch,
	useSelector as useAppSelector,
} from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './Reducer';

const Store = configureStore({
	reducer: rootReducer,
});

export type AppDispatch = typeof Store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector = useAppSelector;
export const useDispatch = () => useAppDispatch();

export default Store;
