/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	updateBillRequest,
} from '../../../Store';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateBillsForm({
	view,
	id,
	details,
	handleCloseEditDialog,
	isUpdateBillLoading,
}) {
	const dispatch = useDispatch();

	const Authentication = useSelector((state) => state.Authentication);
	const Billing = useSelector((state) => state.Billing);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		account_number: Yup.string().required(),
		units: Yup.number().required(),
		prev_reading: Yup.number().required(),
		current_reading: Yup.number().required(),
		reading_mode: Yup.string().required(),
		opening_balance: Yup.number().required(),
	});

	const defaultValues = useMemo(
		() => ({
			bill_id: details?.bill_id ?? '',
			account_number: details?.accountNumber ?? '',
			units: details?.units ?? '',
			prev_reading: details?.previousLowReading ?? '',
			current_reading: details?.presentLowReading ?? '',
			reading_mode: details?.reading_mode ?? '',
			opening_balance: parseFloat(details?.bbf.replaceAll(',', '')) ?? '',
			adjustments: Number(details?.adjustments) ?? '',
		}),
		[details],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Billing.updateBill) {
			// enqueueSnackbar('Edit success!');
			reset();
		}

		if (Billing.error) {
			enqueueSnackbar(Billing.error, {
				variant: 'error',
			});
		}
	}, [Billing.updateBill, Billing.error]);

	const onSubmit = async (data) => {
		const consumerAccountData = {
			...data,
			adjustments: Number(data.adjustments),
			bill_id: id,
		};
		dispatch(updateBillRequest(Authentication.token, consumerAccountData));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Edit
					{' '}
					{details[0]?.account_name}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update the fields and click the buttons below to save or cancel your changes
					</DialogContentText>
				</DialogContent>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Card sx={{ p: 3 }}>
								<Box
									sx={{
										display: 'grid',
										columnGap: 2,
										rowGap: 3,
										gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
									}}
								>
									<RHFTextField
										disabled
										name="account_number"
										label="Account Number"
									/>
									<RHFTextField
										name="current_reading"
										type="number"
										label="Current Low Reading"
										helperText="Current Low Reading is required"
									/>
									<RHFTextField
										name="prev_reading"
										type="number"
										label="Previous Low Reading"
										helperText="Previou Low Reading is required"
									/>
									<RHFTextField
										name="units"
										type="number"
										label="Units"
										helperText="Units is required"
									/>
									<RHFSelect name="reading_mode" label="Reading Mode" placeholder="Reading Mode">
										<option value="ACTUAL">
											ACTUAL
										</option>
										<option value="ESTIMATED">
											ESTIMATED
										</option>
										<option value="DIRECT">
											DIRECT
										</option>
									</RHFSelect>
									<RHFTextField
										name="opening_balance"
										type="number"
										label="Opening Balance"
										helperText="Opening Balance is required"
									/>
									<RHFTextField
										name="adjustments"
										type="number"
										label="Adjustments"
									/>
								</Box>
								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											disabled={isUpdateBillLoading}
											loading={isUpdateBillLoading}
										>
											Edit Bill
										</LoadingButton>
									</DialogActions>
								</Stack>
							</Card>
						</Grid>
					</Grid>
				</FormProvider>
			</Box>
		</Dialog>
	);
}
