import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../API';
import { AppDispatch, AppThunk } from '../Store';
import { NewsState, initialNewsState } from './Types';

const NewsSlice = createSlice({
	name: 'NewsSlice',
	initialState: initialNewsState,
	reducers: {
		setState(state: NewsState, action: PayloadAction<Partial<NewsState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllNewsRequest(
			state: NewsState,
			action: PayloadAction<{
				isGetAllNewsLoading: Partial<NewsState['isGetAllNewsLoading']>,
				getAllNews: Partial<NewsState['getAllNews']>,
				createNews?: Partial<NewsState['createNews']> | undefined,
			}>,
		) {
			const {
				isGetAllNewsLoading,
				getAllNews,
				createNews,
			} = action.payload;
			return {
				...state,
				isGetAllNewsLoading,
				getAllNews,
				createNews,
			};
		},
		getSingleNewsRequest(
			state: NewsState,
			action: PayloadAction<{
				isGetSingleNewsLoading: Partial<NewsState['isGetSingleNewsLoading']>,
				getSingleNews: Partial<NewsState['getSingleNews']>,
			}>,
		) {
			const {
				isGetSingleNewsLoading,
				getSingleNews,
			} = action.payload;
			return {
				...state,
				isGetSingleNewsLoading,
				getSingleNews,
			};
		},
		createNewsRequest(
			state: NewsState,
			action: PayloadAction<{
				isCreateNewsLoading: Partial<NewsState['isCreateNewsLoading']>,
				createNews: Partial<NewsState['createNews']>,
				error?: Partial<NewsState['error']> | undefined,
			}>,
		) {
			const {
				isCreateNewsLoading,
				createNews,
				error,
			} = action.payload;
			return {
				...state,
				isCreateNewsLoading,
				createNews,
				error,
			};
		},
		editNewsRequest(
			state: NewsState,
			action: PayloadAction<{
				isEditNewsLoading: Partial<NewsState['isEditNewsLoading']>,
				editNews: Partial<NewsState['editNews']>,
				error?: Partial<NewsState['error']> | undefined,
			}>,
		) {
			const {
				isEditNewsLoading,
				editNews,
				error,
			} = action.payload;
			return {
				...state,
				isEditNewsLoading,
				editNews,
				error,
			};
		},
		deleteNewsRequest(
			state: NewsState,
			action: PayloadAction<{
				isDeleteNewsLoading: Partial<NewsState['isDeleteNewsLoading']>,
				getAllNews?: Partial<NewsState['getAllNews']>,
				deleteNews: Partial<NewsState['deleteNews']>,
				error?: Partial<NewsState['error']> | undefined,
			}>,
		) {
			const {
				isDeleteNewsLoading,
				getAllNews,
				deleteNews,
				error,
			} = action.payload;
			return {
				...state,
				isDeleteNewsLoading,
				getAllNews,
				deleteNews,
				error,
			};
		},
	},
});

const {
	setState: _setState,
	getAllNewsRequest: _getAllNewsRequest,
	getSingleNewsRequest: _getSingleNewsRequest,
	createNewsRequest: _createNewsRequest,
	editNewsRequest: _editNewsRequest,
	deleteNewsRequest: _deleteNewsRequest,
} = NewsSlice.actions;

export const getAllNewsRequest = (token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllNewsRequest({
		...initialNewsState,
		isGetAllNewsLoading: true,
		createNews: null,
	}));
	try {
		const apiResponseData = await API.getAllNews(token);
		dispatch(_getAllNewsRequest({
			isGetAllNewsLoading: false,
			getAllNews: apiResponseData?.data?.news,
		}));
	} catch (error) {
		dispatch(_getAllNewsRequest({
			...initialNewsState,
			isGetAllNewsLoading: false,
		}));
		dispatch(_setState<Partial<NewsState>>(
			{
				error: error.message ?? 'Unable to get all news. Please try again.',
			},
		));
	}
};

export const getSingleNewsRequest = (token: any, newsId: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSingleNewsRequest({
		...initialNewsState,
		isGetSingleNewsLoading: true,
		getSingleNews: null,
	}));
	try {
		const apiResponseData = await API.getSingleNews(token, newsId);
		dispatch(_getSingleNewsRequest({
			isGetSingleNewsLoading: false,
			getSingleNews: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getSingleNewsRequest({
			...initialNewsState,
			isGetSingleNewsLoading: false,
		}));
		dispatch(_setState<Partial<NewsState>>(
			{
				error: error.message ?? 'Unable to get news. Please try again.',
			},
		));
	}
};

export const createNewsRequest = (userData: any, token: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_createNewsRequest({
		...initialNewsState,
		isCreateNewsLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.createNews(token, userData);
		dispatch(_createNewsRequest({
			isCreateNewsLoading: false,
			createNews: apiResponseData,
		}));
	} catch (error) {
		dispatch(_createNewsRequest({
			...initialNewsState,
			isCreateNewsLoading: false,
		}));
		dispatch(_setState<Partial<NewsState>>(
			{
				error: error.message ?? 'Unable to create news. Please try again.',
			},
		));
	}
};

export const editNewsRequest = (
	token: string,
	newsId:any,
	newsData:any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_editNewsRequest({
		...initialNewsState,
		isEditNewsLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.editNews(
			token,
			newsId,
			newsData,
		);
		dispatch(_editNewsRequest({
			isEditNewsLoading: false,
			editNews: apiResponseData,
		}));
	} catch (error) {
		dispatch(_editNewsRequest({
			...initialNewsState,
			isEditNewsLoading: false,
		}));
		dispatch(_setState<Partial<NewsState>>(
			{
				error: error.message ?? 'Unable to edit news. Please try again.',
			},
		));
	}
};

export const deleteNewsRequest = (
	token: string,
	newsId: any,
	news?: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_deleteNewsRequest({
		...initialNewsState,
		getAllNews: news,
		isDeleteNewsLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.deleteNews(
			token,
			newsId,
		);
		if (news && apiResponseData) {
			dispatch(_deleteNewsRequest({
				isDeleteNewsLoading: false,
				getAllNews: news.filter((singleNews: any) => singleNews.id !== newsId),
				deleteNews: apiResponseData,
			}));
		}

		if (!news) {
			dispatch(_deleteNewsRequest({
				isDeleteNewsLoading: false,
				deleteNews: apiResponseData,
			}));
		}
	} catch (error) {
		dispatch(_deleteNewsRequest({
			...initialNewsState,
			isDeleteNewsLoading: false,
		}));
		dispatch(_setState<Partial<NewsState>>(
			{
				error: error.message ?? 'Unable to delete news. Please try again.',
			},
		));
	}
};

export const { reducer: News } = NewsSlice;
