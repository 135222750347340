/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button, TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createSubstationRequest,
	getAllFeedersRequest,
	createFeederRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

export default function NewFeederSubstationForm() {
	const dispatch = useDispatch();

	const Feeder = useSelector((state) => state.Feeder);
	const Substation = useSelector((state) => state.Substation);
	const Authentication = useSelector((state) => state.Authentication);

	const [feeders, setFeeder] = React.useState([]);

	const [feederName, setFeederName] = React.useState('');

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		name: Yup.string().required(),
		feeder_slug: Yup.string().required(),
		voltage: Yup.string().required(),
		connected_plant: Yup.string().required(),
		area: Yup.string().required(),
		capacity: Yup.number().required(),
		rating: Yup.string().required(),
		latitude: Yup.string().required(),
		longitude: Yup.string().required(),
		ownership: Yup.string().required(),
		metering_status: Yup.string().required(),
		dt_status: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			name: '',
			feeder_slug: feeders[0]?.slug ?? 'cdca',
			voltage: '',
			connected_plant: '',
			area: '',
			capacity: '',
			rating: '',
			latitude: '',
			longitude: '',
			ownership: '',
			metering_status: '',
			dt_status: '',
			reason: '',
			remarks: '',
		}),
		[feeders],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Feeder.createFeeder) {
			enqueueSnackbar('Feeder Created!');
			dispatch(getAllFeedersRequest(Authentication.token));
			setFeederName('');
		}

		if (Substation.createSubstation) {
			reset();
			enqueueSnackbar('Substation Created!');
			setFeederName('');
		}

		if (Feeder.error) {
			enqueueSnackbar(Feeder.error, {
				variant: 'error',
			});
		}

		if (Substation.error) {
			enqueueSnackbar(Substation.error, {
				variant: 'error',
			});
		}
	}, [
		Feeder.createFeeder,
		Feeder.error,
		Substation.createSubstation,
		Substation.error,
	]);

	React.useEffect(() => {
		if (Authentication.token) {
			dispatch(getAllFeedersRequest(Authentication.token));
		}
	}, [Authentication.token]);

	React.useEffect(() => {
		if (Feeder.getAllFeeders) {
			setFeeder([...Feeder.getAllFeeders]);
		}
	}, [
		Feeder.getAllFeeders,
	]);

	const onSubmit = async (data) => {
		const payload = {
			...data,
		};
		dispatch(createSubstationRequest(Authentication.token, payload));
	};

	const handlecreateFeederRequest = () => {
		const payload = {
			name: feederName,
		};
		dispatch(createFeederRequest(Authentication.token, payload));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create Feeders and Substations
					</Typography>
				</Box>
				<Box>
					<Button
						variant="contained"
						component={RouterLink}
						to="/dashboard/accounts/meter/feeders-list"
						startIcon={<Iconify icon="eva:plus-fill" />}
						sx={{ mr: 2 }}
					>
						View Feeders
					</Button>
					<Button
						variant="contained"
						component={RouterLink}
						to="/dashboard/accounts/meter/substations-list"
						startIcon={<Iconify icon="eva:plus-fill" />}
					>
						View Substations
					</Button>
				</Box>
			</Stack>
			<Grid container spacing={3}>
				<Grid item md={12}>
					<Box>
						<Typography variant="h6" gutterBottom>
							Create Feeder
						</Typography>
					</Box>
					<Stack direction="row" alignItems="center">
						<TextField
							label="Feeder Name"
							shrink
							disabled={Feeder.isCreateFeederLoading}
							placeholder="Enter Feeder"
							value={feederName}
							onChange={(event) => {
								setFeederName(event.target.value);
							}}
							sx={{ mr: 2, width: '20rem' }}
						/>
						{
							Authentication?.user?.role !== 'auditor' && (
								<Box>
									<LoadingButton
										type="submit"
										variant="contained"
										color="primary"
										loading={Feeder.isCreateFeederLoading}
										disabled={!feederName?.length || Feeder.isCreateFeederLoading}
										onClick={handlecreateFeederRequest}
									>
										Create Feeder
									</LoadingButton>
								</Box>
							)
						}
					</Stack>
				</Grid>
				<Grid item md={12}>
					<Box>
						<Typography variant="h6" gutterBottom>
							Create Substations
						</Typography>
					</Box>
					<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12}>
								<Card sx={{ p: 3 }}>
									<Box
										sx={{
											display: 'grid',
											columnGap: 2,
											rowGap: 3,
											gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
										}}
									>
										<RHFTextField
											name="name"
											label="Name"
										/>
										<RHFSelect name="feeder_slug" label="Feeder" placeholder="Feeder" helperText="Feeder is required">
											<option value="">{' '}</option>
											{feeders?.map((option, id) => (
												<option key={id} value={option?.slug}>
													{option?.name}
												</option>
											))}
										</RHFSelect>
										<RHFTextField
											name="voltage"
											label="Voltage"
										/>
										<RHFTextField
											helperText="Connected plant is required"
											name="connected_plant"
											label="Connected plant"
										/>
										<RHFTextField
											name="area"
											label="Area"
										/>
										<RHFTextField
											name="capacity"
											type="number"
											label="Capacity"
										/>
										<RHFTextField
											name="rating"
											label="Rating"
										/>
										<RHFTextField
											name="latitude"
											label="Latitude"
										/>
										<RHFTextField
											name="longitude"
											label="Longitude"
										/>
										<RHFTextField
											name="ownership"
											label="Ownership"
										/>
										<RHFTextField
											helperText="Metering status is required"
											name="metering_status"
											label="Metering status"
										/>
										<RHFTextField
											helperText="DT status is required"
											name="dt_status"
											label="DT status"
										/>
										<RHFTextField
											name="reason"
											label="Reason"
										/>
										<RHFTextField
											name="remarks"
											label="Remarks"
										/>
									</Box>
									<Stack alignItems="flex-end" sx={{ mt: 3 }}>
										{
										Authentication?.user?.role !== 'auditor' && (
											<LoadingButton
												type="submit"
												variant="contained"
												loading={Substation.isCreateSubstationLoading}
											>
												Create Substation
											</LoadingButton>
										)
									}
									</Stack>
								</Card>
							</Grid>
						</Grid>
					</FormProvider>
				</Grid>
			</Grid>
		</Box>
	);
}
