/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	updateReconciliationRequest,
} from '../../../Store';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

const reconciliationType = [
	'percentage',
	'fee',
];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ReconciliationForm({
	view,
	details,
	handleCloseEditDialog,
	isReconciliationUpdating,
}) {
	const dispatch = useDispatch();

	const ConsumerManagement = useSelector((state) => state.ConsumerManagement);
	const Authentication = useSelector((state) => state.Authentication);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		reconciliation_percentage: Yup.number().required(),
		reconciliation_fee: Yup.number().required(),
		reconciliation_type: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			reconciliation_percentage: details?.reconciliation_percentage ?? '',
			reconciliation_fee: details?.reconciliation_fee ?? '',
			reconciliation_type: details?.reconciliation_type ?? 'percentage',
		}),
		[details],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (ConsumerManagement.updateReconciliation) {
			reset();
			enqueueSnackbar('Success!');
		}

		if (ConsumerManagement.error) {
			enqueueSnackbar(ConsumerManagement.error, {
				variant: 'error',
			});
		}
	}, [ConsumerManagement.updateReconciliation, ConsumerManagement.error]);

	const onSubmit = async (data) => {
		const consumerAccountData = {
			...data,
			account_number: details?.account_number,
		};
		dispatch(updateReconciliationRequest(Authentication.token, consumerAccountData));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Update
					Reconciliation for
					{' '}
					{details?.account_name}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update the fields and click the buttons below to save or cancel your changes
					</DialogContentText>
				</DialogContent>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Card sx={{ p: 3 }}>
								<Box
									sx={{
										display: 'grid',
										columnGap: 2,
										rowGap: 3,
										gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
									}}
								>
									<RHFTextField
										name="reconciliation_percentage"
										type="number"
										label="Reconciliation Percentage"
										helperText="Reconciliation Percentage is required"
									/>
									<RHFTextField
										name="reconciliation_fee"
										type="number"
										label="Reconciliation Fee"
										helperText="Reconciliation Fee is required"
									/>
									<RHFSelect name="reconciliation_type" label="Reconciliation Type" placeholder="Reconciliation Type">
										{reconciliationType.map((reconciliationTypeValue) => (
											<option key={reconciliationTypeValue} value={reconciliationTypeValue}>
												{reconciliationTypeValue.replace(/[^a-zA-Z ]/g, ' ')}
											</option>
													))}
									</RHFSelect>
								</Box>
								<Stack alignItems="flex-end" sx={{ mt: 3 }}>
									<DialogActions>
										<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											disabled={isReconciliationUpdating}
											loading={isReconciliationUpdating}
										>
											Update Reconciliation
										</LoadingButton>
									</DialogActions>
								</Stack>
							</Card>
						</Grid>
					</Grid>
				</FormProvider>
			</Box>
		</Dialog>
	);
}
