/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../API';
import history from '../../history';
import { getTokenRequest } from '../Chat';
import { AppDispatch, AppThunk } from '../Store';
import { messageToDisplay } from '../functions';
import { AuthenticationState, initialAuthenticationState } from './Types';

const AuthenticationSlice = createSlice({
	name: 'AuthenticationSlice',
	initialState: initialAuthenticationState,
	reducers: {
		setState(state: AuthenticationState, action: PayloadAction<Partial<AuthenticationState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		login(
			state: AuthenticationState,
			action: PayloadAction<{
				isLoginLoading: Partial<AuthenticationState['isLoginLoading']>,
				verificationMessage: Partial<AuthenticationState['verificationMessage']>,
				isAuthenticated: Partial<AuthenticationState['isAuthenticated']>,
				token: Partial<AuthenticationState['token']>,
				user: Partial<AuthenticationState['user']>,
			}>,
		) {
			const {
				isLoginLoading, verificationMessage, isAuthenticated, token, user,
			} = action.payload;
			return {
				...state,
				isLoginLoading,
				verificationMessage,
				isAuthenticated,
				token,
				user,
			};
		},
		loadUser(
			state: AuthenticationState,
			action: PayloadAction<{
				token: Partial<AuthenticationState['token']>,
				user: Partial<AuthenticationState['user']>,
			}>,
		) {
			const { token, user } = action.payload;
			return {
				...state,
				token,
				user,
			};
		},
		register(
			state: AuthenticationState,
			action: PayloadAction<{
				isRegisterLoading: Partial<AuthenticationState['isRegisterLoading']>,
				isRegistered: Partial<AuthenticationState['isRegistered']>,
			}>,
		) {
			const {
				isRegisterLoading, isRegistered,
			} = action.payload;
			return {
				...state,
				isRegisterLoading,
				isRegistered,
			};
		},
		completeRegistration(
			state: AuthenticationState,
			action: PayloadAction<{
				isCompleteUserRegistrationLoading: Partial<AuthenticationState['isCompleteUserRegistrationLoading']>,
				completeUserRegistration: Partial<AuthenticationState['completeUserRegistration']>,
			}>,
		) {
			const {
				isCompleteUserRegistrationLoading, completeUserRegistration,
			} = action.payload;
			return {
				...state,
				isCompleteUserRegistrationLoading,
				completeUserRegistration,
			};
		},
		verifyAccount(
			state: AuthenticationState,
			action: PayloadAction<{
				isVerifyAccountLoading: Partial<AuthenticationState['isVerifyAccountLoading']>,
				verifyAccount: Partial<AuthenticationState['verifyAccount']>,
				user: Partial<AuthenticationState['user']>,
				token: Partial<AuthenticationState['token']>,
			}>,
		) {
			const {
				isVerifyAccountLoading,
				verifyAccount,
				user,
				token,
			} = action.payload;
			return {
				...state,
				isVerifyAccountLoading,
				verifyAccount,
				user,
				token,
			};
		},
		resendVerificationLink(
			state: AuthenticationState,
			action: PayloadAction<{
				isResendVerificationLinkLoading: Partial<AuthenticationState['isResendVerificationLinkLoading']>,
				resendVerificationLink: Partial<AuthenticationState['resendVerificationLink']>,
			}>,
		) {
			const {
				isResendVerificationLinkLoading,
				resendVerificationLink,
			} = action.payload;
			return {
				...state,
				isResendVerificationLinkLoading,
				resendVerificationLink,
			};
		},
		passwordResetRequest(
			state: AuthenticationState,
			action: PayloadAction<{
				isPasswordResetRequestLoading: Partial<AuthenticationState['isPasswordResetRequestLoading']>,
				passwordResetRequest: Partial<AuthenticationState['passwordResetRequest']>,
			}>,
		) {
			const {
				isPasswordResetRequestLoading,
				passwordResetRequest,
			} = action.payload;
			return {
				...state,
				isPasswordResetRequestLoading,
				passwordResetRequest,
			};
		},
		confirmPasswordRequestOTP(
			state: AuthenticationState,
			action: PayloadAction<{
				isConfirmPasswordRequestOTPLoading: Partial<AuthenticationState['isConfirmPasswordRequestOTPLoading']>,
				confirmPasswordRequestOTP: Partial<AuthenticationState['confirmPasswordRequestOTP']>,
			}>,
		) {
			const {
				isConfirmPasswordRequestOTPLoading,
				confirmPasswordRequestOTP,
			} = action.payload;
			return {
				...state,
				isConfirmPasswordRequestOTPLoading,
				confirmPasswordRequestOTP,
			};
		},
		resetPassword(
			state: AuthenticationState,
			action: PayloadAction<{
				isResetPasswordLoading: Partial<AuthenticationState['isResetPasswordLoading']>,
				resetPassword: Partial<AuthenticationState['resetPassword']>,
			}>,
		) {
			const {
				isResetPasswordLoading,
				resetPassword,
			} = action.payload;
			return {
				...state,
				isResetPasswordLoading,
				resetPassword,
			};
		},
		clearCache(
			state: AuthenticationState,
		) {
			return {
				...state,
				...initialAuthenticationState,
			};
		},
	},
});

const {
	setState: _setState,
	login: _login,
	loadUser: _loadUser,
	register: _register,
	completeRegistration: _completeRegistration,
	verifyAccount: _verifyAccount,
	resendVerificationLink: _resendVerificationLink,
	passwordResetRequest: _passwordResetRequest,
	confirmPasswordRequestOTP: _confirmPasswordRequestOTP,
	resetPassword: _resetPassword,
	clearCache: _clearCache,
} = AuthenticationSlice.actions;

// eslint-disable-next-line no-promise-executor-return
const delay = (ms: number) => new Promise((response) => setTimeout(response, ms));

export const logoutUser = (navigate?: any | undefined): AppThunk => async (dispatch: AppDispatch) => {
	try {
		localStorage.removeItem('nesco-pre-paid_token_expiration_date');
		localStorage.removeItem(
			'nesco-pre-paid_user',
		);
		localStorage.removeItem('nesco-prepaid-user');
		localStorage.removeItem('nesco-pre-paid_token');
		dispatch(_setState<Partial<AuthenticationState>>({ ...initialAuthenticationState }));
		if (navigate) {
			navigate('/', { replace: true });
		}
	} catch (error) {
		dispatch(_setState<Partial<AuthenticationState>>({ error }));
	}
};

export const logoutUser2 = (): AppThunk => async (dispatch: AppDispatch) => {
	try {
		localStorage.removeItem('nesco-pre-paid_token_expiration_date');
		localStorage.removeItem('nesco-prepaid-user');
		localStorage.removeItem(
			'nesco-pre-paid_user',
		);
		localStorage.removeItem('nesco-pre-paid_token');
		dispatch(_setState<Partial<AuthenticationState>>({ ...initialAuthenticationState }));
		history.push('/');
	} catch (error) {
		dispatch(_setState<Partial<AuthenticationState>>({ error }));
	}
};

export const checkAuthTimeout = (expirationTime: number) => {
	const count = expirationTime * 800;
	delay(count);
	logoutUser();
};

export const checkAuthState = () => (dispatch: AppDispatch) => {
	dispatch(_setState<Partial<AuthenticationState>>(
		{
			isAuthenticated: true,
			isLoginLoading: false,
			error: null,
		},
	));
	const token: string | null = localStorage.getItem('nesco-pre-paid_token');

	if (!token) return logoutUser();

	const localStorageDate: any = localStorage.getItem('nesco-pre-paid_token_expiration_date');
	const localStorageUser: any = localStorage.getItem('nesco-pre-paid_user');
	const expirationDate: any = new Date(localStorageDate);
	const user: any = JSON.parse(localStorageUser);

	if (expirationDate <= new Date()) return logoutUser();

	dispatch(_loadUser({ token, user }));

	dispatch(getTokenRequest(token));
	checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000);

	return 0;
};

export const loginUser = (userData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_login({
		...initialAuthenticationState,
		isLoginLoading: true,
	}));
	try {
		const apiResponseData = await API.loginUser(userData);

		if (apiResponseData?.message === 'A verfication code has been re-sent to your registered mail and phone number to complete you registration, otp is valid for 4 hours') {
			dispatch(_login({
				isLoginLoading: false,
				verificationMessage: apiResponseData?.message,
				isAuthenticated: true,
				token: null,
				user: null,
			}));
			return;
		}

		const expirationDate: any = new Date(
			new Date().getTime() + apiResponseData.data.authorization.expiresIn * 1000,
		);

		localStorage.setItem(
			'nesco-pre-paid_token_expiration_date',
			expirationDate,
		);
		localStorage.setItem(
			'nesco-pre-paid_token',
			apiResponseData.data.authorization.token,
		);
		localStorage.setItem(
			'nesco-pre-paid_user',
			JSON.stringify(apiResponseData.data.user),
		);

		dispatch(_login({
			isLoginLoading: false,
			verificationMessage: null,
			isAuthenticated: true,
			token: apiResponseData.data.authorization.token,
			user: apiResponseData.data.user,
		}));
	} catch (error) {
		dispatch(_login({
			...initialAuthenticationState,
			isLoginLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to login user. Please try again.',
			},
		));
	}
};

export const registerUser = (userData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_register({
		...initialAuthenticationState,
		isRegisterLoading: true,
	}));
	try {
		const apiResponseData = await API.registerUser(userData);
		dispatch(_register({
			isRegisterLoading: false,
			isRegistered: apiResponseData.data,
		}));
	} catch (error) {
		dispatch(_register({
			...initialAuthenticationState,
			isRegisterLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to register user. Please try again.',
			},
		));
	}
};

export const completeUserRegisteration = (userData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_completeRegistration({
		...initialAuthenticationState,
		isCompleteUserRegistrationLoading: true,
	}));
	try {
		const apiResponseData = await API.completeRegistration(userData);
		dispatch(_completeRegistration({
			isCompleteUserRegistrationLoading: false,
			completeUserRegistration: apiResponseData,
		}));
	} catch (error) {
		dispatch(_completeRegistration({
			...initialAuthenticationState,
			isCompleteUserRegistrationLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: error.message ?? 'Unable to complete registration. Please try again.',
			},
		));
	}
};

export const verifyAccountRequest = (verificationCode: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_verifyAccount({
		...initialAuthenticationState,
		isVerifyAccountLoading: true,
	}));
	try {
		const apiResponseData = await API.verifyAccount(verificationCode);

		const expirationDate: any = new Date(
			new Date().getTime() + apiResponseData.data.authorization.expiresIn * 1000,
		);

		localStorage.setItem(
			'nesco-pre-paid_token_expiration_date',
			expirationDate,
		);
		localStorage.setItem(
			'nesco-pre-paid_token',
			apiResponseData.data.authorization.token,
		);
		localStorage.setItem(
			'nesco-pre-paid_user',
			JSON.stringify(apiResponseData.data.user),
		);
		localStorage.removeItem('nesco_login_details');

		dispatch(_verifyAccount({
			isVerifyAccountLoading: false,
			verifyAccount: apiResponseData.message,
			user: apiResponseData.data.user,
			token: apiResponseData.data.authorization.token,
		}));
	} catch (error) {
		dispatch(_verifyAccount({
			...initialAuthenticationState,
			isVerifyAccountLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to verify account. Please try again.',
			},
		));
	}
};

export const resendVerificationLinkRequest = (userData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_resendVerificationLink({
		...initialAuthenticationState,
		isResendVerificationLinkLoading: true,
	}));
	try {
		const apiResponseData = await API.resendVerificationLink(userData);

		dispatch(_resendVerificationLink({
			isResendVerificationLinkLoading: false,
			resendVerificationLink: apiResponseData.message,
		}));
	} catch (error) {
		dispatch(_resendVerificationLink({
			...initialAuthenticationState,
			isResendVerificationLinkLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to resend verification link. Please try again.',
			},
		));
	}
};

export const passwordResetRequest = (userData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_passwordResetRequest({
		...initialAuthenticationState,
		isPasswordResetRequestLoading: true,
	}));
	try {
		const apiResponseData = await API.passwordResetRequest(userData);
		dispatch(_passwordResetRequest({
			isPasswordResetRequestLoading: false,
			passwordResetRequest: apiResponseData,
		}));
	} catch (error) {
		dispatch(_passwordResetRequest({
			...initialAuthenticationState,
			isPasswordResetRequestLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: error?.response?.data?.message
				?? error?.message ?? 'Unable to resend verification link. Please try again.',
			},
		));
	}
};

export const confirmPasswordRequestOTP = (code: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_confirmPasswordRequestOTP({
		...initialAuthenticationState,
		isConfirmPasswordRequestOTPLoading: true,
	}));
	try {
		const apiResponseData = await API.confirmPasswordRequestOTP(code);
		dispatch(_confirmPasswordRequestOTP({
			isConfirmPasswordRequestOTPLoading: false,
			confirmPasswordRequestOTP: apiResponseData,
		}));
	} catch (error) {
		dispatch(_confirmPasswordRequestOTP({
			...initialAuthenticationState,
			isConfirmPasswordRequestOTPLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to resend verification link. Please try again.',
			},
		));
	}
};

export const resetPassword = (userData: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_resetPassword({
		...initialAuthenticationState,
		isResetPasswordLoading: true,
	}));
	try {
		const apiResponseData = await API.resetPassword(userData);
		dispatch(_resetPassword({
			isResetPasswordLoading: false,
			resetPassword: apiResponseData,
		}));
	} catch (error) {
		dispatch(_resetPassword({
			...initialAuthenticationState,
			isResetPasswordLoading: false,
		}));
		dispatch(_setState<Partial<AuthenticationState>>(
			{
				error: error?.response?.data?.message
				?? error.message ?? 'Unable to resend verification link. Please try again.',
			},
		));
	}
};

export const clearCache = (): AppThunk => async (dispatch: AppDispatch) => dispatch(_clearCache());

export const { reducer: Authentication } = AuthenticationSlice;
