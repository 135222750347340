/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { RoleForm } from '../sections/@dashboard/roles';

export default function BroadcastCreate() {
	return (
		<Page title="Broadcast: Create a new role">
			<Container>
				<RoleForm />
			</Container>
		</Page>
	);
}
