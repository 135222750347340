/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const issueTamperCodeToken = async (token: any, meterNumber: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.get(`/vending/meter/issue-tamper/${meterNumber}`, config);
	return response.data;
};
