/* eslint-disable react/jsx-filename-extension */
import { Container } from '@mui/material';

import Page from '../components/Page';
import { NewsNewForm } from '../sections/@dashboard/news';

export default function NewsCreate() {
	return (
		<Page title="News: Create news">
			<Container>
				<NewsNewForm />
			</Container>
		</Page>
	);
}
