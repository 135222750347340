/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';

export default function FormProvider({ children, onSubmit, methods }) {
	return (
		<Form {...methods}>
			<form onSubmit={onSubmit}>{children}</form>
		</Form>
	);
}

FormProvider.propTypes = {
	children: PropTypes.node.isRequired,
	methods: PropTypes.object.isRequired,
	onSubmit: PropTypes.func,
};
