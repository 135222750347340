export type ControlState = {
	createReportTitle: null | object;
	isCreateReportTitleLoading: boolean;
	getReportTitles: null | object;
	isGetReportTitlesLoading: boolean;
	deleteReportTitle: null | object;
	isDeleteReportTitleLoading: boolean;
	getOpenFaultReport: null | string;
	isgetOpenFaultReportLoading: boolean;
	updateportStatus: null | string;
	isUpdateportStatusLoading: boolean;
	getReportLogs: null | string;
	isGetReportLogsLoading: boolean;
	getSingleReport: null | string;
	isGetSingleReportLoading: boolean;
	searchConsumerByAccountNumber: null | object;
	isSearchConsumerByAccountNumber: boolean;
	error?: null | object;
}

export const initialControlState: ControlState = {
	createReportTitle: null,
	isCreateReportTitleLoading: false,
	getReportTitles: null,
	isGetReportTitlesLoading: false,
	deleteReportTitle: null,
	isDeleteReportTitleLoading: false,
	getOpenFaultReport: null,
	isgetOpenFaultReportLoading: false,
	updateportStatus: null,
	isUpdateportStatusLoading: false,
	getReportLogs: null,
	isGetReportLogsLoading: false,
	getSingleReport: null,
	isGetSingleReportLoading: false,
	searchConsumerByAccountNumber: [{ id: 0, label: '' }],
	isSearchConsumerByAccountNumber: false,
	error: null,
};
