/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getTokenPurchaseHistory = async (token: any, date?: any, meterNumber?: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (date) {
		if (!date.endDateValue) {
			const response = await axios.get(
				// eslint-disable-next-line max-len
				`/vending/meter/token/history?startDate=${date.startDateValue}&page=1&pageSize=15000`,
				config,
			);
			return response.data;
		}

		const response = await axios.get(
			// eslint-disable-next-line max-len
			`/vending/meter/token/history?startDate=${date.startDateValue}&endDate=${date.endDateValue}&page=1&pageSize=15000`,
			config,
		);
		return response.data;
	}

	if (meterNumber.length) {
		const response = await axios.get(
			`/vending/meter/token/history?meter_number=${meterNumber}&page=1&pageSize=15000`,
			config,
		);
		return response.data;
	}

	const response = await axios.get('/vending/meter/token/history?page=1&pageSize=15000', config);
	return response.data;
};
