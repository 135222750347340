/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const searchConsumerByAccountNumber = async (token: any, accountNumber: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const NES_URL = `/user/consumer-account/list?account_number=${accountNumber}`;

	const response = await axios.get(NES_URL, config);
	return response.data;
};
