/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	Button,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Box,
} from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { filter } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { getAllReportTitlesRequest, deleteReportTitleRequest } from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
// import { BroadcastEditForm } from '../sections/@dashboard/broadcast';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

dayjs.extend(advancedFormat);

const TABLE_HEAD = [
	{ id: 'title', label: 'Title', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
	{ id: 'updatedAt', label: 'Date Updated', alignRight: false },
	// { id: 'edit', label: '', alignRight: false },
	{ id: 'delete', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function ReportTitleList({
	token,
	user,
	getReportTitles,
	isGetReportTitlesLoading,
	getAllReportTitlesRequest,
	isDeleteReportTitleLoading,
	deleteReportTitleRequest,
	// editBroadcast,
	// isEditBroadcastLoading,
}) {
	const [page, setPage] = React.useState(0);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [filterName, setFilterName] = React.useState('');
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	// const [editView, setEditView] = React.useState(false);
	// const [editDetails, setEditDetails] = React.useState([]);
	// const [editId, setEditId] = React.useState(null);
	const [deleteId, setDeleteId] = React.useState(null);

	React.useEffect(() => {
		if (token) {
			getAllReportTitlesRequest(token);
		}
	}, [token]);

	// React.useEffect(() => {
	// 	if (editBroadcast) {
	// 		setEditView(false);
	// 		setEditDetails({});
	// 		setEditId(null);
	// 		getAllReportTitlesRequest(token);
	// 	}
	// }, [editBroadcast]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getReportTitles?.map((n) => n.title);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getReportTitles?.length ?? 0) : 0;

	const filteredBroadcast = getReportTitles && applySortFilter(getReportTitles, getComparator(order, orderBy), filterName);

	const isReportTitleNotFound = filteredBroadcast?.length === 0;

	const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

	const loading = isGetReportTitlesLoading;

	const handleDelete = (id) => {
		setDeleteId(id);
		deleteReportTitleRequest(token, id, getReportTitles);
	};
	// const handleSetEditView = (id) => {
	// 	setEditView(true);
	// 	setEditDetails(getReportTitles.filter(broadcast => broadcast.id === id));
	// 	setEditId(id);
	// };
	// const handleCloseEditDialog = () => setEditView(false);

	return (
		<Page title="User">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box>
						<Typography variant="h4" gutterBottom>
							View All Report Titles
						</Typography>
					</Box>
					<Button
						variant="contained"
						component={RouterLink}
						to="/dashboard/customer-support/report-title/create"
						startIcon={<Iconify icon="eva:plus-fill" />}
					>
						Add New Report Title
					</Button>
				</Stack>
				<Card sx={{ pb: 2 }}>
					<UserListToolbar
						loading={loading}
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>
					{
							loading ? <Loader />
							: getReportTitles ? (
								<Box>
									<TableContainer sx={{ minWidth: 800 }}>
										<Table>
											<UserListHead
												order={order}
												orderBy={orderBy}
												headLabel={TABLE_HEAD}
												rowCount={getReportTitles?.length}
												numSelected={selected.length}
												onRequestSort={handleRequestSort}
												onSelectAllClick={handleSelectAllClick}
											/>
											<TableBody>
												{
													filteredBroadcast.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
														const {
															id,
															title,
															createdAt,
															updatedAt,
														} = row;
														const isItemSelected = selected.indexOf(title) !== -1;

														return (
															<TableRow
																hover
																key={id}
																tabIndex={-1}
																sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																selected={isItemSelected}
																aria-checked={isItemSelected}
															>
																<TableCell align="left">
																	<Typography variant="subtitle2" noWrap>
																		{capitalizeFirstLetter(title)}
																	</Typography>
																</TableCell>
																<TableCell align="left">{dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
																<TableCell align="left">{dayjs(updatedAt).format('DD/MM/YYYY')}</TableCell>
																{/* <TableCell align="left">
																	<LoadingButton
																		type="submit"
																		variant="outlined"
																		color="info"
																		component={RouterLink}
																		to={`/dashboard/admin/Control/${id}`}
																		startIcon={<Iconify icon="carbon:view-filled" />}
																	>
																		View
																	</LoadingButton>
																</TableCell>
																<TableCell align="left">
																	<LoadingButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		startIcon={<Iconify icon="bxs:edit" />}
																		onClick={() => handleSetEditView(id)}
																	>
																		Edit
																	</LoadingButton>
																</TableCell> */}
																{
																	user?.role !== 'auditor' && (
																		<TableCell align="left">
																			<LoadingButton
																				type="submit"
																				variant="outlined"
																				color="error"
																				loading={isDeleteReportTitleLoading && id === deleteId}
																				startIcon={<Iconify icon="ep:delete-filled" />}
																				onClick={() => handleDelete(id)}
																			>
																				Delete
																			</LoadingButton>
																		</TableCell>
																	)
																}
															</TableRow>
														);
												})
											}
												{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={12} />
												</TableRow>
											)}
											</TableBody>

											{isReportTitleNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
														<SearchNotFound searchQuery={filterName} />
													</TableCell>
												</TableRow>
											</TableBody>
										)}
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={getReportTitles?.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
									{/* {
										editView && (
										<BroadcastEditForm
											view={editView}
											id={editId}
											details={editDetails[0]}
											handleCloseEditDialog={handleCloseEditDialog}
											isEditBroadcastLoading={isEditBroadcastLoading}
										/>
									)
									} */}
								</Box>
							) : (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography variant="h5" gutterBottom>
										No Report Titles Found
									</Typography>
								</Box>
							)
						}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Control }) => {
	const { token, user } = Authentication;
	const {
		isGetReportTitlesLoading,
		getReportTitles,
		isDeleteReportTitleLoading,
		editBroadcast,
		isEditBroadcastLoading,
	} = Control;

	return {
		token,
		user,
		isGetReportTitlesLoading,
		getReportTitles,
		isDeleteReportTitleLoading,
		editBroadcast,
		isEditBroadcastLoading,
	};
};

export default connect(mapStateToProps, {
	getAllReportTitlesRequest,
	deleteReportTitleRequest,
})(ReportTitleList);
