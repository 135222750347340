/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */

// form

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createConsumerAccountRequest,
	getDistrictRequest,
	getMeterStatusValuesRequest,
	getAcountStatusesRequest,
	getAllFeedersRequest,
	getAllTariffsRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFSelect, RHFTextField,
} from '../../../components/hook-form';

const accountTypeValues = [
	'NES',
	'PUB',
];

const meterTypeValues = [
	'POSTPAID',
	'PREPAID',
];

export default function CreateConsumerForm() {
	const dispatch = useDispatch();

	const [district, setDistrict] = React.useState([]);
	const [substations, setSubstation] = React.useState([]);
	const [accountStatuses, setAccountStatuses] = React.useState([]);
	const [tariffs, setTariff] = React.useState([]);
	const [feeders, setFeeder] = React.useState([]);
	const [meterValues, setMeterStatusValues] = React.useState([]);

	const ConsumerManagement = useSelector((state) => state.ConsumerManagement);
	const Feeder = useSelector((state) => state.Feeder);
	const Tariff = useSelector((state) => state.Tariff);
	const Authentication = useSelector((state) => state.Authentication);

	const navigate = useNavigate();

	React.useEffect(() => {
		if (Authentication.token) {
			dispatch(getDistrictRequest(Authentication.token));
			dispatch(getMeterStatusValuesRequest(Authentication.token));
			dispatch(getAcountStatusesRequest(Authentication.token));
			dispatch(getAllFeedersRequest(Authentication.token));
			dispatch(getAllTariffsRequest(Authentication.token));
		}
	}, [Authentication.token]);

	React.useEffect(() => {
		if (ConsumerManagement.getDistricts) {
			setDistrict([...ConsumerManagement.getDistricts]);
		}
		if (ConsumerManagement.getAccountStatuses) {
			setAccountStatuses([...ConsumerManagement.getAccountStatuses]);
		}
		if (ConsumerManagement.getMeterStatusesValues) {
			setMeterStatusValues([...ConsumerManagement.getMeterStatusesValues]);
		}
		if (Feeder.getAllFeeders) {
			setFeeder([...Feeder.getAllFeeders]);
		}
		if (Tariff.getAllTariffs) {
			setTariff([...Tariff.getAllTariffs]);
		}
	}, [
		ConsumerManagement.getDistricts,
		ConsumerManagement.getAccountStatuses,
		ConsumerManagement.getMeterStatusesValues,
		Feeder.getAllFeeders,
		Tariff.getAllTariffs,
	]);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		account_name: Yup.string().required(),
		phone: Yup.string().required(),
		account_number: Yup.string().required(),
		account_type: Yup.string().required(),
		district: Yup.string().required(),
		meter_type: Yup.string().required(),
		res_address1: Yup.string().required(),
		con_address1: Yup.string().required(),
		feeder: Yup.string().required(),
		substation: Yup.string().required(),
		tariff: Yup.string().required(),
		meter_status: Yup.string().required(),
		account_status: Yup.string().required(),
		lat: Yup.number().required(),
		long: Yup.number().required(),
	});

	const defaultValues = useMemo(
		() => ({
			account_name: '',
			phone: '',
			account_number: '',
			account_type: 'NES',
			district: '',
			// account_type_number: '',
			meter_type: 'PREPAID',
			// postpaid_meter_number: '',
			prepaid_meter_number: '',
			// class: '',
			// category: '',
			res_address1: '',
			// res_address2: '',
			// res_address3: '',
			con_address1: '',
			// con_address2: '',
			// con_address3: '',
			// payments: '',
			// total_vat: '',
			tariff: '',
			feeder: '',
			substation: '',
			meter_status: '',
			account_status: '',
			lat: '',
			long: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
		// getValues,
		watch,
		// setValue,
	} = methods;

	React.useEffect(() => {
		if (ConsumerManagement.createConsumerAccount) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/accounts/consumer-management/nesco');
		}

		if (ConsumerManagement.error) {
			enqueueSnackbar(ConsumerManagement.error, {
				variant: 'error',
			});
		}
	}, [ConsumerManagement.createConsumerAccount, ConsumerManagement.error]);

	const onSubmit = async (data) => {
		const consumerAccountData = {
			...data,
		};
		dispatch(createConsumerAccountRequest(consumerAccountData, Authentication.token));
	};
	//
	// const watchDistrict = watch('district');
	const watchFeeder = watch('feeder');

	// React.useEffect(() => {
	// 	if (district) {
	// 		const foundDistrict = district.find((d => d.code === watchDistrict));
	// 		const watchedSubstation = foundDistrict?.area ? foundDistrict.area : '';
	// 		setValue('substation', watchedSubstation);
	// 	}
	// }, [watchDistrict]);

	React.useEffect(() => {
		if (feeders) {
			const foundFeeder = feeders.find((d => d.slug === watchFeeder));
			const watchedSubstations = foundFeeder?.substations ? foundFeeder.substations : [];
			setSubstation(watchedSubstations);
		}
	}, [watchFeeder]);

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create Consumer Account
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/accounts/consumer-management/nesco"
					startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
				>
					View All NESCO Consumers
				</Button>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/accounts/consumer-management/pub"
					startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
				>
					View All PUB Consumers
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
									display: 'grid',
									columnGap: 2,
									rowGap: 3,
									gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
								}}
							>
								<RHFTextField
									name="account_name"
									label="Account Name"
									helperText="Account Name is required"
								/>
								<RHFTextField
									name="phone"
									label="Phone Number"
									type="number"
									helperText="Phone Number is required"
								/>
								<RHFTextField
									name="account_number"
									label="Account Number"
									helperText="Acccount Number is required"
								/>
								<RHFSelect name="account_type" label="Account Type" placeholder="Account Type">
									{accountTypeValues.map((accountTypeValue) => (
										<option key={accountTypeValue} value={accountTypeValue}>
											{accountTypeValue.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								<RHFSelect name="district" label="District" placeholder="District" helperText="District is required">
									<option value="">{' '}</option>
									{district?.map((option, id) => (
										<option key={id} value={option?.code}>
											{option?.code.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								{/* <RHFTextField
									name="account_type_number"
									label="Account Number Type"
								/> */}
								<RHFSelect name="meter_type" label="Meter Type" placeholder="Meter Type">
									{meterTypeValues.map((meterTypeValue) => (
										<option key={meterTypeValue} value={meterTypeValue}>
											{meterTypeValue.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								{/* <RHFTextField
									name="postpaid_meter_number"
									label="Postpaid Meter Number"
								/> */}
								<RHFTextField
									name="prepaid_meter_number"
									label="Prepaid Meter Number"
								/>
								{/* <RHFTextField
									name="class"
									label="Class"
								/>
								<RHFTextField
									name="category"
									label="Category"
								/> */}
								<RHFTextField
									name="res_address1"
									label="Residential Address"
									helperText="Residential Address is required"
								/>
								{/* <RHFTextField
									name="res_address2"
									label="Residential Address 2"
								/>
								<RHFTextField
									name="res_address3"
									label="Residential Address 3"
								/> */}
								<RHFTextField
									name="con_address1"
									label="Connection Address"
									helperText="Connection Address is required"
								/>
								{/* <RHFTextField
									name="con_address2"
									label="Connection Address 2"
								/>
								<RHFTextField
									name="con_address3"
									label="Connection Address 3"
								/>
								<RHFTextField
									name="payments"
									label="Payments"
								/>
								<RHFTextField
									name="total_vat"
									label="Total Vat"
								/> */}
								<RHFSelect name="tariff" label="Tariff" placeholder="Tariff" helperText="Tariff is required">
									<option value="">{' '}</option>
									{tariffs?.map((option, id) => (
										<option key={id} value={option?.class}>
											{option?.class}
										</option>
									))}
								</RHFSelect>
								<RHFSelect name="feeder" label="feeder" placeholder="feeder" helperText="Feeder is required">
									<option value="">{' '}</option>
									{feeders?.map((option, id) => (
										<option key={id} value={option?.slug}>
											{option?.name}
										</option>
									))}
								</RHFSelect>
								{/* <RHFTextField
									name="tariff"
									label="Tariff"
									helperText="Tariff is required"
								/> */}
								{/* <RHFTextField
									name="feeder"
									label="feeder"
								/> */}
								{/* <RHFTextField
									name="substation"
									label="Substation"
									disabled
								/> */}

								<RHFSelect
									name="substation"
									label="Substation"
									placeholder="Select substation"
									helperText="Substation is required"
								>
									<option value="">{' '}</option>
									{substations?.map((option) => (
										<option key={option?.name} value={option?.name}>
											{option?.name.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								<RHFSelect
									name="meter_status"
									label="Meter Status"
									placeholder="Meter Status"
									helperText="Meter Status is required"
								>
									<option value="">{' '}</option>
									{meterValues?.map((option) => (
										<option key={option?.status} value={option?.status}>
											{option?.status.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								<RHFSelect name="account_status" label="Account Status" placeholder="Account Status" helperText="Acccount Status is required">
									<option value="">{' '}</option>
									{accountStatuses?.map((option) => (
										<option key={option?.status} value={option?.status}>
											{option?.status.replace(/[^a-zA-Z ]/g, ' ')}
										</option>
									))}
								</RHFSelect>
								<RHFTextField
									name="lat"
									label="Latitude"
									helperText="Latitude is required"
								/>
								<RHFTextField
									name="long"
									label="Longitude"
									helperText="Longitude is required"
								/>
							</Box>
							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								{
								Authentication?.user?.role !== 'auditor' && (
									<LoadingButton
										LoadingButton
										type="submit"
										variant="contained"
										loading={ConsumerManagement.isCreateConsumerAccountLoading}
									>
										Create Consumer Account
									</LoadingButton>
								)
							}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
