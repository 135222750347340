/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type NewsData = {
	title: string,
	body: string,
	isDraft?: boolean,
	content : string,
	cover_image : string,
	active: boolean,
	date : string,
}

export const editNews = async (token: any, newsId: string, newsData: NewsData):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.put(`/news/${newsId}`, newsData, config);
	return response.data;
};
