/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Container,
	Typography,
	Grid,
	Card,
	Stack,
	Box,
	TablePagination,
	Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import {
	useParams, Link as RouterLink,
} from 'react-router-dom';

import {
	useDispatch,
	getSingleConsumerAccountsRequest,
	getUpdateLogsRequest,
} from '../Store';
import Iconify from '../components/Iconify';
// import Label from '../components/Label';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import { EditConsumerForm, EditConsumerProfileForm, ReconciliationForm } from '../sections/@dashboard/consumer';
import { UserListHead } from '../sections/@dashboard/user';

dayjs.extend(utc);

const TABLE_HEAD = [
	{ id: 'fields', label: 'Fields', alignRight: false },
	{ id: 'type', label: 'Type', alignRight: false },
	{ id: 'description', label: 'Description', alignRight: false },
	{ id: 'details', label: 'Details', alignRight: false },
	{ id: 'createdAt', label: 'Date created', alignRight: false },
	{ id: 'updatedAt', label: 'Date updated', alignRight: false },
	{ id: 'deletedAt', label: 'Date deleted', alignRight: false },
	{ id: 'fullname', label: 'User Name', alignRight: false },
	{ id: 'email', label: 'User Email', alignRight: false },
	{ id: 'phone', label: 'User Phone Number', alignRight: false },
	{ id: 'role', label: 'User Role', alignRight: false },
];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const notPermittedRoles = 'customer_care' || 'vendor' || 'auditor' || 'meter_engineer' || 'revenue_protection' || 'monitoring_officer' || 'auditor' || 'revenue_officer' || 'area_distribution_engineer';

function SingleConsumer({
	token,
	user,
	getSingleConsumerAccounts,
	isGetSingleConsumerLoading,
	editConsumerAccount,
	isEditConsumerAccountLoading,
	isUpdateLogsLoading,
	getUpdateLogs,
	isReconciliationUpdating,
}) {
	const {
		consumerId,
	} = useParams();
	const dispatch = useDispatch();

	const [editView, setEditView] = React.useState(false);
	const [editId, setEditId] = React.useState(null);

	const [reconciliationView, setReconciliationView] = React.useState(false);
	const [reconciliationId, setReconciliationId] = React.useState(null);

	const [editProfileView, setEditProfileView] = React.useState(false);
	const [editProfileId, setEditProfileId] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState('name');
	const [page, setPage] = React.useState(0);

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	React.useEffect(() => {
		if (token) {
			dispatch(getSingleConsumerAccountsRequest(token, consumerId));
		}
	}, [token]);

	React.useEffect(() => {
		if (editConsumerAccount) {
			getSingleConsumerAccountsRequest(token, consumerId);
			setEditView(false);
			setEditProfileView(false);
		}
	}, [editConsumerAccount]);

	React.useEffect(() => {
		if (getUpdateLogs) {
			setOpen(true);
		}
	}, [getUpdateLogs]);

	const handleSetReconciliationView = (id) => {
		setReconciliationView(true);
		setReconciliationId(id);
	};

	const handleSetEditView = (id) => {
		setEditView(true);
		setEditId(id);
	};

	const handleSetEditProfileView = (id) => {
		setEditProfileView(true);
		setEditProfileId(id);
	};

	const handleCloseEditDialog = () => setEditView(false);
	const handleCloseReconciliationDialog = () => setReconciliationView(false);
	const handleCloseEditProfileDialog = () => setEditProfileView(false);

	const handleViewUpdateLogs = (accountNumber) => {
		dispatch(getUpdateLogsRequest(token, accountNumber));
	};

	const loading = isGetSingleConsumerLoading;

	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
		setOpen(false);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = getUpdateLogs?.logs?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Page title="Meter Reading">
			<Container maxWidth="xl">
				<Stack direction="row" alignItems="start" justifyContent="space-between" mb={2}>
					<Box>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Account name:
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								<strong>{getSingleConsumerAccounts ? getSingleConsumerAccounts.account_name : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Email:
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								<strong>{getSingleConsumerAccounts ? getSingleConsumerAccounts.email : ''}</strong>
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="start">
							<Typography variant="subtitle1" gutterBottom>
								Phone:
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								<strong>{getSingleConsumerAccounts ? getSingleConsumerAccounts.phone : ''}</strong>
							</Typography>
						</Stack>
					</Box>
					<Button
						variant="contained"
						component={RouterLink}
						to={getSingleConsumerAccounts?.account_type === 'PUB' ? '/dashboard/accounts/consumer-management/pub' : '/dashboard/accounts/consumer-management/nesco'}
						startIcon={<Iconify icon="akar-icons:arrow-back-thick-fill" />}
					>
						{
						getSingleConsumerAccounts?.account_type === 'PUB' ? 'Go back to PUB Consumers' : 'Go back to NESCO Consumers'
					}
					</Button>
				</Stack>
				<Stack
					direction="row"
					justifyContent="end"
					mb={2}
				>
					<LoadingButton
						sx={{ ml: 2 }}
						type="submit"
						variant="outlined"
						color="info"
						component={RouterLink}
						to={`/dashboard/accounts/consumer-management/statement/${getSingleConsumerAccounts?.account_number}`}
						startIcon={<Iconify icon="bxs:eye" />}
						onClick={() => handleSetEditView(consumerId)}
					>
						Statement
					</LoadingButton>

					{
						user?.role === notPermittedRoles ? <div /> : (
							<>
								<LoadingButton
									sx={{ ml: 2 }}
									type="submit"
									variant="outlined"
									color="inherit"
									startIcon={<Iconify icon="material-symbols:mobile-friendly-sharp" />}
									onClick={() => handleSetReconciliationView(consumerId)}
								>
									Reconciliation
								</LoadingButton>
								<LoadingButton
									sx={{ ml: 2 }}
									type="submit"
									variant="outlined"
									color="secondary"
									startIcon={<Iconify icon="bxs:edit" />}
									onClick={() => handleSetEditView(consumerId)}
								>
									Edit
								</LoadingButton>
								<LoadingButton
									sx={{ ml: 2 }}
									type="submit"
									variant="outlined"
									color="success"
									startIcon={<Iconify icon="bxs:edit" />}
									onClick={() => handleSetEditProfileView(consumerId)}
								>
									Edit Profile
								</LoadingButton>
							</>
						)
					}
				</Stack>
				{
						loading ? <Loader /> : getSingleConsumerAccounts ? (
							<Grid container spacing={3}>
								<Grid item xs={24} sm={24} md={12}>
									<Card sx={{ p: 2 }}>
										<Box>
											<Grid container spacing={5}>
												<Grid item xs={24} sm={24} md={6}>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Account Number:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{' '}
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.account_number : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Account Type:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.account_type : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															District:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.district : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Account Type Number:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.account_type_number : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Meter Type:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.meter_type : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Post Paid Meter Number:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.postpaid_meter_number : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Prepaid Meter Number:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prepaid_meter_number : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Class:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.class : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Prepaid Meter Number:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.category : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Residential Address 1:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.res_address1 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Residential Address 2:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.res_address2 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Residential Address 3:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.res_address3 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Connection Address 1:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.con_address1 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Connection Address 2:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.con_address2 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Connection Address 3:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.con_address3 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Month Count:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.month_count : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Reading Mode
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.reading_mode : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous Reading Mode:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_reading_mode : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Current High Reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.cur_high_reading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Current Low Reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.cur_low_reading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous High reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_high_reading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous Low reading:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_low_reading : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Less Units Low:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.less_units_low : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Less Units High:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.less_units_high : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Units:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.units : ''}
														</Typography>
													</Stack>
												</Grid>
												<Grid item xs={24} sm={24} md={6}>

													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Multiplying Factor:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.multiplying_factor : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Payments:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.payments : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Current Debt:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.current_debit : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Vat:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.vat : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Total Debit:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.total_current_debit : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Opening Balance:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.opening_balance : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Closing Balance:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.closing_balance : ''}
														</Typography>
													</Stack>

													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous Closing Balance:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_closing_balance : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous Closing Balance 1:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_closing_balance1 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous Closing Balance 2:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_closing_balance2 : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Previous Closing Balance 3:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.prev_closing_balance3 : ''}
														</Typography>
													</Stack>
													{/* <Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Vat:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.vat : ''}
														</Typography>
													</Stack> */}
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															ReconnectionFee:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.reconnection_fee : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Tariff:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.tariff : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Feeder:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.feeder : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Substation:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.substation : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Meter Status:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.meter_status : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Account Status:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.account_status : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Reconcilication Percentage:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.reconciliation_percentage : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Reconcilication Fee:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.reconciliation_fee : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Reconcilication Type:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{getSingleConsumerAccounts ? getSingleConsumerAccounts.reconciliation_type : ''}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Date of Account Creation:
															{' '}
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{' '}
															{dayjs.utc(getSingleConsumerAccounts.createdAt).format('YYYY-MM-DD')}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Created By:
															{' '}
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{' '}
															{getSingleConsumerAccounts?.created_by?.fullname}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Date of Account Update:
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{dayjs.utc(getSingleConsumerAccounts.updatedAt).format('YYYY-MM-DD')}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<Typography variant="subtitle1" gutterBottom>
															Updated By:
															{' '}
														</Typography>
														<Typography sx={{ ml: 2 }} variant="body1" gutterBottom>
															{' '}
															{getSingleConsumerAccounts?.updated_by?.fullname}
														</Typography>
													</Stack>
													<Stack direction="row" alignItems="start">
														<LoadingButton
															variant="outlined"
															color="secondary"
															onClick={() => handleViewUpdateLogs(getSingleConsumerAccounts?.account_number)}
															loading={isUpdateLogsLoading}
														>
															View Update Logs
														</LoadingButton>
													</Stack>
												</Grid>
											</Grid>
											<Dialog
												open={open}
												onClose={handleClose}
												aria-labelledby="alert-dialog-title"
												aria-describedby="alert-dialog-description"
												TransitionComponent={Transition}
												keepMounted
												maxWidth="xl"
											>
												<DialogTitle id="alert-dialog-title">
													Update Logs
												</DialogTitle>
												<DialogContent>
													<DialogContentText id="alert-dialog-description">
														<TableContainer component={Paper}>
															<Table>
																<UserListHead
																	order={order}
																	orderBy={orderBy}
																	headLabel={TABLE_HEAD}
																	rowCount={getUpdateLogs?.users?.length}
																	numSelected={selected.length}
																	onRequestSort={handleRequestSort}
																	onSelectAllClick={handleSelectAllClick}
																/>
																<TableBody>
																	{
																	getUpdateLogs?.logs?.map((row) => {
																			const {
																				user_id,
																				fields,
																				type,
																				description,
																				details,
																				createdAt,
																				updatedAt,
																				deletedAt,
																				user,
																			} = row;

																			return (
																				<TableRow
																					hover
																					key={user_id}
																					tabIndex={-1}
																					role="checkbox"
																				>
																					<TableCell align="left">{fields}</TableCell>
																					<TableCell align="left">{type}</TableCell>
																					<TableCell align="left">{description}</TableCell>
																					<TableCell align="left">{details}</TableCell>
																					<TableCell align="left">{createdAt && moment(createdAt).format('D/M/YY')}</TableCell>
																					<TableCell align="left">{updatedAt && moment(updatedAt).format('D/M/YY')}</TableCell>
																					<TableCell align="left">{deletedAt && moment(deletedAt).format('D/M/YY')}</TableCell>
																					<TableCell align="left">{user?.fullname}</TableCell>
																					<TableCell align="left">{user?.email}</TableCell>
																					<TableCell align="left">{user?.phone}</TableCell>
																					<TableCell align="left">{user?.role}</TableCell>
																				</TableRow>
																			);
																		})
																	}
																</TableBody>
															</Table>
														</TableContainer>
														<TablePagination
															rowsPerPageOptions={[5, 10, 25]}
															component="div"
															count={getUpdateLogs?.logs?.length}
															rowsPerPage={rowsPerPage}
															page={page}
															onPageChange={handleChangePage}
															onRowsPerPageChange={handleChangeRowsPerPage}
														/>
													</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button onClick={handleClose}>OK</Button>
												</DialogActions>
											</Dialog>
											{/* <Stack direction="row" alignItems="start">
												<Typography  variant="subtitle1" gutterBottom>
													Activa
														variant="ghost"
														<Typography  variant="subtitle1" gutterBottom>
													<Label
														variant="ghost"
														color={getSingleConsumerAccounts.isActive ? 'error' : 'success'}
													>
														{getSingleConsumerAccounts.isActive ? 'Account is Not Active' : 'Account is Active'}
													</Label>
												</Typography>
											</Stack>
											*/}
										</Box>

									</Card>
									<Box />
								</Grid>
								<Grid>
									{
										editView && (
										<EditConsumerForm
											view={editView}
											id={editId}
											details={getSingleConsumerAccounts}
											handleCloseEditDialog={handleCloseEditDialog}
											isEditConsumerAccountLoading={isEditConsumerAccountLoading}
										/>
										)
									}
									{reconciliationView && (
									<ReconciliationForm
										view={reconciliationView}
										id={reconciliationId}
										details={getSingleConsumerAccounts}
										handleCloseEditDialog={handleCloseReconciliationDialog}
										isReconciliationUpdating={isReconciliationUpdating}
									/>
									)}
									{
									editProfileView && (
										<EditConsumerProfileForm
											view={editProfileView}
											id={editProfileId}
											details={getSingleConsumerAccounts}
											handleCloseEditDialog={handleCloseEditProfileDialog}
											isEditConsumerAccountLoading={isEditConsumerAccountLoading}
										/>
									)
								}
								</Grid>
							</Grid>
						) : (
							<Card sx={{ pb: 2, pt: 2 }}>
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Typography sx={{ ml: 2 }} variant="h5" gutterBottom>
										No Consumer Found
									</Typography>
								</Box>
							</Card>
						)
					}
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, ConsumerManagement }) => {
	const { token, user } = Authentication;
	const {
		getSingleConsumerAccounts,
		isGetSingleConsumerLoading,
		editConsumerAccount,
		isEditConsumerAccountLoading,
		isUpdateLogsLoading,
		getUpdateLogs,
		isReconciliationUpdating,
	} = ConsumerManagement;
	return {
		token,
		user,
		getSingleConsumerAccounts,
		isGetSingleConsumerLoading,
		editConsumerAccount,
		isEditConsumerAccountLoading,
		isUpdateLogsLoading,
		getUpdateLogs,
		isReconciliationUpdating,
	};
};

export default connect(mapStateToProps)(SingleConsumer);

// id(pin):47404
// user_id(pin):null
// account_name(pin):"RAPSON D. STEPHEN,"
// phone(pin):null
// account_number(pin):"NADN0003"
// account_type(pin):"NES"
// district(pin):"ADN"
// account_type_number(pin):null
// meter_type(pin):"POSTPAID"
// postpaid_meter_number(pin):null
// prepaid_meter_number(pin):null
// class(pin):null
// category(pin):null
// res_address1(pin):"ANGULDI,"
// res_address2(pin):"P.M.B. 2106,"
// res_address3(pin):"JOS."
// con_address1(pin):"ANGULDI,"
// con_address2(pin):"P.M.B. 2106,"
// con_address3(pin):"JOS."
// month_count(pin):1
// reading_mode(pin):"ACTUAL"
// prev_reading_mode(pin):"ACTUAL"
// cur_high_reading(pin):0
// cur_low_reading(pin):58848
// prev_high_reading(pin):0
// prev_low_reading(pin):58848
// less_units_low(pin):0
// less_units_high(pin):0
// units(pin):0
// estimated_units(pin):null
// multiplying_factor(pin):1
// payments(pin):"0.00"
// opening_balance(pin):"37216.56"
// current_debit(pin):"0.00"
// vat(pin):"0.00"
// total_current_debit(pin):null
// closing_balance(pin):"37216.56"
// prev_closing_balance(pin):"0.00"
// prev_closing_balance1(pin):0
// prev_closing_balance2(pin):0
// prev_closing_balance3(pin):0
// reconnection_fee(pin):0
// tariff(pin):"R1"
// feeder(pin):null
// substation(pin):null
// meter_status(pin):null
// account_status(pin):"ACTIVE"
// has_updated(pin):false
// createdAt(pin):"2022-11-14T08:26:30.000Z"
// updatedAt(pin):"2022-11-14T08:26:30.000Z"
// deletedAt(pin):null

// "id": 284,
// 	"user_id": 704,
// 		"consumer_id": "NBJ0345",
// 			"fields": null,
// 				"type": "Consumer",
// 					"description": "Chukwuka Ezeaeka updated NBJ0345",
// 						"details": "reconciliation_type updated to \"percentage\"",
// 							"createdAt": "2023-11-07T16:27:13.000Z",
// 								"updatedAt": "2023-11-07T16:27:13.000Z",
// 									"deletedAt": null,
// 										"user": {
// 	"id": 704,
// 		"fullname": "Chukwuka Ezeaeka",
// 			"email": "chukwuka.ezeaka+ang@gmail.com",
// 				"phone": "08100612293",
// 					"role": "consumer",
// 						"gender": "male"
// }
