/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	createReportTitleRequest,
} from '../../../Store';
import Iconify from '../../../components/Iconify';
import {
	FormProvider, RHFTextField,
} from '../../../components/hook-form';

export default function ReportTitleNewForm() {
	const dispatch = useDispatch();

	const Control = useSelector((state) => state.Control);
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		title: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			title: '',
		}),
		[],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
	} = methods;

	React.useEffect(() => {
		if (Control.createReportTitle) {
			reset();
			enqueueSnackbar('Create success!');
			navigate('/dashboard/customer-support/report-title/all');
		}

		if (Control.error) {
			enqueueSnackbar(Control.error, {
				variant: 'error',
			});
		}
	}, [Control.createReportTitle, Control.error]);

	const onSubmit = async (data) => {
		const payload = {
			titles: [{ ...data }],
		};
		dispatch(createReportTitleRequest(payload, Authentication.token));
	};

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						Create a New Report Title
					</Typography>
				</Box>
				<Button
					variant="contained"
					component={RouterLink}
					to="/dashboard/customer-support/report-title/all"
					startIcon={<Iconify icon="eva:plus-fill" />}
				>
					View Report Titles
				</Button>
			</Stack>

			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 3 }}>
							<Box
								sx={{
									justifyContent: 'center',
                                }}
							>
								<RHFTextField
									name="title"
									label="Title"
								/>
							</Box>
							<Stack alignItems="flex-end" sx={{ mt: 3 }}>
								{
									Authentication?.user?.role !== 'auditor' && (
										<LoadingButton
											type="submit"
											variant="contained"
											loading={Control.isCreateReportTitleLoading}
										>
											Create Report Title
										</LoadingButton>
									)
								}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</FormProvider>
		</Box>
	);
}
