import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { FaultReportingState, initialFaultReportingState } from './Types';

const FaultReportingSlice = createSlice({
	name: 'FaultReportingSlice',
	initialState: initialFaultReportingState,
	reducers: {
		setState(state: FaultReportingState, action: PayloadAction<Partial<FaultReportingState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		submitFaultReportRequest(
			state: FaultReportingState,
			action: PayloadAction<{
				isSubmitFaultReportLoading: Partial<FaultReportingState['isSubmitFaultReportLoading']>,
				submitFaultReport: Partial<FaultReportingState['submitFaultReport']>,
				error?: Partial<FaultReportingState['error']> | undefined,
			}>,
		) {
			const {
				isSubmitFaultReportLoading,
				submitFaultReport,
				error,
			} = action.payload;
			return {
				...state,
				isSubmitFaultReportLoading,
				submitFaultReport,
				error,
			};
		},
		clearSubmitFaultCache(
			state: FaultReportingState,
		) {
			return {
				...state,
				submitFaultReport: null,
			};
		},
	},
});

const {
	setState: _setState,
	submitFaultReportRequest: _submitFaultReportRequest,
	clearSubmitFaultCache: _clearSubmitFaultCache,
} = FaultReportingSlice.actions;

export const submitFaultReportRequest = (
	faultReportData: any,
	token: string,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_submitFaultReportRequest({
		...initialFaultReportingState,
		isSubmitFaultReportLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.submitFaultReport(faultReportData, token);
		dispatch(_submitFaultReportRequest({
			isSubmitFaultReportLoading: false,
			submitFaultReport: apiResponseData,
		}));
	} catch (error) {
		dispatch(_submitFaultReportRequest({
			...initialFaultReportingState,
			isSubmitFaultReportLoading: false,
		}));
		dispatch(_setState<Partial<FaultReportingState>>(
			{
				error: error.message ?? 'Unable to create report title. Please try again.',
			},
		));
	}
};

export const clearSubmitFaultCacheRequest = (): AppThunk => (dispatch: AppDispatch) => {
	dispatch(_clearSubmitFaultCache());
};

export const { reducer: FaultReporting } = FaultReportingSlice;
