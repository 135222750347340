/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
// @mui
import { Grid, Container, Typography } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';

import { getAllUsersRequest, getAllMeterReadingRequest } from '../Store';
import Page from '../components/Page';
// sections
import {
	AppWidgetSummary,
} from '../sections/@dashboard/app';

function DashboardApp({
	token,
	getAllUsers,
	getAllUsersRequest,
	getAllMeterReadingRequest,
	getAllMeterReading,
}) {
	React.useEffect(() => {
		if (token) {
			getAllMeterReadingRequest(token);
			getAllUsersRequest(token);
		}
	}, [token]);

	return (
		<Page title="Dashboard">
			<Container maxWidth="xl">
				<Typography variant="h4" sx={{ mb: 5 }}>
					Hi, Welcome back
				</Typography>

				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title="All Users"
							total={getAllUsers?.length ?? 0}
							color="info"
							icon="clarity:users-solid"
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title="Active Users"
							total={getAllUsers?.filter(user => user?.isActive).length ?? 0}
							icon="clarity:users-solid-badged"
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title="Suspended Users"
							color="warning"
							total={getAllUsers?.filter(user => user?.isSuspended).length ?? 0}
							icon="clarity:users-solid-alerted"
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title="All Meter Readings"
							total={getAllMeterReading?.length ?? 0}
							color="primary"
							icon="material-symbols:view-list"
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title="All Approved Readings"
							total={getAllMeterReading?.users?.filter(reading => reading?.approved).length ?? 0}
							color="primary"
							icon="material-symbols:list-alt"
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							title="All Pending Readings"
							total={
								getAllMeterReading?.users?.filter(
									reading => reading?.approved === false,
								).length ?? 0
							}
							color="warning"
							icon="material-symbols:list-alt-outline"
						/>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, UserManagement, MeterReading }) => {
	const { token } = Authentication;
	const { isGetAllUsersLoading, getAllUsers } = UserManagement;
	const { isgetAllMeterReadingLoading, getAllMeterReading } = MeterReading;
	return {
		token,
		isGetAllUsersLoading,
		getAllUsers,
		isgetAllMeterReadingLoading,
		getAllMeterReading,
	};
};

export default connect(mapStateToProps, { getAllUsersRequest, getAllMeterReadingRequest })(DashboardApp);
