/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type NewsData = {
	title: string,
	body: string,
	isDraft?: boolean,
	content : string,
	cover_image : any,
	active: boolean,
	date : string,
}

export const createNews = async (token: string, newsData: NewsData):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.post('/news/create', newsData, config);

	return response.data;
};

// CLOUDINARY_URL=cloudinary://216351916254384:R7IYXz0wSy50SoOuvlVRgDqPXGc@dnwfbdp56

// const url = "https://api.cloudinary.com/v1_1/dnwfbdp56/image/upload";
