/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type UserData = {
	reportId: number,
	status: string
};

export const submitFaultReport = async (userData: UserData, token: string):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.post('/report/staff/create', userData, config);

	return response.data;
};
