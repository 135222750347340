/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type UserData = {
	email: string,
}
type ResendVerificationLinkResponse = {
	status: string,
	message: string,
}
export const resendVerificationLink = async (userData: UserData):
Promise<ResendVerificationLinkResponse> => {
	const response = await axios.put('/auth/resend-verification', userData);

	return response.data;
};
