/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Container,
	Typography,
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	Box,
} from '@mui/material';
import { filter } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
	getPrintPreviewsRequest,
	getDistrictRequest,
} from '../Store';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import {
	RHFSelect, FormProvider,
} from '../components/hook-form';
import { UserListHead } from '../sections/@dashboard/user';
import ComponentToPreview from './ComponentToPreview';

const TABLE_HEAD = [
	{ id: 'accountName', label: 'Account Name', alignRight: false },
	{ id: 'accountNumber', label: 'Account Number', alignRight: false },
	// { id: 'account_type', label: 'Account type', alignRight: false },
	// { id: 'district', label: 'Districts', alignRight: false },
	{ id: 'previousLowReading', label: 'Previous High Reading', alignRight: false },
	{ id: 'presentLowReading', label: 'Present High Reading', alignRight: false },
	{ id: 'units', label: 'Billed Units', alignRight: false },
	{ id: 'current_debit', label: 'Current Debit', alignRight: false },
	{ id: 'total_current_debit', label: 'Total Current Debit', alignRight: false },
	{ id: '' },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => _user.meter_number.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

function PreviewBills({
	token,
	getDistricts,
	isGetDistrictsLoading,
	viewPrintPreview,
	isViewPrintPreviewLoading,
	getPrintPreviewsRequest,
	getDistrictRequest,
}) {
	const [district, setDistrict] = React.useState([]);
	const [preview, setPreview] = React.useState(false);
	const [ID, setPreviewID] = React.useState(null);
	const [index, setIndex] = React.useState(null);

	// 	accountName(pin):"MR. JOHN DAVOU,"
	// accountNumber(pin):"NADS0001"
	// invoiceId(pin):"NADS0001-"
	// createdAt(pin):"YYYY-MM-DD"
	// previousDate(pin):"0000-00-00"
	// presentDate(pin):"0000-00-00"
	// previousLowReading(pin):17778
	// previousHighReading(pin):0
	// presentLowReading(pin):17883
	// presentHighReading(pin):0
	// tax(pin):"0.00"
	// reading_mode(pin):"ACTUAL"
	// previous_reading_mode(pin):"ACTUAL"
	// tariff(pin):"R2"
	// bbf(pin):"5621.35"
	// bcf(pin):"5621.35"
	// district(pin):"ADS"
	// meterNumber(pin):null
	// current_debit(pin):"0.00"
	// total_current_debit(pin):0
	// outstanding(pin):"5621.35"
	// ocbf(pin):"5621.35"
	// payments(pin):"0.00"
	// lowVoltAmount(pin):"0.00"
	// units(pin):105
	// multiplyingFactor(pin):1
	// billMonth(pin):"February"
	// billYear(pin):"2023"
	// address(pin):"C.T.M. NIGERIA LIMITED,"
	// total(pin):"5621.35"
	// account_type(pin):"NES"
	// period(pin):"28/02/2023"

	React.useEffect(() => {
		if (token) {
			getDistrictRequest(token);
		}
	}, [token]);

	React.useEffect(() => {
		if (getDistricts) {
			setDistrict([...getDistricts]);
		}
	}, [getDistricts]);

	const [page, setPage] = React.useState(0);

	const [order, setOrder] = React.useState('asc');

	const [selected, setSelected] = React.useState([]);

	const [orderBy, setOrderBy] = React.useState('name');

	const [filterName] = React.useState('');

	const [rowsPerPage, setRowsPerPage] = React.useState(15);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = viewPrintPreview?.bills?.map((n) => n.meter_number);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - viewPrintPreview?.bills?.length ?? 0) : 0;

	const filteredUsers = viewPrintPreview?.bills && applySortFilter(viewPrintPreview?.bills, getComparator(order, orderBy), filterName);

	const isUserNotFound = filteredUsers?.length === 0;

	const loading = isViewPrintPreviewLoading;

	const NewUserSchema = Yup.object().shape({
		district: Yup.string().required(),
	});

	const defaultValues = React.useMemo(
		() => ({
			district: district ? district[0]?.code : '',
		}),
		[getDistricts, district],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		handleSubmit,
	} = methods;

	const onSubmit = async (data) => {
		const previewData = {
			...data,
		};
		getPrintPreviewsRequest(token, previewData.district);
	};

	const handleClosePreviewDialog = () => setPreview(false);

	return (
		<Page title="Meter Reading">
			<Container maxWidth="xl">
				<Box>
					{isGetDistrictsLoading ? (
						<Typography variant="h6" gutterBottom>
							Fetching Districts...
						</Typography>
                    ) : (
	<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
		<Stack direction="row" alignItems="center">
			<Box mr={3}>
				<RHFSelect
					name="district"
					label="District"
					disabled={isGetDistrictsLoading}
					placeholder="District"
				>
					<option value="">{' '}</option>
					{district?.map((option, id) => (
						<option key={id} value={option?.code}>
							{option?.code.replace(/[^a-zA-Z ]/g, ' ')}
						</option>
                                        ))}
				</RHFSelect>
			</Box>
			<Box>
				<LoadingButton
					type="submit"
					variant="contained"
					disabled={isGetDistrictsLoading}
					loading={loading}
					color="primary"
				>
					Get Bills
				</LoadingButton>
			</Box>
		</Stack>
	</FormProvider>
                    )}
				</Box>

				<Stack direction="row" justifyContent="space-between" mt={2}>
					<Typography variant="h4" gutterBottom>
						Preview Bills
					</Typography>
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{loading ? <Loader /> : viewPrintPreview?.bills ? (
						<Box>
							<TableContainer sx={{ minWidth: 900 }}>
								<Table>
									<UserListHead
										order={order}
										orderBy={orderBy}
										headLabel={TABLE_HEAD}
										rowCount={viewPrintPreview?.bills?.length}
										numSelected={selected.length}
										onRequestSort={handleRequestSort}
										onSelectAllClick={handleSelectAllClick}
									/>
									<TableBody>
										{filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                                            const {
												bill_id,
                                                accountName,
                                                accountNumber,
                                                // account_type,
                                                // district,
                                                previousLowReading,
                                                presentLowReading,
                                                units,
												current_debit,
												total_current_debit,
                                            } = row;
                                            return (
	<TableRow
		hover
		key={idx}
		tabIndex={-1}
		role="checkbox"
	>
		<TableCell align="left">
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="subtitle2" noWrap>
					{accountName}
				</Typography>
			</Stack>
		</TableCell>
		<TableCell align="left">
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="subtitle2" noWrap>
					{accountNumber}
				</Typography>
			</Stack>
		</TableCell>
		{/* <TableCell align="left">
			<Stack direction="row" alignItems="center" spacing={2}>
				<Typography variant="subtitle2" noWrap>
					{account_type}
				</Typography>
			</Stack>
		</TableCell>
		<TableCell align="left">{district}</TableCell> */}
		<TableCell align="left">{previousLowReading}</TableCell>
		<TableCell align="left">{presentLowReading}</TableCell>
		<TableCell align="left">{units}</TableCell>
		<TableCell align="left">{current_debit}</TableCell>
		<TableCell align="left">{total_current_debit}</TableCell>
		<TableCell align="left">
			<Button
				variant="outlined"
				onClick={() => {
					setIndex(filteredUsers.findIndex(item => item.accountName === accountName));
					setPreviewID(bill_id);
                                                                setPreview(true);
                                                            }}
			>
				Preview
			</Button>
		</TableCell>
	</TableRow>
                                            );
                                        })}
										{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={12} />
										</TableRow>
                                        )}
									</TableBody>
									{isUserNotFound && (
									<TableBody>
										<TableRow>
											<TableCell align="center" colSpan={12} sx={{ py: 3 }}>
												<SearchNotFound searchQuery={filterName} />
											</TableCell>
										</TableRow>
									</TableBody>
                                    )}
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[15, 30, 50]}
								component="div"
								count={viewPrintPreview?.bills?.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
							<ComponentToPreview
								index={index}
								id={ID}
								view={preview}
								details={viewPrintPreview?.bills}
								handleClosePreviewDialog={handleClosePreviewDialog}
							/>
						</Box>
                    ) : (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Typography variant="h5" gutterBottom>
			No Bill Found
		</Typography>
	</Box>
                    )}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, ConsumerManagement, Billing }) => {
	const { token } = Authentication;
	const { getDistricts, isGetDistrictsLoading } = ConsumerManagement;
	const {
		viewPrintPreview,
		isViewPrintPreviewLoading,
	} = Billing;
	return {
		token,
		getDistricts,
		isGetDistrictsLoading,
		viewPrintPreview,
		isViewPrintPreviewLoading,
	};
};

export default connect(mapStateToProps, {
	getPrintPreviewsRequest,
	getDistrictRequest,
})(PreviewBills);
