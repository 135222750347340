/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { MeterReadingState, initialMeterReadingState } from './Types';

const MeterReadingSlice = createSlice({
	name: 'MeterReadingSlice',
	initialState: initialMeterReadingState,
	reducers: {
		setState(state: MeterReadingState, action: PayloadAction<Partial<MeterReadingState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		getAllMeterReadingRequest(
			state: MeterReadingState,
			action: PayloadAction<{
				isGetAllMeterReadingLoading: Partial<MeterReadingState['isGetAllMeterReadingLoading']>,
				getAllMeterReading: Partial<MeterReadingState['getAllMeterReading']>,
			}>,
		) {
			const {
				isGetAllMeterReadingLoading,
				getAllMeterReading,
			} = action.payload;
			return {
				...state,
				isGetAllMeterReadingLoading,
				getAllMeterReading,

			};
		},

		approveMeterReadingRequest(
			state: MeterReadingState,
			action: PayloadAction<{
				isApproveMeterReadingLoading: Partial<MeterReadingState['isApproveMeterReadingLoading']>,
				approveMeterReading: Partial<MeterReadingState['approveMeterReading']>,
			}>,
		) {
			const {
				isApproveMeterReadingLoading,
				approveMeterReading,
			} = action.payload;
			return {
				...state,
				isApproveMeterReadingLoading,
				approveMeterReading,

			};
		},

		getSingleMeterReadingRequest(
			state: MeterReadingState,
			action: PayloadAction<{
				isGetSingleMeterReadingLoading: Partial<MeterReadingState['isGetSingleMeterReadingLoading']>,
				getSingleMeterReading: Partial<MeterReadingState['getSingleMeterReading']>,
			}>,
		) {
			const {
				isGetSingleMeterReadingLoading,
				getSingleMeterReading,
			} = action.payload;
			return {
				...state,
				isGetSingleMeterReadingLoading,
				getSingleMeterReading,

			};
		},

		getMeterReadingHistoryRequest(
			state: MeterReadingState,
			action: PayloadAction<{
				isGetMeterReadingHistoryLoading: Partial<MeterReadingState['isGetMeterReadingHistoryLoading']>,
				getMeterReadingHistory: Partial<MeterReadingState['getMeterReadingHistory']>,
			}>,
		) {
			const {
				isGetMeterReadingHistoryLoading,
				getMeterReadingHistory,
			} = action.payload;
			return {
				...state,
				isGetMeterReadingHistoryLoading,
				getMeterReadingHistory,

			};
		},
	},
});

const {
	setState: _setState,
	getAllMeterReadingRequest: _getAllMeterReadingRequest,
	getSingleMeterReadingRequest: _getSingleMeterReadingRequest,
	getMeterReadingHistoryRequest: _getMeterReadingHistoryRequest,
	approveMeterReadingRequest: _approveMeterReadingRequest,
} = MeterReadingSlice.actions;

export const getAllMeterReadingRequest = (token: any, month?: string, district?: string): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getAllMeterReadingRequest({
		...initialMeterReadingState,
		isGetAllMeterReadingLoading: true,
	}));
	try {
		const apiResponseData = await API.getAllMeterReading(token, month, district);
		dispatch(_getAllMeterReadingRequest({
			isGetAllMeterReadingLoading: false,
			getAllMeterReading: apiResponseData?.data,
		}));
	} catch (error) {
		dispatch(_getAllMeterReadingRequest({
			...initialMeterReadingState,
			isGetAllMeterReadingLoading: false,
		}));
		dispatch(_setState<Partial<MeterReadingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get reading. Please try again.',
			},
		));
	}
};

export const approveMeterReadingRequest = (readingId: any, token: any): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_approveMeterReadingRequest({
		...initialMeterReadingState,
		isApproveMeterReadingLoading: true,
	}));
	try {
		const apiResponseData = await API.approveReading(readingId, token);
		dispatch(_approveMeterReadingRequest({
			isApproveMeterReadingLoading: false,
			approveMeterReading: apiResponseData,
		}));
	} catch (error) {
		dispatch(_approveMeterReadingRequest({
			...initialMeterReadingState,
			isApproveMeterReadingLoading: false,
		}));
		dispatch(_setState<Partial<MeterReadingState>>(
			{
				error: error?.response?.data?.message
				?? error?.message
				?? 'Unable to approve reading. Please try again.',
			},
		));
	}
};

export const getSingleMeterReadingRequest = (
	readingId: any,
	token: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getSingleMeterReadingRequest({
		...initialMeterReadingState,
		isGetSingleMeterReadingLoading: true,
	}));
	try {
		const apiResponseData = await API.getSingleMeterReading(readingId, token);
		dispatch(_getSingleMeterReadingRequest({
			isGetSingleMeterReadingLoading: false,
			getSingleMeterReading: apiResponseData.data,
		}));
	} catch (error) {
		dispatch(_getSingleMeterReadingRequest({
			...initialMeterReadingState,
			isGetSingleMeterReadingLoading: false,
		}));
		dispatch(_setState<Partial<MeterReadingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get reading. Please try again.',
			},
		));
	}
};

export const getMeterReadingHistoryRequest = (
	customerId: any,
	token: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getMeterReadingHistoryRequest({
		...initialMeterReadingState,
		isGetMeterReadingHistoryLoading: true,
	}));
	try {
		const apiResponseData = await API.getMeterReadingHistory(customerId, token);
		dispatch(_getMeterReadingHistoryRequest({
			isGetMeterReadingHistoryLoading: false,
			getMeterReadingHistory: apiResponseData.data.reading,
		}));
	} catch (error) {
		dispatch(_getMeterReadingHistoryRequest({
			...initialMeterReadingState,
			isGetMeterReadingHistoryLoading: false,
		}));
		dispatch(_setState<Partial<MeterReadingState>>(
			{
				error: error?.response?.data?.message ?? error?.message ?? 'Unable to get history. Please try again.',
			},
		));
	}
};

export const { reducer: MeterReading } = MeterReadingSlice;
