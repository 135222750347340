export type UserState = {
	isGetPaymentReceiptLoading: boolean;
	getPaymentReceipt: null | string;
	isGetSinglePaymentDetailsloading: boolean;
	getSinglePaymentDetails: null | string;
	isGetUserBillPaymentHistory: boolean;
	getUserBillPaymentHistory: null | string;
	getDisconnectionList: null|object;
	isGetDisconnectionListLoading: boolean;
	getDebtStatus: null|object;
	isGetDebtStatusLoading: boolean;
	getSalesReport:null|object;
	isGetSalesReportLoading:boolean;
	printDisconnectionList: null | object;
	isPrintDisconnectionListLoading: boolean;
	isGetVendingSalesReportLoading: boolean;
	getVendingSalesReport: null | object;
	error?: null | object;
}

export const initialUserState: UserState = {
	isGetPaymentReceiptLoading: false,
	getPaymentReceipt: null,
	isGetSinglePaymentDetailsloading: false,
	getSinglePaymentDetails: null,
	isGetUserBillPaymentHistory: false,
	getUserBillPaymentHistory: null,
	isGetDisconnectionListLoading: false,
	getDisconnectionList: null,
	getDebtStatus: null,
	isGetDebtStatusLoading: false,
	getSalesReport: null,
	isGetSalesReportLoading: false,
	printDisconnectionList: null,
	isPrintDisconnectionListLoading: false,
	isGetVendingSalesReportLoading: false,
	getVendingSalesReport: null,
};
