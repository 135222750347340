/* eslint-disable react/jsx-filename-extension */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { OutlinedInput, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	confirmPasswordRequestOTP,
	clearCache,
} from '../../../Store';

export default function VerifyCodeForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const Authentication = useSelector((state) => state.Authentication);
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		if (Authentication.confirmPasswordRequestOTP) {
			navigate('/new-password');
		}

		if (Authentication.error) {
			navigate('/forgot-password');
			enqueueSnackbar(Authentication.error, {
				variant: 'error',
			});
			dispatch(clearCache());
		}
	}, [Authentication.error, Authentication.confirmPasswordRequestOTP]);

	const VerifyCodeSchema = Yup.object().shape({
		code1: Yup.string().required('Code is required'),
		code2: Yup.string().required('Code is required'),
		code3: Yup.string().required('Code is required'),
		code4: Yup.string().required('Code is required'),
		code5: Yup.string().required('Code is required'),
		code6: Yup.string().required('Code is required'),
	});

	const defaultValues = {
		code1: '',
		code2: '',
		code3: '',
		code4: '',
		code5: '',
		code6: '',
	};

	const {
		watch,
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(VerifyCodeSchema),
		defaultValues,
	});

	const values = watch();

	const onSubmit = async (data) => {
		const codes = Object.values(data).reduce((a, b) => `${a}${b}`);
		dispatch(confirmPasswordRequestOTP(codes.toString()));
	};

	const handleChangeWithNextField = (event, handleChange) => {
		const { maxLength, value, name } = event.target;
		const fieldIndex = name.replace('code', '');

		const fieldIntIndex = Number(fieldIndex);

		if (value.length >= maxLength) {
			if (fieldIntIndex < 6) {
				const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

				if (nextfield !== null) {
					nextfield.focus();
				}
			}
		}
		handleChange(event);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack direction="row" spacing={2} justifyContent="center">
				{Object.keys(values).map((name, index) => (
					<Controller
						key={name}
						name={`code${index + 1}`}
						control={control}
						render={({ field }) => (
							<OutlinedInput
								{...field}
								id="field-code"
								autoFocus={index === 0}
								placeholder="-"
								onChange={(event) => handleChangeWithNextField(event, field.onChange)}
								inputProps={{
                  maxLength: 1,
                  sx: {
                    p: 0,
                    textAlign: 'center',
                    width: { xs: 36, sm: 56 },
                    height: { xs: 36, sm: 56 },
                  },
                }}
							/>
            )}
					/>
        ))}
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				loading={Authentication.isConfirmPasswordRequestOTPLoading}
				disabled={!isValid}
				sx={{ mt: 3 }}
			>
				Verify
			</LoadingButton>
		</form>
	);
}
