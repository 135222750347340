export type TransactionState = {
	isGetAllTransactionsLoading: boolean;
	getAllTransactions: null | object;
	isGetUncompletedTransactionsLoading: boolean;
	getUncompletedTransactions: null | object;
	error?: null | object;
}

export const initialTransactionState: TransactionState = {
	isGetAllTransactionsLoading: false,
	getAllTransactions: null,
	isGetUncompletedTransactionsLoading: false,
	getUncompletedTransactions: null,
};
