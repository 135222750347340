/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import {
	addDoc, collection, doc, query, orderBy,
} from '@firebase/firestore';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import {
	Card,
	Container,
	styled,
	Box,
	Divider,
	Button,
	InputBase,
	Stack,
} from '@mui/material';
import * as React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { db } from '../Firebase';
import Page from '../components/Page';
import ChatContent from '../sections/@dashboard/chat/ChatContent';

const RootStyleWrite = styled('div')(({ theme }) => ({
	minHeight: 56,
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
}));

const MessageInputWrapper = styled(InputBase)(
	({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`,
);

const Input = styled('input')({
	display: 'none',
});

const ChatWindow = styled(Box)(
	() => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
		`,
);

function OpenedReportChart({ user, getSingleReport }) {
	const { id, channelId } = useParams();

	const q = query(collection(db, `chats/${channelId}/messages`), orderBy('timestamp'));
	const [messages] = useCollectionData(q);
	const bottomOfChat = React.useRef();

	const [writtenMessage, setWrittenMessage] = React.useState('');

	const sendMessage = async (e) => {
		e.preventDefault();
		await addDoc(collection(db, `chats/${channelId}/messages`), {
			sender: {
				fullname: user.fullname,
				id: user.id,
				email: user.email,
			},
			message: writtenMessage,
			timestamp: new Date(),
		});
		setWrittenMessage('');
	};

	return (
		<Page title="Chat">
			<Container>
				<Card sx={{ height: '72vh' }}>
					<ChatWindow>
						<Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
							<Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
								<Stack sx={{ flexGrow: 1 }}>
									<ChatContent user={user} messages={messages} channelId={channelId} />
									<Divider />
									<RootStyleWrite>
										<Box flexGrow={1} display="flex" alignItems="center">
											<MessageInputWrapper
												autoFocus
												placeholder="Write your message here..."
												value={writtenMessage}
												onChange={(e) => setWrittenMessage(e.target.value)}
												fullWidth
											/>
										</Box>
										<div ref={bottomOfChat} />
										<Box>
											<Input accept="image/*" id="messenger-upload-file" type="file" />
											<Button
												startIcon={<SendTwoToneIcon />}
												variant="contained"
												onClick={sendMessage}
											>
												Send
											</Button>
										</Box>
									</RootStyleWrite>
								</Stack>
							</Box>
						</Stack>
					</ChatWindow>
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Control }) => {
	const { user } = Authentication;
	const { getSingleReport } = Control;
	return { user, getSingleReport };
};

export default connect(mapStateToProps)(OpenedReportChart);
