export type LogsState = {
	isGetAllLogsLoading: boolean;
	getAllLogs: null | object;
	error?: null | object;
}

export const initialLogsState: LogsState = {
	isGetAllLogsLoading: false,
	getAllLogs: null,
};
