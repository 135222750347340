/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getVendingSalesReport = async (token: any, query:{month, year}):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			month: query.month,
			year: query.year,
		},
	};

	const response = await axios.get('/analytics/vending-report', config);
	return response.data;
};
