/* eslint-disable camelcase */
import axios from '../../../../configs/Axios';

export const approveReading = async (readingId: string, token: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.put(`meter-reading/approve/${readingId}`, {}, config);

	return response.data;
};
