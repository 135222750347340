/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const getAllLogs = async (token: any, type?: string|undefined):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const url = (type !== 'All' && type) ? `/logs?type=${type}&page=1&pageSize=30000` : '/logs?page=1&pageSize=30000';

	const response = await axios.get(url, config);
	return response.data;
};
