export type ConsumerManagementState = {
	isGetAllNescoConsumerAccountsLoading: boolean;
	getAllNescoConsumerAccounts: null | object;
	isGetAllPUBConsumerAccountsLoading: boolean;
	getAllPUBConsumerAccounts: null | object;
	isGetSingleConsumerLoading: boolean;
	getSingleConsumerAccounts: null | object;
	isCreateConsumerAccountLoading: boolean;
	createConsumerAccount: null | object;
	isGetDistrictsLoading: boolean;
	getDistricts: null | object;
	isGetAccountStatusesLoading: boolean;
	getAccountStatuses: null | object;
	isGetMeterStatusesValuesLoading: boolean;
	getMeterStatusesValues: null | object;
	isEditConsumerAccountLoading: boolean,
	editConsumerAccount: null | object,
	isGetConsumerAccountStatementLoading: boolean,
	getConsumerAccountStatement: null|object,
	isUpdateLogsLoading: boolean,
	getUpdateLogs: null | object,
	isReconciliationUpdating: boolean,
	updateReconciliation: null | object,
	error?: null | object;
}

export const initialConsumerManagementState: ConsumerManagementState = {
	isGetAllNescoConsumerAccountsLoading: false,
	getAllNescoConsumerAccounts: null,
	isGetAllPUBConsumerAccountsLoading: false,
	getAllPUBConsumerAccounts: null,
	isGetSingleConsumerLoading: false,
	getSingleConsumerAccounts: null,
	isCreateConsumerAccountLoading: false,
	createConsumerAccount: null,
	isGetDistrictsLoading: false,
	getDistricts: null,
	isGetAccountStatusesLoading: false,
	getAccountStatuses: null,
	isGetMeterStatusesValuesLoading: false,
	getMeterStatusesValues: null,
	isEditConsumerAccountLoading: false,
	editConsumerAccount: null,
	isGetConsumerAccountStatementLoading: false,
	getConsumerAccountStatement: null,
	isUpdateLogsLoading: false,
	getUpdateLogs: null,
	isReconciliationUpdating: false,
	updateReconciliation: null,
};
