import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as API from '../../../API';
import { AppDispatch, AppThunk } from '../../Store';
import { messageToDisplay } from '../../functions';
import { RecordBillPaymentState, initialRecordBillPaymentState } from './Types';

const RecordBillPaymentSlice = createSlice({
	name: 'RecordBillPaymentSlice',
	initialState: initialRecordBillPaymentState,
	reducers: {
		setState(state: RecordBillPaymentState, action: PayloadAction<Partial<RecordBillPaymentState>>) {
			return state = {
				...state,
				...action.payload,
			};
		},
		recordBillPaymentRequest(
			state: RecordBillPaymentState,
			action: PayloadAction<{
				isRecordBillPaymentLoading: Partial<RecordBillPaymentState['isRecordBillPaymentLoading']>,
				recordBillPayment: Partial<RecordBillPaymentState['recordBillPayment']>,
				error?: Partial<RecordBillPaymentState['error']> | undefined,
			}>,
		) {
			const {
				isRecordBillPaymentLoading,
				recordBillPayment,
				error,
			} = action.payload;
			return {
				...state,
				isRecordBillPaymentLoading,
				recordBillPayment,
				error,
			};
		},
		getPaymentCategoriesRequest(
			state: RecordBillPaymentState,
			action: PayloadAction<{
				isGetPaymentCategoriesLoading: Partial<RecordBillPaymentState['isGetPaymentCategoriesLoading']>,
				getPaymentCategories: Partial<RecordBillPaymentState['getPaymentCategories']>,
				error?: Partial<RecordBillPaymentState['error']> | undefined,
			}>,
		) {
			const {
				isGetPaymentCategoriesLoading,
				getPaymentCategories,
				error,
			} = action.payload;
			return {
				...state,
				isGetPaymentCategoriesLoading,
				getPaymentCategories,
				error,
			};
		},
		getUserByAccountNumberRequest(
			state: RecordBillPaymentState,
			action: PayloadAction<{
				isGetUserByAccountNumberLoading: Partial<RecordBillPaymentState['isGetUserByAccountNumberLoading']>,
				getUserByAccountNumber: Partial<RecordBillPaymentState['getUserByAccountNumber']>,
				error?: Partial<RecordBillPaymentState['error']> | undefined,
			}>,
		) {
			const {
				isGetUserByAccountNumberLoading,
				getUserByAccountNumber,
				error,
			} = action.payload;
			return {
				...state,
				isGetUserByAccountNumberLoading,
				getUserByAccountNumber,
				error,
			};
		},
		clearCustomRecordBillPaymentCache(
			state: RecordBillPaymentState,
		) {
			return {
				...state,
				recordBillPayment: null,
			};
		},
	},
});

const {
	setState: _setState,
	recordBillPaymentRequest: _recordBillPaymentRequest,
	getPaymentCategoriesRequest: _getPaymentCategoriesRequest,
	getUserByAccountNumberRequest: _getUserByAccountNumberRequest,
	clearCustomRecordBillPaymentCache: _clearCustomRecordBillPaymentCache,
} = RecordBillPaymentSlice.actions;

export const recordBillPaymentRequest = (
	faultReportData: any,
	token: string,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_recordBillPaymentRequest({
		...initialRecordBillPaymentState,
		isRecordBillPaymentLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.recordBillPayment(faultReportData, token);
		dispatch(_recordBillPaymentRequest({
			isRecordBillPaymentLoading: false,
			recordBillPayment: apiResponseData,
		}));
	} catch (error) {
		dispatch(_recordBillPaymentRequest({
			...initialRecordBillPaymentState,
			isRecordBillPaymentLoading: false,
		}));
		dispatch(_setState<Partial<RecordBillPaymentState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to record bill payment. Please try again.',
			},
		));
	}
};

export const getPaymentCategoriesRequest = (
	token: string,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getPaymentCategoriesRequest({
		...initialRecordBillPaymentState,
		isGetPaymentCategoriesLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.getPaymentCategories(token);
		dispatch(_getPaymentCategoriesRequest({
			isGetPaymentCategoriesLoading: false,
			getPaymentCategories: apiResponseData.data,
		}));
	} catch (error) {
		dispatch(_getPaymentCategoriesRequest({
			...initialRecordBillPaymentState,
			isGetPaymentCategoriesLoading: false,
		}));
		dispatch(_setState<Partial<RecordBillPaymentState>>(
			{
				error: messageToDisplay(error) ?? 'Unable to get payment categories. Please try again.',
			},
		));
	}
};

export const getUserByAccountNumberRequest = (
	token: string,
	accountNumber: any,
): AppThunk => async (dispatch: AppDispatch) => {
	dispatch(_getUserByAccountNumberRequest({
		...initialRecordBillPaymentState,
		isGetUserByAccountNumberLoading: true,
		error: null,
	}));
	try {
		const apiResponseData = await API.getUsersByAccountNumber(token, accountNumber);
		dispatch(_getUserByAccountNumberRequest({
			isGetUserByAccountNumberLoading: false,
			getUserByAccountNumber: apiResponseData.data?.users[0],
		}));
	} catch (error) {
		dispatch(_getUserByAccountNumberRequest({
			...initialRecordBillPaymentState,
			isGetUserByAccountNumberLoading: false,
		}));
		dispatch(_setState<Partial<RecordBillPaymentState>>(
			{
				error: messageToDisplay(error) ?? 'User not found.',
			},
		));
	}
};

export const clearCustomRecordBillPaymentCache = (): AppThunk => (dispatch: AppDispatch) => {
	dispatch(_clearCustomRecordBillPaymentCache());
};

export const { reducer: RecordBillPayment } = RecordBillPaymentSlice;
