/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	Button,
	Container,
	Typography,
	Box,
} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';

import { getSingleBroadcastRequest, deleteBroadcastRequest } from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import { BroadcastEditForm } from '../sections/@dashboard/broadcast';

function BroadcastSingle({
	token,
	user,
	getSingleBroadcast,
	isGetSingleBroadcastLoading,
	getSingleBroadcastRequest,
	isDeleteBroadcastLoading,
	deleteBroadcast,
	deleteBroadcastRequest,
	editBroadcast,
	isEditBroadcastLoading,
}) {
	const [editView, setEditView] = React.useState(false);
	const [editId, setEditId] = React.useState(null);
	const [deleteId, setDeleteId] = React.useState(null);

	const { broadcastId } = useParams();

	const navigate = useNavigate();

	React.useEffect(() => {
		if (token) {
			getSingleBroadcastRequest(token, broadcastId);
		}
	}, [token]);

	React.useEffect(() => {
		if (editBroadcast) {
			getSingleBroadcastRequest(token, broadcastId);
			setEditView(false);
		}
	}, [editBroadcast]);

	const handleDelete = (id) => {
		setDeleteId(id);
		deleteBroadcastRequest(token, id);
	};
	const handleSetEditView = (id) => {
		setEditView(true);
		setEditId(id);
	};
	const handleCloseEditDialog = () => setEditView(false);

	if (deleteBroadcast) {
		navigate('/dashboard/admin/notification/list', { replace: true });
	}

	return (
		<Page title="User">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Box>
						<Typography variant="h4" gutterBottom>
							{getSingleBroadcast?.title}
						</Typography>
					</Box>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Button
							variant="contained"
							component={RouterLink}
							to="/dashboard/admin/notification/list"
							startIcon={<Iconify icon="eva:plus-fill" />}
						>
							View All
						</Button>
						{
							((user?.role !== 'monitoring_officer') || (user?.role !== 'auditor')) && (
								<LoadingButton
									sx={{ ml: 2 }}
									type="submit"
									variant="outlined"
									color="success"
									startIcon={<Iconify icon="bxs:edit" />}
									onClick={() => handleSetEditView(broadcastId)}
								>
									Edit
								</LoadingButton>
							)
						}
						<LoadingButton
							sx={{ ml: 2 }}
							type="submit"
							variant="outlined"
							color="error"
							loading={isDeleteBroadcastLoading && broadcastId === deleteId}
							startIcon={<Iconify icon="ep:delete-filled" />}
							onClick={() => handleDelete(broadcastId)}
						>
							Delete
						</LoadingButton>
					</Stack>

				</Stack>
				<Box sx={{ p: 2 }}>
					{isGetSingleBroadcastLoading ? <Loader /> : (
						<Box>
							{ getSingleBroadcast?.body}
							{
                                editView && (
                                    <BroadcastEditForm
	view={editView}
	id={editId}
	details={getSingleBroadcast}
	handleCloseEditDialog={handleCloseEditDialog}
	isEditBroadcastLoading={isEditBroadcastLoading}
                                    />
                                )
                            }
						</Box>
                    )}
				</Box>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Notification }) => {
	const { token, user } = Authentication;
	const {
		isGetSingleBroadcastLoading,
		getSingleBroadcast,
		isDeleteBroadcastLoading,
		editBroadcast,
		deleteBroadcast,
		isEditBroadcastLoading,
	} = Notification;

	return {
		token,
		user,
		isGetSingleBroadcastLoading,
		getSingleBroadcast,
		isDeleteBroadcastLoading,
		deleteBroadcast,
		editBroadcast,
		isEditBroadcastLoading,
	};
};

export default connect(mapStateToProps, {
	getSingleBroadcastRequest,
	deleteBroadcastRequest,
})(BroadcastSingle);
