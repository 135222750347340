/* eslint-disable react/jsx-filename-extension */
// component
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

// const rolesWithPermissions = {
// 	admin: {
// 		userManagement: {
// 			list: [],
// 			create: [],
// 		},
// 		notification: {
// 			list: [],
// 			create: [],
// 		},

// 	},
// 	billing: {
// 		meterReading: {
// 			allReading: [],
// 		},
// 		tariffs: {
// 			allTariffs: [],
// 		},
// 	},
// 	customerSupport: {
// 		faultReporting: {
// 			AllOpenedFaults: [],
// 		},
// 		ReportManagement: {
// 			allTitles: [],
// 			createTitle: [],
// 		},
// 	},
// 	accounts: {
// 		Transactions: {
// 			allTransactions: [],
// 			unTransactions: [],
// 		},
// 	},
// };

const navConfig = [
	{
		subheader: 'General',
		items: [
			{
				title: 'Dashboard',
				path: '/dashboard/home',
				icon: getIcon('clarity:home-line'),
				roles: ['superadmin', 'auditor'],
			},
		],
		roles: ['superadmin', 'auditor', 'monitoring_officer'],
	},
	{
		subheader: 'Admin',
		items: [
			{
				title: 'User Management',
				path: '/dashboard/admin/user-management',
				icon: getIcon('eva:people-fill'),
				children: [
					{
						title: 'list',
						path: 'admin/user-management/list',
						roles: [
							'control',
							'superadmin', 'auditor',
							'meter_reader',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'monitoring_officer',
						],
					},
					{
						title: 'create',
						path: 'admin/user-management/create',
						roles: ['superadmin', 'auditor'],
					},
				],
				roles: [
					'control',
					'superadmin', 'auditor',
					'meter_reader',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'monitoring_officer',
				],
			},
			{
				title: 'Notification',
				path: '/dashboard/admin/notification',
				icon: getIcon('ant-design:notification-filled'),
				children: [
					{
						title: 'list',
						path: 'admin/notification/list',
						roles: [
							'superadmin', 'auditor',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'monitoring_officer',
						],
					},
					{
						title: 'create',
						path: 'admin/notification/create',
						roles: [
							'superadmin', 'auditor',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'monitoring_officer',
				],
			},
			{
				title: 'Logs',
				path: '/dashboard/admin/logs',
				icon: getIcon('fontisto:list-2'),
				children: [
					{
						title: 'all logs',
						path: 'admin/logs/list',
						roles: [
							'superadmin', 'auditor',
							'managing_director',
							// 'chief_engineer_utilities',
							// 'assistant_chief_engineer_services',
							// 'monitoring_officer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'managing_director',
					// 'chief_engineer_utilities',
					// 'assistant_chief_engineer_services',
					// 'monitoring_officer',
				],
			},
		],
		roles: [
			'control',
			'superadmin', 'auditor',
			'meter_reader',
			'managing_director',
			'chief_engineer_utilities',
			'assistant_chief_engineer_services',
			'monitoring_officer',
		],
	},
	{
		subheader: 'Billing',
		items: [
			{
				title: 'Meter Reading',
				path: '/dashboard/billing/meter-reading',
				icon: getIcon('fluent:reading-mode-mobile-24-filled'),
				children: [
					{
						title: 'all readings',
						path: '/dashboard/billing/meter-reading/all-readings',
						roles: [
							'superadmin', 'auditor',
							'revenue_officer',
							'cashier',
							'meter_reader',
							'billing_officer',
							'monitoring_officer',
						],
					},
				],
				roles: ['superadmin',
					'auditor', 'cashier', 'meter_reader', 'revenue_officer', 'billing_officer', 'monitoring_officer'],
			},
			{
				title: 'Bill Records',
				path: '/dashboard/billing/bill-records',
				icon: getIcon('vaadin:records'),
				children: [
					{
						title: 'meter reading report',
						path: '/dashboard/billing/bill-records/meter-reading-report',
						roles: [
							'superadmin', 'auditor',
							'cashier',
							'meter_reader',
							'billing_officer',
							'monitoring_officer',
						],
					},
					{
						title: 'preview bills',
						path: '/dashboard/billing/bill-records/preview-bills',
						roles: [
							'superadmin', 'auditor',
							'cashier',
							'meter_reader',
							'billing_officer',
							'monitoring_officer',
						],
					},
					{
						title: 'print all bills',
						path: '/dashboard/billing/bill-records/print-receipts',
						roles: [
							'superadmin', 'auditor',
							'cashier',
							'meter_reader',
							'billing_officer',
							'monitoring_officer',
						],
					},
				],
				roles: ['superadmin', 'auditor', 'cashier', 'meter_reader', 'billing_officer', 'monitoring_officer'],
			},
			{
				title: 'Tariff',
				path: '/dashboard/billing/tariff',
				icon: getIcon('fa-solid:money-check'),
				children: [
					{
						title: 'all tariffs',
						path: '/dashboard/billing/tariff/all-tariffs',
						roles: ['superadmin', 'auditor', 'cashier', 'billing_officer', 'monitoring_officer'],
					},
				],
				roles: ['superadmin', 'auditor', 'cashier', 'billing_officer', 'monitoring_officer'],
			},
		],
		roles: [
			'superadmin',
			'auditor', 'cashier', 'meter_reader', 'revenue_officer', 'billing_officer', 'monitoring_officer',
		],
	},
	{
		subheader: 'Customer Support',
		items: [
			{
				title: 'Fault Reports',
				path: '/dashboard/customer-support/fault-reports',
				icon: getIcon('eva:alert-triangle-fill'),
				children: [
					{
						title: 'all opened faults',
						path: '/dashboard/customer-support/fault-reports/all-opened',
						roles: [
							'superadmin', 'auditor',
							'control',
							'customer_care',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'monitoring_officer',
							'billing_officer',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
					{
						title: 'submit fault report',
						path: '/dashboard/customer-support/fault-reports/submit-report',
						roles: [
							'superadmin', 'auditor',
							'control',
							'customer_care',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'monitoring_officer',
							'billing_officer',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'customer_care',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'monitoring_officer',
					'billing_officer',
					'revenue_officer',
					'area_distribution_engineer',
				],
			},
			{
				title: 'Report Management',
				path: '/dashboard/customer-support/report-title',
				icon: getIcon('eos-icons:cluster-management'),
				children: [
					{
						title: 'all titles',
						path: '/dashboard/customer-support/report-title/all',
						roles: [
							'superadmin', 'auditor',
							'control',
							'customer_care',
							'monitoring_officer',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
					{
						title: 'create title',
						path: '/dashboard/customer-support/report-title/create',
						roles: [
							'superadmin', 'auditor',
							'control',
							'customer_care',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'customer_care',
					'monitoring_officer',
					'revenue_officer',
					'area_distribution_engineer',
				],
			},
		],
		roles: [
			'superadmin', 'auditor',
			'control',
			'customer_care',
			'managing_director',
			'chief_engineer_utilities',
			'assistant_chief_engineer_services',
			'monitoring_officer',
			'revenue_officer',
			'area_distribution_engineer',
		],
	},
	{
		subheader: 'Accounts',
		items: [
			{
				title: 'Transactions',
				path: '/dashboard/accounts/transactions',
				icon: getIcon('fa-solid:money-check'),
				children: [
					{
						title: 'all transactions',
						path: '/dashboard/accounts/transactions/all-transactions',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'monitoring_officer',
							'customer_care',
							// 'billing_officer',
						],
					},
					{
						title: 'uncompleted transactions',
						path: '/dashboard/accounts/transactions/uncompleted-transactions',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'monitoring_officer',
							// 'billing_officer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'accounts',
					'monitoring_officer',
					'customer_care',
					// 'billing_officer',
				],
			},
			{
				title: 'Consumer Management',
				path: '/dashboard/accounts/consumer-management',
				icon: getIcon('carbon:user-avatar-filled'),
				children: [
					{
						title: 'Create Consumer Account',
						path: '/dashboard/accounts/consumer-management/create',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							// 'revenue_officer',
							// 'area_distribution_engineer',
						],
					},
					{
						title: 'NESCO',
						path: '/dashboard/accounts/consumer-management/nesco',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'meter_engineer',
							'revenue_protection',
							'customer_care',
							'vendor',
							'monitoring_officer',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
					{
						title: 'PUB',
						path: '/dashboard/accounts/consumer-management/pub',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'meter_engineer',
							'revenue_protection',
							'customer_care',
							'vendor',
							'monitoring_officer',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'accounts',
					'billing_officer',
					'cashier',
					'meter_engineer',
					'revenue_protection',
					'customer_care',
					'vendor',
					'monitoring_officer',
					'revenue_officer',
					'area_distribution_engineer',
				],
			},
			{
				title: 'Bill Management',
				path: '/dashboard/accounts/bill',
				icon: getIcon('ri:bill-fill'),
				children: [
					{
						title: 'all bill records',
						path: '/dashboard/accounts/bill/all-records',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'monitoring_officer',
						],
					},
					{
						title: 'Record Bill Payment',
						path: '/dashboard/accounts/bill/record-bill-payment',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'cashier',
							// 'billing_officer',
							'monitoring_officer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'accounts',
					'billing_officer',
					'cashier',
					'monitoring_officer',
				],
			},
			{
				title: 'Distribution Network',
				path: '/dashboard/accounts/meter',
				icon: getIcon('material-symbols:controller-gen'),
				children: [
					{
						title: 'feeders list',
						path: '/dashboard/accounts/meter/feeders-list',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'monitoring_officer',
							'meter_engineer',
							'revenue_protection',
							'store',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
					{
						title: 'substations list',
						path: '/dashboard/accounts/meter/substations-list',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'monitoring_officer',
							'meter_engineer',
							'revenue_protection',
							'store', 'revenue_officer',
							'area_distribution_engineer',
						],
					},
					{
						title: 'create feeders and substations',
						path: '/dashboard/accounts/meter/create-feeders-substations',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'monitoring_officer', 'meter_engineer',
							'revenue_protection',
							'store',
							'revenue_officer',
							'area_distribution_engineer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'accounts',
					'monitoring_officer',
					'meter_engineer',
					'revenue_protection',
					'store',
					'revenue_officer',
					'area_distribution_engineer',
				],
			},
			{
				title: 'Reports & Analytics',
				path: '/dashboard/accounts/reports-analytics',
				icon: getIcon('ic:round-analytics'),
				children: [
					{
						title: 'Disconnection List',
						path: '/dashboard/accounts/reports-analytics/disconnection-list',
						roles: [
							'superadmin', 'auditor',
							'control',
							'customer_care',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'monitoring_officer',
							'revenue_officer',
						],
					},
					{
						title: 'Debt Statuses',
						path: '/dashboard/accounts/reports-analytics/debt-statuses',
						roles: [
							'superadmin', 'auditor',
							'control',
							'customer_care',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'monitoring_officer',
							'revenue_officer',
						],
					},
					{
						title: 'Sales Report',
						path: '/dashboard/accounts/reports-analytics/sales-report',
						roles: [
							'superadmin', 'auditor',
							'control',
							// 'customer_care',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'monitoring_officer',
							'revenue_officer',
						],
					},
					{
						title: 'Vending Sales Report',
						path: '/dashboard/accounts/reports-analytics/vending-sales-report',
						roles: [
							'superadmin', 'auditor',
							'control',
							// 'customer_care',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'monitoring_officer',
							'revenue_officer',
						],
					},
					{
						title: 'Prepaid Metering Report',
						path: '/dashboard/accounts/reports-analytics/prepaid-metering-report',
						roles: [
							'superadmin', 'auditor',
							'control',
							// 'customer_care',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'billing_officer',
							'cashier',
							'monitoring_officer',
							'revenue_officer',
						],
					},
				],
				roles: [
					'superadmin', 'auditor',
					'control',
					'customer_care',
					'vendor',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'accounts',
					'billing_officer',
					'cashier',
					'monitoring_officer',
					'store',
					'revenue_officer',
				],
			},
		],
		roles: [
			'superadmin', 'auditor',
			'control',
			'customer_care',
			'managing_director',
			'chief_engineer_utilities',
			'assistant_chief_engineer_services',
			'accounts',
			'billing_officer',
			'cashier',
			'meter_engineer',
			'revenue_protection',
			'revenue_officer',
			'monitoring_officer',
			'store',
			'area_distribution_engineer',
		],
	},
	{
		subheader: 'Settings',
		items: [
			{
				title: 'News',
				path: '/dashboard/settings/news',
				icon: getIcon('material-symbols:newspaper-rounded'),
				children: [
					{
						title: 'list',
						path: '/dashboard/settings/news/list',
						roles: [
							'media',
							'monitoring_officer',
						],
					},
					{
						title: 'create',
						path: '/dashboard/settings/news/create',
						roles: [
							'media',
						],
					},
				],
				roles: [
					'media',
					'monitoring_officer',
				],
			},
		],
		roles: [
			'media',
			'monitoring_officer',
		],
	},
	{
		subheader: 'Prepaid',
		items: [
			{
				title: 'Vending',
				path: '/dashboard/prepaid/vending',
				icon: getIcon('grommet-icons:vend'),
				children: [
					{
						title: 'add new meter',
						path: '/dashboard/prepaid/vending/add-meter',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'store',
						],
					},
					{
						title: 'all meters',
						path: '/dashboard/prepaid/vending/all-meters',
						roles: [
							'store',
							'revenue_officer',
							'area_distribution_engineer',
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'store',
							'monitoring_officer',
							'revenue_protection',
							'meter_engineer',
							'billing_officer',
							'customer_care',
							'vendor',
						],
					},
					{
						title: 'issue credit token',
						path: '/dashboard/prepaid/vending/issue-credit-token',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'customer_care',
							'vendor',
							'accounts',
						],
					},
					{
						title: 'token purchase history',
						path: '/dashboard/prepaid/vending/token-purchase-history',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'revenue_officer',
							'area_distribution_engineer',
							'customer_care',
							'vendor',
							'monitoring_officer',
							'billing_officer',
							'meter_engineer',
							'revenue_protection',
						],
					},
					{
						title: 'issue KCT',
						path: '/dashboard/prepaid/vending/issue-KCT',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'meter_engineer',
							'customer_care',
							'vendor',
						],
					},
					{
						title: 'issue tamper code/token',
						path: '/dashboard/prepaid/vending/issue-tamper-code-token',
						roles: [
							'superadmin', 'auditor',
							'control',
							'managing_director',
							'chief_engineer_utilities',
							'assistant_chief_engineer_services',
							'accounts',
							'meter_engineer',
						],
					},
				],
				roles: [
					'store',
					'superadmin', 'auditor',
					'control',
					'managing_director',
					'chief_engineer_utilities',
					'assistant_chief_engineer_services',
					'accounts',
					'meter_engineer',
					'revenue_protection',
					'customer_care',
					'vendor',
					'store',
					'monitoring_officer',
					'billing_officer',
					'revenue_officer',
					'area_distribution_engineer',
				],
			},
		],
		roles: [
			'store',
			'superadmin', 'auditor',
			'control',
			'managing_director',
			'chief_engineer_utilities',
			'assistant_chief_engineer_services',
			'accounts',
			'meter_engineer',
			'revenue_protection',
			'customer_care',
			'vendor',
			'store',
			'monitoring_officer',
			'billing_officer',
			'revenue_officer',
			'area_distribution_engineer',
		],
	},
];

export default navConfig;
