/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type UserData = {
	meter_number: string,
	account_number: string,
}
type RegisterUserResponse = {
	status: string,
	message: string,
	data: {
		account_id: number,
		phone: string
	}
}
export const registerUser = async (userData: UserData):
Promise<RegisterUserResponse> => {
	const response = await axios.post('/auth/register', userData);

	return response.data;
};
