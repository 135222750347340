/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Stack,
	TextField,
	Alert,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
	useDispatch,
	useSelector,
	registerUser,
} from '../../../Store';

export default function RegisterForm() {
	const dispatch = useDispatch();
	const Authentication = useSelector((state) => state.Authentication);
	const navigate = useNavigate();

	const RegisterSchema = Yup.object().shape({
		meter_number: Yup.string().min(3, 'Too Short!').max(20, 'Too Long!').required('meter number is required'),
		account_number: Yup.string().min(3, 'Too Short!').max(20, 'Too Long!').required('account number is required'),
	});

	const formik = useFormik({
		initialValues: {
			meter_number: '',
			account_number: '',
		},
		validationSchema: RegisterSchema,
		onSubmit: (values) => {
			dispatch(registerUser(values));
		},
	});

	const {
		errors, touched, handleSubmit, getFieldProps,
	} = formik;

	React.useEffect(() => {
		if (Authentication.token !== null) {
			navigate('/dashboard', { replace: true });
		}
	});

	if (Authentication.isRegistered) {
		navigate('/complete-registeration', { replace: true });
	}

	return (
		<>
			<motion.div
				initial={{ opacity: 0, marginBottom: 0 }}
				animate={{
				opacity: Authentication.error ? 1 : 0,
				marginBottom: Authentication.error ? 20 : 0,
				}}
			>
				<Alert severity="error" variant="filled">
					{Authentication.error && Authentication.error}
				</Alert>
			</motion.div>
			<FormikProvider value={formik}>
				<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<TextField
							fullWidth
							label="Meter number"
							{...getFieldProps('meter_number')}
							error={Boolean(touched.meter_number && errors.meter_number)}
							helperText={touched.meter_number && errors.meter_number}
						/>

						<TextField
							fullWidth
							label="Account Number"
							{...getFieldProps('account_number')}
							error={Boolean(touched.account_number && errors.account_number)}
							helperText={touched.account_number && errors.account_number}
						/>

						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							loading={Authentication.isRegisterLoading}
						>
							Register
						</LoadingButton>
					</Stack>
				</Form>
			</FormikProvider>
		</>
	);
}
