/* eslint-disable camelcase */
import axios from '../../configs/Axios';

type UserData = {
	email: string,
	password: string,
}

export const resetPassword = async (userData: UserData):
Promise<any> => {
	const response = await axios.put('/auth/password-reset', userData);

	return response.data;
};
