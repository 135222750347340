/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type BroadcastData = {
	title: string,
	body: string,
	isDraft?: boolean,
}

export const createBroadcast = async (token: string, broadcastData: BroadcastData):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.post('/notification/broadcast/create', broadcastData, config);

	return response.data;
};
