/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
	Box, Card, Grid, Stack, Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

// isGetMeterStatusesValuesLoading
// isGetDistrictsLoading
// isGetAccountStatusesLoading

import {
	useDispatch,
	useSelector,
	editConsumerAccountRequest,
	// getAllFeedersRequest,
	getAllTariffsRequest,
	getDistrictRequest,
	// getMeterStatusValuesRequest,
	// getAcountStatusesRequest,
} from '../../../Store';
import { Loader } from '../../../components/Loader';
import {
	FormProvider, RHFTextField, RHFSelect,
} from '../../../components/hook-form';

// const accountTypeValues = [
// 	'NES',
// 	'PUB',
// ];

// const meterTypeValues = [
// 	'POSTPAID',
// 	'PREPAID',
// ];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EditConsumerForm({
	view,
	details,
	handleCloseEditDialog,
	isEditConsumerAccountLoading,
}) {
	const dispatch = useDispatch();

	// const [accountStatuses, setAccountStatuses] = React.useState([]);
	// const [meterValues, setMeterStatusValues] = React.useState([]);

	const [district, setDistrict] = React.useState([]);
	// const [substations, setSubstation] = React.useState([]);
	const [tariffs, setTariff] = React.useState([]);
	// const [feeders, setFeeder] = React.useState([]);

	const ConsumerManagement = useSelector((state) => state.ConsumerManagement);
	const Authentication = useSelector((state) => state.Authentication);
	const Feeder = useSelector((state) => state.Feeder);
	const Tariff = useSelector((state) => state.Tariff);

	React.useEffect(() => {
		if (Authentication.token) {
			dispatch(getDistrictRequest(Authentication.token));
			// dispatch(getAllFeedersRequest(Authentication.token));
			dispatch(getAllTariffsRequest(Authentication.token));
			// dispatch(getMeterStatusValuesRequest(Authentication.token));
			// dispatch(getAcountStatusesRequest(Authentication.token));
		}
	}, [Authentication.token, details]);

	React.useEffect(() => {
		if (ConsumerManagement.getDistricts) {
			setDistrict([...ConsumerManagement.getDistricts]);
		}
		// if (Feeder.getAllFeeders) {
		// 	setFeeder([...Feeder.getAllFeeders]);
		// }
		if (Tariff.getAllTariffs) {
			setTariff([...Tariff.getAllTariffs]);
		}
	}, [
		ConsumerManagement.getDistricts,
		// Feeder.getAllFeeders,
		Tariff.getAllTariffs,
	]);

	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
		// account_name: Yup.string().required(),
		cur_low_reading: Yup.number().required(),
		units: Yup.number().required(),
		multiplying_factor: Yup.number().required(),
		tariff: Yup.string().required(),
		district: Yup.string().required(),
		// lat: Yup.number().required(),
		// long: Yup.number().required(),
		// phone: Yup.string().required(),
		// account_number: Yup.string().required(),
		// account_type: Yup.string().required(),
		// meter_type: Yup.string().required(),
		// res_address1: Yup.string().required(),
		// con_address1: Yup.string().required(),
		// meter_status: Yup.string().required(),
		// account_status: Yup.string().required(),
	});

	const defaultValues = useMemo(
		() => ({
			// account_name: details?.account_name ?? '',
			cur_low_reading: details?.cur_low_reading ?? '',
			units: details?.units ?? '',
			multiplying_factor: details?.multiplying_factor ?? '',
			tariff: details?.tariff ?? '',
			district: details?.district ?? '',
			lat: details?.latitude ?? '',
			long: details?.longitude ?? '',
			// phone: details?.phone ?? '',
			//
			// account_type: details?.account_type ?? 'NES',
			// account_type_number: details?.account_type_number ?? '',
			// meter_type: details?.meter_type ?? 'PREPAID',
			// postpaid_meter_number: details?.postpaid_meter_number ?? '',
			// prepaid_meter_number: details?.prepaid_meter_number ?? '',
			// class: details?.class ?? '',
			// category: details?.category ?? '',
			// res_address1: details?.res_address1 ?? '',
			// res_address2: details?.res_address2 ?? '',
			// res_address3: details?.res_address3 ?? '',
			// con_address1: details?.con_address1 ?? '',
			// con_address2: details?.con_address2 ?? '',
			// con_address3: details?.con_address3 ?? '',
			// payments: details?.payments ?? '',
			// total_vat: details?.total_vat ?? '',
			// meter_status: details?.meter_status ?? '',
			// account_status: details?.account_status ?? '',
			// account_number: details?.account_number ?? '',
		}),
		[
			details,
			// ConsumerManagement.getDistricts,
			ConsumerManagement.getAccountStatuses,
			// ConsumerManagement.getMeterStatusesValues,
		],
	);

	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
		// watch,
	} = methods;

	React.useEffect(() => {
		if (ConsumerManagement.editConsumerAccount) {
			reset();
			enqueueSnackbar('Edit success!');
		}

		if (ConsumerManagement.error) {
			enqueueSnackbar(ConsumerManagement.error, {
				variant: 'error',
			});
		}
	}, [ConsumerManagement.editConsumerAccount, ConsumerManagement.error]);

	// const watchFeeder = watch('feeder');

	// React.useEffect(() => {
	// 	if (feeders) {
	// 		const foundFeeder = feeders.find((d => d.slug === watchFeeder));
	// 		const watchedSubstations = foundFeeder?.substations ? foundFeeder.substations : [];
	// 		setSubstation(watchedSubstations);
	// 	}
	// }, [watchFeeder]);

	const isEditFormLoading = ConsumerManagement.isGetAccountStatusesLoading || Tariff.isGetAllTariffsLoading || Feeder.isGetAllFeedersLoading || ConsumerManagement.isGetDistrictsLoading;

	const onSubmit = async (data) => {
		const consumerAccountData = {
			...data,
			account_number: details?.account_number,
		};

		dispatch(editConsumerAccountRequest(Authentication.token, consumerAccountData));
	};

	return (
		<Dialog
			open={view}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseEditDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<Box>
				<DialogTitle>
					Edit
					{' '}
					{details[0]?.account_name}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Update the fields and click the buttons below to save or cancel your changes
					</DialogContentText>
				</DialogContent>
				{
					(ConsumerManagement.isGetAccountStatusesLoading || isEditFormLoading) ? <Loader />
						: (
							<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12}>
										<Card sx={{ p: 3 }}>
											<Box
												sx={{
												display: 'grid',
												columnGap: 2,
												rowGap: 3,
												gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
											}}
											>
												{/* <RHFTextField
													name="account_name"
													label="Account Name"
													helperText="Account Name is required"
												/> */}
												<RHFTextField
													name="cur_low_reading"
													type="number"
													label="Current low reading"
													helperText="Current low reading is required"
												/>
												<RHFTextField
													name="units"
													type="number"
													label="Units"
													helperText="Units is required"
												/>
												<RHFTextField
													name="multiplying_factor"
													type="number"
													label="Multiplying Factor"
													helperText="Multiplying Factor is required"
												/>
												<RHFSelect name="tariff" label="Tariff" placeholder="Tariff" helperText="Tariff is required">
													<option value="">{' '}</option>
													{tariffs?.map((option, id) => (
														<option key={id} value={option?.class}>
															{option?.class}
														</option>
													))}
												</RHFSelect>
												<RHFSelect name="district" label="District" placeholder="District" helperText="District is required">
													<option value="">{' '}</option>
													{district?.map((option, id) => (
														<option key={id} value={option?.code}>
															{option?.code.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
													))}
												</RHFSelect>
												<RHFTextField
													name="lat"
													label="Latitude"
												/>
												<RHFTextField
													name="long"
													label="Longitude"
												/>
												{/* <RHFSelect name="feeder" label="feeder" placeholder="feeder" helperText="Feeder is required">
													<option value="">{' '}</option>
													{feeders?.map((option, id) => (
														<option key={id} value={option?.slug}>
															{option?.name}
														</option>
													))}
												</RHFSelect>
												<RHFSelect
													name="substation"
													label="Substation"
													placeholder="Select substation"
													helperText="Substation is required"
												>
													<option value="">{' '}</option>
													{substations?.map((option) => (
														<option key={option?.name} value={option?.name}>
															{option?.name.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
													))}
												</RHFSelect> */}
												{/* <RHFTextField
													name="res_address1"
													label="Residential Address"
													helperText="Residential Address is required"
												/>
												<RHFTextField
													name="res_address2"
													label="Residential Address 2"
												/>
												<RHFTextField
													name="res_address3"
													label="Residential Address 3"
												/>
												<RHFTextField
													name="con_address1"
													label="Connection Address"
													helperText="Connection Address is required"
												/>
												<RHFTextField
													name="con_address2"
													label="Connection Address 2"
												/>
												<RHFTextField
													name="con_address3"
													label="Connection Address 3"
												/> */}

												{/* <RHFTextField
													name="phone"
													label="Phone Number"
													type="number"
													helperText="Phone Number is required"
												/>
												<RHFTextField
													name="account_number"
													label="Account Number"
													helperText="Acccount Number is required"
												/>
												<RHFSelect name="account_type" label="Account Type" placeholder="Account Type">
													{accountTypeValues.map((accountTypeValue) => (
														<option key={accountTypeValue} value={accountTypeValue}>
															{accountTypeValue.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
												))}
												</RHFSelect> */}
												{/* <RHFSelect name="district" label="District" placeholder="District" helperText="District is required">
													<option value="">{' '}</option>
													{district?.map((option, idx) => (
														<option key={idx} value={option?.code}>
															{option?.code.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
												))}
												</RHFSelect> */}
												{/* <RHFTextField
													name="account_type_number"
													label="Account Number Type"
												/>
												<RHFSelect name="meter_type" label="Meter Type" placeholder="Meter Type">
													{meterTypeValues.map((meterTypeValue) => (
														<option key={meterTypeValue} value={meterTypeValue}>
															{meterTypeValue.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
												))}
												</RHFSelect>
												<RHFTextField
													name="postpaid_meter_number"
													label="Postpaid Meter Number"
												/>
												<RHFTextField
													name="prepaid_meter_number"
													label="Prepaid Meter Number"
												/>
												<RHFTextField
													name="class"
													label="Class"
												/>
												<RHFTextField
													name="category"
													label="Category"
												/>

												<RHFTextField
													name="payments"
													label="Payments"
												/>
												<RHFTextField
													name="total_vat"
													label="Total Vat"
												/>
												<RHFTextField
													name="tariff"
													label="Tariff"
													helperText="Tariff is required"
												/>
												<RHFTextField
													name="feeder"
													label="Feeder"
												/>
												<RHFTextField
													name="substation"
													label="Substation"
												/> */}
												{/* <RHFSelect name="meter_status" label="Meter Status" placeholder="Meter Status" helperText="Meter Status is required">
													<option value="">{' '}</option>
													{meterValues?.map((option) => (
														<option key={option?.status} value={option?.status}>
															{option?.status.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
												))}
												</RHFSelect> */}
												{/* <RHFSelect name="account_status" label="Account Status" placeholder="Account Status" helperText="Acccount Status is required">
													<option value="">{' '}</option>
													{accountStatuses?.map((option) => (
														<option key={option?.status} value={option?.status}>
															{option?.status.replace(/[^a-zA-Z ]/g, ' ')}
														</option>
												))}
												</RHFSelect> */}
												{/* <RHFTextField
													disabled
													name="account_number"
													label="Account Number"
												/> */}
											</Box>
											<Stack alignItems="flex-end" sx={{ mt: 3 }}>
												<DialogActions>
													<Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
													<LoadingButton
														type="submit"
														variant="contained"
														disabled={isEditConsumerAccountLoading}
														loading={isEditConsumerAccountLoading}
													>
														Edit Consumer Account
													</LoadingButton>
												</DialogActions>
											</Stack>
										</Card>
									</Grid>
								</Grid>
							</FormProvider>
						)
				}
			</Box>
		</Dialog>
	);
}
