/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

type SubstationData = any

export const createSubstations = async (token: string, substationData: SubstationData):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const response = await axios.post('/meter/substations', substationData, config);

	return response.data;
};
