/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { LoadingButton } from '@mui/lab';
import {
	Card,
	Table,
	Stack,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	Box,
	Button,
} from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { connect } from 'react-redux';

import { getAllTariffsRequest, saveUpdatedTariffTempRequest, updateTarrifRequest } from '../Store';
import Iconify from '../components/Iconify';
import { Loader } from '../components/Loader';
import Page from '../components/Page';
import { TariffEditForm } from '../sections/@dashboard/tariff';
import { UserListHead } from '../sections/@dashboard/user';

dayjs.extend(advancedFormat);

const TABLE_HEAD = [
	{ id: 'class', label: 'Class', alignRight: false },
	{ id: 'rate', label: 'Rate', alignRight: false },
	{ id: 'incoming_rate', label: 'Incoming Rate', alignRight: false },
	{ id: 'is_rate_verified', label: 'Rate Verification Status', alignRight: false },
	{ id: 'createdAt', label: 'Date Created', alignRight: false },
	{ id: 'updatedAt', label: 'Updated Created', alignRight: false },
	{ id: '' },
];

function Tariffs({
	token,
	user,
	getAllTariffs,
	isGetAllTariffsLoading,
	getAllTariffsRequest,
	updateTariff,
	isUpdateTariffLoading,
	updateTarrifRequest,
	saveUpdatedTempTariffs,
	saveUpdatedTariffTempRequest,
}) {
	const [editView, setEditView] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState([]);
	const [temp, SetTemp] = React.useState([]);

	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		token && getAllTariffsRequest(token);
	}, [token]);

	React.useEffect(() => {
		if (updateTariff) {
			SetTemp([]);
			setEditView(false);
			setEditDetails({});
			getAllTariffsRequest(token);
		}
	}, [updateTariff]);

	React.useEffect(() => {
		if (temp) {
			saveUpdatedTariffTempRequest(temp);
		}
	}, [temp]);

	const loading = isGetAllTariffsLoading;

	const handleSetEditView = (id) => {
		setEditView(true);
		setEditDetails(getAllTariffs.filter(tariff => tariff.id === id));
	};
	const handleCloseEditDialog = () => setEditView(false);

	const handleSubmitData = async (data) => {
		const payload = {
			...data,
			isDraft: false,
		};

		if (!temp.length) {
			enqueueSnackbar('Tariff Updated!', { variant: 'success' });
			SetTemp([payload]);
			setEditView(false);
		}
		if (temp.length) {
			const isFound = temp.some(element => {
				if (element.class === data.class || element.rate === data.rate) {
					return true;
				}

				return false;
			});

			if (!isFound) {
				enqueueSnackbar('Tariff Updated!', { variant: 'success' });
				SetTemp(previous => [...previous, payload]);
				setEditView(false);
			}

			// if (isFound) {
			// 	const tt = temp.map(t => {
			// 		if (t.class === payload.class) {
			// 			t.rate = payload.rate;
			// 			t.class = payload.class;

			// 			return t;
			// 		}
			// 		return t;
			// 	});

			// 	console.log(tt);
			// }

			if (isFound) {
				enqueueSnackbar('Tariff already exist, change the fields to update.', {
					variant: 'error',
				});
			}
		}
	};

	const handleTariffUpdate = () => {
		const payload = {
			tariffs: saveUpdatedTempTariffs,
		};
		updateTarrifRequest(token, payload);
	};

	return (
		<Page title="Tariffs">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>
						All Tariffs
					</Typography>
					{
						saveUpdatedTempTariffs.length ? (
							<LoadingButton
								loading={isUpdateTariffLoading}
								variant="contained"
								startIcon={<Iconify icon="ant-design:save-filled" />}
								onClick={handleTariffUpdate}
							>
								Save Updated Tariffs
							</LoadingButton>
						) : ''
					}
				</Stack>
				<Card sx={{ pb: 2, pt: 2 }}>
					{
						loading ? <Loader />
						: getAllTariffs ? (
							<Box>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<UserListHead
											headLabel={TABLE_HEAD}
											rowCount={getAllTariffs?.length}
										/>
										<TableBody>
											{getAllTariffs.map((row) => {
														const {
															id,
															rate,
															incoming_rate,
															is_rate_verified,
															createdAt,
															updatedAt,
															} = row;
														return (
															<TableRow
																hover
																key={id}
																tabIndex={-1}
																role="checkbox"
															>
																<TableCell align="left">
																	<Typography variant="subtitle2">
																		{row.class}
																	</Typography>
																</TableCell>
																<TableCell align="left">{rate}</TableCell>
																<TableCell align="left">{incoming_rate}</TableCell>
																<TableCell align="left">{is_rate_verified}</TableCell>
																<TableCell align="left">{createdAt && dayjs(createdAt).format('DD/MM/YYYY')}</TableCell>
																<TableCell align="left">{updatedAt && dayjs(updatedAt).format('DD/MM/YYYY')}</TableCell>
																<TableCell align="left">
																	{
																		((user?.role !== 'monitoring_officer') || (user?.role !== 'auditor')) && (
																		<Button
																			type="submit"
																			variant="outlined"
																			color="info"
																			startIcon={<Iconify icon="bxs:edit" />}
																			onClick={() => handleSetEditView(id)}
																		>
																			Edit
																		</Button>
																		)
																	}
																</TableCell>
															</TableRow>
														);
												})}
										</TableBody>
									</Table>
								</TableContainer>
								{
									editView && (
										<TariffEditForm
											view={editView}
											handleSubmitData={handleSubmitData}
											details={editDetails[0]}
											handleCloseEditDialog={handleCloseEditDialog}
											isUpdateTariffLoading={isUpdateTariffLoading}
										/>
									)
								}
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Typography variant="h5" gutterBottom>
									No Tariffs Found
								</Typography>
							</Box>
						)
					}
				</Card>
			</Container>
		</Page>
	);
}

const mapStateToProps = ({ Authentication, Tariff }) => {
	const { token, user } = Authentication;
	const {
		isGetAllTariffsLoading, getAllTariffs, isUpdateTariffLoading, updateTariff, saveUpdatedTempTariffs,
	} = Tariff;
	return {
		token,
		user,
		isGetAllTariffsLoading,
		getAllTariffs,
		isUpdateTariffLoading,
		updateTariff,
		saveUpdatedTempTariffs,
	};
};

export default connect(mapStateToProps, {
	getAllTariffsRequest,
	saveUpdatedTariffTempRequest,
	updateTarrifRequest,
})(Tariffs);
