/* eslint-disable camelcase */
import axios from '../../../configs/Axios';

export const editConsumerAccount = async (token: string, accountData: any):
Promise<any> => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await axios.put('/user/update/consumer', accountData, config);

	return response.data;
};
